<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'10%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="GlossariesDialog">
      <div class="glo_top" v-if="showFlag === 1">
        <p
          :class="Operatortabs == '1' ? 'setbackground' : ''"
          @click="changeOperatortabs('1')"
        >
          {{ $t("ProjectManagement.STT1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '1'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '2' ? 'setbackground' : ''"
          @click="changeOperatortabs('2')"
        >
          {{ $t("ProjectManagement.TRA1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '2'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '3' ? 'setbackground' : ''"
          @click="changeOperatortabs('3')"
        >
          {{ $t("ProjectManagement.REV1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '3'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '5' ? 'setbackground' : ''"
          @click="changeOperatortabs('5')"
        >
          {{ $t("ProjectManagement.STQ1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '5'"
          ></i>
        </p>
      </div>
      <div class="glo_bottom" style="height: 275px">
        <div class="glo_bottom_top">
          <!-- <el-dropdown @command="commandClicks" trigger="click">
            <div class="buttondiv">
              <svg-icon icon-class="Edit"></svg-icon>
              {{ $t("ProjectManagement.Replace3") }}
            </div>
            <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
              <el-input
                v-model="searchText"
                :placeholder="$t('OverwriteOperation.Searchsource1')"
                class="iconp_input"
              ></el-input>
              <el-dropdown-item v-if="MemberOptions.length == 0" command="-1"
                >{{ $t("OverwriteOperation.warningmessage14") }}
              </el-dropdown-item>
              <el-dropdown-item
                v-for="item in filteredOptions"
                :key="item.member_id"
                :command="{ name: item.nickname, id: item.member_id }"
              >
                <span>{{ item.nickname }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-select
            v-model="selectUser"
            :placeholder="$t('CommonName.Pleaseselect1')"
            filterable
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in MemberOptions"
              :key="item.member_id"
              :label="item.nickname"
              :value="item.member_id"
            >
              <div class="selectContentClass">
                <p class="onep overflowEllipsis">
                  <span>{{ item.nickname }}</span>
                </p>
              </div>
            </el-option>
          </el-select>
          <el-checkbox v-model="NewFolderCheck">{{
            $t("PersonalEndLanguage.AssignText2")
          }}</el-checkbox>
          <el-select
            v-model="FolderSelect"
            :placeholder="$t('CommonName.Pleaseselect2')"
            filterable
            clearable
            v-show="!NewFolderCheck"
          >
            <el-option
              v-for="item in FolderList"
              :key="item.folder_id"
              :label="item.folder_name"
              :value="item.folder_id"
            >
              <div class="selectContentClass">
                <p class="onep overflowEllipsis">
                  <span>{{ item.folder_name }}</span>
                </p>
              </div>
            </el-option>
          </el-select>
          <el-input
            v-model="FolderName"
            :maxlength="60"
            :placeholder="$t('CommonName.Pleaseinput1')"
            v-show="NewFolderCheck"
          ></el-input>
        </div>
        <div
          class="tableClass borderTableStyle"
          :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: 'none',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="233px"
            border
          >
            <!--            @row-click="rowClick"
                 @selection-change="handleSelectionChange"
            :row-class-name="setRowClassName"
               -->
            <!--            <el-table-column type="selection" width="39"></el-table-column>-->
            <!-- <p>术语</p> -->
            <el-table-column
              width="400"
              show-overflow-tooltip
              prop="job_name"
              :label="$t('CommonName.TaskName')"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="nickname"
              :label="$t('ProjectManagement.Replace1')"
            >
              <template slot="header">
                <div class="tableheaderdiv">
                  <p class="fontSize12">
                    {{ $t("ProjectManagement.Replace1") }}
                  </p>
                  <div class="icondiv">
                    <el-dropdown
                      @command="Operationcommand"
                      trigger="click"
                      placement="bottom-end"
                    >
                      <span class="el-dropdown-link">
                        <svg-icon
                          icon-class="Filter"
                          class="iconsize"
                        ></svg-icon>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                        <el-dropdown-item :command="$t('dubbing.All')"
                          >{{ $t("dubbing.All") }}
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-for="item in setNickName"
                          :key="item.name"
                          :command="item.name"
                        >
                          <span>{{ item.name }}</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">{{ scope.row.nickname }}</template>
            </el-table-column>
            <el-table-column
              prop="newName"
              show-overflow-tooltip
              :label="$t('ProjectManagement.Replace2')"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          :loading="buttonLoading"
        >
          {{ $t("CommonName.Sure") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  get_member_list,
  get_processor_project_job_dubbing_list,
  set_processor_project_job_dubbing,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  name: "ReplaceExecutor",
  computed: {
    setNickName() {
      let nameArr = [];
      let unique = [];
      this.new_tableData.forEach((i) => {
        nameArr.push({
          name: i.nickname,
        });
      });
      const seen = new Set();
      for (const item of nameArr) {
        const itemKey = item.name;
        if (!seen.has(itemKey)) {
          seen.add(itemKey);
          unique.push(item);
        }
      }
      return unique;
    },

    // 筛选 任务执行人
    filteredOptions() {
      return this.MemberOptions.filter((option) =>
        option.nickname.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Number,
      default: 1,
    },
    multipleSelection: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {
    this.project_id = this.$route.query.id;
  },
  data() {
    return {
      searchText: "",
      title: "",
      dialogVisible: false,
      buttonLoading: false,
      project_id: "",
      MemberOptions: [],
      Operatortabs: "1",
      tableData: [],
      startrow: {},
      allTableData: [],
      new_tableData: [],
      ShiftDown: false,
      CtrlDown: false,
      selectUser: "",
      NewFolderCheck: false,
      FolderSelect: "",
      FolderName: "",
      FolderList: [],
    };
  },
  mounted() {
    // console.log(this.multipleSelection, "11111");
    let mode = "1";
    if (this.multipleSelection.length > 0) {
      let first_status = this.multipleSelection[0]["status"];
      this.multipleSelection.forEach((i) => {
        if (first_status != i.status) {
          first_status = "";
        }
      });
      if (first_status == "20") {
        this.Operatortabs = "1";
        mode = "1";
      } else if (first_status == "25") {
        this.Operatortabs = "5";
        mode = "5";
      } else if (first_status == "30") {
        this.Operatortabs = "2";
        mode = "2";
      } else if (first_status == "40") {
        this.Operatortabs = "3";
        mode = "3";
      }
    }

    this.dialogVisible = this.showdialog;
    this.title = this.$t("ProjectDetails.buttonList.name22");
    this.get_member_list();
    if (this.showFlag === 1) {
      this.get_processor_project_job_list(mode);
    } else {
      this.get_processor_project_job_dubbing_list();
    }
    // window.addEventListener("keyup", this.windowkeyup, true);
    // window.addEventListener("keydown", this.windowkeydown, true);
  },
  beforeDestroy() {
    // window.removeEventListener("keyup ", this.windowkeyup, true);
    // window.removeEventListener("keydown", this.windowkeydown, true);
  },

  methods: {
    windowkeyup(e) {
      if (e.keyCode === 16) this.ShiftDown = false;
      if (e.keyCode === 17) this.CtrlDown = false;
    },
    windowkeydown(e) {
      // console.log(e.keyCode);
      if (e.keyCode === 16 && !e.ctrlKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.ShiftDown = true;
      }
      if (e.keyCode === 17 && !e.shiftKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.CtrlDown = true;
      }
    },
    rowClick(row) {
      // console.log(row);
      // 按住shift
      if (this.ShiftDown) {
        this.allTableData.push(row);
        let data;
        if (this.startrow.index < row.index) {
          data = this.tableData.slice(this.startrow.index, row.index + 1);
        } else {
          data = this.tableData.slice(row.index, this.startrow.index + 1);
        }
        this.allTableData = data;
        return;
      }
      // 按住Ctrl
      if (this.CtrlDown) {
        let a = this.allTableData.find((i) => i.job_id === row.job_id);
        if (a) {
          this.allTableData.forEach((i, index) => {
            if (i.job_id === a.job_id) {
              this.allTableData.splice(index, 1);
            }
          });
          return;
        }
        this.allTableData.push(row);
        return;
      }
      //   什么都不按
      if (this.allTableData.length === 1) {
        let a = this.allTableData.find((i) => i.job_id === row.job_id);
        if (a) {
          this.allTableData = [];
          return;
        }
      }
      if (this.allTableData.length > 1) {
        let a = this.allTableData.find((i) => i.job_id === row.job_id);
        if (a) {
          this.startrow = {};
          this.allTableData = [];
          this.startrow = row;
          this.allTableData.push(row);
          return;
        }
      }
      this.startrow = {};
      this.allTableData = [];
      this.startrow = row;
      this.allTableData.push(row);
    },
    //下拉选项
    selectChange(value) {
      get_member_list({
        action: "get_member_folder_list",
        member_id: value,
      }).then((res) => {
        if (!res) {
          return;
        }
        //STT
        this.FolderSelect = "";
        this.FolderList = res.list;
      });
      if (this.tableData.length === 0) {
        return;
      }
      let findItem = this.MemberOptions.find((i) => i.member_id === value);
      if (findItem) {
        this.tableData.forEach((j) => {
          j.newName = findItem.nickname;
          j.member_id = findItem.member_id;
        });
      } else {
        this.tableData.forEach((j) => {
          j.newName = "";
          j.member_id = 0;
        });
      }
      this.$forceUpdate();
    },
    /**
     *
     * @param {*tab 页 comments 点击 高亮} param0
     */
    setRowClassName({ row }) {
      let arr = this.allTableData.find(
        (i) => i.job_id === row.job_id && i.job_name === row.job_name
      );
      if (arr) {
        // console.log(arr);
        return "success-row";
      }
      return "";
    },
    /**
     *
     * @param {*获取成员列表信息}
     */
    get_member_list() {
      get_member_list({
        action: "get_member_list",
        team_user_status: 2,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.MemberOptions = res.list;
      });
    },
    /**
     *
     * @param data
     */
    get_processor_project_job_list(mode) {
      let job_list = [];
      if (this.multipleSelection.length === 0) {
        job_list = [];
      } else {
        this.multipleSelection.forEach((i) => {
          job_list.push(i.job_id);
        });
      }
      return get_processor_project_job_dubbing_list({
        action: "get_processor_project_job_list",
        project_id: this.project_id,
        job_list,
        mode: 1,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.selectUser = "";
        this.NewFolderCheck = false;
        this.FolderSelect = "";
        this.FolderName = "";
        if (mode == "1") {
          this.tableData = res.stt_list;
        } else if (mode == "2") {
          this.tableData = res.tra_list;
        } else if (mode == "5") {
          this.tableData = res.sqt_list;
        } else {
          this.tableData = res.rev_list;
        }
        this.tableData.forEach((i, index) => {
          i.newName = "";
          i.member_id = "";
          i.index = index;
        });
        this.new_tableData = JSON.parse(JSON.stringify(this.tableData));
        hideLoading();
      });
    },
    /**
     * 配音任务列表
     * @param data
     */
    get_processor_project_job_dubbing_list() {
      let job_list = [];
      // console.log(this.multipleSelection);
      if (this.multipleSelection.length === 0) {
        job_list = [];
      } else {
        this.multipleSelection.forEach((i) => {
          job_list.push(i.job_dubbing_id);
        });
      }
      return get_processor_project_job_dubbing_list({
        action: "get_processor_project_job_dubbing_list",
        project_id: this.project_id,
        job_list,
        mode: 1,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.selectUser = "";
        this.NewFolderCheck = false;
        this.FolderSelect = "";
        this.FolderName = "";
        this.tableData = res.dub_list;
        this.tableData.forEach((i, index) => {
          i.newName = "";
          i.member_id = "";
          i.index = index;
          i.job_id = i.job_dubbing_id;
        });
        this.new_tableData = JSON.parse(JSON.stringify(this.tableData));
        hideLoading();
      });
    },
    /**
     * 弹窗 内部的 tab页切换
     */
    changeOperatortabs(str) {
      this.allTableData = [];
      this.startrow = {};
      this.Operatortabs = str;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.get_processor_project_job_list(str);
    },
    /**
     * 多选
     */
    handleSelectionChange(val) {
      this.allTableData = val;
    },
    /**
     *
     * @param comments
     */
    commandClicks(comments) {
      if (this.tableData.length === 0) {
        return;
      }
      // this.allTableData.forEach((i) => {
      this.tableData.forEach((j) => {
        // if (i.job_id === j.job_id) {
        j.newName = comments.name;
        j.member_id = comments.id;
        // }
      });
      // });

      // this.new_tableData.forEach((i) => {
      //   i.newName = comments.name;
      //   i.member_id = comments.id;
      // });
      this.$forceUpdate();
      // console.log(comments.name, comments.id);
    },
    /**
     * 筛选
     * @param comments
     * @constructor
     */
    Operationcommand(comments) {
      if (comments === this.$t("dubbing.All")) {
        this.tableData = this.new_tableData;
        return;
      }
      this.tableData = this.new_tableData.filter(
        (i) => i.nickname === comments
      );
    },
    /**
     * 取消按钮
     */
    closeDialog() {
      this.buttonLoading = false;
      this.dialogVisible = false;
      this.$emit("closeOperator", this.dialogVisible);
    },
    /**
     * 确定按钮
     */
    determineClick() {
      // console.log(this.tableData);
      // return;
      if (this.tableData.length === 0) {
        return;
      }
      // if (this.tableData.some((i) => !i.newName)) {
      //   return;
      // }

      // if (this.tableData.some((i) => !i.member_id)) {
      //   return;
      // }
      if (this.NewFolderCheck && this.FolderName.trim() == "") {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage47")
        );
        return;
      }
      let arr = [];
      this.tableData.forEach((item) => {
        arr.push(item.job_id);
      });
      let member_id =
        this.tableData[0].member_id == "" ? 0 : this.tableData[0].member_id;
      let action =
        this.showFlag === 1
          ? "set_processor_job_user"
          : "set_processor_dubbing_job_user";
      if (member_id == 0) {
        let title = "";
        title = this.$t("PersonalEndLanguage.warningmessage48");
        this.$confirm(title, this.$t("VideoDubbing.comfirmTips1"), {
          confirmButtonText: this.$t("VideoDubbing.comfirmTips2"),
          cancelButtonText: this.$t("VideoDubbing.comfirmTips3"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: false,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            this.buttonLoading = true;
            showLoading(this.$t("CommonName.loadingmessage"));
            set_processor_project_job_dubbing({
              action,
              job_ids: arr,
              workflow: this.Operatortabs,
              member_id: member_id,
              folder_mode: this.NewFolderCheck ? 2 : 1,
              folder_id: this.FolderSelect,
              folder_name: this.FolderName,
            }).then((res) => {
              if (!res) {
                this.buttonLoading = false;
                hideLoading();
                return;
              }
              hideLoading();
              this.dialogVisible = false;
              this.$emit("clickOperator", this.dialogVisible);
              this.buttonLoading = false;
            });
          })
          .catch((action) => {});
      } else {
        this.buttonLoading = true;
        showLoading(this.$t("CommonName.loadingmessage"));
        set_processor_project_job_dubbing({
          action,
          job_ids: arr,
          workflow: this.Operatortabs,
          member_id: member_id,
          folder_mode: this.NewFolderCheck ? 2 : 1,
          folder_id: this.FolderSelect,
          folder_name: this.FolderName,
        }).then((res) => {
          if (!res) {
            this.buttonLoading = false;
            hideLoading();
            return;
          }
          hideLoading();
          this.dialogVisible = false;
          this.$emit("clickOperator", this.dialogVisible);
          this.buttonLoading = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;
    position: relative;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .glo_bottom {
    margin-top: 22px;
    height: 233px;

    .glo_bottom_top {
      margin-bottom: 9px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      ::v-deep .el-checkbox {
        color: #adb0bc;
      }
      ::v-deep .el-input {
        width: auto;
      }
      .buttondiv {
        height: 28px;
        padding: 0 10px;
        background: #51546a;
        border-radius: 2px;
        line-height: 28px;
        color: #b7d8f2;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .tabs1_bottom {
      // height: 300px;
      margin-top: 24px;
    }

    .tableClass {
      width: 100%;
    }

    .tabs2 {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .setSelectClass {
        ::v-deep .el-input__inner {
          width: 316px !important;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .tableappend {
        height: 40px;
        background-color: #393c4e;
        line-height: 40px;
        padding-left: 12px;
        border-left: 1px solid #515464;
        border-bottom: 1px solid #515464;
        border-right: 1px solid #515464;
      }

      ::v-deep .el-table {
        overflow: initial;
      }
    }
  }
}

.iconp_input {
  ::v-deep .el-input__inner {
    width: auto !important;
    margin: 0 5px 8px 5px;
  }
}

::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background: #3d7ab0;
}

::v-deep .el-table .success-row {
  background: #3d7ab0 !important;
  // position: sticky;
  td {
    color: #ffffff !important;
  }

  &:hover {
    td {
      background-color: #3d7ab0 !important;
    }
  }
}

.setUserSelect {
  user-select: none;
}
</style>
