<!--
 * @Description: 术语表详情
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-24 16:38:33
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-24 11:36:39
-->
<template>
  <div class="mainCenter">
    <div class="mainHeader">
      <div class="Detailstitlediv">
        <svg-icon
          icon-class="BackPrevious"
          class="svgClass"
          @click="goback"
        ></svg-icon>
        <div class="Splitline"></div>
        <p class="titlep">{{ title }}</p>
        <!-- 编辑 -->
        <el-button type="text" @click="clickdetailedinformation(0)">
          <div class="svgbtn">
            <svg-icon icon-class="Edit" class="icon"></svg-icon>
            <p>{{ $t("TMManagement.edit") }}</p>
          </div>
        </el-button>
        <!-- 导入术语表 -->
        <!-- <el-button type="text" @click="clickdetailedinformation(1)">
          <div class="svgbtn">
            <svg-icon icon-class="Import" class="icon"></svg-icon>
            <p>{{ $t("TermBaseManagement.ImportGlossary") }}</p>
          </div>
        </el-button> -->
        <!-- 快速添加术语 -->
        <!-- <el-button type="text" @click="clickdetailedinformation(2)">
          <div class="svgbtn">
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("TermBaseManagement.Quicklyaddterms") }}</p>
          </div>
        </el-button> -->
        <!-- 操作记录 -->
        <!-- <el-button type="text" @click="clickdetailedinformation(3)">
          <div class="svgbtn">
            <svg-icon icon-class="TextVoice" class="icon"></svg-icon>
            <p>{{ $t("TermBaseManagement.Operationrecord") }}</p>
          </div>
        </el-button> -->
      </div>
    </div>
    <!-- <div class="mainCenter_center">
      <div>
        <p class="mainCenter_center_p">{{ $t("CommonName.Sourcelanguage") }}</p>
        <el-input
          v-model="Sourceinput"
          :placeholder="$t('TermBaseManagement.Sourceinput')"
          @keyup.enter.native="searchDataList"
        ></el-input>
      </div>
      <div>
        <p class="mainCenter_center_p">{{ $t("CommonName.Targetlanguage") }}</p>
        <el-input
          v-model="Targetinput"
          :placeholder="$t('TermBaseManagement.Targetinput')"
          @keyup.enter.native="searchDataList"
        ></el-input>
      </div>
      <div>
        <p class="mainCenter_center_p">&nbsp;</p>
        <el-button
          type="primary"
          plain
          class="setpadding16"
          @click="searchDataList"
        >
          <p>{{ $t("TMManagement.Search") }}</p>
        </el-button>
      </div>
    </div> -->
    <div class="mainCenter_bottom tableClass">
      <el-table
        :row-style="{
          height: 40 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 40 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
      >
        <div slot="empty" class="newnoDataDiv" style="margin-top: 50px">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <el-table-column width="70" label="#" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableTitleData"
          :key="index"
          min-width="60"
          show-overflow-tooltip
        >
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="namep">
                {{ item }}
              </p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div>{{ scope.row[index]["term"] }}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="tableData.length == 0" show-overflow-tooltip>
        </el-table-column>
        <!-- 操作 -->
        <!-- <el-table-column width="50" label="">
          <template slot-scope="scope">
            <div class="operationStyle">
              <el-dropdown @command="operationcommandClick">
                <span class="el-dropdown-link">
                  <svg-icon
                    icon-class="MoreOperations"
                    style="color: #cbd7e8"
                  ></svg-icon>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdowndiv">
                  <el-dropdown-item
                    v-for="item in dropdownList"
                    :key="item.command"
                    :command="{ type: item.command, id: scope.row }"
                  >
                    <svg-icon
                      :icon-class="item.icon"
                      style="margin-right: 8px; font-size: 18px"
                    ></svg-icon>
                    <span>{{ item.name }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-show="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="tableData"
          :pageSize="pageSize"
          :currentPage="currentPage"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
    </div>
    <!-- <div v-else class="noDataDiv">
      <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
      <p>{{ $t("CommonName.noData") }}</p>
    </div> -->
    <DialogComVue
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      @determineClick="determineClick"
      :footerbtnName="$t('CommonName.Sure')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      :showfooter="true"
      v-if="dialogVisible"
    >
      <div class="dialogContent">
        <div
          v-if="dialogtitle == $t('TermBaseManagement.Editglossaryinformation')"
        >
          <el-form
            ref="form"
            :model="form"
            label-position="top"
            class="formStyleClass dialogformStyleClass"
            @keydown.enter.prevent
          >
            <el-form-item :label="$t('TermBaseManagement.GlossaryName')">
              <el-input
                v-model="form.name"
                placeholder=""
                :maxlength="45"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('CommonName.remarks')">
              <el-input
                type="textarea"
                v-model="form.desc"
                resize="none"
                :maxlength="150"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 快速添加  术语 -->
        <div
          v-if="dialogtitle == $t('TermBaseManagement.Quicklyaddterms')"
          class="dialogContent_Edit"
        >
          <div class="mainCenter_center_Edit">
            <div>
              <p class="mainCenter_center_p">
                {{ $t("CommonName.Sourcelanguage") }}
              </p>
              <el-input
                v-model="form1.Sourceinput"
                :maxlength="150"
                :placeholder="$t('TermBaseManagement.dialogSourceinput')"
              ></el-input>
            </div>
            <div class="rightinput">
              <p class="mainCenter_center_p">
                {{ $t("CommonName.Targetlanguage") }}
              </p>
              <el-input
                v-model="form1.Targetinput"
                :maxlength="200"
                :placeholder="$t('TermBaseManagement.dialogTargetinput')"
              ></el-input>
            </div>
          </div>
        </div>
        <div
          v-if="dialogtitle == $t('TermBaseManagement.Editglossary')"
          class="dialogContent_Edit"
        >
          <div class="mainCenter_center_Edit">
            <div class="disabledInputClass">
              <p class="mainCenter_center_p">
                {{ $t("CommonName.Sourcelanguage") }}
              </p>
              <el-input
                v-model="form2.Sourceinput"
                :placeholder="$t('TermBaseManagement.dialogSourceinput')"
                disabled
              ></el-input>
            </div>
            <div class="rightinput">
              <p class="mainCenter_center_p">
                {{ $t("CommonName.Targetlanguage") }}
              </p>
              <el-input
                v-model="form2.Targetinput"
                :placeholder="$t('TermBaseManagement.dialogTargetinput')"
              ></el-input>
            </div>
          </div>
        </div>
        <div v-if="showForm == '3'">
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("TermBaseManagement.Uploadtermfile") }}</p>
              <el-button type="text" @click="Download">
                {{ $t("TermBaseManagement.DownloadGlossaryTemplate") }}
              </el-button>
            </div>
            <el-input
              v-model="uploadinput"
              class="input-with-select srtUnput"
              @input="uploadinput = ''"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                name="term_file"
                :data="uploadData"
                :action="$uploadURL + '/term/upload_term'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button type="text" @click="updateNewfile">
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
      </div>
    </DialogComVue>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
  </div>
</template>
<script>
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import {
  update_termfile_info,
  get_term_info,
  get_terms_list,
  add_term,
  update_term,
  delete_term,
  get_language,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  components: {
    PaginationComVue,
    DialogComVue,
  },
  created() {
    // this.title = this.$route.query.name;
    this.term_file_id = this.$route.query.id;
    // this.form.name = this.$route.query.name;
    // this.form.desc = this.$route.query.comments;
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_term_info(this.term_file_id);
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 336;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 336;
    };
  },
  data() {
    return {
      term_file_id: "",
      showForm: "0", //0 编辑 2 快速添加术语 1 编辑 3上传术语表
      dataInfo: {},
      uploadData: {},
      loading: false,
      percentage: 0,
      form: {
        name: "",
        desc: "",
      },
      uploadinput: "", //上传术语表
      form1: {
        //快速添加术语 弹窗
        Sourceinput: "",
        Targetinput: "",
      },
      form2: {
        //编辑术语 弹窗
        Sourceinput: "",
        Targetinput: "",
      },
      dialogVisible: false,
      dialogtitle: "",
      title: "",
      Sourceinput: "",
      Targetinput: "",
      tableData: [],
      langList: [],
      commandData: {},
      dropdownList: [
        {
          command: "0",
          name: this.$t("TMManagement.edit"),
          icon: "Edit",
        },
        {
          command: "1",
          name: this.$t("CommonName.delete"),
          icon: "delete",
        },
      ],
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      tableTitleData: [],
    };
  },
  methods: {
    /**
     * 点击下载 术语表模板
     */
    Download() {
      window.location.href = "/resourcefile/Glossary_Temp.xlsx";
    },
    /**
     * 获取语言接口
     */
    get_language(data) {
      return get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.langList = res.list;
          this.get_terms_list(data);
        }
      });
    },
    setTableData(array) {
      for (let index = 0; index < array.length; index++) {
        let findItem = this.langList.find(
          (item) => item.language_id == array[index].slang_id
        );
        if (findItem) {
          array[index]["slang_name"] = findItem["ename"];
        }
      }
      return array;
    },
    /**
     * 获取 基础数据
     */
    get_term_info(data) {
      return get_term_info({
        action: "get_term_set_info",
        term_file_id: data,
      }).then((res) => {
        console.log(res);
        if (res) {
          this.dataInfo = res.info;
          this.title = res.info.name;
          this.form.name = res.info.name;
          this.form.desc = res.info.comments;
          this.get_language(data);
        } else {
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {*获取 术语表数据} data
     */
    get_terms_list(data, term, meaning) {
      return get_terms_list({
        action: "get_terms_list_for_info",
        term_file_id: data,
        term: term ? term : "",
        meaning: meaning ? meaning : "",
      }).then((res) => {
        if (res) {
          hideLoading();
          // console.log(res.list);
          this.tableData = res.list;
          let array = {};
          if (this.tableData.length > 0) {
            Object.keys(this.tableData[0]).forEach((key) => {
              console.log(key, this.tableData[0][key]);
              let findItem = this.langList.find(
                (item) => item.language_id == key
              );
              if (findItem) {
                array[key] = findItem["ename"];
              }
            });
            this.tableTitleData = array;
          }
        } else {
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {添加术语} data
     */
    add_term(data) {
      return add_term({
        action: "add_term",
        term_file_id: data,
        term: this.form1.Sourceinput,
        meaning: this.form1.Targetinput,
      }).then((res) => {
        if (res) {
          this.$messages(
            "success",
            this.$t("TermBaseManagement.successmessage1")
          );
        }
      });
    },
    /**
     *
     * @param {*删除单条术语} item
     */
    delete_term(item) {
      delete_term({
        action: "delete_term",
        term_file_id: item.term_file_id,
        term_id: item.term_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_terms_list(
          this.term_file_id,
          this.Sourceinput,
          this.Targetinput
        ).then(() => {
          this.$messages("success", this.$t("CommonName.successmessage2"));
        });
      });
    },
    /**
     * 查找表格数据
     */
    searchDataList() {
      showLoading(this.$t("CommonName.loadingmessage"));
      this.currentPage = 1;
      this.get_terms_list(
        this.term_file_id,
        this.Sourceinput,
        this.Targetinput
      );
    },
    /**
     * 重置
     */
    initData() {
      // this.form = {
      //   name: "",
      //   desc: "",
      // };
      this.form1 = {
        //快速添加术语 弹窗
        Sourceinput: "",
        Targetinput: "",
      };
      this.form2 = {
        //编辑术语 弹窗
        Sourceinput: "",
        Targetinput: "",
      };
    },
    /**
     * 回到上一页
     */
    goback() {
      this.$router.push("/ContainerHome/Team/TermBaseManagement");
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
    },
    /**
     * 弹窗确定按钮
     */
    determineClick() {
      if (this.showForm == "0") {
        /**
         * 更新术语表信息
         */
        if (!this.form.name) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        update_termfile_info({
          action: "update_term_set",
          term_set_id: this.term_file_id,
          name: this.form.name,
          comments: this.form.desc,
        }).then((res) => {
          if (res) {
            this.get_term_info(this.term_file_id).then(() => {
              this.$messages(
                "success",
                this.$t("TermBaseManagement.warningmessage2")
              );
              this.dialogVisible = false;
            });
            // console.log(1111);
          }
        });
      } else if (this.showForm == "2") {
        if (!this.form1.Sourceinput || !this.form1.Targetinput) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        if (
          this.form1.Sourceinput.trim() == "" ||
          this.form1.Targetinput.trim() == ""
        ) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.add_term(this.term_file_id).then(() => {
          this.get_terms_list(
            this.term_file_id,
            this.Sourceinput.trim(),
            this.Targetinput.trim()
          ).then(() => {
            this.dialogVisible = false;
          });
        });
      } else if (this.showForm == "3") {
        if (!this.uploadinput) {
          this.$messages("warning", this.$t("CommonName.warningmessage5"));
          return;
        }
        this.uploadData = {
          term_file_id: this.term_file_id,
        };
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      } else {
        if (!this.form2.Targetinput) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        update_term({
          action: "update_term",
          term_file_id: this.term_file_id,
          term_id: this.commandData.id.term_id,
          meaning: this.form2.Targetinput,
        }).then((res) => {
          if (res) {
            this.get_terms_list(
              this.term_file_id,
              this.Sourceinput,
              this.Targetinput
            ).then(() => {
              this.$messages(
                "success",
                this.$t("TermBaseManagement.warningmessage2")
              );

              this.dialogVisible = false;
            });
          } else {
            this.$messages("error", res.msg);
          }
        });
      }
    },
    /**
     *
     * @param {详情信息 点击弹窗} val
     */
    clickdetailedinformation(val) {
      switch (val) {
        case 0:
          this.dialogtitle = this.$t(
            "TermBaseManagement.Editglossaryinformation"
          );
          this.showForm = "0";
          this.form.name = this.dataInfo.name;
          this.form.desc = this.dataInfo.comments;
          this.dialogVisible = true;
          break;
        case 2:
          this.form1 = {
            Sourceinput: "",
            Targetinput: "",
          };
          this.dialogtitle = this.$t("TermBaseManagement.Quicklyaddterms");
          this.showForm = "2";
          this.dialogVisible = true;
          break;
        case 3:
          this.$router.push({
            path: "/ContainerHome/Team/TermBaseManagement/TermBaseManagementOperationrecord",
            query: { name: this.title, id: this.term_file_id },
          });

          break;
        default:
          this.uploadinput = "";
          this.dialogtitle = this.$t("TermBaseManagement.ImportGlossary");
          this.showForm = "3";
          this.dialogVisible = true;
          break;
      }
    },
    /**
     *
     * @param {*点击表格操作 下拉框} command
     */
    operationcommandClick(command) {
      // console.log(command);
      if (command.type == "0") {
        this.dialogtitle = this.$t("TermBaseManagement.Editglossary");
        this.showForm = "1";
        this.dialogVisible = true;
        this.commandData = command;
        this.form2.Sourceinput = command.id.term;
        this.form2.Targetinput = command.id.meaning;
      } else {
        this.$confirm(
          this.$t("CommonName.deleteMessage2"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
            cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        )
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.delete_term(command.id);
          })
          .catch(() => {});
      }
    },
    // ===================================上传  术语表 方法===========================================
    /**
     * 点击重新上传
     */
    updateNewfile() {
      this.uploadinput = "";
      let uploadFilesArr = this.$refs["upload"].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs["upload"].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        this.uploadinput = "";
        this.$refs.upload.clearFiles();
        return false;
      } else {
        this.uploadinput = file.name;
        return true;
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadSuccess(response) {
      if (response.result == "SUCCESS") {
        this.get_terms_list(
          this.term_file_id,
          this.Sourceinput,
          this.Targetinput
        ).then(() => {
          this.dialogVisible = false;
          this.percentage = 0;
          this.loading = false;
          this.$messages(
            "success",
            this.$t("CommonName.uploadTermTip1") +
              response.total +
              this.$t("CommonName.uploadTermTip2") +
              response.success_count +
              this.$t("CommonName.uploadTermTip3"),
            4000
          );
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.dialogVisible = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      // if (file.percent == 100) {
      //   this.percentage = 0;
      //   this.loading = false;
      //   // showLoading("文件已上传完毕，正在解析中... ");
      // }
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainCenter {
  margin-top: 24px;
  height: calc(100% - 24px);

  .mainHeader {
    margin-left: 24px;
  }

  .btnClass {
    display: flex;
    align-items: center;

    p {
      margin-right: 6px;
    }
  }

  .mainCenter_center {
    margin-left: 24px;

    .mainCenter_center_p {
      text-align: left;
      margin-bottom: 9px;
      color: #adb0bc;
      font-size: 12px;
    }

    ::v-deep .el-input__inner {
      width: 280px;
    }

    .el-input {
      width: 280px;
      margin-right: 24px;
    }

    display: flex;
  }
}

.mainCenter_bottom {
  margin-top: 25px;
  height: calc(100% - 142px);

  ::v-deep .el-tooltip {
    white-space: pre-line !important;
    word-break: break-word;
  }
}

.dialogContent {
  margin: 24px;

  .dialogContent_Edit {
    .rightinput {
      margin-left: 40px;
    }

    .mainCenter_center_Edit {
      display: flex;

      .mainCenter_center_p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      ::v-deep .el-input__inner {
        width: 400px !important;
      }
    }
  }
}

.Detailstitlediv {
  .el-button {
    margin-right: 23px;
  }
}

.dropdowndiv {
  ::v-deep .popper__arrow {
    left: 80px !important;
  }
}

.content {
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 0;
  }

  .uploadp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    .el-button {
      padding: 0;
    }
  }

  .srtUnput {
    ::v-deep .el-input__inner {
      width: 755px !important;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
      width: 84px;
      background: none;
      border: 1px solid #7d7f8b;
      border-left: none;
      text-align: center;
      padding: 0;
    }

    .icon {
      margin-right: 6px;
    }
  }
}
</style>
