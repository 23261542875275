<template>
  <DialogComVue
    :dialogVisible="dialogAutoVisible"
    @determineClick="determineClick"
    @changeDialogVisible="changeDialogVisible"
    :width="'888px'"
    :top="'5%'"
    custom-class="innerDialogClass"
    :showTitle="false"
    :destroyonclose="true"
    :title="$t('VideoDubbingClone.dubText43')"
    v-if="dialogAutoVisible"
  >
    <div class="innerDialog_div">
      <div
        class="innerDialog_div_top_p"
        v-if="HardSubtitleRecognitionTab == 'second'"
      >
        <div class="showOpenButton">
          <!--              开启遮罩-->
          <el-button
            type="primary"
            class="setpadding16"
            @click="OpenCanvas"
            v-if="showOpen"
            >{{ $t("PersonalEndLanguage.ViewExamples5") }}</el-button
          >
          <!--              关闭遮罩-->
          <el-button
            type="primary"
            class="setpadding16"
            @click="closeCanvas"
            v-else
            >{{ $t("PersonalEndLanguage.ViewExamples6") }}</el-button
          >
        </div>
      </div>

      <div class="innerDialog_video_div" id="innerDialog_video_div">
        <!-- 视频展示 -->
        <video
          class="innerDialog_video_class"
          id="dialogVideo_daub"
          ref="videoref"
          :src="videoUrl"
          type="video/mp4"
          oncontextmenu="return false"
        >
          <!-- <source /> -->
        </video>
        <canvas id="canvas_daub" :width="1" :height="360"></canvas>
      </div>
      <div class="sliderClass">
        <el-slider
          v-model="slidervalue"
          :show-tooltip="true"
          :step="step"
          @change="sliderInput"
          :max="silderMax"
          :disabled="sliderChange"
          :format-tooltip="formatTooltip"
        ></el-slider>
      </div>
      <div class="left_videobom_div">
        <div class="left"></div>
        <div class="center">
          <!-- 回退 -->
          <div @click="rew" class="btndiv">
            <!-- ! todo -->
            <svg-icon icon-class="VideoBack" class="svgiconclass"></svg-icon>
          </div>
          <!-- 开始时间 -->
          <p>{{ videoStartTime }}</p>
          <!-- 暂停播放按钮 -->

          <div @click="playvideo" v-if="player" class="btndiv" id="step-5">
            <svg-icon icon-class="VideoPlay" class="svgiconclass"></svg-icon>
          </div>
          <div @click="closevideo" v-else class="btndiv" id="step-5">
            <svg-icon icon-class="VideoPause" class="svgiconclass"></svg-icon>
          </div>

          <!-- 结束时间 -->
          <p>{{ videolangtime }}</p>
          <!-- 前进 -->
          <div @click="speek" class="btndiv">
            <!-- ! todo -->
            <svg-icon icon-class="VideoForward" class="svgiconclass"></svg-icon>
          </div>
        </div>
        <div class="right">
          <!-- 播放倍数 -->
          <el-dropdown @command="handleCommand" placement="top" trigger="click">
            <p
              class="el-dropdown-link"
              style="text-align: end; cursor: pointer"
            >
              {{ ds + "X" }}
            </p>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item command="0.5">0.5X</el-dropdown-item>
              <el-dropdown-item command="0.75">0.75X</el-dropdown-item>
              <el-dropdown-item command="1">1X</el-dropdown-item>
              <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
              <el-dropdown-item command="1.5">1.5X</el-dropdown-item>
              <el-dropdown-item command="1.75">1.75X</el-dropdown-item>
              <el-dropdown-item command="2">2X</el-dropdown-item>
              <el-dropdown-item command="2.5">2.5X</el-dropdown-item>
              <el-dropdown-item command="3">3X</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <p class="tips_p" id="tips_p1">
        {{ $t("VideoDubbingClone.dubText44") }}
      </p>
      <div class="time_div" ref="time_div">
        <div
          class="item_time_div"
          v-for="(item, index) in rectList"
          :key="item.rect_id"
          @click="item_time_div_click(index, item)"
          :class="item.rect_id == selectedRectId ? 'selected' : ''"
        >
          <div class="start_time_div">
            <div>
              <!--                起始时间点-->
              <span>{{ $t("VideoDubbingClone.dubText45") }}</span>
              <el-input
                v-model="item.startTime"
                placeholder="00:00:00.00"
                suffix-icon="el-icon-time"
                @blur="inputBlur(0, index)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="TimeIconClick(0, videoDom.currentTime, index, $event)"
            >
              <i class="el-icon-location icon"></i>
            </div>
          </div>
          <div class="end_time_div">
            <div>
              <!--                结束时间点-->
              <span>{{ $t("VideoDubbingClone.dubText46") }}</span>
              <el-input
                v-model="item.endTime"
                placeholder="00:00:00.00"
                suffix-icon="el-icon-time"
                @blur="inputBlur(1, index)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="TimeIconClick(1, videoDom.currentTime, index, $event)"
            >
              <i class="el-icon-location icon1"></i>
            </div>
          </div>
          <!-- 保存 -->
          <el-tooltip
            :content="$t('VideoDubbingClone.dubText47')"
            placement="top"
            effect="dark"
            :enterable="false"
            :visible-arrow="false"
            v-if="item.isShowSave"
          >
            <div
              class="svgdiv"
              id="step-4"
              @click="saveTimeRange(index, $event)"
            >
              <svg-icon icon-class="save" class="icon"></svg-icon>
            </div>
          </el-tooltip>
          <div v-else style="width: 32px; height: 31px"></div>
          <!-- 移除 -->
          <el-tooltip
            :content="$t('VideoDubbingClone.dubText48')"
            placement="top"
            effect="dark"
            :enterable="false"
            :visible-arrow="false"
          >
            <div
              class="svgdiv"
              id="step-4"
              @click="deleteTimeRange(index, $event)"
            >
              <svg-icon icon-class="delete3" class="icon"></svg-icon>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </DialogComVue>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import { set_caption_info } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import { fabric } from "fabric";
import { getStringToTime, getTimeString } from "@/utils/tools";
import { TimeHandle } from "@/api/setTime";
export default {
  components: {
    DialogComVue,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    videoUrl: {
      type: String,
      default: "",
    },
    job_id: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {},
  mounted() {
    this.dialogAutoVisible = this.dialogVisible;
    this.initVideo();
    window.addEventListener("keydown", this.windowkeydown, true);
    window.addEventListener("keyup", this.windowkeyup, true);
  },
  data() {
    return {
      dialogAutoVisible: false,
      videoDom: {}, //创建 视频对象
      ds: 1.0, // 倍数
      isMoveLeft: true,
      maxScaleX: 1,
      isMoveTop: true,
      maxScaleY: 1,
      HardSubtitleRecognitionTab: "first", // 硬字幕识别 tab
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      showOpen: true, //是否开启遮罩 true 显示开启按钮 false 显示 关闭按钮
      slidervalue: 0, //当前步
      startTime: "", //片头时间点
      endTime: "", //片头时间点
      old_time: "", //记录 片头时间
      old_time1: "", //记录 片尾事件
      step: 0.01, //步长
      silderMax: 0, //总步长
      marks: {}, // 硬字幕识别 滑块 标记 时间范围
      sliderChange: false,
      canvas: null,
      downPoint: null, // 按下鼠标时的坐标
      upPoint: null, // 松开鼠标时的坐标
      rect: null,
      player: true,
      rectList: [], // 矩形属性 时间范围
      rect_id: 1, //矩形ID
      selectedRectId: 0, //选中矩形ID
      canvasStart: true, //是否可以开始画框
    };
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.windowkeydown, true);
    window.removeEventListener("keyup", this.windowkeyup, true);
  },
  methods: {
    /**
     * 初始化 视频
     * @param data
     */
    initVideo() {
      this.$nextTick(() => {
        // this.videoDom = null;
        this.videoDom = document.getElementById("dialogVideo_daub"); // 获取视频dom元素
        let isCurrentMetadataLoaded = false;
        this.videoDom.addEventListener("loadedmetadata", () => {
          if (!isCurrentMetadataLoaded) {
            // this.marks = {};
            this.videolangtime = TimeHandle(this.videoDom.duration);
            this.silderMax = this.videoDom.duration;
            // this.startTime = "";
            // this.endTime = getTimeString(this.silderMax);
            // this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
            // this.marks = Object.assign({}, this.marks);
            this.initCanvas(); //创建canvas
            isCurrentMetadataLoaded = true;
          }
        });
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          this.player = true;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
        });
      });
    },
    /**
     *
     * @param {* 回退} data
     */
    rew() {
      this.gospeek = true;
      this.videoDom.currentTime = this.videoDom.currentTime - 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {*播放} data
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
    },
    /**
     *
     * @param {* 暂停播放} data
     */
    closevideo() {
      this.player = true;
      this.videoDom.pause();
    },
    /**
     *
     * @param {* 快进} data
     */
    speek() {
      this.gospeek = true;
      if (this.videoDom.currentTime == this.videoDom.duration) {
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {* 调整播放速度} command
     */
    handleCommand(command) {
      this.ds = command;
      this.videoDom.playbackRate = command;
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogAutoVisible = val;
      this.$emit("changeAutoVisible", this.dialogAutoVisible);
    },
    /**
     *
     * @param {*确定按钮} val
     */
    determineClick() {
      if (this.selectedRectId > 0) {
        let old_index = this.rectList.findIndex(
          (item1) =>
            this.selectedRectId == item1.rect_id && item1.isShowSave == true
        );
        console.log("old_index", old_index);
        if (old_index > -1) {
          //调用保存方法
          if (!this.saveTimeRange(old_index)) {
            return false;
          }
        }
      }
      this.cutPicture();
    },
    cutPicture() {
      var v = this.$refs.videoref;
      let video = null;
      let videoHeight = 0;
      let videoWidth = 0;
      let tops = 0;
      let lefts = 0;
      let widths = 0;
      let heights = 0;
      video = document.createElement("video");
      video.src = this.videoUrl;
      document.body.appendChild(video);
      video.onloadedmetadata = () => {
        videoWidth = video.videoWidth;
        videoHeight = video.videoHeight;
        if (videoWidth > videoHeight) {
          heights = Number(
            (
              videoHeight / Number((videoWidth / v.clientWidth).toFixed(2))
            ).toFixed(2)
          );
          widths = v.clientWidth;
          if (heights > v.clientHeight) {
            heights = v.clientHeight;
            widths = Number(
              (
                videoWidth / Number((videoHeight / v.clientHeight).toFixed(2))
              ).toFixed(2)
            );
            lefts = Number(((v.clientWidth - widths) / 2).toFixed(2));
          }
          tops = (v.clientHeight - heights) / 2;
        } else {
          widths = Number(
            (
              videoWidth / Number((videoHeight / v.clientHeight).toFixed(2))
            ).toFixed(2)
          );
          heights = v.clientHeight;
          lefts = (v.clientWidth - widths) / 2;
        }
        let list = [];
        this.rectList.forEach((item) => {
          let arr = {
            position_original_x: item.left - lefts,
            position_original_y: item.top - tops,
            position_original_w: item.width * item.scaleX,
            position_original_h: item.height * item.scaleY,
            start: item.start,
            end: item.end,
          };
          list.push(arr);
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        set_caption_info({
          action: "set_daub",
          job_id: this.job_id,
          list,
          original_width: widths,
          original_height: heights,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.dialogAutoVisible = false;
          this.$emit("dialogMethod", this.dialogAutoVisible);
          this.closeCanvas();
        });
      };

      document.body.removeChild(video);
    },
    // ---------------------------------------canvas---------------------------------------
    initCanvas() {
      if (this.canvas) {
        this.canvas.setWidth(1);
        this.canvas.dispose();
        this.canvas = null;
      }
      this.canvas = new fabric.Canvas("canvas_daub");
      this.canvas.setWidth(this.videoDom.clientWidth);
      this.canvas.wrapperEl.style.position = "absolute";
      this.canvas.off("mouse:down").on("mouse:down", this.canvasMouseDown); // 鼠标在画布上按下
      this.canvas.off("mouse:up").on("mouse:up", this.canvasMouseUp); // 鼠标在画布上松开
    },
    canvasMouseDown(e) {
      if (this.rect) {
        return;
      }
      if (this.canvasStart == false) {
        console.log("清理选中内容");
        // 清理选中内容
        if ("getSelection" in window) {
          window.getSelection().removeAllRanges();
        } else {
          document.selection.empty();
        }
        this.canvas.clear();
      }
      this.canvasStart = false;
      // 鼠标左键按下时，将当前坐标 赋值给 downPoint。{x: xxx, y: xxx} 的格式
      this.downPoint = e.absolutePointer;
      this.closevideo();
    },
    canvasMouseUp(e) {
      // 松开鼠标左键时，将当前坐标 赋值给 upPoint
      if (this.rect) {
        return;
      }
      this.canvasStart = true;
      this.upPoint = e.absolutePointer;
      if (
        this.upPoint.x < 0 ||
        this.upPoint.x > this.videoDom.clientWidth ||
        this.upPoint.y < 0 ||
        this.upPoint.y > this.videoDom.clientHeight
      ) {
        return;
      }
      // 调用 创建矩形 的方法
      this.createRect();
    },
    /**
     *  开启遮罩
     * @param data
     * @constructor
     */
    OpenCanvas() {
      if (this.rect) {
        return;
      }
      // console.log(2222222);
      this.initCanvas(); //创建canvas
      this.showOpen = false;
    },
    /**
     * 关闭遮罩
     * @param
     */
    closeCanvas() {
      this.canvas.setWidth(1);
      this.canvas.dispose();
      this.canvas = null;
      this.rect = null;
      this.showOpen = true;
    },
    /**
     *  创建 canvas 矩形
     */
    createRect(index = -1) {
      let top = 0;
      let left = 0;
      let width = 0;
      let height = 0;
      let scaleX = 1;
      let scaleY = 1;
      if (index == -1) {
        // 如果点击和松开鼠标，都是在同一个坐标点，不会生成矩形
        if (JSON.stringify(this.downPoint) === JSON.stringify(this.upPoint)) {
          // 清理选中内容
          if ("getSelection" in window) {
            window.getSelection().removeAllRanges();
          } else {
            document.selection.empty();
          }
          return;
        }
        if (this.videoDom.currentTime + 1 > this.videoDom.duration) {
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        // 矩形参数计算（前面总结的4条公式）
        top = Math.min(this.downPoint.y, this.upPoint.y);
        left = Math.min(this.downPoint.x, this.upPoint.x);
        width = Math.abs(this.downPoint.x - this.upPoint.x);
        height = Math.abs(this.downPoint.y - this.upPoint.y);
        // 新增选区
        this.addTimeRange();
      } else {
        // 矩形参数计算（依据现有数据）
        top = this.rectList[index]["top"];
        left = this.rectList[index]["left"];
        width = this.rectList[index]["width"];
        height = this.rectList[index]["height"];
        scaleX = this.rectList[index]["scaleX"];
        scaleY = this.rectList[index]["scaleY"];
      }
      // 创建矩形

      // 矩形对象
      this.rect = new fabric.Rect({
        top,
        left,
        width,
        height,
        fill: "blue", // 填充色：透明
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        cornerSize: 5,
        scaleX,
        scaleY,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      });
      this.canvas.add(this.rect);
      // const mask = new fabric.Rect({
      //   left: 0,
      //   top: 0,
      //   width: this.canvas.width,
      //   height: this.canvas.height,
      //   // backgroundVpt: false,
      //   // height: 370,
      //   fill: "rgba(0,0,0,0.4)",
      //   // fill: "rgba(255,255,255,0.5)",
      //   selectable: false,
      // });

      // 将矩形添加到画布上
      // this.canvas.add(mask);

      // 创建完矩形，清空 downPoint 和 upPoint。当然，你也可以不做这步。
      this.downPoint = null;
      this.upPoint = null;
      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小

        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;
            this.isMoveLeft = false;
          } else {
            // console.log(this.maxScaleX, "maxScaleX");
            rect.scaleX = this.maxScaleX;
          }
        }

        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
      });
    },
    //-------------------------------------------------------------------------------------
    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      this.videoStartTime = TimeHandle(slider);
      this.videoDom.currentTime = slider;
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id == 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 点击 片头片尾 按钮  获取当前时间 赋值片头片尾时间
     * @param data 0：片头 1：片尾
     * @constructor
     */
    TimeIconClick(data, time, index, e) {
      if (!!e == true) {
        e.preventDefault();
        e.stopPropagation();
      }
      setTimeout(() => {
        if (data == 0) {
          if (time >= this.rectList[index].end) {
            return;
          }
          this.rectList[index].start = time;
          this.rectList[index].old_start = time;
          this.rectList[index].startTime = getTimeString(time);
        } else {
          if (time <= this.rectList[index].start) {
            return;
          }
          this.rectList[index].end = time;
          this.rectList[index].old_end = time;
          this.rectList[index].endTime = getTimeString(time);
        }
      }, 100);
    },
    /**
     * 清除 输入框里得内容
     * @param data
     */
    clearInput(data) {
      for (let Key in this.marks) {
        if (data == 0) {
          if (this.marks[Key].id === 1) {
            delete this.marks[Key];
          }
          this.old_time = "";
        } else {
          if (this.marks[Key].id === 2) {
            delete this.marks[Key];
          }
          this.old_time1 = "";
        }
      }
      this.marks = Object.assign({}, this.marks);
    },
    /**
     *  片头片尾 时间点 失去焦点
     * @param data
     */
    inputBlur(data, index) {
      if (data === 0) {
        if (!this.rectList[index].startTime) {
          return;
        }
        //判断时间格式是否正确的正则表达式
        if (isNaN(getStringToTime(this.rectList[index].startTime, 2))) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          this.rectList[index].startTime = getTimeString(
            this.rectList[index].start
          );
          return;
        }

        let time = getStringToTime(this.rectList[index].startTime);
        if (this.rectList[index].endTime) {
          let time1 = getStringToTime(this.rectList[index].endTime);
          if (+time >= +time1) {
            this.rectList[index].startTime = getTimeString(
              this.rectList[index].start
            );
            return;
          }
        }
        if (+time > this.silderMax) {
          this.rectList[index].startTime = getTimeString(
            this.rectList[index].start
          );
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.TimeIconClick(data, time, index);
      } else {
        if (!this.rectList[index].endTime) {
          return;
        }
        if (isNaN(getStringToTime(this.rectList[index].endTime, 2))) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          this.rectList[index].endTime = getTimeString(
            this.rectList[index].end
          );
          return;
        }
        let time = getStringToTime(this.rectList[index].endTime);
        if (this.rectList[index].startTime) {
          let time1 = getStringToTime(this.rectList[index].startTime);
          if (+time <= +time1) {
            this.rectList[index].endTime = getTimeString(
              this.rectList[index].end
            );
            return;
          }
        }
        if (+time > this.silderMax) {
          this.rectList[index].endTime = getTimeString(
            this.rectList[index].end
          );
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.TimeIconClick(data, time, index);
      }
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    formattedTime(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      return totalSeconds; // 返回总秒数
    },
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      const nodeName = e.target.nodeName;
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          this.playvideo();
        } else {
          this.closevideo();
        }
      }
    },
    //保存时间区域
    saveTimeRange(index, e) {
      if (!!e == true) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (
        this.rectList[index]["startTime"] == "" ||
        this.rectList[index]["endTime"] == ""
      ) {
        this.$messages(
          "warning",
          this.$t("VideoDubbingClone.warningmessage29")
        );
        return false;
      }
      this.selectedRectId = 0;
      this.rectList[index]["left"] = this.rect.left;
      this.rectList[index]["top"] = this.rect.top;
      this.rectList[index]["width"] = this.rect.width;
      this.rectList[index]["height"] = this.rect.height;
      this.rectList[index]["scaleX"] = this.rect.scaleX;
      this.rectList[index]["scaleY"] = this.rect.scaleY;
      this.rectList[index]["isShowSave"] = false;
      this.closeCanvas();
      this.OpenCanvas();
      return true;
    },
    //删除时间区域
    deleteTimeRange(index, e) {
      e.preventDefault();
      e.stopPropagation();
      this.closeCanvas();
      this.OpenCanvas();
      this.selectedRectId = 0;
      this.rectList.splice(index, 1);
    },
    //添加选区
    addTimeRange() {
      let start = this.videoDom.currentTime;
      let end = this.videoDom.currentTime + 1;
      let arr = {
        rect_id: this.rect_id,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        scaleX: 1,
        scaleY: 1,
        start,
        end,
        startTime: getTimeString(start),
        old_start: start,
        ole_end: end,
        endTime: getTimeString(end),
        isShowSave: true,
      };
      this.selectedRectId = arr.rect_id;
      this.rectList.push(arr);
      this.rect_id++;
      this.$nextTick(() => {
        this.$refs.time_div.scrollTo({
          top: this.$refs.time_div.scrollHeight,
          behavior: "smooth",
        });
      });
      console.log(this.rectList);
    },
    //选区点击事件
    item_time_div_click(index, item) {
      setTimeout(() => {
        console.log("this.selectedRectId", this.selectedRectId);
        console.log(index, item);
        if (this.selectedRectId == item.rect_id) {
          this.videoDom.currentTime = this.rectList[index]["start"];
          return;
        }
        let old_index = this.rectList.findIndex(
          (item1) =>
            this.selectedRectId == item1.rect_id && item1.isShowSave == true
        );
        console.log("old_index", old_index);
        if (old_index > -1) {
          //调用保存方法
          if (!this.saveTimeRange(old_index)) {
            return false;
          }
        }
        this.selectedRectId = item.rect_id;
        this.createRect(index);
        this.rectList[index]["isShowSave"] = true;
        this.videoDom.currentTime = this.rectList[index]["start"];
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.innerDialog_div {
  width: 100%;
  height: 600px;
  box-sizing: border-box;

  .showOpenButton {
    position: absolute;
    right: 24px;
  }

  .innerDialog_div_top_p {
    color: #ffffff;
    font-size: 14px;
    margin: 16px 0 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 600px;
      text-align: center;
      word-break: break-word;
    }
  }

  .innerDialog_video_div {
    background: #000000;
    height: 360px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .innerDialog_video_class {
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    #canvas {
      position: absolute !important;
      //height: 370px !important;
    }
  }

  .sliderClass {
    width: 97%;
    //margin-top: 10px;
    height: 15px;

    ::v-deep .el-slider__marks-text {
      margin-top: 3px !important;
    }

    ::v-deep .el-slider__runway {
      margin: 10px 1.5% 0 1.5%;
    }

    ::v-deep .el-slider__button-wrapper {
      width: 26px !important;
      height: 26px !important;
      top: -10px !important;
    }

    ::v-deep .el-slider__button {
      width: 10px !important;
      height: 10px !important;
      border: none !important;
      background: #409eff !important;
    }
  }
  .left_videobom_div {
    height: 32px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    position: relative;

    .btndiv {
      color: #46adff;
      // margin-top: 2px;
      cursor: pointer;
      .svgiconclass {
        font-size: 16px;
        outline: 0 !important;
      }
      ::v-deep .popperClassDiv {
        min-width: 100px;
      }
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
      p {
        color: #46adff;
        width: 40px;
      }

      ::v-deep .popper__arrow {
        left: 20px !important;
      }
    }
    .center {
      display: flex;
      align-items: center;
      p {
        color: #cbd7e8;
        font-size: 12px;
        margin: 0 17px;
      }
    }
  }
  .time_div {
    margin: 10px 0;
    height: 132px;
    overflow-y: auto;
    .item_time_div {
      display: flex;
      align-items: center;
      width: 750px;
      background: #424657;
      border-radius: 6px;
      height: 38px;
      margin: 0 auto;
      border: 1px solid;
      border-color: #424657;
      cursor: pointer;

      .start_time_div {
        margin-left: 24px;
      }

      .end_time_div {
        margin-right: 24px;
      }

      .start_time_div,
      .end_time_div {
        display: flex;
        align-items: center;
        width: 315px;

        .el-input {
          width: 130px !important;
        }

        span {
          color: #adb0bc;
          font-size: 14px;
          margin-right: 6px;
        }

        .icon_div {
          margin-left: 6px;
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #51546a;
          border-radius: 2px;
          //color: #b7d8f2;
          .icon {
            color: #98ff6c;
          }

          .icon1 {
            color: #ffb655;
          }

          &:hover {
            .icon,
            .icon1 {
              color: #ffffff;
            }

            background: #3d7ab0;

            cursor: pointer;
          }
        }

        ::v-deep .el-input__inner {
          width: 100% !important;
          background: #2a2d3a !important;
          border-radius: 2px !important;
          height: 28px !important;
        }

        ::v-deep .el-input__icon {
          height: 28px !important;
          line-height: 28px !important;
        }
      }
      .svgdiv {
        font-size: 20px;
        cursor: pointer;
        margin-right: 6px;
        margin-left: 6px;
        color: #b7d8f2;
        &:hover {
          color: #46adff;
        }
      }
    }
    .item_time_div.selected {
      border-color: #46adff;
    }
    .item_time_div + .item_time_div {
      margin-top: 6px;
    }
  }

  .tips_p {
    margin-top: 10px;
    color: rgba(203, 215, 232, 0.67);
    text-align: center;
  }
}
</style>
