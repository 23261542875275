/*
 * @Description: 自定义指令
 * @version:
 * @Author: Carful
 * @Date: 2023-04-21 09:47:57
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-05 11:36:42
 */
import Vue from "vue";
/**
 * author: carful
 * 水印
 * text：水印文字
 * font：字体
 * textColor：文字颜色
 * width：宽度
 * height：高度
 * textRotate：偏转度 -90到0， 负数值，不包含-90
 */
Vue.directive("watermark", (el, binding) => {
  let text = binding.value.text;
  let font = binding.value.font || "12px Microsoft YaHei";
  let textColor = binding.value.textColor || "rgba(255,255,255,0.6)";
  let width, height;
  if (binding.value.fullscreen) {
    width = binding.value.width || 250;
    height = binding.value.height || 200;
  } else {
    width = binding.value.width || 500;
    height = binding.value.height || 400;
  }

  // let textRotate = binding.value.textRotate || -20;
  // console.log(binding.value.fullscreen);
  function addWaterMarker(parentNode) {
    var can = document.createElement("canvas");
    parentNode.appendChild(can);
    can.width = width;
    can.height = height;
    can.style.display = "none";
    var cans = can.getContext("2d");
    // cans.rotate((textRotate * Math.PI) / 180);
    cans.rotate((-15 * Math.PI) / 180); // 逆时针旋转π/9
    cans.font = font;
    cans.fillStyle = textColor;
    cans.textAlign = "left";
    cans.textBaseline = "Middle";
    cans.fillText(text, 0, can.height);
    cans.fillText(text, can.width / 2, can.height / 2);
    // cans.fillText(text, can.width / 3, can.height / 2);

    // const textWidth = cans.measureText(text).width;
    // const textHeight = 20;
    // const angle = -30; // 水印倾斜角度
    // const intervalX = textWidth + 200; // 水印之间的水平间距
    // const intervalY = textHeight + 100; // 水印之间的垂直间距
    // const screenWidth =
    //   window.innerWidth ||
    //   document.documentElement.clientWidth ||
    //   document.body.clientWidth;
    // const screenHeight =
    //   window.innerHeight ||
    //   document.documentElement.clientHeight ||
    //   document.body.clientHeight;
    // let rowCount, colCount;
    // if (screenWidth < 768) {
    //   // 非全屏时每行显示2个水印
    //   rowCount = Math.ceil(screenHeight / intervalY);
    //   colCount = 2;
    // } else {
    //   // 全屏时根据容器宽度计算水印个数
    //   rowCount = Math.ceil(screenHeight / intervalY);
    //   colCount = Math.ceil(screenWidth / intervalX);
    // }
    // for (let row = 0; row < rowCount; row++) {
    //   for (let col = 0; col < colCount; col++) {
    //     const x = col * intervalX - (colCount * intervalX - screenWidth) / 2;
    //     const y = row * intervalY;

    //     cans.save();
    //     cans.translate(x, y);
    //     cans.rotate((angle * Math.PI) / 180);
    //     cans.fillText(text, 0, 0);
    //     cans.restore();
    //   }
    // }

    parentNode.style.backgroundImage =
      "url(" + can.toDataURL("image/png") + ")";
  }
  addWaterMarker(el);
});
Vue.directive("focus", (el, binding) => {
  setTimeout(() => {
    if (el.querySelector("textarea")) {
      el.querySelector("textarea").focus();
    } else {
      el.querySelector("input").focus();
    }
  });
});
Vue.directive("drag", (el, binding) => {
  el.style.cursor = "move";
  el.onmousedown = function (e) {
    var disx = e.pageX - el.offsetLeft;
    var disy = e.pageY - el.offsetTop;

    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false; // 解决快速频繁拖动滞后问题
    }

    document.onmousemove = function (e) {
      el.style.bottom = "auto";
      // 鼠标位置-鼠标相对元素位置=元素位置
      let left = e.clientX - disx;
      let top = e.clientY - disy;

      // 限制拖拽范围不超出可视区
      if (left <= 0) {
        left = 0; // 设置成5,离边缘不要太近
      } else if (
        left >
        // document.documentElement.clientWidth - el.clientWidth
        document.getElementById("video1").clientWidth - el.clientWidth
      ) {
        // document.documentElement.clientWidth屏幕可视区宽度
        left = document.getElementById("video1").clientWidth - el.clientWidth;
      }
      if (top <= 0) {
        top = 5;
      } else if (
        top >
        // document.documentElement.clientHeight - el.clientHeight
        document.getElementById("video1").clientHeight - el.clientHeight
      ) {
        top = document.getElementById("video1").clientHeight - el.clientHeight;
      }
      el.style.left = left + "px";
      el.style.top = top + "px";
    };

    document.onmouseup = function () {
      document.onmousemove = document.onmouseup = null;
    };
  };
});
// directives: {
//   watermark: {
//     inserted(el, binding) {
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');

//       const resizeHandler = () => {
//         setCanvasSize(canvas, el);
//         drawWatermark(canvas, context, binding.value);
//       };

//       const setCanvasSize = (canvas, el) => {
//         canvas.width = el.offsetWidth;
//         canvas.height = el.offsetHeight;
//       };

//       const drawWatermark = (canvas, context, options) => {
//         const { text, color, font, angle, intervalX, intervalY } = options;
//         context.clearRect(0, 0, canvas.width, canvas.height);

//         context.fillStyle = color;
//         context.font = font;
//         context.textAlign = 'center';
//         context.textBaseline = 'middle';
//         context.globalAlpha = 0.3;

//         const textWidth = context.measureText(text).width;
//         const textHeight = 20;

//         const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//         const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

//         let rowCount, colCount;
//         if (screenWidth < 768) {
//           rowCount = Math.ceil(screenHeight / intervalY);
//           colCount = 2;
//         } else {
//           rowCount = Math.ceil(screenHeight / intervalY);
//           colCount = Math.ceil(screenWidth / intervalX);
//         }

//         for (let row = 0; row < rowCount; row++) {
//           for (let col = 0; col < colCount; col++) {
//             const x = col * intervalX - (colCount * intervalX - screenWidth) / 2;
//             const y = row * intervalY;

//             context.save();
//             context.translate(x, y);
//             context.rotate(angle * Math.PI / 180);
//             context.fillText(text, 0, 0);
//             context.restore();
//           }
//         }
//       };

//       setCanvasSize(canvas, el);
//       drawWatermark(canvas, context, binding.value);

//       window.addEventListener('resize', resizeHandler);

//       el.appendChild(canvas);
//     },
//     unbind(el) {
//       window.removeEventListener('resize', resizeHandler);
//     }
//   }
// }
// };
