/*
 * @Description: axios拦截器 请求地址
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:36:01
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-04 15:56:10
 */
import axios from "axios";
import errorCode from "./errorCode";
import { hideLoading } from "@/utils/loading";
import router from "@/router";
import alertOK from "@/utils/message/message";
import Cookies from "js-cookie";
import config from "@/config";
// http://wiimedia.ectranslate.com
// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // 请求后端地址
  // baseURL: "/api",
  // baseURL: "http://iwork-api.ectranslate.com",
  baseURL: config.baseUrl.dev,
  withCredentials: true,
  // 超时
  timeout: 600000,
  // 是否跨域请求A
  // crossDomain: true,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    // const token_code = sessionStorage.getItem("token");
    // token_code && (config.headers.token_code = token_code);
    // config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  (error) => {
    // console.log(error);
    return alertOK("error", error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      //自定义消息提示
      return alertOK("error", msg);
    } else if (code === 500) {
      //自定义消息提示
      return alertOK("error", msg);
    } else if (code !== 200) {
      //自定义消息提示
      return alertOK("error", msg);
    } else {
      //检查返回值是否为blob数据
      if (res.data instanceof Blob) {
        return res.data;
      } else {
        //通过
        if (res.data.result == "SUCCESS") {
          return res.data;
        } else if (res.data.result == "success") {
          return res.data;
        } else if (res.data.result == "LOGIN_FAILED") {
          alertOK("warning", "token已失效请重新登陆");
          router.push("/Login");
          Cookies.remove("token_code");
          sessionStorage.clear();
          hideLoading();
        } else if (
          res.data.result == "QUOTE_FAILED" ||
          res.data.result == "SPACE_FAILED"
        ) {
          alertOK("error", res.data.msg);
          hideLoading();
          return res.data;
        } else {
          alertOK("error", res.data.msg);
          hideLoading();
          return;
          // console.log(res);
        }
      }
    }
  },
  (error) => {
    // console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "网络异常,请重试";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    //自定义消息提示
    hideLoading();
    return alertOK("error", message);
  }
);

export default service;
