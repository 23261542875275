var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentdiv"},[_c('div',{staticClass:"titlediv"},[_c('p',[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.BackPrevious}},[_c('svg-icon',{staticClass:"svgClass",attrs:{"icon-class":"BackPrevious"}})],1),_c('span',{staticClass:"spanClass"},[_vm._v(_vm._s(this.title + _vm.$t("TermBaseManagement.Operationrecord1")))])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"tableborderClass tableClass borderTableStyle",class:_vm.tableData.length == 0 ? 'tablebtmborder' : ''},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"row-style":{
          height: 40 + 'px',
          background: '#393c4e',
          color: '#ffffff',
        },"cell-style":{ padding: 0 + 'px' },"header-cell-style":{
          height: 40 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#454758',
        },"data":_vm.tableData,"tooltip-effect":"dark","border":""}},[_c('div',{staticClass:"newnoDataDiv",attrs:{"slot":"empty"},slot:"empty"},[_c('svg-icon',{staticClass:"noDataicon",attrs:{"icon-class":"NoData"}}),_c('p',[_vm._v(_vm._s(_vm.$t("CommonName.noData")))])],1),_c('el-table-column',{attrs:{"prop":"create_time","label":_vm.$t('TermBaseManagement.OperationTime'),"width":"200"}}),_c('el-table-column',{attrs:{"prop":"content","label":_vm.$t('TermBaseManagement.Operationcontent'),"label-class-name":"labelClassName"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }