<!--
 * @Description: 任务记录
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-22 10:16:05
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-23 15:57:59
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <span class="spanClass">{{
          this.title + $t("MemberDetails.details")
        }}</span>
      </p>
    </div>
    <div class="content">
      <div class="contenttop">
        <div style="margin-right: 24px">
          <el-select
            v-model="projectSelect"
            class="selectclass"
            @change="projectSelectChange"
            filterable
          >
            <el-option
              v-for="item in projectList"
              :key="item.project_id"
              :label="item.project_name"
              :value="item.project_id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-right: 24px">
          <el-date-picker
            v-model="time"
            type="daterange"
            :range-separator="$t('MemberDetails.datetime2')"
            :start-placeholder="$t('MemberDetails.datetime1')"
            :end-placeholder="$t('MemberDetails.datetime3')"
            unlink-panels
            :editable="false"
            value-format="yyyy-MM-dd"
            :format="time_format"
            @change="daterangeChange"
            style="width: 320px"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button type="text">
            <div class="svgbtn" @click="exportMemberData">
              <!-- 导出数据 -->
              <svg-icon icon-class="export" class="icon"></svg-icon>
              <p>{{ $t("MemberDetails.exportData") }}</p>
            </div>
          </el-button>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="contentCenter_tags screenStyle">
        <p>{{ $t("CommonName.screen") }}</p>
        <el-tag
          v-for="tag in tags"
          :key="tag.name"
          closable
          :type="tag.type"
          @close="handleClose(tag)"
        >
          <p
            class="screenp"
            :id="'tag' + tag.id"
            @mouseover="onShowNameTipsMouseenter(tag)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="tag.name"
              placement="top-start"
              :disabled="!tag.showdropdown"
            >
              <span>{{ tag.name }}</span>
            </el-tooltip>
          </p>
        </el-tag>
      </div>
      <div class="contentbtm tableClass">
        <el-table
          :row-style="{
            height: 48 + 'px',
            background: '#393c4e',
            color: '#cbd7e8',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 48 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#393c4e',
          }"
          ref="multipleTable"
          :data="
            tableData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="sortChange"
        >
          <div slot="empty" class="newnoDataDiv" style="margin-top: 50px">
            <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
            <p>{{ $t("CommonName.noData") }}</p>
          </div>
          <el-table-column :min-width="140">
            <template slot="header">
              <!-- 任务名称 -->
              <div class="tableheaderdiv">
                <p class="namep">{{ $t("CommonName.taskname") }}</p>
                <div class="icondiv">
                  <el-popover
                    placement="bottom-end"
                    width="278"
                    v-model="visible"
                    :visible-arrow="false"
                  >
                    <div class="popoverDiv">
                      <el-input
                        v-model="popoverInput"
                        :placeholder="$t('CommonName.search')"
                        maxlength="100"
                      ></el-input>
                      <el-button
                        type="primary"
                        class="setpadding16"
                        @click="determineClick"
                        >{{ $t("CommonName.determine") }}</el-button
                      >
                    </div>
                    <svg-icon
                      icon-class="Filter"
                      class="iconsize"
                      slot="reference"
                    ></svg-icon>
                  </el-popover>

                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="menuscopediv overflowEllipsis">
                {{ scope.row.job_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <!-- 任务内容 -->
              <div class="tableheaderdiv">
                <p>{{ $t("MemberDetails.taskcontent") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="taskcontentcommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in workflowDropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div
                v-if="scope.row.workflow < 4"
                class="taskcontentStyle"
                :class="workflowDropdownList[scope.row.workflow - 1].class"
              >
                {{ workflowDropdownList[scope.row.workflow - 1].name }}
              </div>
              <div v-else>
                {{ workflowDropdownList[scope.row.workflow - 1].name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <!-- 状态 -->
              <div class="tableheaderdiv">
                <p>{{ $t("CommonName.status") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="statuscommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in statusdropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.status == "10"
                    ? $t("ProjectManagement.haveinhand")
                    : $t("CommonName.Completed")
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <!-- 源语言 -->
              <div class="tableheaderdiv">
                <p>{{ $t("CommonName.sourcelanguagetable") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="sourcelanguagecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in sourcelanguagedropdownList"
                        :key="item.language_id"
                        :command="{ name: item.ename, value: item.language_id }"
                      >
                        <span>{{ item.ename }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="overflowEllipsis">
                {{ scope.row.slang_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <!-- 目标语言 -->
              <div class="tableheaderdiv">
                <p>{{ $t("CommonName.targetlanguagetable") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="targetlanguagecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in targetlanguagedropdownList"
                        :key="item.language_id"
                        :command="{ name: item.ename, value: item.language_id }"
                      >
                        <span>{{ item.ename }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="overflowEllipsis">
                {{ scope.row.tlang_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <!-- 时长 -->
              <div class="tableheaderdiv">
                <p>{{ $t("CommonName.duration") }}</p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="overflowEllipsis">
                {{ scope.row.duration }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <!-- 字数 -->
              <div class="tableheaderdiv">
                <p>{{ $t("CommonName.Numberofwords") }}</p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.word_count }}
              </div>
            </template>
          </el-table-column>
          <!-- 创建时间 -->
          <el-table-column
            prop="create_time"
            sortable
            :min-width="100"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span>{{ $t("MemberDetails.createTime") }}</span>
              <span class="tableheaderspan"></span>
            </template>
          </el-table-column>
          <!-- 提交时间 -->
          <el-table-column
            prop="delivery_time"
            sortable
            :min-width="100"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span class="tableheaderspantop">{{
                $t("MemberDetails.Submissiontime")
              }}</span>
            </template>
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.delivery_time != "" ? scope.row.delivery_time : "--"
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="margin-top: 20px; float: right; margin-right: 20px"
          v-show="tableData.length > 0"
        >
          <PaginationComVue
            :tableData="tableData"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></PaginationComVue>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import PaginationComVue from "@/components/PaginationCom.vue";
import { timestampToTime } from "@/api/setTime";
import {
  get_member_job,
  get_language,
  get_project_list,
  export_member_data,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import config from "@/config";
export default {
  components: {
    PaginationComVue,
  },
  created() {
    this.$emit("header", false, false, true, true, true);
    this.title = this.$route.query.name;
    this.member_id = this.$route.query.member_id;
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    this.time = [
      timestampToTime(start).split(" ")[0],
      timestampToTime(end).split(" ")[0],
    ];
  },
  data() {
    return {
      visible: false, //名称筛选
      popoverInput: "",
      title: "", //名称
      member_id: "",
      tableData: [
        // {
        //   taskname: "Task Name.mp4",
        //   createTime: "2022-08-12 18:09",
        //   taskcontent: "转写",
        // },
        // {
        //   taskcontent: "翻译",
        // },
      ],
      time: [], //选择日期
      input: "",
      projectSelect: "0",
      projectList: [
        {
          project_id: "0",
          project_name: this.$t("MemberManagement.AllProject"),
        },
      ],
      tags: [],
      workflowDropdownList: [
        {
          id: "1",
          name: this.$t("CommonName.statusName1"), //"转写",
          class: "blueClass",
        },
        {
          id: "2",
          name: this.$t("CommonName.statusName2"), // "翻译",
          class: "greenClass",
        },
        {
          id: "3",
          name: this.$t("CommonName.statusName3"), //"校对",
          class: "yellowClass",
        },
        {
          id: "4",
          name: this.$t("CommonName.statusName10"), //"声纹定制",
          class: "",
        },
        {
          id: "5",
          name: this.$t("CommonName.statusName11"), //"视频克隆",
          class: "",
        },
      ],
      statusdropdownList: [
        {
          id: "10",
          name: this.$t("ProjectManagement.haveinhand"),
        },
        {
          id: "20",
          name: this.$t("CommonName.Completed"),
        },
      ],
      // 源语言筛选 下拉框
      sourcelanguagedropdownList: [],
      // 目标语言 下拉框
      targetlanguagedropdownList: [],
      older: "create_time",
      sort: "desc",
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      baseUrl: config.baseUrl.dev, //请求地址
      time_format:
        sessionStorage.getItem("lang") == "en" ? "MMM dd yyyy" : "yyyy-MM-dd",
    };
  },
  methods: {
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.sourcelanguagedropdownList = res.list;
          this.targetlanguagedropdownList = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取成员任务信息} id
     */
    get_member_job() {
      let data = { action: "get_member_job", member_id: this.member_id };
      if (this.projectSelect !== "0") {
        data["project_id"] = this.projectSelect;
      }
      if (this.time !== null && this.time.length == 2) {
        data["start"] = this.time[0];
        data["end"] = this.time[1];
      }
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      get_member_job(data).then((res) => {
        if (res) {
          this.tableData = res.list;
          hideLoading();
        }
      });
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.get_member_job();
    },
    /**
     * 表格表头 名称 筛选 按钮
     */
    determineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("CommonName.taskname"),
          this.popoverInput,
          this.tags,
          "job_name",
          this.popoverInput
        );
      }
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
    },
    /**
     *
     * @param {* 任务内容 点击筛选 下拉} command
     */
    taskcontentcommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("MemberDetails.taskcontent"),
        command.name,
        this.tags,
        "workflow",
        command.value
      );
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
    },
    /**
     *
     * @param {*目标语言} command
     */
    targetlanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.targetlanguagetable"),
        command.name,
        this.tags,
        "tlang_id",
        command.value
      );
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
      this.get_member_job();
    },
    /**
     *
     * @param {*源语言} command
     */
    sourcelanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.sourcelanguagetable"),
        command.name,
        this.tags,
        "slang_id",
        command.value
      );
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
      this.get_member_job();
    },
    /**
     *
     * @param {表头状态 下拉框} command
     */
    statuscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.status"),
        command.name,
        this.tags,
        "status",
        command.value
      );
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
    },
    daterangeChange() {
      // console.log(this.time);
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
    },
    /**
     * 获取 项目列表数据
     */
    get_project_list() {
      return get_project_list({
        action: "get_project_name_list",
      }).then((res) => {
        if (res.result == "SUCCESS") {
          // console.log(res);
          this.projectList = [...this.projectList, ...res.list];
          hideLoading();
        } else {
          this.$messages("error", res.msg);
          hideLoading();
        }
      });
    },
    projectSelectChange(value) {
      this.get_member_job();
      setTimeout(() => {
        this.currentPage = 1;
      }, 100);
    },
    exportMemberData() {
      let url = this.baseUrl + "/member/export_member_data?";
      url += "member_id=" + this.member_id;
      url += "&sheetname=" + this.title + this.$t("MemberDetails.details");
      if (this.projectSelect !== "0") {
        url += "&project_id=" + this.projectSelect;
      }
      if (this.time !== null && this.time.length == 2) {
        url += "&start=" + timestampToTime(this.time[0]);
        url += "&end=" + timestampToTime(this.time[1]);
      }
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        url += "&" + item.key + "=" + item.value;
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
      // let data = { action: "export_member_data", member_id: this.member_id };
      // if (this.projectSelect !== "0") {
      //   data["project_id"] = this.projectSelect;
      // }
      // if (this.time !== null && this.time.length == 2) {
      //   data["start"] = this.time[0];
      //   data["end"] = this.time[1];
      // }
      // if (this.older != "" && this.sort != "") {
      //   data["older"] = this.older;
      //   data["sort"] = this.sort;
      // }
      // this.tags.forEach((item, index) => {
      //   data[item.key] = item.value;
      // });
      // export_member_data(data).then((res) => {
      //   if (!res) return;
      //   const blob = new Blob([res], {
      //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      //   });
      //   let downloadElement = document.createElement("a");
      //   let href = window.URL.createObjectURL(blob); //创建下载的链接
      //   downloadElement.href = href;
      //   downloadElement.download =
      //     this.title + this.$t("MemberDetails.details") + `.xlsx`; //下载后文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); //点击下载
      //   document.body.removeChild(downloadElement); //下载完成移除元素
      //   window.URL.revokeObjectURL(href);
      // });
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_project_list();
    this.get_language();
    this.get_member_job();
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 350;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 350;
    };
  },
};
</script>
<style scoped lang="scss">
.contenttop {
  padding: 24px 0 16px 24px;
  display: flex;
  align-items: center;

  ::v-deep .el-input__icon {
    line-height: 32px;
  }
  ::v-deep .el-input__inner {
    width: 160px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 160px;
  }
}
.contentbtm {
  height: calc(100% - 116px);
}
.namep,
.menuscopediv {
  padding-left: 14px;
}
.taskcontentStyle {
  width: 36px;
  height: 22px;
  background-color: #3b99e3;
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}
.blueClass {
  background-color: #3b99e3;
}
.greenClass {
  background-color: #41b293;
}
.yellowClass {
  background-color: #d48d47;
}
.tabledropdowndiv {
  max-height: 210px;
  overflow-y: auto;
}
</style>
