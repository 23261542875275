<!--
 * @Description: 术语库管理 操作记录
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-25 10:13:06
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-18 09:14:10
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <span class="spanClass">{{
          this.title + $t("TermBaseManagement.Operationrecord1")
        }}</span>
      </p>
    </div>
    <div class="content">
      <div
        class="tableborderClass tableClass borderTableStyle"
        :class="tableData.length == 0 ? 'tablebtmborder' : ''"
      >
        <el-table
          :row-style="{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
        >
          <div slot="empty" class="newnoDataDiv">
            <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
            <p>{{ $t("CommonName.noData") }}</p>
          </div>
          <!--操作时间  -->
          <el-table-column
            prop="create_time"
            :label="$t('TermBaseManagement.OperationTime')"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="content"
            :label="$t('TermBaseManagement.Operationcontent')"
            label-class-name="labelClassName"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { get_termchange_log } from "@/utils/https";
export default {
  created() {
    this.$emit("header", false, false, true, true, true);
    this.title = this.$route.query.name;
    this.term_file_id = this.$route.query.id;
  },
  data() {
    return {
      maxHeight: 735,
      title: "",
      term_file_id: 0,
      tableData: [],
    };
  },
  methods: {
    get_termchange_list() {
      get_termchange_log({
        action: "get_termchange_log",
        term_file_id: this.term_file_id,
      }).then((res) => {
        console.log(res);
        if (!res) {
          return;
        }
        this.tableData = res.list;
      });
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.get_termchange_list();
  },
};
</script>
<style lang="scss" scoped>
.content {
  .tableborderClass {
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    overflow-y: auto;
  }
}
</style>
