/**
 * @Description: 查找方法
 * @param {string}  find_text 查找的内容
 * @param {array}  data 被查找的数据
 * @param {string}  mode 1 原文 ；2 译文
 * @param {string} match_case 是否大小写匹配 1：是
 * @param {string} match_whole 是否整词匹配 1: 是
 * @param {number} current_tr 当前匹配tr位置
 * @param {number} current_index 同一句段当前匹配位置
 * @param {number} find_direction  查找方向 0：向上； 1：向下
 * @return {Object}
 */
export function find_next(
  find_content,
  data,
  mode,
  match_case,
  match_whole,
  current_tr,
  current_index,
  find_direction = 1
) {
  let inline_index = current_index;
  let arr = JSON.parse(JSON.stringify(data));
  let tr_index = current_tr;
  if (current_tr == -1) {
    if (find_direction == 0) {
      tr_index = arr.length - 1;
    } else {
      tr_index = 0;
    }
  }
  // console.log("current_index", current_index);
  if (find_content.length == 0) {
    let result = { status: false, errorCode: 0 };
    return result;
  }
  if (data.length == 0) {
    let result = { status: false, errorCode: 1 };
    return result;
  }
  //检测内容是否是中文
  var pattern = new RegExp("[\u4E00-\u9FA5]+");
  if (pattern.test(find_content)) {
    match_whole = 0;
  }
  var special_html_label = /&amp;|&lt;|&gt;|&nbsp;/g;
  //var html_label = /<img\s[^<>]+>/g;
  var html_label = /<(img|font)\s[^<>]+>|<\/font>/g;
  find_content = label_encode_e(find_content);
  var search_length = find_content.length;
  var special_char = /[.?!+|[(\)#$%^*\\/]/g;
  var special_find = find_content.match(special_char);
  if (special_find) {
    find_content = label_special_transfer(find_content);
  }
  let req, regEvery;
  let match_whole_type_str = "";
  if (match_whole == 1) {
    match_whole_type_str = "\\b";
  }
  if (match_case == "1") {
    eval(
      "req = /" +
        match_whole_type_str +
        find_content +
        match_whole_type_str +
        "/;"
    );
    eval(
      "regEvery = /" +
        match_whole_type_str +
        find_content +
        match_whole_type_str +
        "/g;"
    );
  } else {
    eval(
      "req = /" +
        match_whole_type_str +
        find_content +
        match_whole_type_str +
        "/i;"
    );
    eval(
      "regEvery = /" +
        match_whole_type_str +
        find_content +
        match_whole_type_str +
        "/gi;"
    );
  }
  // console.log(req);
  var regExp = new RegExp(req);
  var inline_regExp = new RegExp(regEvery);
  let isHaveFind = false;
  let isFindRes = false;
  let result = {};
  var img_label_flg;
  var position_arr = new Array();
  var find_arr;
  var special_label_regExp;
  var special_label_arr;
  var find_flg;
  var replace_str;
  var replaceText;
  var new_content;
  if (find_direction == 0) {
    //向上查找
    for (let index = arr.length - 1; index >= 0; index--) {
      arr[index].sentence_trans_find = "";
      arr[index].sentence_source_find = "";
      arr[index].sentence_edit_find = "";
      if (isFindRes) {
        continue;
      }
      if (tr_index == index) {
        let current_trans_content = "";
        if (mode == "2") {
          current_trans_content = arr[index].sentence_trans_html;
        } else if (mode == "3") {
          current_trans_content = arr[index].sentence_edit_html;
        } else {
          current_trans_content = arr[index].sentence_source_html;
        }
        img_label_flg = new RegExp(html_label);
        if (img_label_flg.test(current_trans_content)) {
          position_arr = label_position_arr(current_trans_content, html_label);
        }
        if (regExp.test(current_trans_content)) {
          find_arr = find_position_arr(current_trans_content, inline_regExp);
          find_arr = remove_element(find_arr, position_arr);
          special_label_regExp = new RegExp(special_html_label);
          if (!special_label_regExp.test(find_content)) {
            special_label_arr = label_position_arr(
              current_trans_content,
              special_html_label
            );
            find_arr = remove_element(find_arr, special_label_arr);
          }
          // console.log("find_arr", find_arr);
          // console.log("current_trans_content", current_trans_content);
          if (inline_index == -1) {
            inline_index = current_trans_content.length;
          }
          // console.log("inline_index", inline_index);
          if (find_arr.length > 1) {
            find_flg = false;
            for (let fi = find_arr.length - 1; fi >= 0; fi--) {
              replace_str = find_arr[fi].content;
              replaceText =
                '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
                tr_index +
                '" t_i_cnt="' +
                find_arr.length +
                '" c_i_cnt="' +
                fi +
                '">' +
                replace_str +
                "</span>";
              if (inline_index > find_arr[fi].start) {
                new_content = replaceByPos(
                  current_trans_content,
                  find_arr[fi].start,
                  search_length,
                  replaceText
                );

                if (mode == "2") {
                  arr[index].sentence_trans_find = new_content;
                } else if (mode == "3") {
                  arr[index].sentence_edit_find = new_content;
                } else {
                  arr[index].sentence_source_find = new_content;
                }

                find_flg = true;
                inline_index = find_arr[fi].start;
              }
              if (find_flg) {
                let new_data = {
                  find_content,
                  data: arr,
                  mode,
                  match_case,
                  match_whole,
                  current_tr: tr_index,
                  current_index: inline_index,
                };
                let cur_tr_data = {
                  start: find_arr[fi].start,
                  len: search_length,
                  content: current_trans_content,
                  sentence_id: arr[index].sentence_id,
                };
                result = { status: true, data: new_data, cur_tr_data };
                isFindRes = true;
                break;
              }
            }
            if (!find_flg) {
              // console.log("find_arr1");
              tr_index--;
              inline_index = -1;
              continue;
            }
          } else if (find_arr.length == 1) {
            if (inline_index <= find_arr[0].start) {
              // console.log("find_arr2");
              tr_index--;
              inline_index = -1;
              continue;
            }
            replace_str = find_arr[0].content;
            replaceText =
              '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
              tr_index +
              '" t_i_cnt="1" c_i_cnt="0">' +
              replace_str +
              "</span>";
            new_content = replaceByPos(
              current_trans_content,
              find_arr[0].start,
              search_length,
              replaceText
            );
            if (mode == "2") {
              arr[index].sentence_trans_find = new_content;
            } else if (mode == "3") {
              arr[index].sentence_edit_find = new_content;
            } else {
              arr[index].sentence_source_find = new_content;
            }
            // tr_index--;
            inline_index = find_arr[0].start;
            let new_data = {
              find_content,
              data: arr,
              mode,
              match_case,
              match_whole,
              current_tr: tr_index,
              current_index: inline_index,
              find_direction,
            };
            let cur_tr_data = {
              start: find_arr[0].start,
              len: search_length,
              content: current_trans_content,
              sentence_id: arr[index].sentence_id,
            };
            result = { status: true, data: new_data, cur_tr_data };
            isFindRes = true;
            continue;
          } else {
            tr_index--;
            inline_index = -1;
          }
        } else {
          tr_index--;
          inline_index = -1;
        }
      }
    }
    if (isFindRes) {
      return result;
    }
    //如果没有查询到结果 就结尾开始继续查一遍
    tr_index = arr.length - 1;
    inline_index = -1;
    for (let index = arr.length - 1; index >= 0; index--) {
      arr[index].sentence_trans_find = "";
      arr[index].sentence_source_find = "";
      arr[index].sentence_edit_find = "";
      if (isFindRes) {
        continue;
      }
      if (tr_index == index) {
        let current_trans_content = "";
        if (mode == "2") {
          current_trans_content = arr[index].sentence_trans_html;
        } else if (mode == "3") {
          current_trans_content = arr[index].sentence_edit_html;
        } else {
          current_trans_content = arr[index].sentence_source_html;
        }
        img_label_flg = new RegExp(html_label);
        if (img_label_flg.test(current_trans_content)) {
          position_arr = label_position_arr(current_trans_content, html_label);
        }
        if (regExp.test(current_trans_content)) {
          find_arr = find_position_arr(current_trans_content, inline_regExp);
          find_arr = remove_element(find_arr, position_arr);
          special_label_regExp = new RegExp(special_html_label);
          if (!special_label_regExp.test(find_content)) {
            special_label_arr = label_position_arr(
              current_trans_content,
              special_html_label
            );
            find_arr = remove_element(find_arr, special_label_arr);
          }
          // console.log("find_arr", find_arr);
          // console.log("current_trans_content", current_trans_content);
          if (inline_index == -1) {
            inline_index = current_trans_content.length;
          }
          // console.log("inline_index", inline_index);
          if (find_arr.length > 1) {
            find_flg = false;
            for (let fi = find_arr.length - 1; fi >= 0; fi--) {
              replace_str = find_arr[fi].content;
              replaceText =
                '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
                tr_index +
                '" t_i_cnt="' +
                find_arr.length +
                '" c_i_cnt="' +
                fi +
                '">' +
                replace_str +
                "</span>";
              if (inline_index > find_arr[fi].start) {
                new_content = replaceByPos(
                  current_trans_content,
                  find_arr[fi].start,
                  search_length,
                  replaceText
                );

                // if (mode == "2") {
                //   arr[index].sentence_trans_find = new_content;
                // } else {
                //   arr[index].sentence_source_find = new_content;
                // }

                find_flg = true;
                inline_index = find_arr[fi].start;
              }
              if (find_flg) {
                let new_data = {
                  find_content,
                  data: arr,
                  mode,
                  match_case,
                  match_whole,
                  current_tr: tr_index,
                  current_index: inline_index,
                };
                let cur_tr_data = {
                  start: find_arr[fi].start,
                  len: search_length,
                  content: current_trans_content,
                  sentence_id: arr[index].sentence_id,
                };
                result = {
                  status: false,
                  data: new_data,
                  cur_tr_data,
                  errorCode: 2,
                };
                // result = { status: true, data: new_data, cur_tr_data };
                isFindRes = true;
                break;
              }
            }
            if (!find_flg) {
              // console.log("find_arr1");
              tr_index--;
              inline_index = -1;
              continue;
            }
          } else if (find_arr.length == 1) {
            if (inline_index <= find_arr[0].start) {
              // console.log("find_arr2");
              tr_index--;
              inline_index = -1;
              continue;
            }
            replace_str = find_arr[0].content;
            replaceText =
              '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
              tr_index +
              '" t_i_cnt="1" c_i_cnt="0">' +
              replace_str +
              "</span>";
            new_content = replaceByPos(
              current_trans_content,
              find_arr[0].start,
              search_length,
              replaceText
            );
            // if (mode == "2") {
            //   arr[index].sentence_trans_find = new_content;
            // } else {
            //   arr[index].sentence_source_find = new_content;
            // }
            // tr_index--;
            inline_index = find_arr[0].start;
            let new_data = {
              find_content,
              data: arr,
              mode,
              match_case,
              match_whole,
              current_tr: tr_index,
              current_index: inline_index,
              find_direction,
            };
            let cur_tr_data = {
              start: find_arr[0].start,
              len: search_length,
              content: current_trans_content,
              sentence_id: arr[index].sentence_id,
            };
            result = {
              status: false,
              data: new_data,
              cur_tr_data,
              errorCode: 2,
            };
            // result = { status: true, data: new_data, cur_tr_data };
            isFindRes = true;
            continue;
          } else {
            tr_index--;
            inline_index = -1;
          }
        } else {
          tr_index--;
          inline_index = -1;
        }
      }
    }
    if (isFindRes) {
      return result;
    }
  } else {
    for (let index = 0; index < arr.length; index++) {
      arr[index].sentence_trans_find = "";
      arr[index].sentence_source_find = "";
      arr[index].sentence_edit_find = "";
      if (isFindRes) {
        continue;
      }
      if (tr_index == index) {
        let current_trans_content = "";
        if (mode == "2") {
          current_trans_content = arr[index].sentence_trans_html;
        } else if (mode == "3") {
          current_trans_content = arr[index].sentence_edit_html;
        } else {
          current_trans_content = arr[index].sentence_source_html;
        }
        img_label_flg = new RegExp(html_label);
        if (img_label_flg.test(current_trans_content)) {
          position_arr = label_position_arr(current_trans_content, html_label);
        }
        if (regExp.test(current_trans_content)) {
          find_arr = find_position_arr(current_trans_content, inline_regExp);
          find_arr = remove_element(find_arr, position_arr);
          special_label_regExp = new RegExp(special_html_label);
          if (!special_label_regExp.test(find_content)) {
            special_label_arr = label_position_arr(
              current_trans_content,
              special_html_label
            );
            find_arr = remove_element(find_arr, special_label_arr);
          }
          console.log("find_arr", find_arr);
          // console.log("current_trans_content", current_trans_content);
          if (find_arr.length > 1) {
            if (inline_index == -1) {
              inline_index = 0;
            }
            find_flg = false;
            for (let fi = 0; fi < find_arr.length; fi++) {
              replace_str = find_arr[fi].content;
              replaceText =
                '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
                tr_index +
                '" t_i_cnt="' +
                find_arr.length +
                '" c_i_cnt="' +
                fi +
                '">' +
                replace_str +
                "</span>";
              if (inline_index <= find_arr[fi].start) {
                new_content = replaceByPos(
                  current_trans_content,
                  find_arr[fi].start,
                  search_length,
                  replaceText
                );

                if (mode == "2") {
                  arr[index].sentence_trans_find = new_content;
                } else if (mode == "3") {
                  arr[index].sentence_edit_find = new_content;
                } else {
                  arr[index].sentence_source_find = new_content;
                }

                find_flg = true;
                inline_index = find_arr[fi].start;
              }
              if (find_flg) {
                let new_data = {
                  find_content,
                  data: arr,
                  mode,
                  match_case,
                  match_whole,
                  current_tr: tr_index,
                  current_index: inline_index,
                  find_direction,
                };
                let cur_tr_data = {
                  start: find_arr[fi].start,
                  len: search_length,
                  content: current_trans_content,
                  sentence_id: arr[index].sentence_id,
                };
                result = { status: true, data: new_data, cur_tr_data };
                isFindRes = true;
                break;
              }
            }
            if (!find_flg) {
              // console.log("find_arr1");
              tr_index++;
              inline_index = -1;
              continue;
            }
          } else if (find_arr.length == 1) {
            if (inline_index > find_arr[0].start) {
              // console.log("find_arr2");
              tr_index++;
              inline_index = -1;
              continue;
            }
            replace_str = find_arr[0].content;
            replaceText =
              '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
              tr_index +
              '" t_i_cnt="1" c_i_cnt="0">' +
              replace_str +
              "</span>";
            new_content = replaceByPos(
              current_trans_content,
              find_arr[0].start,
              search_length,
              replaceText
            );
            if (mode == "2") {
              arr[index].sentence_trans_find = new_content;
            } else if (mode == "3") {
              arr[index].sentence_edit_find = new_content;
            } else {
              arr[index].sentence_source_find = new_content;
            }
            // tr_index++;
            inline_index = find_arr[0].start;
            let new_data = {
              find_content,
              data: arr,
              mode,
              match_case,
              match_whole,
              current_tr: tr_index,
              current_index: inline_index,
              find_direction,
            };
            let cur_tr_data = {
              start: find_arr[0].start,
              len: search_length,
              content: current_trans_content,
              sentence_id: arr[index].sentence_id,
            };
            result = { status: true, data: new_data, cur_tr_data };
            isFindRes = true;
            continue;
          } else {
            tr_index++;
            inline_index = -1;
          }
        } else {
          tr_index++;
          inline_index = -1;
        }
      }
    }
    if (isFindRes) {
      return result;
    }
    //如果没有查询到结果 就重头开始继续查一遍
    tr_index = 0;
    inline_index = -1;
    for (let index = 0; index < arr.length; index++) {
      arr[index].sentence_trans_find = "";
      arr[index].sentence_source_find = "";
      arr[index].sentence_edit_find = "";
      if (isFindRes) {
        continue;
      }
      if (tr_index == index) {
        let current_trans_content = "";
        if (mode == "2") {
          current_trans_content = arr[index].sentence_trans_html;
        } else if (mode == "3") {
          current_trans_content = arr[index].sentence_edit_html;
        } else {
          current_trans_content = arr[index].sentence_source_html;
        }
        img_label_flg = new RegExp(html_label);
        if (img_label_flg.test(current_trans_content)) {
          position_arr = label_position_arr(current_trans_content, html_label);
        }
        if (regExp.test(current_trans_content)) {
          find_arr = find_position_arr(current_trans_content, inline_regExp);
          find_arr = remove_element(find_arr, position_arr);
          special_label_regExp = new RegExp(special_html_label);
          if (!special_label_regExp.test(find_content)) {
            special_label_arr = label_position_arr(
              current_trans_content,
              special_html_label
            );
            find_arr = remove_element(find_arr, special_label_arr);
          }
          // console.log("find_arr", find_arr);
          // console.log("current_trans_content", current_trans_content);
          if (find_arr.length > 1) {
            if (inline_index == -1) {
              inline_index = 0;
            }
            find_flg = false;
            for (let fi = 0; fi < find_arr.length; fi++) {
              replace_str = find_arr[fi].content;
              replaceText =
                '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
                tr_index +
                '" t_i_cnt="' +
                find_arr.length +
                '" c_i_cnt="' +
                fi +
                '">' +
                replace_str +
                "</span>";
              if (inline_index <= find_arr[fi].start) {
                new_content = replaceByPos(
                  current_trans_content,
                  find_arr[fi].start,
                  search_length,
                  replaceText
                );

                // if (mode == "2") {
                //   arr[index].sentence_trans_find = new_content;
                // } else {
                //   arr[index].sentence_source_find = new_content;
                // }

                find_flg = true;
                inline_index = find_arr[fi].start;
              }
              if (find_flg) {
                let new_data = {
                  find_content,
                  data: arr,
                  mode,
                  match_case,
                  match_whole,
                  current_tr: tr_index,
                  current_index: inline_index,
                  find_direction,
                };
                let cur_tr_data = {
                  start: find_arr[fi].start,
                  len: search_length,
                  content: current_trans_content,
                  sentence_id: arr[index].sentence_id,
                };
                result = {
                  status: false,
                  data: new_data,
                  cur_tr_data,
                  errorCode: 2,
                };
                isFindRes = true;
                break;
              }
            }
            if (!find_flg) {
              // console.log("find_arr1");
              tr_index++;
              inline_index = -1;
              continue;
            }
          } else if (find_arr.length == 1) {
            if (inline_index > find_arr[0].start) {
              // console.log("find_arr2");
              tr_index++;
              inline_index = -1;
              continue;
            }
            replace_str = find_arr[0].content;
            replaceText =
              '<span class="highlight" style="background:#2196f3" c_r_cnt="' +
              tr_index +
              '" t_i_cnt="1" c_i_cnt="0">' +
              replace_str +
              "</span>";
            new_content = replaceByPos(
              current_trans_content,
              find_arr[0].start,
              search_length,
              replaceText
            );
            // if (mode == "2") {
            //   arr[index].sentence_trans_find = new_content;
            // } else {
            //   arr[index].sentence_source_find = new_content;
            // }
            // tr_index++;
            inline_index = find_arr[0].start;
            let new_data = {
              find_content,
              data: arr,
              mode,
              match_case,
              match_whole,
              current_tr: tr_index,
              current_index: inline_index,
              find_direction,
            };
            let cur_tr_data = {
              start: find_arr[0].start,
              len: search_length,
              content: current_trans_content,
              sentence_id: arr[index].sentence_id,
            };
            result = {
              status: false,
              data: new_data,
              cur_tr_data,
              errorCode: 2,
            };
            isFindRes = true;
            continue;
          } else {
            tr_index++;
            inline_index = -1;
          }
        } else {
          tr_index++;
          inline_index = -1;
        }
      }
    }
    if (isFindRes) {
      return result;
    }
  }
  result = { status: false, errorCode: 3 };
  return result;
}
function find_position_arr(find_content, regStr) {
  var position_arr = new Array();
  //var label_regExp = new RegExp(regStr);
  var every_find;
  while ((every_find = regStr.exec(find_content)) != null) {
    var position = {};
    position["start"] = every_find.index;
    position["end"] = regStr.lastIndex;
    position["content"] = every_find[0];
    position_arr.push(position);
  }
  return position_arr;
}
function label_encode_e(s) {
  if (s.length == 0) return "";
  //s = s.replace(/&(?=[^>]*?(<|$))/g,"&amp;");
  //s = s.replace(/<(?=[^>]*?(<|$))/g,"&lt;");
  // s = s.replace(/&/g, "&amp;");
  s = s.replace(/</g, "&lt;");
  s = s.replace(/>/g, "&gt;");
  //s = s.replace(/ /g,"&nbsp;");
  //s = s.replace(/\'/g,"&#39;");
  //s = s.replace(/\"/g,"&quot;");
  //s = s.replace(/\n/g,"<br>");
  return s;
}
function label_decode(s) {
  //var s = str;
  if (s.length == 0) return "";
  // s = s.replace(/&amp;/g, "&");
  s = s.replace(/&lt;/g, "<");
  s = s.replace(/&gt;/g, ">");
  s = s.replace(/&nbsp;/g, " ");
  //s = s.replace(/&#39;/g,"\'");
  //s = s.replace(/&quot;/g,"\"");
  //s = s.replace(/<br>/g,"\n");
  //s = str.replace(/&nbsp;/g," ");
  return s;
}
function label_special_transfer(str) {
  var s = "";
  if (str.length == 0) return "";
  s = str.replace(/\\/g, "\\\\");
  s = s.replace(/\*/g, "\\*");
  //s = s.replace(/\&/g,'\\&');
  s = s.replace(/\./g, "\\.");
  s = s.replace(/\?/g, "\\?");
  s = s.replace(/\+/g, "\\+");
  s = s.replace(/\$/g, "\\$");
  s = s.replace(/\^/g, "\\^");
  s = s.replace(/\[/g, "\\[");
  s = s.replace(/\(/g, "\\(");
  s = s.replace(/\)/g, "\\)");
  s = s.replace(/\|/g, "\\|");
  s = s.replace(/\//g, "\\/");
  return s;
}
function label_position_arr(find_content, regStr) {
  var position_arr = new Array();
  var label_regExp = new RegExp(regStr);
  var every_find;
  while ((every_find = label_regExp.exec(find_content)) != null) {
    var position = {};
    position["start"] = every_find.index;
    position["end"] = label_regExp.lastIndex;
    position["content"] = every_find[0];
    position_arr.push(position);
  }
  return position_arr;
}
function remove_element(source_arr, target_arr) {
  var new_find_arr = Array();
  if (target_arr.length > 0) {
    for (let index = 0; index < source_arr.length; index++) {
      var exits_flg = false;
      for (let tindex = 0; tindex < target_arr.length; tindex++) {
        if (
          source_arr[index].start >= target_arr[tindex].start &&
          source_arr[index].end <= target_arr[tindex].end
        ) {
          exits_flg = true;
          break;
        }
      }
      if (!exits_flg) {
        //alert(JSON.stringify(s_val));
        new_find_arr.push(source_arr[index]);
      }
    }
  } else {
    new_find_arr = source_arr;
  }
  return new_find_arr;
}
function replaceByPos(strObj, start_index, searchLen, replaceText) {
  var len = strObj.length;
  var end_index = start_index + searchLen;
  var new_str =
    strObj.substr(0, start_index) +
    replaceText +
    strObj.substring(end_index, len);
  return new_str;
}
/*
    查找替换
 * @param {string}  replace_content 替换的字符串
 * @param {string}  content 被替换的字符串
 * @param {number}  start 替换起始位置
 * @param {number}  len   替换长度
 * @param {number}  find_direction  查找方向 0：向上； 1：向下
 * @return {Object}
*/
export function replace_find_content(
  replace_content,
  content,
  start,
  len,
  find_direction
) {
  let replace_content_html = label_encode(replace_content);
  let new_content_html = replaceByPos(
    content,
    start,
    len,
    replace_content_html
  );

  let new_content = label_decode(new_content_html);
  let new_start = start + replace_content_html.length - len - len;
  if (find_direction == 0) {
    new_start = start;
  }
  // console.log("start", start);
  // console.log("len", len);
  // console.log("replace_content_html.length", replace_content_html.length);
  // console.log("new_start", new_start);
  let result = {
    new_content: new_content,
    new_content_html: new_content_html,
    new_start: new_start,
  };

  return result;
}
function label_encode(s) {
  if (s.length == 0) return "";
  // s = str.replace(/&/g, "&amp;");
  s = s.replace(/</g, "&lt;");
  s = s.replace(/>/g, "&gt;");
  //s = s.replace(/ /g,"&nbsp;");
  //s = s.replace(/\'/g,"&#39;");
  //s = s.replace(/\"/g,"&quot;");
  //s = s.replace(/\n/g,"<br>");
  return s;
}
