<!--
 * @Description: TM管理详情
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-24 08:38:20
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-02 14:24:19
-->
<template>
  <div class="mainCenter">
    <div class="mainHeader">
      <div class="Detailstitlediv">
        <svg-icon
          icon-class="BackPrevious"
          class="svgClass"
          @click="goback"
        ></svg-icon>
        <div class="Splitline"></div>
        <p class="titlep">{{ title }}</p>
        <el-button type="text" @click="clickdetailedinformation">
          <div class="svgbtn">
            <svg-icon icon-class="Details" class="icon"></svg-icon>
            <p>{{ $t("TMManagement.detailedinformation") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="mainCenter_center">
      <el-input
        v-model="input"
        :placeholder="$t('TMManagement.placeholder')"
        @keyup.enter.native="SearchClick"
      ></el-input>
      <el-button type="primary" plain class="setpadding16" @click="SearchClick">
        <div class="btnClass">
          <p>{{ $t("TMManagement.Search") }}</p>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('TMManagement.tootipcontent')"
            placement="top-start"
          >
            <svg-icon icon-class="icon_tips"></svg-icon>
          </el-tooltip>
        </div>
      </el-button>
    </div>
    <div class="mainCenter_bottom tableClass">
      <el-table
        :row-style="{
          height: 40 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 40 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
      >
        <div slot="empty" class="newnoDataDiv" style="margin-top: 50px">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <el-table-column
          type="index"
          :index="indexMethod"
          width="70"
          label="#"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="source" :label="$t()" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="namep">
                {{ $t("TMManagement.tabletitle1") }} -
                {{ datainfo.slang_name }}
              </p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="target"
          :label="
            $t('TMManagement.tabletitle2') + ' - ' + datainfo.tlang_name + ''
          "
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <div
      style="margin-top: 20px; float: right; margin-right: 20px"
      v-show="PaginationShow && this.pageTotal > 0"
    >
      <PaginationComVue
        :tableData="[]"
        :pageSize="pageSize"
        :total="pageTotal"
        :currentPage="currentPage"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></PaginationComVue>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      :showfooter="false"
      v-if="dialogVisible"
    >
      <div class="dialogContent">
        <div class="dialogcontent_body">
          <div class="body">
            <div class="left">
              <p>{{ $t("TMManagement.TMName1") }}</p>
            </div>
            <div class="right right2 textareainput">
              <!-- <p>{{ datainfo.name }}</p> -->
              <el-input
                ref="inputref"
                v-model="datainfo.name"
                :maxlength="45"
                :disabled="autofocus"
                class="inputClass"
                @blur="inputblur"
              ></el-input>
              <el-button
                type="text"
                class="rightBtn"
                @click="EditInput"
                v-if="showEditbtn"
              >
                <div class="svgbtn">
                  <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  <p>{{ $t("TMManagement.edit") }}</p>
                </div>
              </el-button>
            </div>
          </div>
          <div class="body">
            <div class="left">
              <p>{{ $t("TMManagement.TMKey") }}</p>
            </div>
            <div class="right">
              <p>{{ datainfo.tm_key }}</p>
            </div>
          </div>
          <div class="body">
            <div class="left">
              <p>{{ $t("TMManagement.TMType") }}</p>
            </div>
            <div class="right">
              <p>
                {{
                  datainfo.type == "1"
                    ? $t("TMManagement.MainTM")
                    : $t("TMManagement.TemporaryTM")
                }}
              </p>
            </div>
          </div>
          <div class="body">
            <div class="left">
              <p>{{ $t("TMManagement.LanguagePair") }}</p>
            </div>
            <div class="right">
              <p>{{ datainfo.pair }}</p>
            </div>
          </div>
          <div class="body">
            <div class="left">
              <p>{{ $t("TMManagement.Segments") }}</p>
            </div>
            <div class="right right1">
              <p>{{ datainfo.segment }}</p>
            </div>
            <div class="left">
              <p>{{ $t("TMManagement.CreatedDate") }}</p>
            </div>
            <div class="right">
              <p>{{ datainfo.create_time }}</p>
            </div>
          </div>
          <div class="body body1 body2">
            <div class="left">
              <p>{{ $t("TMManagement.Comments") }}</p>
            </div>
            <div class="right textareainput">
              <el-input
                class="textareaClass"
                type="textarea"
                v-model="datainfo.comments"
                resize="none"
                :maxlength="150"
                :rows="3"
                @blur="textareablur"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import { get_tm_info, update_tm_info, get_tm_content } from "@/utils/https";

export default {
  components: {
    PaginationComVue,
    DialogComVue,
  },
  created() {
    // this.title = this.$route.query.name;
    this.tm_id = this.$route.query.id;
  },
  data() {
    return {
      showEditbtn: true,
      autofocus: true,
      dialogVisible: false,
      dialogtitle: "",
      tm_id: "",
      title: "",
      input: "",
      datainfo: {},
      tableData: [],
      maxheight1: "500",
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      PaginationShow: true,
      pageTotal: 0,
    };
  },
  mounted() {
    this.get_tm_info().then(() => {
      this.get_tm_content(this.input);
    });
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 350;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 350;
    };
  },
  methods: {
    // 修改名字 点击编辑按钮
    EditInput() {
      this.autofocus = false;
      this.showEditbtn = false;
      this.$nextTick(() => {
        this.$refs.inputref.focus(); // 视图出现后使input获取焦点
      });
    },
    /**
     * 修改名称
     */
    inputblur() {
      if (!this.datainfo.name) {
        this.$messages("warning", this.$t("TMManagement.warningmessage1"));
        return;
      }
      this.update_tm_info().then(() => {
        this.autofocus = true;
        this.showEditbtn = true;
      });
    },
    /**
     *修改备注
     */
    textareablur() {
      this.update_tm_info();
    },
    /**
     * 返回主页
     */
    goback() {
      this.$router.push("/ContainerHome/Team/TMManagement");
    },
    /**
     * 修改 名称和备注
     */
    update_tm_info() {
      return update_tm_info({
        action: "update_tm_info",
        tm_id: this.tm_id,
        name: this.datainfo.name,
        comments: this.datainfo.comments,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_tm_info().then(() => {
          this.$messages("success", this.$t("CommonName.successmessage3"));
        });
      });
    },

    /**
     * 获取 数据 数组
     */
    get_tm_info() {
      return get_tm_info({
        action: "get_tm_info",
        tm_id: this.tm_id,
      }).then((res) => {
        if (res) {
          // console.log(res.info);
          this.datainfo = res.info;
          this.title = res.info.name;
        }
      });
    },
    /**
     *
     * @param {*获取TM内容列表} input
     */
    get_tm_content(input) {
      return get_tm_content({
        action: "get_tm_content",
        tm_id: this.tm_id,
        content: input,
        page_current: this.currentPage,
        page_size: this.pageSize,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.tableData = res.list;
        this.pageTotal = res.total * 1;
        // console.log(res.list);
      });
    },
    /**
     * 点击 Search 按钮
     */
    SearchClick() {
      if (this.input) {
        this.PaginationShow = false;
        // this.$messages("warning", this.$t("TMManagement.warningmessage3"));
        // return;
      } else {
        this.PaginationShow = true;
      }
      this.currentPage = 1;
      this.get_tm_content(this.input);
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
    },
    /**
     * 详情信息 点击弹窗
     */
    clickdetailedinformation() {
      this.get_tm_info().then(() => {
        this.dialogVisible = true;
        this.autofocus = true;
        this.showEditbtn = true;
        this.dialogtitle = this.$t("TMManagement.TMdetailedinformation");
      });

      // this.get_tm_info().then(() => {

      // });
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.get_tm_content("");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.get_tm_content("");
    },
    indexMethod(index) {
      return index + (this.currentPage - 1) * this.pageSize + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainCenter {
  height: calc(100% - 24px);
  margin-top: 24px;

  .mainHeader {
    margin-left: 24px;
  }

  .btnClass {
    display: flex;
    align-items: center;

    p {
      margin-right: 6px;
    }
  }

  .mainCenter_center {
    margin-left: 24px;

    ::v-deep .el-input__inner {
      width: 280px;
    }

    .el-input {
      width: 280px;
      margin-right: 24px;
    }

    display: flex;
    align-items: center;
  }
}

.mainCenter_bottom {
  margin-top: 25px;

  ::v-deep .el-tooltip {
    white-space: pre-line !important;
    word-break: break-word;
  }
}

.dialogContent {
  margin: 24px;
}

.textareainput {
  .inputClass {
    margin: 0 10px;
  }

  ::v-deep .el-input.is-disabled .el-input__inner {
    border: #393c4e !important;
    // border: none !important;
  }

  ::v-deep .el-input__inner {
    width: 100%;
    padding: 0 5px;
    // border: 1px solid #7d7f8b !important;
  }

  .textareaClass {
    margin: 10px;
    width: 630px !important;
    height: 50px !important;

    // border: 1px !important;
    // border-color: #7d7f8b !important;
    border: 1px solid #7d7f8b;
    border-radius: 2px !important;
    padding: 5px !important;

    ::v-deep .el-textarea__inner {
      height: 100%;
      overflow-y: auto !important;
    }
  }

  ::v-deep .el-textarea__inner {
    // padding: 8px 9px !important;
    &:focus {
      background: none !important;
      color: #ffffff !important;
    }
  }
}
</style>
