<!--
 * @Description: 领取本月免费礼包
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-12 10:20:00
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-31 09:20:54
-->
<template>
  <el-dialog
    title=""
    :show-close="false"
    :visible.sync="centerDialogVisible1"
    width="888px"
    center
    custom-class="showGiftBagbtndialog"
    :close-on-click-modal="false"
  >
    <div class="showGiftBagbtndialog_div">
      <div class="showGiftBagbtndialog_div_div">
        <svg-icon icon-class="success" class="icon"></svg-icon>
        <!--        领取成功-->
        <p class="showGiftBagbtndialog_titlep">
          {{ $t("PersonalEndLanguage.Freegiftpackage") }}
        </p>
        <p>{{ $t("PersonalEndLanguage.Freegiftpackage1") }}</p>
        <p>
          {{
            $t("PersonalEndLanguage.Freegiftpackage2") + centerDialogVisibleTime
          }}
        </p>
      </div>
      <div class="showGiftBagbtndialog_div_btmdiv">
        <p>{{ $t("PersonalEndLanguage.Freegiftpackage3") }}</p>
        <div class="cardiv">
          <p>{{ $t("PersonalEndLanguage.Freegiftpackage4") }}</p>
          <p class="tipsp">
            {{ $t("PersonalEndLanguage.Freegiftpackage5") }}
          </p>
        </div>
        <div class="cardiv">
          <p>{{ $t("PersonalEndLanguage.Freegiftpackage6") }}</p>
          <p class="tipsp">
            {{ $t("PersonalEndLanguage.Freegiftpackage7") }}
          </p>
        </div>
        <div class="cardiv">
          <p>{{ $t("PersonalEndLanguage.Freegiftpackage8") }}</p>
          <p class="tipsp">
            {{ $t("PersonalEndLanguage.Freegiftpackage9") }}
          </p>
        </div>
        <!-- <div class="cardiv">
          <p>{{ $t("PersonalEndLanguage.Freegiftpackage10") }}</p>
          <p class="tipsp">
            {{ $t("PersonalEndLanguage.Freegiftpackage11") }}
          </p>
        </div> -->
      </div>
      <div class="showGiftBagbtndialog_div_btn">
        <el-button class="setpadding23" type="primary" @click="closeDialog">
          <p>{{ $t("PersonalEndLanguage.Freegiftpackage12") }}</p>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { get_free_quate } from "@/utils/https";
export default {
  props: {
    centerDialogVisible: {
      type: Boolean,
      default: false,
    },
    centerDialogVisibleTime: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      centerDialogVisible1: false,
    };
  },
  methods: {
    closeDialog() {
      // this.$messages("success", "领取成功");
      this.centerDialogVisible1 = false;
      this.$emit("closeGiftBagbtndialog", false);
    },
  },
  mounted() {
    this.centerDialogVisible1 = this.centerDialogVisible;
  },
};
</script>
<style lang="scss" scoped>
.showGiftBagbtndialog_div {
  margin: 0 60px;
  .showGiftBagbtndialog_div_div {
    text-align: center !important;
    .showGiftBagbtndialog_titlep {
      color: #ffffff;
      font-size: 18px;
      margin: 20px 0 16px 0;
    }
    .icon {
      width: 50.57px;
      height: 52.92px;
      color: #46adff;
      margin-top: 36px;
    }
    p {
      color: #ffffff;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }
  .showGiftBagbtndialog_div_btmdiv {
    margin-top: 14px;
    p {
      color: #ffffff;
      font-size: 14px;
      margin-left: 45px;
    }
    .cardiv {
      width: 768px;
      height: 74px;
      background-color: #454758;
      border-radius: 6px;
      margin-top: 16px;
      p {
        padding-top: 14px;
      }
      .tipsp {
        color: #adb0bc;
        margin-top: 10px;
        padding-top: 0;
      }
    }
  }
  .showGiftBagbtndialog_div_btn {
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
    padding-bottom: 35px;
  }
}
</style>
