/*
 * @Description: 响应code码对应提示
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:44:54
 * @LastEditors: Carful
 * @LastEditTime: 2022-10-19 14:51:38
 */
export default {
  401: "认证失败，无法访问系统资源",
  403: "当前操作没有权限",
  404: "访问资源不存在",
  default: "系统未知错误，请反馈给管理员",
};
