/*
 * @Description: 主播之间点击切换更新
 * @version:
 * @Author: Tom
 * @Date: 2022-10-28 14:05:23
 * @LastEditors: Tom
 * @LastEditTime: 2023-11-21 10:35:42
 */
export default {
  namespaced: true,
  state: {
    selectedAnchorId: 0, //主播设置时选中的主播id
    selectedAnchorName: 0, //主播设置时选中的主播名称
    selectedAnchorGender: 0, //主播设置时选中的主播性别
    playAnchorId: 0, //主播设置时播放的主播id
    collectAnchorGroup: [], //收藏主播组
    selectedRoleCollectId: 0, //收藏角色中选中的角色id
  },
  getters: {
    selectedAnchorId: (state) => state.selectedAnchorId,
    selectedAnchorName: (state) => state.selectedAnchorName,
    selectedAnchorGender: (state) => state.selectedAnchorGender,
    playAnchorId: (state) => state.playAnchorId,
    collectAnchorGroup: (state) => state.collectAnchorGroup,
    selectedRoleCollectId: (state) => state.selectedRoleCollectId,
  },
  mutations: {
    setSelectedAnchorId(state, value) {
      state.selectedAnchorId = value;
    },
    setSelectedAnchorName(state, value) {
      state.selectedAnchorName = value;
    },
    setSelectedAnchorGender(state, value) {
      state.selectedAnchorGender = value;
    },
    setPlayAnchorId(state, value) {
      state.playAnchorId = value;
    },
    setcollectAnchorGroup(state, value) {
      state.collectAnchorGroup = value;
    },
    setSelectedRoleCollectId(state, value) {
      state.selectedRoleCollectId = value;
    },
  },
  actions: {
    //修改当前选中主播id
    updateSelectedAnchorId({ state, commit }, AnchorId) {
      commit("setSelectedAnchorId", AnchorId);
    },
    //修改当前选中主播名称
    updateSelectedAnchorName({ state, commit }, AnchorName) {
      commit("setSelectedAnchorName", AnchorName);
    },
    //修改当前选中主播性别
    updateSelectedAnchorGender({ state, commit }, AnchorGender) {
      commit("setSelectedAnchorGender", AnchorGender);
    },
    //修改当前播放主播id
    updatePlayAnchorId({ state, commit }, AnchorId) {
      commit("setPlayAnchorId", AnchorId);
    },
    //修改收藏主播组(单个添加与删除)
    updateCollectAnchorGroup({ state, commit }, { AnchorId, isCollect }) {
      let AnchorGroup = state.collectAnchorGroup;
      if (isCollect) {
        AnchorGroup.push(AnchorId);
      } else {
        AnchorGroup.map((item, index) => {
          if (item == AnchorId) {
            AnchorGroup.splice(index, 1);
          }
        });
      }
      commit("setcollectAnchorGroup", AnchorGroup);
    },
    //修改收藏主播组
    updateCollectAnchorGroupAll({ state, commit }, AnchorGroup) {
      commit("setcollectAnchorGroup", AnchorGroup);
    },
    //修改当前选中收藏角色id
    updateSelectedRoleCollectId({ state, commit }, CollectId) {
      commit("setSelectedRoleCollectId", CollectId);
    },
  },
};
