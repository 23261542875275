/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2022-10-27 15:49:35
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-10 10:31:50
 */
export function formatSeconds(value) {
  let secondTime = value; // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime > 60) {
    // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60);
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60);
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60);
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  let result = ":" + secondTime.toFixed(2);
  if (minuteTime > 0) {
    result = ":" + parseInt(minuteTime) + result;
  }
  if (hourTime > 0) {
    result = "" + parseInt(hourTime) + result;
  }
  return result;
}

export function TimeHandle(time, type) {
  // 转换为式分秒
  let h = parseInt((time / 60 / 60) % 24);

  h = h < 10 ? "0" + h : h;

  let m = parseInt((time / 60) % 60);

  m = m < 10 ? "0" + m : m;
  let s;
  if (type) {
    s = time % 60;
  } else {
    s = (time % 60).toFixed(2);
  }
  s = s < 10 ? "0" + s : s;

  // 作为返回值返回
  return h + ":" + m + ":" + s;
}

export function getCurrentTime(data) {
  let date = "";
  if (data == "1") {
    date = new Date(new Date().getTime() + 60 * 120 * 1000);
  } else if (data == "2") {
    date = new Date(new Date().getTime() + 60 * 180 * 1000);
  } else {
    date = new Date(new Date().getTime() + 60 * 60 * 1000); //当前时间戳;
  }
  // console.log(date.getMonth());
  // 获取当前时间并打印
  const yy = date.getFullYear();
  const mm =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const mf =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  // const ss =
  //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + "00";
}

//时间，前个月 负数  后几个月 正数
// export function getCurrentTime(now, addMonths, data) {
//   let date = "";
//   if (data == "1") {
//     date = new Date(new Date().getTime() + 60 * 60 * 1000);
//   } else if (data == "2") {
//     date = new Date(new Date().getTime() + 60 * 120 * 1000);
//   } else {
//     date = new Date(new Date().getTime()); //当前时间戳;
//   }
//   var dd = new Date(now);
//   var m = dd.getMonth() + 1;
//   var y =
//     dd.getMonth() + 1 + addMonths > 12
//       ? dd.getFullYear() + 1
//       : dd.getFullYear();
//   var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
//   const hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
//   const mf =
//     date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
//   if (m + addMonths == 0) {
//     y = y - 1;
//     m = 12;
//   } else {
//     if (m + addMonths > 12) {
//       m = "01";
//     } else {
//       m = m + 1 < 10 ? "0" + (m + addMonths) : m + addMonths;
//     }
//   }
//   return y + "-" + m + "-" + d + " " + hh + ":" + mf + ":" + "00";
// }

export function timestampToTime(timestamp) {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

/**
 *
 * @param {*判断 文件类型} url
 */
export function getFileTypeFromUrl(url) {
  const extension = url.split(".").pop().toLowerCase();
  return extension === "mp4" || extension === "webm";
}
