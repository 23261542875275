var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(false)?_c('div',{staticClass:"sassxingkong"},[_c('div',{staticClass:"layer1"}),_c('div',{staticClass:"layer2"}),_c('div',{staticClass:"layer3"}),_c('div',{staticClass:"layer4"}),_c('div',{staticClass:"layer5"})]):_vm._e(),(false)?_c('div',{staticClass:"marytree"},[_c('div',{staticClass:"tree"},[_vm._l((50),function(item,index){return _c('div',{key:index,staticClass:"tree-light",style:({
          '--appear': index,
          '--y': 2 * index,
          '--rotate': 1440 - 28.8 * index,
          '--radius': 12.5 - 0.25 * index,
          '--speed': index * 0.1,
          '--delay': index * 0.05,
        })})}),_c('svg',{staticClass:"tree-star",staticStyle:{"--delay":"50"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 113.32 108.44"}},[_c('path',{attrs:{"d":"M90.19 104.33L57.12 87.38 24.4 105l5.91-36.69L3.44 42.65l36.72-5.72 16.1-33.5L73.06 36.6l36.83 4.97-26.35 26.21z","fill":"none","stroke-width":"6.88","stroke-linecap":"round","stroke-linejoin":"round"}})])],2)]):_vm._e(),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }