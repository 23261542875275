<!--
 * @Description: 批量指派任务人
 * @version: 
 * @Author: Tom
 * @Date: 2023-03-21 08:41:36
 * @LastEditors: Tom
 * @LastEditTime: 2023-03-21 08:41:36
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'10%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="GlossariesDialog">
      <div class="glo_top" v-if="showFlag === 1">
        <p
          :class="Operatortabs == '1' ? 'setbackground' : ''"
          @click="changeOperatortabs('1')"
        >
          {{ $t("ProjectManagement.STT1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '1'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '5' ? 'setbackground' : ''"
          @click="changeOperatortabs('5')"
        >
          {{ $t("ProjectManagement.STQ1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '5'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '2' ? 'setbackground' : ''"
          @click="changeOperatortabs('2')"
        >
          {{ $t("ProjectManagement.TRA1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '2'"
          ></i>
        </p>
        <p
          :class="Operatortabs == '3' ? 'setbackground' : ''"
          @click="changeOperatortabs('3')"
        >
          {{ $t("ProjectManagement.REV1") }}
          <i
            class="el-icon-caret-bottom tabsbtnicon"
            v-if="Operatortabs == '3'"
          ></i>
        </p>
      </div>
      <div class="glo_bottom" v-if="showFlag === 1">
        <div v-show="Operatortabs == '1'" class="tabs1_top">
          <div
            class="tableClass borderTableStyle"
            :class="tableDatagSTT.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableDatagSTT"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="233px"
              @selection-change="handleSelectionChangeSTT"
              border
            >
              <el-table-column
                type="selection"
                width="39"
                v-if="showFlag === 2"
              ></el-table-column>
              <!-- <p>术语</p> -->
              <el-table-column
                prop="job_name"
                :label="$t('CommonName.TaskName')"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="setSelectClass">
            <p>{{ $t("ExternalCreateTask.Taskprocessor") }}</p>
            <el-select
              v-model="STTUser"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              @change="selectChange(1, $event)"
            >
              <el-option
                v-for="item in MemberOptions"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.nickname }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <div class="AssignFolder">
              <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
              <el-checkbox v-model="STTNew">{{
                $t("PersonalEndLanguage.AssignText2")
              }}</el-checkbox>
            </div>
            <el-select
              v-model="STTFolderSelect"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              v-show="!STTNew"
            >
              <el-option
                v-for="item in STTFolderList"
                :key="item.folder_id"
                :label="item.folder_name"
                :value="item.folder_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.folder_name }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <el-input
              v-model="STTFolderName"
              :maxlength="60"
              ref="STTFolderinput"
              v-show="STTNew"
            ></el-input>
          </div>
        </div>
        <div v-show="Operatortabs == '2'" class="tabs1_top">
          <div
            class="tableClass borderTableStyle"
            :class="tableDatagTRA.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableDatagTRA"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="233px"
              @selection-change="handleSelectionChangeTRA"
              border
            >
              <el-table-column
                type="selection"
                width="39"
                v-if="showFlag === 2"
              ></el-table-column>
              <!-- <p>术语</p> -->
              <el-table-column
                prop="job_name"
                :label="$t('CommonName.TaskName')"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="setSelectClass">
            <p>{{ $t("ExternalCreateTask.Translator") }}</p>
            <el-select
              v-model="TRAUser"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              @change="selectChange(2, $event)"
            >
              <el-option
                v-for="item in MemberOptions"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.nickname }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <div class="AssignFolder">
              <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
              <el-checkbox v-model="TRANew">{{
                $t("PersonalEndLanguage.AssignText2")
              }}</el-checkbox>
            </div>
            <el-select
              v-model="TRAFolderSelect"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              v-show="!TRANew"
            >
              <el-option
                v-for="item in TRAFolderList"
                :key="item.folder_id"
                :label="item.folder_name"
                :value="item.folder_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.folder_name }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <el-input
              v-model="TRAFolderName"
              :maxlength="60"
              ref="TRAFolderinput"
              v-show="TRANew"
            ></el-input>
          </div>
        </div>
        <div v-show="Operatortabs == '3'" class="tabs1_top">
          <div
            class="tableClass borderTableStyle"
            :class="tableDatagREV.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableDatagREV"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="233px"
              @selection-change="handleSelectionChangeREV"
              border
            >
              <el-table-column
                type="selection"
                width="39"
                v-if="showFlag === 2"
              ></el-table-column>
              <!-- <p>术语</p> -->
              <el-table-column
                prop="job_name"
                :label="$t('CommonName.TaskName')"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="setSelectClass">
            <p>{{ $t("ExternalCreateTask.ProofreadTaskHandler") }}</p>
            <el-select
              v-model="REVUser"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              @change="selectChange(3, $event)"
            >
              <el-option
                v-for="item in MemberOptions"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.nickname }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <div class="AssignFolder">
              <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
              <el-checkbox v-model="REVNew">{{
                $t("PersonalEndLanguage.AssignText2")
              }}</el-checkbox>
            </div>
            <el-select
              v-model="REVFolderSelect"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              v-show="!REVNew"
            >
              <el-option
                v-for="item in REVFolderList"
                :key="item.folder_id"
                :label="item.folder_name"
                :value="item.folder_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.folder_name }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <el-input
              v-model="REVFolderName"
              :maxlength="60"
              ref="REVFolderinput"
              v-show="REVNew"
            ></el-input>
          </div>
        </div>
        <div v-show="Operatortabs == '5'" class="tabs1_top">
          <div
            class="tableClass borderTableStyle"
            :class="tableDatagSQT.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableDatagSQT"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="233px"
              @selection-change="handleSelectionChangeSQT"
              border
            >
              <el-table-column
                type="selection"
                width="39"
                v-if="showFlag === 2"
              ></el-table-column>
              <!-- <p>术语</p> -->
              <el-table-column
                prop="job_name"
                :label="$t('CommonName.TaskName')"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="setSelectClass">
            <p>{{ $t("ExternalCreateTask.STQprocessor") }}</p>
            <el-select
              v-model="SQTUser"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              @change="selectChange(5, $event)"
            >
              <el-option
                v-for="item in MemberOptions"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.nickname }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <div class="AssignFolder">
              <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
              <el-checkbox v-model="SQTNew">{{
                $t("PersonalEndLanguage.AssignText2")
              }}</el-checkbox>
            </div>
            <el-select
              v-model="SQTFolderSelect"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              v-show="!SQTNew"
            >
              <el-option
                v-for="item in SQTFolderList"
                :key="item.folder_id"
                :label="item.folder_name"
                :value="item.folder_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.folder_name }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <el-input
              v-model="SQTFolderName"
              :maxlength="60"
              ref="REVFolderinput"
              v-show="SQTNew"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-if="showFlag === 2" class="glo_bottom tabs1_top">
        <div
          class="tableClass borderTableStyle"
          :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="233px"
            @selection-change="handleSelectionChange"
            border
          >
            <el-table-column type="selection" width="39"></el-table-column>
            <!-- <p>术语</p> -->
            <el-table-column prop="job_name" :label="$t('CommonName.TaskName')">
            </el-table-column>
          </el-table>
        </div>
        <div class="setSelectClass">
          <p>{{ $t("ProjectManagement.Waterprint1") }}</p>
          <el-select
            v-model="select"
            :placeholder="$t('CommonName.Pleaseselect')"
            filterable
            clearable
            @change="selectChange(4, $event)"
          >
            <el-option
              v-for="item in MemberOptions"
              :key="item.member_id"
              :label="item.nickname"
              :value="item.member_id"
            >
              <div class="selectContentClass">
                <p class="onep overflowEllipsis">
                  <span>{{ item.nickname }}</span>
                </p>
              </div>
            </el-option>
          </el-select>
          <div class="AssignFolder">
            <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
            <el-checkbox v-model="DubNew">{{
              $t("PersonalEndLanguage.AssignText2")
            }}</el-checkbox>
          </div>
          <el-select
            v-model="DubFolderSelect"
            :placeholder="$t('CommonName.Pleaseselect')"
            filterable
            clearable
            v-show="!DubNew"
          >
            <el-option
              v-for="item in DubFolderList"
              :key="item.folder_id"
              :label="item.folder_name"
              :value="item.folder_id"
            >
              <div class="selectContentClass">
                <p class="onep overflowEllipsis">
                  <span>{{ item.folder_name }}</span>
                </p>
              </div>
            </el-option>
          </el-select>
          <el-input
            v-model="DubFolderName"
            :maxlength="60"
            ref="DubFolderinput"
            v-show="DubNew"
          ></el-input>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          :loading="buttonLoading"
        >
          {{ $t("CommonName.Sure") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  get_processor_project_job_list,
  set_processor_project_job,
  remove_termfile,
  get_member_list,
  get_processor_project_job_dubbing_list,
  set_processor_project_job_dubbing,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Number,
      default: 1,
    },
    multipleSelection: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      buttonLoading: false,
      dialogVisible: false,
      title: "",
      project_id: "",
      Operatortabs: "1",
      MemberOptions: [],
      selectrowData: {}, //术语弹窗 表格 下拉框使用
      tableDatagSTT: [], //STT 任务列表
      STTSelection: [], //STT 数据列表
      tableDatagTRA: [], //TRA 任务列表
      TRASelection: [], //TRA 数据列表
      tableDatagREV: [], //REV 任务列表
      REVSelection: [], //REV 数据列表
      tableDatagSQT: [], //SQT 任务列表
      SQTSelection: [], //SQT数据列表
      tableData: [], // 视频配音 批量指派人
      dubSelection: [], //视频配音 数据列表
      select: "",
      STTUser: "",
      TRAUser: "",
      REVUser: "",
      SQTUser: "",
      STTNew: false, //是否选择新建文件夹
      STTFolderSelect: "", //选择保存文件夹选项
      STTFolderName: "", //新建文件夹名称
      STTFolderList: [], //文件夹路径
      TRANew: false,
      TRAFolderSelect: "",
      TRAFolderName: "",
      TRAFolderList: [],
      REVNew: false,
      REVFolderSelect: "",
      REVFolderName: "",
      REVFolderList: [],
      DubNew: false, //是否选择新建文件夹
      DubFolderSelect: "", //选择保存文件夹选项
      DubFolderName: "", //新建文件夹名称
      DubFolderList: [], //文件夹路径
      SQTNew: false,
      SQTFolderSelect: "",
      SQTFolderName: "",
      SQTFolderList: [],
    };
  },
  created() {
    this.project_id = this.$route.query.id;
  },
  mounted() {
    // console.log(11111);
    this.dialogVisible = this.showdialog;
    this.title = this.$t("ProjectDetails.buttonList.name7");
    if (this.multipleSelection.length > 0) {
      let first_status = this.multipleSelection[0]["status"];
      this.multipleSelection.forEach((i) => {
        if (first_status != i.status) {
          first_status = "";
        }
      });
      if (first_status == "20") {
        this.Operatortabs = "1";
      } else if (first_status == "25") {
        this.Operatortabs = "5";
      } else if (first_status == "30") {
        this.Operatortabs = "2";
      } else if (first_status == "40") {
        this.Operatortabs = "3";
      }
    }
    this.get_member_list();
    if (this.showFlag === 1) {
      this.get_project_termfile_list();
    } else {
      this.get_processor_project_job_dubbing_list();
    }
  },
  methods: {
    //================================ 调用接口======================================
    /**
     *
     * @param {*获取项目已添加术语列表信息} id
     */
    get_project_termfile_list() {
      let job_list = [];
      if (this.multipleSelection.length === 0) {
        job_list = [];
      } else {
        this.multipleSelection.forEach((i) => {
          job_list.push(i.job_id);
        });
      }
      return get_processor_project_job_list({
        action: "get_processor_project_job_list",
        project_id: this.project_id,
        job_list,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.tableDatagSTT = res.stt_list;
        this.tableDatagTRA = res.tra_list;
        this.tableDatagREV = res.rev_list;
        this.tableDatagSQT = res.sqt_list;
        hideLoading();
      });
    },
    /**
     *
     * @param data
     */
    get_processor_project_job_dubbing_list() {
      return get_processor_project_job_dubbing_list({
        action: "get_processor_project_job_dubbing_list",
        project_id: this.project_id,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.tableData = res.dub_list;
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取成员列表信息}
     */
    get_member_list() {
      get_member_list({
        action: "get_member_list",
        team_user_status: 2,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.MemberOptions = res.list;
      });
    },
    // =========================================其他 方法========================================================
    /**
     * 弹窗 内部的 tab页切换
     */
    changeOperatortabs(str) {
      this.Operatortabs = str;
    },
    /**
     *
     * @param {*点击保存 某个术语} row
     */
    confirmClick(row, data) {
      if (data == 1) {
        if (!row.term_file_id) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        // console.log(row);
        this.remove_termfile(row.old_term_file_id).then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          this.add_termfile(row.term_file_id);
        });
      } else {
        this.$confirm(
          this.$t("CommonName.deleteMessage2"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
            cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        ).then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          this.remove_termfile(row.term_file_id).then(() => {
            this.get_project_termfile_list();
          });
        });
        // console.log(row);
      }
    },
    /**
     * 多选
     */
    handleSelectionChangeSTT(val) {
      this.STTSelection = val;
    },
    handleSelectionChangeTRA(val) {
      this.TRASelection = val;
    },
    handleSelectionChangeREV(val) {
      this.REVSelection = val;
    },
    handleSelectionChangeSQT(val) {
      this.SQTSelection = val;
    },
    handleSelectionChange(val) {
      this.dubSelection = val;
    },
    /**
     *
     * @param {* 确定按钮} data
     */
    determineClick() {
      if (this.showFlag === 1) {
        let stt_selection = [];
        this.tableDatagSTT.forEach((item) => {
          stt_selection.push(item.job_id);
        });
        let tra_selection = [];
        this.tableDatagTRA.forEach((item) => {
          tra_selection.push(item.job_id);
        });
        let rev_selection = [];
        this.tableDatagREV.forEach((item) => {
          rev_selection.push(item.job_id);
        });
        let sqt_selection = [];
        this.tableDatagSQT.forEach((item) => {
          sqt_selection.push(item.job_id);
        });
        if (this.STTNew && this.STTFolderName.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage47")
          );
          return;
        }
        if (this.TRANew && this.TRAFolderName.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage47")
          );
          return;
        }
        if (this.REVNew && this.REVFolderName.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage47")
          );
          return;
        }
        if (this.SQTNew && this.SQTFolderName.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage47")
          );
          return;
        }
        let data = {
          action: "set_processor_project_job",
          project_id: this.project_id,
          stt_member: this.STTUser,
          tra_member: this.TRAUser,
          rev_member: this.REVUser,
          sqt_member: this.SQTUser,
          stt_selection,
          tra_selection,
          rev_selection,
          sqt_selection,
          stt_folder_mode: this.STTNew ? 2 : 1,
          tra_folder_mode: this.TRANew ? 2 : 1,
          rev_folder_mode: this.REVNew ? 2 : 1,
          sqt_folder_mode: this.SQTNew ? 2 : 1,
          stt_folder_id: this.STTFolderSelect,
          tra_folder_id: this.TRAFolderSelect,
          rev_folder_id: this.REVFolderSelect,
          sqt_folder_id: this.SQTFolderSelect,
          stt_folder_name: this.STTFolderName,
          tra_folder_name: this.TRAFolderName,
          rev_folder_name: this.REVFolderName,
          sqt_folder_name: this.SQTFolderName,
        };
        // console.log(data);
        this.buttonLoading = true;
        set_processor_project_job(data).then((res) => {
          if (!res) {
            this.buttonLoading = false;
            return;
          }
          this.dialogVisible = false;
          this.$emit("clickOperator", this.dialogVisible);
          this.buttonLoading = false;
        });
      } else {
        if (this.dubSelection.length === 0) {
          return;
        }
        if (!this.select) {
          return;
        }
        if (this.DubNew && this.DubFolderName.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage47")
          );
          return;
        }
        let arr = [];
        this.dubSelection.forEach((item) => {
          arr.push(item.job_dubbing_id);
        });
        this.buttonLoading = true;
        set_processor_project_job_dubbing({
          action: "set_processor_project_job_dubbing",
          project_id: this.project_id,
          dub_member: this.select,
          dub_selection: arr,
          dub_folder_mode: this.DubNew ? 2 : 1,
          dub_folder_id: this.DubFolderSelect,
          dub_folder_name: this.DubFolderName,
        }).then((res) => {
          if (!res) {
            this.buttonLoading = false;
            return;
          }
          this.dialogVisible = false;
          this.$emit("requireDialog", this.dialogVisible);
          this.buttonLoading = false;
        });
      }
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      if (this.showFlag === 1) {
        this.$emit("closeOperator", this.dialogVisible);
      } else {
        this.$emit("closeDialog", this.dialogVisible);
      }
      this.buttonLoading = false;
      this.dialogVisible = false;
    },
    STTSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2) {
        return {
          rowspan: this.tableDatagSTT.length,
          colspan: 1,
        };
      }
    },
    selectChange(mode, value) {
      get_member_list({
        action: "get_member_folder_list",
        member_id: value,
      }).then((res) => {
        if (!res) {
          return;
        }
        //STT
        if (mode == 1) {
          this.STTFolderSelect = "";
          this.STTFolderList = res.list;
        } else if (mode == 2) {
          //TRA
          this.TRAFolderSelect = "";
          this.TRAFolderList = res.list;
        } else if (mode == 3) {
          //REV
          this.REVFolderSelect = "";
          this.REVFolderList = res.list;
        } else if (mode == 4) {
          //Dub
          this.DubFolderSelect = "";
          this.DubFolderList = res.list;
        } else if (mode == 5) {
          //SQT
          this.SQTFolderSelect = "";
          this.SQTFolderList = res.list;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;
    position: relative;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .glo_bottom {
    margin-top: 22px;
    height: 233px;

    .tabs1_bottom {
      // height: 300px;
      margin-top: 24px;
    }

    .tableClass {
      width: 50%;
    }

    .tabs2 {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .setSelectClass {
        ::v-deep .el-input__inner {
          width: 316px !important;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .tableappend {
        height: 40px;
        background-color: #393c4e;
        line-height: 40px;
        padding-left: 12px;
        border-left: 1px solid #515464;
        border-bottom: 1px solid #515464;
        border-right: 1px solid #515464;
      }

      ::v-deep .el-table {
        overflow: initial;
      }
    }
  }

  .tabs1_top {
    display: flex;
    justify-content: space-between;

    .tabs1_top_left {
      display: flex;

      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .selectclass {
        margin-right: 24px;
      }

      ::v-deep .el-input__inner {
        width: 220px !important;
      }

      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }

    .tabs1_top_right {
      margin-top: 24px;
    }

    .setSelectClass {
      .AssignFolder {
        margin: 12px 0 6px 0;
        color: #adb0bc;
        display: flex;
        justify-content: space-between;
      }
      ::v-deep .el-checkbox {
        color: #adb0bc;
      }
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}

.selectContentClass {
  width: 318px;

  p {
    width: 100%;
  }
}
</style>
