/*
 * @Description: 语言
 * @version:
 * @Author: Carful
 * @Date: 2022-10-17 10:50:31
 * @LastEditors: Carful
 * @LastEditTime: 2022-10-18 16:17:13
 */
module.exports = {
  login: {
    Stow: "收起",
    open: "展開",
    upload: "上傳",
    uploadMessage: "上傳檔案僅限於mp4格式！",
    ExitAccount: "登出帳號",
    ExitAccountClew: "確定登出帳號嗎？",
    popoverContent:
      "中文和英文可不選擇字幕，將自動使用影片轉寫服務。其他語系需要對應的字幕檔案，否則將無法創建任務。",
    checkAll: "全選",
    languageSet: "來源語系和目標語系存在相同數值，請重新選擇",
    deleteMessage: "確定要刪除嗎？",
    loadingtext: "載入中……",
    bottomTitle: "我譯網科技 版權所有並保留所有權利",
    SearchForVideoTitles: "搜尋影片標題",
    searchListName: "搜尋列表名稱",
    listName: "列表名稱",
    videoTitle: "影片標題",
    operation: "操作",
    subtitle: "YouTube 已有字幕",
    times: "YouTube 日期",
    subtitlePlaceholder: "請選擇來源語系字幕",
    subtitleSelect: "（中英語系若未選擇字幕將自動轉寫）",
    delete: "刪除",
    VideoLanguage: "（影片語系）",
    message: {
      success: "創建成功",
      createTaskWarning: "請於選定後再進行批次創建操作",
      createTaskWarning1: "批次創建翻譯任務之目標語系無法選擇",
    },
    alluploadprogress: {
      VideoUploading: "影片上傳中，剩餘",
      number: "個",
      Uploaded: "已上傳",
    },
    WiitubeChannelManagement: {
      addNewChannel: "添加新頻道",
      searchChannel: "搜尋頻道",
      channelAdministration: "頻道管理",
      default: "目前頻道",
      setDefault: "切換頻道",
      CancelAuthorization: "取消授權",
      SetSuccessfully: "設置成功",
      Administrationchannel: "管理頻道",
    },
    dialogContent: {
      warningTips1: "暫無字幕資訊無法創建任務",
      warningTips2: "尚有未上傳的影片檔案存在，請上傳",
      warningTips3: "個影片無字幕，無法直接使用字幕翻譯服務",
      clickUpload: "點擊上傳",
      ReUpload: "重新上傳",
      videofile: "影片檔案",
      count: "共",
      artificial: "人工",
      automatic: "自動",
      QualityLevel: "品質等級",
      ServiceType: "服務類型",
      phoneticTranscription: "語音轉寫",
      subtitleTranslation: "字幕翻譯",
      SelectSubtitles: "選擇字幕",
      SubtitleTranscription: "字幕轉寫",
      Tips: "提示：無字幕影片文件不顯示，預設使用字幕轉寫服務",
      diatitle: "影片標題",
      AllTranscribingService: "全部使用字幕轉寫服務",
      messagesTips: "該語系尚無字幕，請選擇語音轉寫+字幕翻譯",
      TranslationOfOtherContents: "其他內容翻譯",
      TranslateVideoTitle: "翻譯影片標題和說明",
      TranslateVideoDescription: "翻譯影片說明",
      uploadVideoFile: "上傳影片文件",
      popoverContent:
        "我們嚴格遵守 YouTube 開發者條款、版權限制與隱私政策，因此無法透過 YouTube 直接獲取您的影片/音訊檔案。若您需要轉寫服務，需要自用戶端上傳影片/音訊檔案。一旦點擊上傳按鈕，即視為您默許我們因業務需要，有權在一定期限內保存並使用您的影片。我們將保證您的資料及隱私安全，僅將檔案用於 WiiTube 在地化相關服務，不會進行修改、遮蔽以及傳播。",
    },

    TabHome: {
      innerLayerP: "您未選擇有效的字幕，確定要使用影片轉寫服務嗎？",
      innerLayerTitle: "提示",
      remarks: "備註",
      subtitleLanguagePlaceholder: "請選擇字幕",
      warningMessage: "存在未選擇項目，請先進行選擇",
      SetLanguagePairs: "設置語系配對",
      sourceLanguage: "來源語系",
      sourceLanguagePlaceholder: "請選擇需要翻譯的語系",
      targetLanguage: "目標語系",
      targetLanguages: "（可多選）",
      targetLanguagePlaceholder: "請選擇目標語系",
      BatchCreateTranslationTasks: "批次創建任務",
      UpdateVideoList: "更新影片列表",
      CreateATranslationTask: "創建任務",
      ExistingVideo: "現有影片",
    },
    WiiPlayList: {
      playTitle: "列表",
      playNumber: "影片數量",
      playTime: "YouTube 上次更新時間",
    },
    WiitubeUnderway: {
      progressPrepare: "正在處理",
      LanguagePair: "語系配對",
      sourceLanguage: "來源語系",
      targetLanguage: "目標語系",
      speedOfProgress: "進度",
      speedOfProgressState: "準備中",
      Completed: "已完成",
      deleteSuccess: "刪除成功",
      TaskPreparationInProgress: "任務檔案解析中…",
      estimatedTime: "任務檔案解析中，預計時間",
      taskType: "任務類型",
      taskAmount: "任務金額",
      pattern: "模式",
      humanTranslation: "人工翻譯",
      AutomaticTranslation: "自動翻譯",
      TitleTranslation: "影片標題和說明翻譯",
      ExplanationTranslation: "說明翻譯",
    },
    WiitubeTranslationDone: {
      TaskCreationTime: "任務創建時間",
      TaskCompletionTime: "任務完成時間",
      preview: "預覽字幕",
      proviews: "預覽",
      proviewTitle: "預覽標題和說明",
      repulse: "退回",
      OneClickApplication: "一鍵套用",
      ApplicationTips: "確定要將新的字幕配置到影片檔案上？",
      state: "狀態",
      stateValue0: "未套用",
      stateValue1: "已套用",
      ReasonForReturn: "退回原因",
      repulseerror: "請輸入500字以內的內容",
      repulsesuccess: "退回成功",
      OneClickApplicationsuccess: "套用成功",
      Unpublished: "未發表到YouTube",
      VideoIsBeingReleased: "影片發表中",
      PublishingFailed: "發表失敗，請重試",
      clickRelease: "一鍵發表",
      releasevideo: "發表影片",
      thumbnail: "縮圖",
      proposal: "提案",
      proposaltips:
        "將您的縮圖設為 1280 x 720 像素（比例為 16:9）確保您的縮圖大小不會超過 2MB，並使用 JPG 與 PNG 檔案格式",
      note: "選擇或上傳一張可展示您影片內容的圖片。好的縮圖能脫穎而出，吸引觀眾的注意。",
      uploadthumbnail: "點擊上傳縮圖",
      audience: "觀眾",
      audiencetips:
        "根據法律要求，無論您身在何處，都必須遵守《兒童線上隱私保護法》(COPPA) 和/或其他法律。您必須指明自己的影片是否屬於為兒童打造的內容。",
      ForChildrenY: "是，本內容是為兒童打造的",
      ForChildrenN: "否，本内容並非為兒童打造的",
      ScopeOfDisclosure: "公開範圍",
      ScopeOfDisclosuretips: "選擇影片的發佈時間以及哪些人可以看到您的影片",
      SaveOrPublish: "保存或發表",
      SaveOrPublishTips: "將影片設為公開、不公開列出或私人影片",
      ScheduleTime: "安排時間",
      ScheduleTimetips: "選擇要將您的影片設為公開的日期",
      year: "年",
      month: "月",
      day: "日",
      selectdate: "選擇日期",
      share: "私人影片",
      notPublic: "不公開",
      Public: "公開",
      imagetips: "上傳檔案只能是jpg或png格式！",
      imagesize: "上傳圖片大小不能超過 2MB！",
      PublishSuccessfully: "發表成功",
    },
    checkBoxName: "全選",
    buttonLangusge: {
      confirm: "確定授權",
      confirms: "確 定",
      cancel: "取 消",
    },
    tabLanguage: {
      tabFirst: "YouTube 所有影片",
      tabSecond: "播放列表",
      tabFive: "尚待發表的影片",
      tabThird: "進行中",
      tabFour: "已完成",
    },
    headerLanguage: {
      name: "谷歌账号@163.com",
      management: "管理授權",
      dialogTitle: "設置授權",
    },
    released: {
      UploadedSubtitles: "已上傳字幕",
      UploadedTime: "上傳日期",
      UploadedVideo: "上傳影片",
      ContinueToUpload: "繼續上傳",
      more: "更多選項",
      uploaderrtips: "上傳失敗，請重新上傳",
      EditTitleAndDescription: "編輯標題和說明",
      UploadSourceLanguageSubtitles: "上傳來源語系字幕",
      ReUpload: "重新上傳",
      uploadTips: "請拖曳要上傳的影片檔案",
      choicefile: "選擇檔案",
      titlt: "標題",
      explain: "說明",
      explainPlaceholder: "向觀賞者介紹您的影片",
      uploadfileTypesrt: "上傳字幕檔案(.srt)",
      subtitleLanguage: "字幕語系",
      subtitleLanguagePlaceholder: "請選擇字幕語系",
      srtTips: "上傳檔案僅限於srt格式！",
      uploadSubtitle: "上傳字幕",
      EditBasicInformation: "編輯基本資訊",
      subtitletips: "以下影片無字幕，請上傳字幕",
      subtitlefile: "字幕檔案",
    },
  },
};
