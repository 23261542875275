<!--
 * @Description: 漫画管理
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 10:05:51
 * @LastEditors: Carful
 * @LastEditTime: 2022-11-25 14:08:12
-->
<template>
  <div>
    <div class="setcolor">
      <div
        class="colorDiv"
        v-for="i in color"
        :style="{ background: i }"
        :key="i"
      ></div>
    </div>
    <div class="btmDiv">
      <div
        class="colorDiv"
        v-for="i in color1"
        :style="{ background: i }"
        :key="i"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      color: [
        "#00CEA5",
        "#226466",
        "#235D62",
        "#236247",
        "#2A629F",
        "#3F88AF",
        "#417874",
        "#458798",
        "#477B5E",
        "#49678F",
        "#4E95B7",
        "#567D53",
        "#598088",
        "#5BA062",
        "#5F7480",
        "#656094",
        "#6965B0",
        "#6B92E0",
        "#6F7B9B",
        "#707A1C",
        "#70884F",
        "#78676C",
        "#79928C",
        "#7A618E",
        "#7E6689",
        "#7E7EA9",
        "#80559C",
        "#847B6F",
        "#85502C",
        "#858296",
        "#8583A8",
        "#87876A",
        "#87A795",
        "#886094",
        "#88618B",
        "#88C291",
        "#8B6D40",
        "#8F7488",
        "#8F92C0",
        "#90494F",
        "#9B6868",
        "#9B7E70",
        "#9CAA84",
        "#A76446",
        "#AE5372",
        "#AF5FA4",
        "#B0A7A8",
        "#B59B66",
        "#C77E7E",
        "#D28622",
      ],
      color1: [
        "#E35B5B",
        "#FFAE0D",
        "#F1E32B",
        "#E6FEFF",
        "#06FBD2",
        "#A38CFF",
        "#9CFE48",
        "#FF36BB",
        "#96000F",
        "#FFAFE4",
        "#3140FF",
        "#267032",
        "#BE6EC4",
        "#A55200",
        "#26817A",
        "#BB8790",
        "#7B7168",
        "#6E00D1",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.setcolor {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 100px;
  .colorDiv {
    width: 30px;
    height: 30px;
  }
}
.btmDiv {
  width: 100%;
  display: flex;
  margin-top: 100px;

  justify-content: center;
  .colorDiv {
    width: 30px;
    height: 30px;
  }
}
</style>
