/*
 * @Description: 主播之间点击切换更新
 * @version:
 * @Author: Tom
 * @Date: 2022-10-28 14:05:23
 * @LastEditors: Tom
 * @LastEditTime: 2023-11-21 10:35:42
 */
export default {
  namespaced: true,
  state: {
    selectedAnchorId: 0, //主播设置时选中的主播id
    selectedAnchorName: 0, //主播设置时选中的主播名称
    selectedAnchorGender: 0, //主播设置时选中的主播性别
    playAnchorId: 0, //主播设置时播放的主播id
    playAIAnchorId: 0, //主播设置时播放的AI主播id
    multipleAnchorGroup: [], //收藏主播组
    selectedMultipleId: 0, //收藏角色中选中的角色id
    selectedAIAnchorId: 0, //主播设置时选中的AI主播id
  },
  getters: {
    selectedAnchorId: (state) => state.selectedAnchorId,
    selectedAIAnchorId: (state) => state.selectedAIAnchorId,
    selectedAnchorName: (state) => state.selectedAnchorName,
    selectedAnchorGender: (state) => state.selectedAnchorGender,
    playAnchorId: (state) => state.playAnchorId,
    playAIAnchorId: (state) => state.playAIAnchorId,
    multipleAnchorGroup: (state) => state.multipleAnchorGroup,
    selectedMultipleId: (state) => state.selectedMultipleId,
  },
  mutations: {
    setSelectedAnchorId(state, value) {
      state.selectedAnchorId = value;
    },
    setSelectedAIAnchorId(state, value) {
      state.selectedAIAnchorId = value;
    },
    setSelectedAnchorName(state, value) {
      state.selectedAnchorName = value;
    },
    setSelectedAnchorGender(state, value) {
      state.selectedAnchorGender = value;
    },
    setPlayAnchorId(state, value) {
      state.playAnchorId = value;
    },
    setPlayAIAnchorId(state, value) {
      state.playAIAnchorId = value;
    },
    setMultipleAnchorGroup(state, value) {
      state.multipleAnchorGroup = value;
    },
    setSelectedMultipleId(state, value) {
      state.selectedMultipleId = value;
    },
  },
  actions: {
    //修改当前选中主播id
    updateSelectedAnchorId({ state, commit }, AnchorId) {
      commit("setSelectedAnchorId", AnchorId);
    },
    //修改当前选中AI主播id
    updateSelectedAIAnchorId({ state, commit }, AnchorId) {
      commit("setSelectedAIAnchorId", AnchorId);
    },
    //修改当前选中主播名称
    updateSelectedAnchorName({ state, commit }, AnchorName) {
      commit("setSelectedAnchorName", AnchorName);
    },
    //修改当前选中主播性别
    updateSelectedAnchorGender({ state, commit }, AnchorGender) {
      commit("setSelectedAnchorGender", AnchorGender);
    },
    //修改当前播放主播id
    updatePlayAnchorId({ state, commit }, AnchorId) {
      commit("setPlayAnchorId", AnchorId);
    },
    //修改当前播放AI主播id
    updatePlayAIAnchorId({ state, commit }, AnchorId) {
      commit("setPlayAIAnchorId", AnchorId);
    },
    //修改收藏主播组(单个添加与删除)
    updateMultipleAnchorGroup({ state, commit }, { AnchorId, isSelect }) {
      let AnchorGroup = state.multipleAnchorGroup;
      if (isSelect) {
        AnchorGroup.push(AnchorId);
      } else {
        AnchorGroup.map((item, index) => {
          if (item == AnchorId) {
            AnchorGroup.splice(index, 1);
          }
        });
      }
      commit("setMultipleAnchorGroup", AnchorGroup);
    },
    //修改收藏主播组
    updateMultipleAnchorGroupAll({ state, commit }, AnchorGroup) {
      commit("setMultipleAnchorGroup", AnchorGroup);
    },
    //修改当前选中收藏角色id
    updateSelectedMultipleId({ state, commit }, AnchorId) {
      commit("setSelectedMultipleId", AnchorId);
    },
  },
};
