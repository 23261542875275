/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2023-02-09 11:31:22
 * @LastEditors: Carful
 * @LastEditTime: 2023-04-21 10:45:50
 */
let arr = [
  "#00C468",
  "#C275C7",
  "#0085AE",
  "#6C78E0",
  "#246FD3",
  "#ACAC00",
  "#1D9100",
  "#B8751D",
  "#0C9E9E",
  "#DF579B",
  "#91C500",
  "#AF3838",
  "#99CA8C",
  "#9558FF",
  "#54771D",
  "#BD7070",
  "#FF92DB",
  "#857AB8",
  "#37B0CD",
  "#B5009E",
];
var newarr = [];
export function getColor(oldarr) {
  newarr.forEach((j) => {
    if (oldarr.color == j) {
      newarr.splice(j, 1);
    }
  });
  // console.log(newarr);
}
export function colorListLength() {
  return newarr.length;
}
export function initColor() {
  newarr = JSON.parse(JSON.stringify(arr));
}
export function setColor(index) {
  console.log(index);
  return newarr[index - 1];
}
