<!--
 * @Description: 分页器
 * @version: 
 * @Author: Carful
 * @Date: 2022-10-27 10:33:24
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-17 11:18:43
-->
<template>
  <el-pagination
    background
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    popper-class="select_bottom"
    :page-sizes="[20, 50, 80, 100, 150]"
    :page-size="pageSize"
    class="paginationClass"
    layout="total,prev,pager,next,sizes,jumper"
    :total="tableData.length == 0 ? total : tableData.length"
    style="display: flex"
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //   pageSize1: 10,
      //   currentPage1: 1,
    };
  },
  mounted() {
    let pageText = document.getElementsByClassName("el-pagination__jump")[0];
    if (pageText) {
      //可能存在不显示页码的情况，所以最好判断一下避免报错~
      // pageText.childNodes[0].nodeValue = this.$t("CommonName.goTo");
    }
  },

  methods: {
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-pagination__total {
  color: #cbd7e8;
  font-size: 14px;
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .btn-prev {
  background-color: #393c4e !important;
  border: 1px solid;
  border-color: #757784;
  border-radius: 2px;
  color: #c0c4cc !important;
  width: 32px;
  height: 32px;
}
::v-deep .btn-next {
  background-color: #393c4e !important;
  border: 1px solid;
  border-color: #757784;
  border-radius: 2px;
  color: #c0c4cc !important;
  width: 32px;
  height: 32px;
}
::v-deep .el-pager li {
  min-width: 32px;
  height: 32px;
  background-color: #393c4e !important;
  border: 1px solid;
  border-color: #757784;
  border-radius: 2px;
  line-height: 32px;
  color: #cbd7e8 !important;
  &.active {
    background-color: #46adff !important;
    color: #ffffff !important;
    border: none;
  }
}
::v-deep .el-pager li.active + li {
  border-left: 1px solid;
  border-color: #757784;
}
::v-deep .el-pagination__jump {
  margin-left: 0;
  color: #cbd7e8;
}
::v-deep .el-pagination__editor {
  padding: 0 8px;
}
::v-deep .el-input__inner {
  height: 32px !important;
  width: 100% !important;
}
</style>
