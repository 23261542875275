<!--
 * @Description: 点击默认文件夹 跳转页面
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-21 15:34:53
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-29 16:30:08
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <svg-icon
        icon-class="BackPrevious"
        class="svgClass"
        @click="goback"
      ></svg-icon>
      <div style="width: 60%">
        <el-tooltip
          :content="$t('MyFiles.MyFilesTitle') + ' / ' + folder_name"
          placement="bottom-start"
          effect="dark"
          :visible-arrow="false"
        >
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ $t("MyFiles.MyFilesTitle") }} / {{ folder_name }}
          </p>
        </el-tooltip>
      </div>
    </div>
    <MyFilesTableVue :table-data="tableData" :gofolder="1"></MyFilesTableVue>
  </div>
</template>
<script>
import MyFilesTableVue from "./MyFilesTable.vue";
import { get_job_list } from "@/utils/https";
import { timestampToTime } from "@/api/setTime";
export default {
  components: {
    MyFilesTableVue,
  },
  data() {
    return {
      tableData: [],
      folder_id: "",
      folder_name: this.$t("MyFiles.defaultFolder"),
      time1: "",
      time2: "",
    };
  },
  created() {
    //   $emit子组件调用父组件的方法并传递数据
    this.$emit("header", true, true, true, true);
    this.folder_id = this.$route.query.folder_id;
    this.folder_name = this.$route.query.name;
    this.time2 = timestampToTime(new Date());
    this.time1 = new Date();
    this.time1.setTime(this.time1.getTime() - 3600 * 1000 * 24 * 90);
    // console.log(this.time1, this.time2);
    this.time1 = timestampToTime(this.time1);
  },
  methods: {
    goback() {
      this.$router.push("/ContainerHome/MyFiles");
    },
    /**
     * 获取数据
     */
    get_job_list() {
      return get_job_list({
        action: "get_job_list",
        name: "",
        folder_id: this.folder_id,
        start_time: this.time1,
        end_time: this.time2,
        sort_type: "desc",
        sort_name: "create_time",
      }).then((res) => {
        if (res) {
          console.log(res.list);
          this.tableData = res.list;
        }
      });
    },
  },
  mounted() {
    // this.get_job_list();
  },
};
</script>
<style scoped lang="scss">
.titlediv {
  display: flex;
  align-items: center;
  p {
    margin-left: 0;
    color: #adb0bc;
    span {
      color: #ffffff;
    }
  }
  .svgClass {
    font-size: 20px;
    margin-left: 38px;
    margin-right: 11px;
    cursor: pointer;
  }
}
</style>
