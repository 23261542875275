<!--
 * @Description: 购买服务 PurchaseServices
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-08 16:54:42
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-30 17:26:40
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <div class="tabdiv">
        <p>
          <el-button type="text" @click="BackPrevious">
            <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
          </el-button>
        </p>
        <div class="tabdiv_div">
          <p
            @click="changeTabs('first')"
            :class="BuyServices == 'first' ? 'borderandcolorClass' : ''"
          >
            <!--            购买服务-->
            {{ $t("PersonalEndLanguage.PurchaseServices") }}
          </p>
          <div></div>
        </div>
        <div class="tabdiv_div">
          <p
            @click="changeTabs('second')"
            :class="BuyServices !== 'first' ? 'borderandcolorClass' : ''"
          >
            <!--            开通企业 VIP-->
            {{ $t("PersonalEndLanguage.removeVIP") }}
          </p>
          <div></div>
        </div>
      </div>
      <div class="showGiftBagbtn" @click="handlePackageClick">
        <!--        <p>礼包码兑换</p>-->
        <p>{{ $t("PersonalEndLanguage.GiftPackCodeRedemption") }}</p>
      </div>
    </div>
    <div class="content">
      <PurchaseServicesIndex
        v-show="BuyServices == 'first'"
        @goVIP="goVIP"
      ></PurchaseServicesIndex>
      <EnterprisesOpenedVIP
        v-show="BuyServices == 'second'"
        @goVIP="goVIP"
      ></EnterprisesOpenedVIP>
      <router-view></router-view>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'448px'"
      :top="'10%'"
      :title="$t('PersonalEndLanguage.GiftPackCodeRedemption')"
      :showfooter="true"
      :destroyonclose="true"
      v-if="dialogVisible"
    >
      <div class="dialogContents">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm"
          label-position="top"
          :hide-required-asterisk="false"
          @keydown.enter.prevent
        >
          <!--          礼包码-->
          <el-form-item
            :label="$t('PersonalEndLanguage.Giftpackagecode')"
            prop="package_code"
            :rules="[
              {
                required: true,
                message: $t('PersonalEndLanguage.warningmessage28'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="ruleForm.package_code"
              :placeholder="$t('PersonalEndLanguage.warningmessage29')"
              class="topinput"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import PurchaseServicesIndex from "./MemberServices/PurchaseServicesIndex.vue";
import EnterprisesOpenedVIP from "./MemberServices/EnterprisesOpenedVIP.vue";
import { get_package_code } from "@/utils/https";

export default {
  components: { DialogComVue, PurchaseServicesIndex, EnterprisesOpenedVIP },
  created() {
    // 变换
    this.$emit("header", false, false, true, true, true);
    if (sessionStorage.getItem("BuyServices") == null) {
      this.BuyServices = "first";
    } else {
      this.BuyServices = sessionStorage.getItem("BuyServices");
    }
  },
  mounted() {
    window.addEventListener("setItem", () => {
      if (sessionStorage.getItem("BuyServices") == null) {
        this.BuyServices = "first";
      } else {
        this.BuyServices = sessionStorage.getItem("BuyServices");
      }
    });
  },
  data() {
    return {
      BuyServices: "first",
      dialogVisible: false,
      ruleForm: {
        package_code: "",
      },
      submit_loading: false,
    };
  },

  methods: {
    goVIP(data) {
      this.BuyServices = data;
    },
    handlePackageClick() {
      this.dialogVisible = true;
    },
    changeTabs(flag) {
      this.BuyServices = flag;
      this.setSessionItem("BuyServices", flag);
      // sessionStorage.setItem("BuyServices", flag);
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.$refs["ruleForm"].resetFields();
    },
    determineClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log("submit!");
          if (this.submit_loading == false) {
            this.submit_loading = true;
            get_package_code({
              action: "get_package_code",
              package_code: this.ruleForm.package_code,
              address: this.ruleForm.contact_lname,
              email: this.ruleForm.contact_email,
              tel: this.ruleForm.contact_text,
            }).then((res) => {
              this.submit_loading = false;
              if (!res) {
                return;
              }
              this.dialogVisible = false;
              this.$messages("success", res.msg);
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    // 移除对 sessionStorage 的监听
    window.removeEventListener("setBuyServices", () => {});
  },
};
</script>
<style lang="scss" scoped>
.titlediv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabdiv {
  display: flex;
  height: 56px;

  .tabdiv_div {
    display: flex;
    align-items: center;

    p {
      color: #cbd7e8;
      font-size: 14px;
      cursor: pointer;
      height: calc(100% - 2px);
      box-sizing: border-box;
    }

    .borderandcolorClass {
      transition: 0.1s;
      border-bottom: 2px solid #46adff;
      font-weight: 700;
      color: #46adff;
    }

    div {
      width: 1px;
      height: 12px;
      background-color: #515464;
      margin-left: 32px;
    }
  }
}

.showGiftBagbtn {
  float: right;
  box-sizing: border-box;
  width: 120px;
  height: 32px;
  border: 1px solid;
  border-color: #ffb92e;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  cursor: pointer;

  p {
    margin-left: 0 !important;
    color: #ffb92e !important;
  }
}

.dialogContents {
  padding: 16px 24px;

  ::v-deep .el-form-item__label {
    line-height: 24px;
    margin-top: 8px;
    color: #adb0bc;
    font-size: 14px;
    padding: 0;
  }

  ::v-deep .el-input__inner {
    width: 100%;
  }

  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
}
</style>
