/*
 * @Description: 配置文件
 * @version:
 * @Author: Tom
 * @Date: 2022-11-01 10:26:01
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-13 11:08:08
 */
export default {
  title: "Ai Dubbing",
  /**
   * 本地
   */
  // baseUrl: {
  //   dev: "http://wiimedia.ectranslate.com",
  //   AnimationFlipURL: "http://comic.ectranslate.com", //测试
  // },
  // mediaUrl: {
  //   dev: "http://iwork.ectranslate.com/", //测试
  // },
  // cookies: {
  //   domain: ".ectranslate.com", //测试
  // },
  // WebSocketUrl: {
  //   dev: "wss://iwork-ws.ectranslate.com", //测试
  // },
  /**
   * 测试
   */
  // baseUrl: {
  //   dev: "http://iwork-api.ectranslate.com", //测试
  //   AnimationFlipURL: "http://comic.ectranslate.com", //测试
  // },
  // mediaUrl: {
  //   dev: "http://iwork.ectranslate.com/", //测试
  // },
  // cookies: {
  //   domain: ".ectranslate.com", //测试
  // },
  // WebSocketUrl: {
  //   dev: "wss://iwork-ws.ectranslate.com", //测试
  // },
  /**
   * 正式
   */
  baseUrl: {
    dev: "https://iwork-api.wiimedia.video", //  正、式
    AnimationFlipURL: "https://comic.wiimedia.video", //正式/、、
  },
  mediaUrl: {
    dev: "https://iiwork.wiimedia.video/", //正式/
  },
  cookies: {
    domain: ".wiimedia.video", //正式
  },
  WebSocketUrl: {
    dev: "wss://iwork-ws.wiimedia.video", //  正式
  },
};
