<!--
 * @Description: 成员邀请 页面
 * @version: 
 * @Author: Carful
 * @Date: 2023-01-16 16:53:36
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-27 16:38:30
-->
<template>
  <div class="login">
    <div class="login_div">
      <div class="left">
        <img src="@/assets/imgs/login1.png" alt="" />
      </div>
      <div class="right">
        <!-- <img src="@/assets/imgs/img_invite.png" alt="" /> -->
        <div class="right_div" v-if="status == '0'">
          <p class="topp">用户 {{ userName }}</p>
          <p>邀请您加入他的团队</p>

          <el-button type="primary" class="topbtn" @click="invitationClick(1)"
            >同意邀请</el-button
          >
          <el-button
            type="text"
            style="font-size: 1.8vh"
            @click="invitationClick(2)"
            >拒绝</el-button
          >
        </div>
        <div v-if="status == '1'" class="right_div">
          <p class="statusClass">您已成功加入</p>
          <p>{{ userName }} 团队</p>
        </div>
        <div v-if="status == '2'" class="right_div">
          <p class="statusClass">您已拒绝</p>
          <p>{{ userName }} 团队的邀请</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { update_invitation_member, get_invitation_info } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
export default {
  data() {
    return {
      tuid: "",
      email: "",
      useremail: "",
      status: "",
      userName: "",
    };
  },
  methods: {
    update_invitation_member(data) {
      update_invitation_member({
        tuid: this.tuid,
        type: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.status = res.status;
        if (data == 1) {
          this.$messages("success", "已同意邀请");
        } else {
          this.$messages("success", "已拒绝同意邀请");
        }
        hideLoading();
      });
    },
    /**
     *
     * @param {*同意 拒绝邀请} data
     */
    invitationClick(data) {
      showLoading("加载中...");
      this.update_invitation_member(data);
    },
  },
  created() {
    this.tuid = this.$route.query.tuid;
    this.email = this.$route.query.email;
  },
  mounted() {
    // console.log(this.$route.query.email);
    get_invitation_info({
      email: this.email,
      tuid: this.tuid,
    }).then((res) => {
      if (!res) {
        return;
      }
      this.useremail = res.email;
      this.status = res.status;
      this.userName = res.nickname;
    });
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  min-height: 610px;
  // background-image: linear-gradient(70.44deg, #282f58 0%, #0c0e2a 100%);
  background-image: url("@/assets/imgs/bg_login.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_div {
    width: 75%;
    height: 70%;
    display: flex;
    align-items: center;
    .left {
      width: 65%;
      min-width: 666px;
      min-height: 458px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .right {
      // width: 520px;
      // height: 548px;
      width: 35%;
      height: 70%;
      .right_div {
        width: 100%;
        min-width: 340px;
        max-width: 440px;
        height: 100%;
        min-height: 352px;
        max-height: 460px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        // background: #282f58;
        background-image: url("@/assets/imgs/img_invite.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .statusClass {
          color: #ffffff;
          font-size: 2vh;
          margin-top: 30%;
        }
        p {
          color: #ffffff;
          font-size: 2vh;
        }
        .topp {
          margin-top: 7.3vh;
          margin-bottom: 6px;
        }
        .topbtn {
          margin-top: 6.6vh;
          margin-bottom: 2.4vh;
          padding: 0 8.8vh;
          font-size: 1.8vh;
          height: 4vh !important;
          min-height: 3vh;
        }
      }
    }
  }
}
.el-button {
  padding: 0px;
  line-height: normal !important;
}
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
</style>
