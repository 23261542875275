/*
 * @Descripttion: 国际化语言 切换
 * @version:
 * @Author: Carful
 * @Date: 2022-10-17 10:34:05
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-15 15:35:04
 */
import Vue from "vue";
// 使用插件
import Element from "element-ui";
import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import twLocale from "element-ui/lib/locale/lang/zh-TW";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: sessionStorage.getItem("lang") || "cn", // 语言标识,第一次登录默认是中文
  messages: {
    cn: { ...require("./languages/cn"), ...zhLocale },
    en: { ...require("./languages/en"), ...enLocale },
    tc: { ...require("./languages/tc"), ...twLocale },
  },
});
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});
export default i18n;
