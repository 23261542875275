<!--
 * @Description: 购买记录
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-12 15:10:11
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-29 14:06:15
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <!--        购买记录-->
        <span class="spanClass">{{
          $t("PersonalCenter.PurchaseRecordText")
        }}</span>
      </p>
    </div>
    <div class="content">
      <div class="content_time">
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('MemberDetails.datetime2')"
          :start-placeholder="$t('MemberDetails.datetime1')"
          :end-placeholder="$t('MemberDetails.datetime3')"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          @blur="setTime"
        >
        </el-date-picker>
      </div>
      <div
        class="tableborderClass tableClass borderTableStyle"
        :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        v-if="tableData.length > 0"
      >
        <el-table
          :row-style="{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="maxHeight"
          border
        >
          <el-table-column
            prop="pay_time"
            :label="$t('PersonalCenter.PurchaseRecordText1')"
          >
          </el-table-column>
          <el-table-column
            prop="pay_action"
            :label="$t('PersonalCenter.PurchaseRecordText2')"
          >
          </el-table-column>
          <el-table-column
            prop="pay_type"
            :label="$t('PersonalCenter.PurchaseRecordText3')"
          >
          </el-table-column>
          <el-table-column
            prop="money"
            :label="$t('PersonalCenter.PurchaseRecordText4')"
            align="right"
          >
          </el-table-column>
        </el-table>
      </div>
      <div v-else class="noDataDiv">
        <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
        <p>{{ $t("CommonName.noData") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { get_pay_list } from "@/utils/https";
import { timestampToTime } from "@/api/setTime";

export default {
  created() {
    // 变换
    this.$emit("header", false, false, true, true, true);
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    this.time = [timestampToTime(start), timestampToTime(end)];
  },
  data() {
    return {
      time: "",
      maxHeight: 700,
      tableData: [],
    };
  },
  methods: {
    /**
     *
     * @param {*获取 数据} data
     */
    get_pay_list(data) {
      return get_pay_list({
        action: "get_pay_list",
        start: data[0],
        end: data[1],
      }).then((res) => {
        if (res) {
          let arr = res.list;
          if (arr) {
            arr.forEach((i) => {
              i.money = i.money + " 元";
            });
          }

          this.tableData = arr;
        }
      });
    },
    /**
     *设置时间 获取数据
     */
    setTime() {
      // console.log(this.time);
      this.get_pay_list(this.time);
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // console.log(this.time);
    this.get_pay_list(this.time);
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 0 24px;

  .content_time {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 16px 0;
    padding-top: 24px;
  }
}
</style>
