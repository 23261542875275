<!--
 * @Description: 多媒体批量下载
 * @version: 
 * @Author: Tom
 * @Date: 2023-03-21 08:41:36
 * @LastEditors: Tom
 * @LastEditTime: 2023-03-21 08:41:36
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'1000px'"
    :top="'10%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="GlossariesDialog">
      <div class="glo_top">
        <el-button type="text" @click="downloadClick(6)">
          <div class="buttondiv">
            {{ $t("ProjectDetails.dubbingText5") }}
          </div>
        </el-button>
      </div>
      <div class="glo_bottom">
        <div
          class="tableClass borderTableStyle"
          :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="273px"
            border
          >
            <!-- 名称 -->
            <el-table-column prop="job_name" :label="$t('CommonName.TaskName')">
            </el-table-column>
            <!-- 压制视频 -->
            <el-table-column
              prop="job_dubbing_id"
              :label="$t('ProjectDetails.dubbingText5')"
              width="140"
            >
              <template slot-scope="scope">
                <div style="" class="menuscopediv">
                  <i v-if="scope.row.icon5" class="el-icon-loading"></i>
                  {{ scope.row.text5 }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { get_project_info } from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    jobIds: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      buttonLoading: false,
      dialogVisible: false,
      title: "",
      project_id: "",
      Operatortabs: "1",
      MemberOptions: [],
      tableData: [], // 视频配音 批量指派人
      dubbingInterval: null,
    };
  },
  created() {
    this.project_id = this.$route.query.id;
  },
  mounted() {
    // console.log(11111);
    this.dialogVisible = this.showDialog;
    this.title = this.$t("ProjectDetails.downloadText");
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_download_job_list().then((res) => {
      hideLoading();
      clearInterval(this.dubbingInterval);
      this.dubbingInterval = setInterval(() => {
        this.get_download_job_list();
      }, 60000);
    });
  },
  methods: {
    /**
     *
     * @param data
     */
    get_download_job_list() {
      return get_project_info({
        action: "get_download_job_list",
        job_ids: this.jobIds,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.tableData = this.setTableData(res.list);
        return res;
      });
    },
    setTableData(data) {
      data.forEach((item) => {
        //压制视频文件
        item["text5"] = this.$t("ProjectDetails.downloadText5");
        item["icon5"] = false;
        if (item["file_suppression_path"] != "") {
          item["text5"] = this.$t("ProjectDetails.downloadText7");
        } else if (item["file_suppression_status"] == "1") {
          item["icon5"] = true;
          item["text5"] = this.$t("ProjectDetails.downloadText6");
        }
      });
      return data;
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.$emit("closeDialog", this.dialogVisible);
      this.buttonLoading = false;
      this.dialogVisible = false;
    },
    downloadClick(mode) {
      showLoading(this.$t("MyFiles.downloadLoading"));
      let list = [];
      let no_url_job_ids = [];
      let status_error_job_ids = []; //状态不为0 的任务
      this.get_download_job_list().then((res) => {
        // console.log("res", this.tableData);
        this.tableData.forEach((item) => {
          let path = "";
          let name = item.job_name;
          if (item.file_suppression_status != "0") {
            status_error_job_ids.push(item.job_id);
          }
          switch (mode) {
            case 6:
              //压制视频
              if (item.file_suppression_path) {
                path = item.file_suppression_path;
                // console.log('name.lastIndexOf(".")', name.lastIndexOf("."));
                if (name.lastIndexOf(".") > -1) {
                  name = name.slice(0, name.lastIndexOf(".")) + ".mp4";
                } else {
                  name = name + ".mp4";
                }
              } else {
                no_url_job_ids.push(item.job_id);
              }
              break;
            default:
              break;
          }
          list.push({ name, path });
        });
        if (no_url_job_ids.length == 0) {
          // console.log("list", list);
          // return false;
          if (list.length == 1) {
            this.download_method(list[0].path, list[0].name);
          } else {
            this.downloadMethod(list);
          }
        } else {
          //请求 将没有url的id重新生成音轨
          //如果有状态不为0的任务无法生成音轨
          if (status_error_job_ids.length == 0) {
            this.$messages("success", this.$t("ProjectDetails.downloadTips2"));
            get_project_info({
              action: "download_dubbing_sound_track_batch",
              job_ids: no_url_job_ids,
              mode: mode - 1,
            }).then((res) => {
              hideLoading();
              if (!res) {
                return;
              }
              this.get_download_job_list();
            });
          } else {
            this.$messages("warning", this.$t("ProjectDetails.downloadTips"));
            hideLoading();
            // console.log("333");
          }
        }
        // console.log("no_url_job_ids", no_url_job_ids);
      });
    },
    //单文件下载
    download_method(url, file_name) {
      let name = file_name.split("?t=");
      const oReq = new XMLHttpRequest();
      const URL = url; // URL 为下载的URL地址
      oReq.open("GET", URL, true);
      oReq.responseType = "blob";
      let that = this;
      oReq.onload = function () {
        const file = new Blob([oReq.response], {
          type: "blob",
        });
        FileSaver.saveAs(file, name[0]); // fileName为文件名
        hideLoading();
      };
      oReq.send();
    },
    //多文件打包
    downloadMethod(list) {
      var blogTitle = this.$t("MyFiles.downloadTitle") + new Date().getTime(); // 下载后压缩包的命名
      var zip = new JSZip();
      var promises = [];
      let cache = {};
      let arrImg = list;
      for (let item of arrImg) {
        if (item.name != "" && item.path != "") {
          // item.path为文件链接地址
          const promise = this.getImgArrayBuffer(item.path).then((data) => {
            // 下载文件, 并存成ArrayBuffer对象(blob)
            zip.file(item.name, data, { binary: true }); // 逐个添加文件
            // cache[item.name] = data;
          });
          promises.push(promise);
        }
      }
      if (promises.length > 0) {
        Promise.all(promises)
          .then(() => {
            zip
              .generateAsync({
                type: "blob",
                compression: "DEFLATE", // STORE：默认不压缩 DEFLATE：需要压缩
                compressionOptions: {
                  level: 9, // 压缩等级1~9    1压缩速度最快，9最优压缩方式
                },
              })
              .then((content) => {
                // 生成二进制流
                FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  自定义文件名
                hideLoading();
                promises = null;
              });
          })
          .catch((res) => {
            hideLoading();
            this.$messages("error", this.$t("MyFiles.downloadTips1"));
          });
      }
    },
    //文件以流的形式获取（参数url为文件链接地址）
    getImgArrayBuffer(url) {
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlhttp.send();
        xmlhttp = null;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    margin-top: 22px;
    .el-button + .el-button {
      margin-left: 18px;
    }
  }
  .glo_bottom {
    margin-top: 22px;
    height: 273px;

    .tableClass {
      width: 100%;
    }
  }
}
</style>
