<!--
 * @Description: 消息中心
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-25 17:18:26
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-17 09:58:15
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <span class="spanClass">{{ $t("message.title") }}</span>
      </p>
    </div>
    <div class="content">
      <div class="content_content_div">
        <div class="content_content" v-if="messageList.length > 0">
          <div
            v-for="(i, index) in messageList"
            :key="index"
            class="content_center"
          >
            <p class="content_center_title">{{ i.message_title }}</p>
            <div class="content_center_div">
              <div class="content_center_div_left">
                <p class="timep">{{ i.create_time }}</p>
                <p class="explainp">{{ i.message }}</p>
              </div>
              <div class="content_center_div_right">
                <el-button
                  type="text"
                  v-if="i.type == '2' && i.extra == ''"
                  @click="refuseClick(i)"
                  ><p>{{ $t("message.refuse") }}</p></el-button
                >
                <el-button
                  type="text"
                  v-if="i.type == '2' && i.extra == ''"
                  class="btn"
                  @click="agreeClick(i)"
                  ><p>{{ $t("message.accept") }}</p></el-button
                >
                <el-button
                  type="text"
                  v-if="i.type == '1' && i.extra == ''"
                  @click="renewClick"
                  ><p class="btn2">{{ $t("message.renew") }}</p></el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else class="newnoDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  get_message_list,
  update_invitation_member_method,
} from "@/utils/https";

export default {
  created() {
    // 变换
    this.$emit("header", false, false, true, true, true);
    this.id = this.$route.query.id;
  },
  data() {
    return {
      id: "",
      messageList: [],
      info: [],
    };
  },
  methods: {
    get_message_info() {
      get_message_list({
        action: "get_message_list",
      }).then((res) => {
        console.log(res);
        if (!res) {
          return;
        }
        this.messageList = res.list;
      });
    },
    renewClick() {
      let pathInfo = this.$router.resolve({
        path: "/ContainerHome/PurchaseServices",
      });
      window.open(pathInfo.href, "_blank");
    },
    refuseClick(item) {
      this.$confirm(this.$t("message.refuseTitle"), this.$t("message.title1"), {
        confirmButtonText: this.$t("message.confirm"),
        cancelButtonText: this.$t("message.cancel"),
        cancelButtonClass: "el-button--info",
        iconClass: "none",
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        closeOnPressEscape: false,
        type: "warning",
      })
        .then(() => {
          update_invitation_member_method({
            action: "update_invitation_member",
            team_id: item.tid,
            message_user_id: item.message_user_id,
            type: 2,
          }).then((res) => {
            console.log(res);
            if (!res) {
              return;
            }
            this.$messages("success", this.$t("message.refuseTip"));
            setTimeout(() => {
              this.$router.go(0);
            }, 2000);
          });
        })
        .catch((action) => {});
    },
    agreeClick(item) {
      this.$confirm(this.$t("message.agreeTitle"), this.$t("message.title1"), {
        confirmButtonText: this.$t("message.confirm"),
        cancelButtonText: this.$t("message.cancel"),
        cancelButtonClass: "el-button--info",
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        closeOnPressEscape: false,
      })
        .then(() => {
          update_invitation_member_method({
            action: "update_invitation_member",
            team_id: item.tid,
            message_user_id: item.message_user_id,
            type: 1,
          }).then((res) => {
            console.log(res);
            if (!res) {
              return;
            }
            this.$messages("success", this.$t("message.agreeTip"));
            setTimeout(() => {
              this.$router.go(0);
            }, 2000);
          });
        })
        .catch((action) => {});
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.get_message_info();
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.info.message_count = 0;
    this.setSessionItem("info", JSON.stringify(this.info));
  },
};
</script>
<style lang="scss" scoped>
.content_content_div {
  height: 99%;
  overflow-y: auto;

  .newnoDataDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.content_content {
  padding-top: 12px;
  margin: 0 auto;
  width: 888px;
}

.content_center {
  border-bottom: 1px solid #515464;

  .content_center_title {
    color: #ffffff;
    font-size: 14px;
    text-align: left;
    // letter-spacing: 15px;
    margin-top: 24px;
  }

  .content_center_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 24px 0;

    .content_center_div_left {
      display: flex;
      align-items: center;

      p {
        color: #adb0bc;
        font-size: 14px;
      }

      .explainp {
        margin-left: 24px;
        text-align: left;
      }

      .timep {
        min-width: 120px;
      }
    }

    .content_center_div_right {
      .el-button {
        padding: 0;
      }

      .btn {
        margin-left: 26px;
      }

      .btn2 {
        color: #ffc629;
        margin-left: 16px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-message-box__content {
  text-align: left !important;
  padding: 0px 25px !important;
}
</style>
