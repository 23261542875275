/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:05:23
 * @LastEditors: Carful
 * @LastEditTime: 2023-04-21 10:34:59
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import config from "@/config";
import i18n from "./assets/languages/index"; // 第一步：引入多语言配置文件index.js
import "@/assets/icons"; //svg图标
import "@/api/EventsetSessionItem";

Vue.config.productionTip = false;
import alertOK from "@/utils/message/message";

Vue.prototype.$messages = alertOK;
import Cookies from "js-cookie";
import qs from "qs";
import "@/assets/fonts/font.css";
import "@/utils/waterMark";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueYouTube from "vue-youtube";

// 把intro.js加入到vue的prototype中，方便使用，就可以直接通过this.$intro()来调用了

Vue.use(VueYouTube);
Vue.use(VueVirtualScroller);
Vue.use(ElementUI);
Vue.use(elTableInfiniteScroll);
Vue.prototype.$qs = qs;
Vue.prototype.$Cookie = Cookies;
Vue.prototype.$uploadURL = config.baseUrl.dev;
// Vue.prototype.$uploadURL = "http://iwork-api.ectranslate.com";
Vue.prototype.$AnimationFlipURL = config.baseUrl.AnimationFlipURL;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
