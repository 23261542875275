/*
 * @Description: 监听 用户信息更新
 * @version:
 * @Author: Carful
 * @Date: 2022-12-30 09:44:05
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-30 09:44:36
 */
import Vue from "vue";
Vue.prototype.setSessionItem = function (key, newVal) {
  if (key == "info" || key == "BuyServices") {
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化 StorageEvent 事件
        newStorageEvent.initStorageEvent(
          "setItem", // 事件别名
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发事件
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
  // 创建 StorageEvent 事件
};
