/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:05:23
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-21 10:27:52
 */
import Vue from "vue";
import Vuex from "vuex";

import anchor from "./Dubbing/anchor";
import VideoDubbinganchor from "./VideoDubbing/anchor";
import VideoDubbingClone from "./VideoDubbingClone/anchor";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    anchor,
    VideoDubbinganchor,
    VideoDubbingClone,
  },
});
