/*
 * @Description: svg
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 17:39:29
 * @LastEditors: Carful
 * @LastEditTime: 2022-10-26 10:18:31
 */
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon";
Vue.component("svg-icon", SvgIcon);

/**
 *  <svg-icon icon-class="search"></svg-icon> 使用
 * require.context 的参数说明
 * './svg' 代表要查找的文件路径
 * false 代表是否查找子目录
 * /\.svg$/ 代表要匹配文件的正则
 *
 */
const svg = require.context("@/assets/icons/svg", false, /\.svg$/);
const requireAll = (requireContext) => {
  requireContext.keys().map(requireContext);
};

requireAll(svg);
