/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2023-04-06 11:06:35
 * @LastEditors: Carful
 * @LastEditTime: 2023-04-06 11:06:45
 */
export default {
  methods: {
    dispatch(componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.name;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }

      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(rest));
      }
    },
  },
};
