<!--
 * @Description: 个人中心
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <span class="spanClass">{{ $t("PersonalCenter.title") }}</span>
      </p>
      <!-- 所在团队信息 -->
      <el-button type="text" class="rightbtn" @click="Teaminformation">
        <div class="svgbtn14">
          <svg-icon icon-class="Details" class="icon"></svg-icon>
          <span>{{ $t("PersonalCenter.teamInformation") }}</span>
        </div>
      </el-button>
    </div>
    <div class="content">
      <div class="content_top">
        <div class="content_top_head">
          <div class="leftdiv">
            <!-- 头像 -->
            <div class="avatar">
              <el-avatar :size="88" :src="avatarurl" fit="cover"></el-avatar>
              <div class="setavatar">
                <el-upload
                  class="upload-demo"
                  ref="imgupload"
                  :with-credentials="true"
                  :action="$uploadURL + '/user/update_head'"
                  name="head_file"
                  :before-upload="beforeUpload"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :auto-upload="true"
                  :show-file-list="false"
                  :limit="1"
                >
                  <svg-icon
                    icon-class="Edit"
                    class="icon"
                    @click="clearFile"
                  ></svg-icon>
                </el-upload>
              </div>
            </div>
            <div class="leftdiv_right">
              <!-- 昵称 -->
              <div class="leftdiv_right_top">
                <p>{{ title }}</p>
                <div class="titlediv" v-if="info.role == '2'">
                  <p>{{ $t("PersonalCenter.corporateVIP") }}</p>
                </div>
                <div v-if="info.role == '2'" class="timep">
                  {{ $t("PersonalCenter.expirationTime") }}{{ info.vip_time }}
                </div>
              </div>
              <div class="leftdiv_right_btm">
                <!-- ID -->
                <div class="btm_div">
                  <p class="titlep">{{ $t("PersonalCenter.ID") + ID }}</p>
                  <el-button type="text" @click="copyClick(ID)">
                    <div class="svgbtn">
                      <svg-icon icon-class="Copy" class="icon"></svg-icon>
                    </div>
                  </el-button>
                </div>
                <!-- 邮箱 -->
                <div class="btm_div" style="margin: 0 109px 0 37px">
                  <p class="titlep">
                    {{ $t("PersonalCenter.email") }}{{ email }}
                  </p>
                </div>
                <!-- 手机号 -->
                <div class="btm_div">
                  <p class="titlep">
                    {{ $t("PersonalCenter.mobilephone") }}{{ mobilephone }}
                  </p>
                  <el-button
                    type="text"
                    v-if="mobilephone == this.$t('PersonalCenter.unbound')"
                    @click="bindingmobilephone(0)"
                  >
                    <div class="svgbtn">
                      <p>{{ $t("PersonalCenter.binding") }}</p>
                    </div>
                  </el-button>
                  <el-button type="text" v-else @click="bindingmobilephone(1)">
                    <div class="svgbtn">
                      <p>{{ $t("PersonalCenter.replace") }}</p>
                    </div>
                  </el-button>
                </div>
                <!-- 微信 -->
                <div class="btm_div" style="margin-left: 109px">
                  <p class="titlep">
                    {{ $t("PersonalCenter.wechat") }}{{ WeChataccount }}
                  </p>
                  <el-button
                    type="text"
                    v-if="WeChataccount == this.$t('PersonalCenter.unbound')"
                    @click="WxLogin"
                  >
                    <div class="svgbtn">
                      <p>{{ $t("PersonalCenter.binding") }}</p>
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    v-if="WeChataccount == this.$t('PersonalCenter.bound')"
                  >
                    <el-dropdown @command="commandClick" placement="bottom-end">
                      <span class="el-dropdown-link">
                        <el-button type="text">
                          <div class="svgbtn">
                            <p>{{ $t("PersonalCenter.setUp") }}</p>
                          </div>
                        </el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          command="2"
                          v-if="wx_message == '1'"
                          >{{
                            $t("PersonalCenter.TurnOnMessage")
                          }}</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="3"
                          v-if="wx_message == '0'"
                          >{{
                            $t("PersonalCenter.TurnOffMessage")
                          }}</el-dropdown-item
                        >
                        <el-dropdown-item command="4">{{
                          $t("PersonalCenter.unbind")
                        }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-button>
                  <!-- <el-button
                  type="text"
                  v-if="WeChataccount == this.$t('PersonalCenter.bound')"
                  @click="check_unbind"
                >
                  <div class="svgbtn">
                    <p>{{ $t("PersonalCenter.unbind") }}</p>
                  </div>
                </el-button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="rightdiv">
            <!-- 设置 -->
            <!-- <el-button type="text">
              <el-dropdown @command="commandClick" placement="bottom-end">
                <span class="el-dropdown-link">
                  <el-button type="text">
                    <div class="svgbtn14">
                      <svg-icon
                        icon-class="ChannelManagement"
                        class="icon"
                      ></svg-icon>
                      <p>{{ $t("PersonalCenter.setUp") }}</p>
                    </div>
                  </el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="0">{{
                    $t("PersonalCenter.accountCancellation")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="1">{{
                    $t("PersonalCenter.changePassword")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="5">{{
                    $t("PersonalCenter.exportInfo")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-button> -->
          </div>
        </div>
        <div style="display: flex; margin-top: 10px">
          <el-button type="text" @click="commandClick(6)">
            <div class="">
              <p>{{ $t("PersonalCenter.personInfo") }}</p>
            </div>
          </el-button>
          <el-button type="text" @click="commandClick(1)">
            <div class="">
              <p>{{ $t("PersonalCenter.changePassword") }}</p>
            </div>
          </el-button>
          <el-button type="text" @click="commandClick(7)">
            <div class="">
              <p>{{ $t("PersonalCenter.otherInfo") }}</p>
            </div>
          </el-button>
          <el-button type="text" @click="commandClick(5)">
            <div class="">
              <p>{{ $t("PersonalCenter.exportInfo") }}</p>
            </div>
          </el-button>
          <el-button type="text" @click="commandClick(0)">
            <div class="">
              <p>{{ $t("PersonalCenter.accountCancellation") }}</p>
            </div>
          </el-button>
        </div>
      </div>
      <div class="content_btm">
        <div class="content_btm_top">
          <div
            @click="ClickshowGiftBagbtn"
            :class="showGiftBagBtn ? 'trueClass' : 'falseClass'"
          >
            <!--            领取本月免费礼包-->
            <p v-if="showGiftBagBtn">{{ $t("PersonalCenter.giftText") }}</p>
            <p v-else>{{ $t("PersonalCenter.giftText1") }}</p>
          </div>
          <div>
            <!-- 购买记录 -->
            <el-button type="text" @click="gorecord(0)">{{
              $t("PersonalCenter.giftText2")
            }}</el-button>
            <!-- 服务明细 -->
            <el-button type="text" @click="gorecord(1)">{{
              $t("PersonalCenter.giftText3")
            }}</el-button>
          </div>
        </div>
        <!-- 译制功能包 -->
        <div class="content_btm_center_div">
          <div class="content_btm_center_div_leftdiv">
            <div class="content_btm_center_div_leftdiv_top">
              <div></div>
              <p>{{ $t("PersonalCenter.giftText4") }}</p>
            </div>
            <p>
              {{ $t("PersonalCenter.remainingDuration") }}&nbsp;&nbsp;&nbsp;{{
                duration
              }}{{ $t("PersonalCenter.hour") }}
            </p>
            <p>
              <span
                >{{ $t("PersonalCenter.expirationTime")
                }}{{ expire_time }}</span
              ><span>{{ $t("PersonalCenter.giftText5") }}</span>
            </p>
          </div>
          <div>
            <el-button
              type="primary"
              class="setpadding16"
              @click="gobuyPurchaseServices"
              ><p>{{ $t("PersonalCenter.giftText6") }}</p></el-button
            >
          </div>
        </div>
        <!-- AI 视频配音包 -->
        <div class="content_btm_center_div">
          <div class="content_btm_center_div_leftdiv">
            <div class="content_btm_center_div_leftdiv_top">
              <div></div>
              <p>{{ $t("PersonalCenter.giftText15") }}</p>
            </div>
            <p v-if="quotaInfo.video_dubbing">
              <span class="pspan"
                >{{
                  $t("PersonalCenter.remainingDuration")
                }}&nbsp;&nbsp;&nbsp;{{
                  quotaInfo.video_dubbing.video_dubbing_minute
                }}{{ $t("PersonalCenter.minute") }}</span
              >
              <span class="pspan"
                >{{ $t("PersonalCenter.giftText9") }}&nbsp;&nbsp;&nbsp;{{
                  quotaInfo.video_dubbing.video_audio_count
                }}
                {{ $t("PersonalCenter.giftText11") }}</span
              >
              <span class="pspan"
                >{{ $t("PersonalCenter.giftText16") }}&nbsp;&nbsp;&nbsp;{{
                  quotaInfo.video_dubbing.video_dubbing_count
                }}
                {{ $t("PersonalCenter.giftText11") }}</span
              >
              <!-- <span class="pspan">{{ $t("PersonalCenter.giftText12") }}&nbsp;&nbsp;&nbsp;{{ $t("PersonalCenter.giftText13") }}</span> -->
            </p>
            <p>
              <span
                >{{ $t("PersonalCenter.expirationTime")
                }}{{ expire_time3 }}</span
              ><span>{{ $t("PersonalCenter.giftText14") }}</span>
            </p>
          </div>
          <div>
            <el-button
              type="primary"
              class="setpadding16"
              @click="gobuyPurchaseServices"
              ><p>{{ $t("PersonalCenter.giftText6") }}</p></el-button
            >
          </div>
        </div>
        <!-- 视频涂抹功能包 -->
        <div class="content_btm_center_div">
          <div class="content_btm_center_div_leftdiv">
            <div class="content_btm_center_div_leftdiv_top">
              <div></div>
              <p>{{ $t("PersonalCenter.giftText27") }}</p>
            </div>
            <p v-if="quotaInfo.daub">
              <span class="pspan"
                >{{
                  $t("PersonalCenter.remainingDuration")
                }}&nbsp;&nbsp;&nbsp;{{ quotaInfo.daub.video_daub_minute
                }}{{ $t("PersonalCenter.minute") }}</span
              >
              <!-- <span class="pspan">{{ $t("PersonalCenter.giftText12") }}&nbsp;&nbsp;&nbsp;{{ $t("PersonalCenter.giftText13") }}</span> -->
            </p>
            <p>
              <span
                >{{ $t("PersonalCenter.expirationTime")
                }}{{ expire_time4 }}</span
              ><span>{{ $t("PersonalCenter.giftText28") }}</span>
            </p>
          </div>
          <div>
            <el-button
              type="primary"
              class="setpadding16"
              @click="gobuyPurchaseServices"
              ><p>{{ $t("PersonalCenter.giftText6") }}</p></el-button
            >
          </div>
        </div>
        <!-- AI 文本配音包 -->
        <div class="content_btm_center_div">
          <div class="content_btm_center_div_leftdiv">
            <div class="content_btm_center_div_leftdiv_top">
              <div></div>
              <p>{{ $t("PersonalCenter.giftText7") }}</p>
            </div>
            <p>
              <span class="pspan"
                >{{ $t("PersonalCenter.giftText8") }}&nbsp;&nbsp;&nbsp;{{
                  dubbing_word
                }}</span
              >
              <span class="pspan"
                >{{ $t("PersonalCenter.giftText9") }}&nbsp;&nbsp;&nbsp;{{
                  audio_count
                }}
                {{ $t("PersonalCenter.giftText11") }}</span
              >
              <span class="pspan"
                >{{ $t("PersonalCenter.giftText10") }}&nbsp;&nbsp;&nbsp;{{
                  dubbing_count
                }}
                {{ $t("PersonalCenter.giftText11") }}</span
              >
              <!-- <span class="pspan">{{ $t("PersonalCenter.giftText12") }}&nbsp;&nbsp;&nbsp;{{ $t("PersonalCenter.giftText13") }}</span> -->
            </p>
            <p>
              <span
                >{{ $t("PersonalCenter.expirationTime")
                }}{{ expire_time1 }}</span
              ><span>{{ $t("PersonalCenter.giftText14") }}</span>
            </p>
          </div>
          <div>
            <el-button
              type="primary"
              class="setpadding16"
              @click="gobuyPurchaseServices"
              ><p>{{ $t("PersonalCenter.giftText6") }}</p></el-button
            >
          </div>
        </div>

        <!--        漫画快翻译-->
        <!--        <div class="content_btm_center_div">-->
        <!--          <div class="content_btm_center_div_leftdiv">-->
        <!--            <div class="content_btm_center_div_leftdiv_top">-->
        <!--              <div></div>-->
        <!--              <p>{{ $t("PersonalCenter.giftText17") }}</p>-->
        <!--            </div>-->
        <!--            <p>-->
        <!--              {{ $t("PersonalCenter.giftText18") }}&nbsp;&nbsp;&nbsp;{{-->
        <!--                page_count-->
        <!--              }}{{ $t("PersonalCenter.giftText19") }}-->
        <!--            </p>-->
        <!--            <p>-->
        <!--              <span-->
        <!--                >{{ $t("PersonalCenter.expirationTime")-->
        <!--                }}{{ expire_time2 }}</span-->
        <!--              ><span>{{ $t("PersonalCenter.giftText20") }}</span>-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <el-button-->
        <!--              type="primary"-->
        <!--              class="setpadding16"-->
        <!--              @click="gobuyPurchaseServices"-->
        <!--              ><p>{{ $t("PersonalCenter.giftText6") }}</p></el-button-->
        <!--            >-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- 空间用量 -->
        <div class="content_btm_center_div">
          <div class="content_btm_center_div_leftdiv">
            <div class="content_btm_center_div_leftdiv_top">
              <div></div>
              <p>{{ $t("PersonalCenter.giftText21") }}</p>
            </div>
            <div class="progress_class">
              <p>
                {{ percentages + "MB" + " / " }}{{ totalpercentage + "MB" }}
              </p>
              <el-progress
                :percentage="percentage"
                :color="customColor"
                define-back-color="#393c4e"
                :stroke-width="8"
                :show-text="false"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="content_bottom_callme content_btm_center_div">
          <div class="content_bottom_callme_div">
            <!-- 联系客服 -->
            <p class="content_bottom_callme_topp">
              {{ $t("PersonalCenter.giftText22") }}
            </p>
            <!-- 邮箱 -->
            <svg-icon icon-class="mailbox" class="icon"></svg-icon>
            <p>{{ $t("PersonalCenter.email") }}services@wiitrans.com</p>
            <!-- 手机号 -->
            <svg-icon icon-class="PhoneCall" class="icon"></svg-icon>
            <p>{{ $t("PersonalCenter.mobilephone") }}400-060-8287</p>
          </div>
          <!-- <div class="content_bottom_callme_div">
            <el-button type="text" @click="goPrivacy">{{
              $t("Login.text11")
            }}</el-button>
          </div> -->
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      :showfooter="showfooter"
      v-if="dialogVisible"
    >
      <div
        class="dialogContents"
        v-if="
          dialogtitle == this.$t('PersonalCenter.giftText23') ||
          dialogtitle == this.$t('PersonalCenter.giftText24')
        "
      >
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="115px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <el-form-item
            :label="this.$t('PersonalCenter.phoneNumber')"
            prop="phone"
          >
            <el-input
              v-model="ruleForm.phone"
              :placeholder="this.$t('PersonalCenter.tips1')"
              @input="
                if (ruleForm.phone.length > 11)
                  ruleForm.phone = ruleForm.phone.slice(0, 11);
              "
              class="topinput"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.phoneText')"
            prop="VerificationCode"
          >
            <div class="inputbtn">
              <el-input
                v-model="ruleForm.VerificationCode"
                :placeholder="this.$t('PersonalCenter.phoneText1')"
                class="btminput"
              ></el-input>
              <el-button
                type="primary"
                class="btnClass"
                :disabled="buttonLoading"
                @click="Sendverificationcode(1)"
              >
                <div class="btndiv">
                  <p v-if="time == 60">
                    {{ this.$t("PersonalCenter.phoneText2") }}
                  </p>
                  <p v-else>{{ time + this.$t("PersonalCenter.second") }}</p>
                </div>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="dialogContents"
        v-if="dialogtitle == this.$t('PersonalCenter.changePassword')"
      >
        <el-form
          :model="ruleForms"
          :rules="rulesForm"
          ref="ruleForms"
          label-width="110px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          @keydown.enter.prevent
        >
          <el-form-item
            :label="this.$t('PersonalCenter.oldPassword')"
            prop="oldpass"
          >
            <el-input
              v-model="ruleForms.oldpass"
              :placeholder="this.$t('PersonalCenter.oldPasswordText')"
              class="topinput"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.newPassword')"
            prop="pass"
          >
            <el-input
              v-model="ruleForms.pass"
              :placeholder="this.$t('PersonalCenter.newPasswordText')"
              type="password"
              class="topinput"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.confirmPassword')"
            prop="checkPass"
          >
            <el-input
              v-model="ruleForms.checkPass"
              type="password"
              :placeholder="this.$t('PersonalCenter.confirmPasswordText')"
              class="topinput"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="dialogContents"
        v-if="dialogtitle == this.$t('PersonalCenter.giftText25')"
      >
        <el-form
          :model="ruleForm1"
          ref="ruleForm1"
          :rules="rules1"
          label-width="70px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          @keydown.enter.prevent
        >
          <el-form-item :label="this.$t('PersonalCenter.email1')" prop="email">
            <el-input
              v-model="ruleForm1.email"
              placeholder=""
              disabled
              class="topinput"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.phoneText')"
            prop="emailCode"
          >
            <div class="inputbtn">
              <el-input
                v-model="ruleForm1.emailCode"
                :placeholder="this.$t('PersonalCenter.giftText26')"
                class="btminput"
              ></el-input>
              <el-button
                type="primary"
                class="btnClass"
                :disabled="buttonLoading"
                @click="Sendverificationcode(2)"
              >
                <div class="btndiv">
                  <p v-if="time == 60">
                    {{ this.$t("PersonalCenter.phoneText2") }}
                  </p>
                  <p v-else>{{ time + this.$t("PersonalCenter.second") }}</p>
                </div>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="dialogContents"
        v-if="dialogtitle == this.$t('PersonalCenter.teamInformation')"
      >
        <div
          class="tableborderClass tableClass borderTableStyle"
          :class="TeaminformationTableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#ffffff',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 40 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#454758',
            }"
            :data="TeaminformationTableData"
            tooltip-effect="dark"
            style="width: 100%"
            :max-height="400"
            border
          >
            <!--邀请时间  -->
            <el-table-column
              prop="team_user"
              :label="this.$t('PersonalCenter.team')"
              width="652"
            >
            </el-table-column>

            <el-table-column
              prop="time"
              :label="this.$t('PersonalCenter.joinedOn')"
              width="189"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogWxVisible"
      @changeDialogVisible="handleWxClose"
      :width="'888px'"
      :top="'10%'"
      :title="''"
      :showfooter="false"
      v-if="dialogWxVisible"
    >
      <div style="text-align: center">
        <div class="wechat_title">
          {{ this.$t("PersonalCenter.wechatText") }}
        </div>
        <!-- 微信二维码 -->
        <div class="wechat_content">
          <div class="wechat_content_loading" v-show="QRCodeStatus == 0">
            <img
              src="@/assets/imgs/loading_generated.gif"
              width="36"
              height="36"
              class="wechat_content_loading_img"
            />
            <span class="wechat_content_loading_span">{{
              this.$t("PersonalCenter.wechatText1")
            }}</span>
          </div>
          <img
            :src="wechatQRCodeSrc"
            alt=""
            width="250"
            height="250"
            v-show="QRCodeStatus == 1"
          />
          <div class="wechat_content_loading" v-show="QRCodeStatus == 2">
            <img
              :src="QRCodeErrorSrc"
              width="250"
              height="250"
              class="wechat_content_error_img"
            />
            <div class="wechat_content_error_div"></div>
            <span class="wechat_content_error_span1">{{
              this.$t("PersonalCenter.wechatText2")
            }}</span>
            <span class="wechat_content_error_span" @click="refresh_qrcode">{{
              this.$t("PersonalCenter.wechatText3")
            }}</span>
          </div>
          <div class="wechat_title1">
            {{ this.$t("PersonalCenter.wechatText4") }}
          </div>
        </div>
        <div style="height: 24px"></div>
      </div>
    </DialogComVue>
    <GiftBagbtndialogVue
      v-if="centerDialogVisible"
      :centerDialogVisible="centerDialogVisible"
      :centerDialogVisibleTime="centerDialogVisibleTime"
      @closeGiftBagbtndialog="closeGiftBagbtndialog"
    ></GiftBagbtndialogVue>
    <DialogComVue
      :dialogVisible="dialogCancellationVisible"
      @changeDialogVisible="handleCancellationClose"
      @determineClick="determineCancellationClick"
      :width="'888px'"
      :top="'10%'"
      :title="$t('PersonalCenter.comfirmTips1')"
      v-if="dialogCancellationVisible"
    >
      <div style="padding: 24px">
        <div class="wechat_title">
          {{ $t("PersonalCenter.CancellationTips") }}
        </div>
        <div class="wechat_content">
          <div>
            <el-input
              type="textarea"
              class="textAreaClassHeight"
              :placeholder="$t('PersonalCenter.CancellationTips1')"
              :maxlength="300"
              v-model="CancellationDisc"
            ></el-input>
          </div>
        </div>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogPersonVisible"
      @changeDialogVisible="dialogPersonVisible = false"
      :width="'600px'"
      :top="'10%'"
      :title="$t('PersonalCenter.personInfo')"
      :showfooter="false"
      v-if="dialogPersonVisible"
    >
      <div class="dialogContents" style="justify-content: flex-start">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="115px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <el-form-item
            :label="this.$t('PersonalCenter.Nickname')"
            prop="phone"
          >
            <div>{{ title }}</div>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.ID')"
            prop="VerificationCode"
          >
            <div>{{ ID }}</div>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.email')"
            prop="VerificationCode"
          >
            <div>{{ email }}</div>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.mobilephone')"
            prop="VerificationCode"
          >
            <div>{{ mobilephone }}</div>
          </el-form-item>
          <el-form-item
            :label="this.$t('PersonalCenter.wechat')"
            prop="VerificationCode"
          >
            <div>{{ WeChataccount }}</div>
          </el-form-item>
        </el-form>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogOtherVisible"
      @changeDialogVisible="dialogOtherVisible = false"
      :width="'1024px'"
      :top="'10%'"
      :title="$t('PersonalCenter.otherInfo')"
      :showfooter="false"
      v-if="dialogOtherVisible"
    >
      <div
        class="dialogContents tableClass"
        style="justify-content: flex-start"
      >
        <el-table
          :row-style="{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="otherList"
          tooltip-effect="dark"
          style="width: 100%"
          height="100%"
          border
        >
          <el-table-column label="功能类型" prop="index1"> </el-table-column>
          <el-table-column label="使用目的" prop="index2"></el-table-column>
          <el-table-column label="处理方式" prop="index3"> </el-table-column>
          <el-table-column label="第三方名称" prop="index4"> </el-table-column>
          <el-table-column label="涉及个人信息" prop="index5">
          </el-table-column>
          <el-table-column label="第三方连接" prop="index6">
            <template slot-scope="scope">
              <a
                :href="scope.row.index6"
                style="color: #46adff !important"
                target="_blank"
                >{{ scope.row.index6 }}</a
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import GiftBagbtndialogVue from "@/components/GiftBagbtndialog.vue";
import md5 from "js-md5"; //md5 密码加密
import { copyClick } from "@/assets/js/Publicmethods";
// import cookies from "js-cookie";
import {
  send_phone_code,
  update_phone,
  remove_user,
  update_password,
  logout_user,
  change_phone_mail_code,
  check_phone_mail_code,
  get_user_quota_info,
  get_myteam,
  get_user_info,
  wechat_login,
  check_qrcode_status,
  get_free_quate,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import { Base64 } from "js-base64";
import config from "@/config";
export default {
  components: {
    DialogComVue,
    GiftBagbtndialogVue,
  },
  created() {
    // 变换
    this.$emit("header", false, false, true, true, true);
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.showGiftBagBtn = this.info.free_status == "0";
    this.id = this.$route.query.id;
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        callback(new Error(this.$t("PersonalCenter.phoneText3")));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t("PersonalCenter.phoneText4")));
        }
      }
    };
    var validateoldPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("PersonalCenter.oldPasswordText")));
        return;
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error(this.$t("PersonalCenter.PasswordText")));
          return;
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("PersonalCenter.newPasswordText")));
        return;
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error(this.$t("PersonalCenter.PasswordText")));
          return;
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("PersonalCenter.PasswordText")));
        return;
      } else if (value !== this.ruleForms.pass) {
        callback(new Error(this.$t("PersonalCenter.PasswordText")));
        return;
      } else {
        callback();
      }
    };
    return {
      info: {},
      quotaInfo: {},
      centerDialogVisible: false,
      centerDialogVisibleTime: "",
      showGiftBagBtn: false,
      title: "",
      id: "",
      ID: "",
      email: "",
      mobilephone: "",
      WeChataccount: "",
      avatarurl: "",
      dialogVisible: false,
      showfooter: true,
      dialogtitle: "",
      time: 60,
      timer: null,
      percentage: 0, //占用空间 ，动态
      percentages: 0,
      totalpercentage: 0,
      duration: 0,
      expire_time: "",
      audio_count: 0,
      dubbing_count: 0,
      dubbing_word: 0,
      expire_time1: "",
      page_count: 0,
      expire_time2: "", //漫画剩余时长
      expire_time3: "", //视频配音剩余时长
      expire_time4: "", //涂抹剩余时长
      customColor: "#a9d7f9",
      // 绑定手机号
      ruleForm: {
        phone: "",
        VerificationCode: "",
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "change" }],
        VerificationCode: [{ required: true, message: " ", trigger: "blur" }],
      },

      // 更改密码
      ruleForms: {
        oldpass: "",
        pass: "",
        checkPass: "",
      },
      rulesForm: {
        oldpass: [{ validator: validateoldPass, trigger: "change" }],
        pass: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
      },
      // 验证邮箱
      ruleForm1: {
        email: "",
        emailCode: "",
      },
      rules1: {
        emailCode: [{ required: true, message: " ", trigger: "blur" }],
      },
      TeaminformationTableData: [],
      dialogWxVisible: false,
      baseUrl: config.baseUrl.dev, //后端请求地址
      wechatQRCodeSrc: "",
      QRCodeErrorSrc: config.baseUrl.dev + "/QRCode/logo/error.png", //微信二维码失效地址
      scene_id: 0, //微信二维码场景id
      openid: "", //微信用户openid
      WxTimer: null, //微信登录专用
      Wxbind: false,
      QRCodeStatus: 0, //二维码状态 0：加载中； 1成功生成；2二维码失效
      buttonLoading: false,
      user_info: [],
      wx_message: "0",
      dialogCancellationVisible: false,
      CancellationDisc: "",
      dialogPersonVisible: false,
      dialogOtherVisible: false,
      otherList: [],
    };
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("PersonalCenter.loadingTips"));
    this.user_info = JSON.parse(sessionStorage.getItem("info"));
    this.get_user_info().then(() => {
      this.get_user_quota_info();
    });
    this.otherList = [
      {
        index1: "发送短信",
        index2: "向用户发送短信验证码，包含密码修改、提现密码修改、更换手机号",
        index3: "调用阿里云的短信服务，向指定手机号发送短信。",
        index4: "阿里云计算有限公司",
        index5: "手机号、短信信息",
        index6:
          "http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html",
      },
      {
        index1: "微信支付",
        index2: "完成在线订单支付、企业VIP服务、译制或声音时长包的购买功能",
        index3:
          "使用微信提供的支付功能，生成支付二维码，在微信端支付成功后将用户的支付信息返 WiiMedia 以完成支付功能。",
        index4: "财付通支付科技有限公司",
        index5: "订单及具体金额信息",
        index6: "https://www.tenpay.com/v3/helpcenter/low/privacy.shtml",
      },
      {
        index1: "支付宝支付",
        index2: "完成在线订单支付、企业VIP服务、译制或声音时长包的购买功能",
        index3:
          "使用支付宝提供的支付功能，生成支付二维码，在支付宝支付成功后将用户的支付信息返 WiiMedia 以完成支付功能。",
        index4: "支付宝（杭州）信息技术有限公司",
        index5: "订单及具体金额信息",
        index6: "https://render.alipay.com/p/c/k2h4n8ug",
      },
      {
        index1: "五三客服",
        index2: "对用户在线使用平台过程中产生的问题进行答疑",
        index3: "使用53客服软件提供在线对话功能",
        index4: "浙江快服集团有限公司",
        index5: "您的对话内容、IP 地址、浏览器的类型、运营商信息",
        index6: "https://www.53kf.com/policy",
      },
    ];
    // console.log(this.percentage);
    // console.log();
  },
  methods: {
    /**
     * 查看隐私政策
     */
    goPrivacy() {
      window.open("https://www.wiitrans.com/service/privacypolicy", "_blank");
    },
    //确认注销
    determineCancellationClick() {
      remove_user({
        action: "cancel_account",
        comments: this.CancellationDisc,
      }).then((res) => {
        if (res) {
          logout_user({
            action: "logout_user",
          }).then((res) => {
            if (res) {
              this.$messages("success", this.$t("PersonalCenter.comfirmTips4"));
              this.$router.push("/Login");
              this.$Cookie.remove("token_code");
              sessionStorage.clear();
            }
          });
        }
      });
    },
    /**
     * 点击领取 礼包
     */
    ClickshowGiftBagbtn() {
      // console.log(1111111);
      if (this.showGiftBagBtn) {
        get_free_quate({
          action: "get_free_quate",
        }).then((res) => {
          if (!res) {
            return;
          }
          this.centerDialogVisibleTime = res.time;
          this.centerDialogVisible = true;
        });
      }
    },
    /**
     *
     * @param {*关闭领取 弹窗} val
     */
    closeGiftBagbtndialog(val) {
      this.get_user_quota_info().then(() => {
        this.centerDialogVisible = val;
        this.showGiftBagBtn = false;
      });
    },
    handleCancellationClose(value) {
      this.dialogCancellationVisible = false;
    },
    /**
     * 获取用户信息
     */
    get_user_info() {
      return get_user_info({
        action: "get_user_info",
      }).then((res) => {
        // console.log(res);
        if (res) {
          this.setSessionItem("info", JSON.stringify(res.info));
          let info = res.info;
          this.title = info.nickname;
          this.mobilephone = info.phone
            ? Base64.decode(info.phone)
            : this.$t("PersonalCenter.unbound");
          this.WeChataccount = info.openid
            ? this.$t("PersonalCenter.bound")
            : this.$t("PersonalCenter.unbound");
          this.email = Base64.decode(info.email);
          this.ID = info.show_id;
          this.avatarurl = info.head;
          this.wx_message = info.wx_message;
          hideLoading();
        }
      });
    },
    /**
     * 限制只能上传一个文件
     */
    clearFile() {
      let uploadFilesArr = this.$refs["imgupload"].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs["imgupload"].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*更换头像上传 之前} file
     */
    beforeUpload(file) {
      // console.log(file);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt500M = file.size / 1024 / 1024 < 2;
      let extension = testmsg.toLowerCase() === "jpg";
      let extension1 = testmsg.toLowerCase() === "jpeg";
      let extension3 = testmsg.toLowerCase() === "png";
      if (!extension && !extension1 && !extension3) {
        this.$messages("warning", this.$t("PersonalCenter.uploadText"));
        return false;
      } else if (!isLt500M) {
        this.$messages("warning", this.$t("PersonalCenter.uploadText1"));
        return false;
      } else {
        showLoading(this.$t("PersonalCenter.loadingTips"));
        return true;
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      // if (file.percent > 0) {
      //   showLoading("加载中...");
      // }
    },
    /**
     *
     * @param {*上传成功时} response
     */
    uploadSuccess(response) {
      if (response.result == "SUCCESS") {
        this.avatarurl = response.head;
        // console.log(111111);
        this.get_user_info().then(() => {});
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("PersonalCenter.uploadText2"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.$messages("error", response.msg);
        hideLoading();
      }
    },
    /**
     *
     * @param {*点击复制id} data
     */
    copyClick(data) {
      copyClick(data);
      this.$messages("success", this.$t("PersonalCenter.copyText"));
    },
    /**
     * 获取用户数据
     */
    get_user_quota_info() {
      return get_user_quota_info({ action: "get_user_quota_info" }).then(
        (res) => {
          if (res) {
            this.percentages = res.info.space.current;
            this.totalpercentage = res.info.space.max;
            this.percentage = Number(
              (
                (Number(res.info.space.current) / this.totalpercentage) *
                100
              ).toFixed()
            );
            this.quotaInfo = res.info;
            this.expire_time = res.info.media.expire_time;
            this.duration = res.info.media.duration;
            this.audio_count = res.info.dubbing.audio_count;
            this.dubbing_count = res.info.dubbing.dubbing_count;
            this.dubbing_word = res.info.dubbing.dubbing_word;
            this.expire_time1 = res.info.dubbing.expire_time;
            this.page_count = res.info.comic.page_count;
            this.expire_time2 = res.info.comic.expire_time;
            this.expire_time3 = res.info.video_dubbing.expire_time;
            this.expire_time4 = res.info.daub.expire_time;
            hideLoading();
          }
        }
      );
    },
    /**
     * 点击购买更多
     */
    gobuyPurchaseServices() {
      sessionStorage.setItem("BuyServices", "first");
      this.$router.push({
        path: "/ContainerHome/PurchaseServices",
      });
    },
    /**
     *
     * @param {*点击服务明细跟购买记录} data
     */
    gorecord(data) {
      if (data == 0) {
        this.$router.push({
          path: "/ContainerHome/PurchaseRecord",
        });
      } else {
        this.$router.push({
          path: "/ContainerHome/ServiceDetails",
        });
      }
    },
    /**
     *
     * @param {*点击设置按钮} command
     */
    commandClick(command) {
      // console.log(command);
      // 注销账号
      if (command == "0") {
        this.dialogCancellationVisible = true;
      } else if (command == "1") {
        this.dialogtitle = this.$t("PersonalCenter.changePassword");
        this.dialogVisible = true;
        this.showfooter = true;
      } else if (command == "2") {
        //开启微信消息提醒
        showLoading(this.$t("CommonName.loadingmessage"));
        get_user_info({
          action: "set_wx_message",
          mode: 0,
        }).then((res) => {
          hideLoading();
          if (res) {
            this.wx_message = "0";
          }
        });
      } else if (command == "3") {
        //关闭微信消息提醒
        showLoading(this.$t("CommonName.loadingmessage"));
        get_user_info({
          action: "set_wx_message",
          mode: 1,
        }).then((res) => {
          hideLoading();
          if (res) {
            this.wx_message = "1";
          }
        });
      } else if (command == "4") {
        //解除绑定
        this.check_unbind();
      } else if (command == "5") {
        //导出个人信息副本
        let url = `${config.baseUrl.dev}/user/export_person_data`;
        window.open(url, "_blank");
      } else if (command == "6") {
        //个人信息
        this.dialogPersonVisible = true;
      } else if (command == "7") {
        //第三方共享清单
        this.dialogOtherVisible = true;
      }
    },
    /**
     * 点击所在团队信息
     */
    Teaminformation() {
      if (this.info.team_count == "0") {
        this.$messages("warning", this.$t("PersonalCenter.teamTips"));
      } else {
        get_myteam({
          action: "get_myteam",
        }).then((res) => {
          // console.log(res);
          if (res) {
            // console.log(res.list);
            this.TeaminformationTableData = res.list;
            this.dialogtitle = this.$t("PersonalCenter.teamInformation");
            this.dialogVisible = true;
            this.showfooter = false;
          }
        });
      }
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.ruleForms.oldpass = "";
      this.ruleForms.pass = "";
      this.ruleForms.checkPass = "";
      this.ruleForm.phone = "";
      this.ruleForm.VerificationCode = "";
      this.ruleForm1.emailCode = "";
      this.buttonLoading = false;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.time = 60;
    },
    /**
     * 绑定手机号
     */
    bindingmobilephone(data) {
      if (data == 0) {
        this.ruleForm = {
          phone: "",
          VerificationCode: "",
        };
        this.dialogtitle = this.$t("PersonalCenter.giftText23");
        this.dialogVisible = true;
        this.showfooter = true;
        this.buttonLoading = false;
      } else {
        this.dialogtitle = this.$t("PersonalCenter.giftText25");
        this.ruleForm1.email = Base64.decode(
          JSON.parse(sessionStorage.getItem("info")).email
        );
        this.dialogVisible = true;
        this.showfooter = true;
        this.buttonLoading = false;
      }
    },
    /**
     * 弹窗确定按钮
     */
    determineClick() {
      if (
        this.dialogtitle == this.$t("PersonalCenter.giftText23") ||
        this.dialogtitle == this.$t("PersonalCenter.giftText24")
      ) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            // alert("submit!");
            update_phone({
              action: "update_phone",
              phone: Base64.encode(this.ruleForm.phone),
              code: this.ruleForm.VerificationCode,
            }).then((res) => {
              // console.log(res);
              if (res) {
                this.$messages("success", this.$t("PersonalCenter.phoneText5"));
                this.dialogVisible = false;
                this.mobilephone = this.ruleForm.phone;
                this.ruleForm = {
                  phone: "",
                  VerificationCode: "",
                };
                if (this.timer) {
                  clearInterval(this.timer);
                }
                this.time = 60;
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else if (this.dialogtitle == this.$t("PersonalCenter.changePassword")) {
        this.$refs["ruleForms"].validate((valid) => {
          if (valid) {
            update_password({
              action: "update_password",
              opwd: md5(this.ruleForms.oldpass),
              npwd: md5(this.ruleForms.checkPass),
            }).then((res) => {
              // console.log(res);
              if (res) {
                logout_user({
                  action: "logout_user",
                }).then((res) => {
                  if (res) {
                    this.$messages(
                      "success",
                      this.$t("PersonalCenter.PasswordText3")
                    );
                    this.$router.push("/Login");
                    this.$Cookie.remove("token_code");
                    sessionStorage.clear();
                    this.buttonLoading = false;
                  }
                });
              } else {
                this.$messages("error", res.msg);
              }
            });
          } else {
            return false;
          }
        });
      } else if (this.dialogtitle == this.$t("PersonalCenter.giftText25")) {
        this.$refs["ruleForm1"].validate((valid) => {
          if (valid) {
            // console.log(1111);
            check_phone_mail_code({
              action: "check_phone_mail_code",
              code: this.ruleForm1.emailCode,
            }).then((res) => {
              if (res) {
                this.$messages("success", this.$t("PersonalCenter.phoneText6"));
                if (this.timer) {
                  clearInterval(this.timer);
                }
                this.ruleForm1.emailCode = "";
                this.time = 60;
                this.dialogtitle = this.$t("PersonalCenter.giftText24");
                this.buttonLoading = false;
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    /**
     * 发送验证码
     */
    Sendverificationcode(data) {
      // showLoading("加载中...");

      if (data == 1) {
        if (!this.ruleForm.phone) {
          this.$messages("warning", this.$t("PersonalCenter.phoneText7"));
        } else {
          const reg = /^1[3456789]\d{9}$/;
          if (reg.test(this.ruleForm.phone)) {
            this.buttonLoading = true;
            send_phone_code({
              action: "send_phone_code",
              phone: Base64.encode(this.ruleForm.phone),
            }).then((res) => {
              // console.log(res);
              if (res) {
                // this.buttonLoading = true;
                this.$messages("success", this.$t("PersonalCenter.phoneText8"));
                // hideLoading();
                this.timer = setInterval(() => {
                  this.time--;
                  if (this.time == 0) {
                    clearInterval(this.timer);
                    this.time = 60;
                    this.buttonLoading = false;
                  }
                }, 1000);
              }
            });
          }
        }
      } else {
        /**
         * 向邮箱里发送验证码
         */
        this.buttonLoading = true;
        change_phone_mail_code({
          action: "change_phone_mail_code",
        }).then((res) => {
          if (res) {
            this.$messages("success", this.$t("PersonalCenter.phoneText8"));
            this.timer = setInterval(() => {
              this.time--;
              if (this.time == 0) {
                clearInterval(this.timer);
                this.time = 60;
                this.buttonLoading = false;
              }
            }, 1000);
          }
        });
      }
    },
    /**
     * Wechat 绑定 请求二维码
     */
    WxLogin() {
      this.dialogWxVisible = true;
      this.Wxbind = false;
      this.scene_id = 0;
      this.openid = "";
      wechat_login({
        action: "wechat_login",
      }).then((res) => {
        if (res.result == "SUCCESS") {
          this.QRCodeStatus = 1;
          this.wechatQRCodeSrc = res.url + res.ticket;
          this.scene_id = res.scene_id;
          this.WxTimer = setInterval(() => {
            this.check_qrcode_status();
          }, 2000);
        } else {
          this.QRCodeStatus = 2;
        }
      });
    },
    /**
     * 重新生成二维码
     */
    refresh_qrcode() {
      clearInterval(this.WxTimer);
      this.QRCodeStatus = 0;
      this.WxLogin();
    },
    /**
     * Wechat 登录 扫描二维码之后查询是否关注
     */
    check_qrcode_status() {
      check_qrcode_status({
        action: "check_qrcode_status",
        scene_id: this.scene_id,
      }).then((res) => {
        if (res.result == "SUCCESS") {
          if (res.error == 0) {
            //openid为空，继续轮询查询
            // $("#wechat_title").html(wechat_tip_1);
          } else if (res.error == 1) {
            //openid有值，但没绑定
            clearInterval(this.WxTimer);
            this.openid = res.openid;
            this.check_qrcode_to_bind(this.ID, this.openid);
          } else if (res.error == 2) {
            //openid有值，已绑定账号直接绑定
            clearInterval(this.WxTimer);
            this.dialogWxVisible = false;
            // this.openid = res.openid;
            this.$messages("error", this.$t("PersonalCenter.wechatText5"));
          } else if (res.error == 3) {
            //二维码超时了
            clearInterval(this.WxTimer);
            this.QRCodeStatus = 2;
          }
        } else {
          this.QRCodeStatus = 2;
        }
      });
    },
    /**
     * 扫码后微信登录
     */
    check_qrcode_to_bind(ID, openid) {
      console.log(ID);
      wechat_login({
        action: "qrcode_to_bind",
        user_show_id: ID,
        openid: openid,
      }).then((res) => {
        // console.log(res);
        if (res.result == "SUCCESS") {
          this.$router.go(0);
        } else {
          this.$messages("error", res.msg);
          this.QRCodeStatus = 2;
        }
      });
    },
    /**
     * Wechat 登录 关闭弹窗
     */
    handleWxClose() {
      clearInterval(this.WxTimer);
      this.dialogWxVisible = false;
    },
    /**
     * 微信解除绑定
     */
    check_unbind() {
      this.$confirm(
        this.$t("PersonalCenter.wechatText6"),
        this.$t("PersonalCenter.comfirmTips1"),
        {
          confirmButtonText: this.$t("PersonalCenter.comfirmTips2"),
          cancelButtonText: this.$t("PersonalCenter.comfirmTips3"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          closeOnPressEscape: false,
          type: "warning",
        }
      )
        .then(() => {
          wechat_login({
            action: "wechat_unbind",
            user_show_id: this.ID,
          }).then((res) => {
            // console.log(res);
            if (res.result == "SUCCESS") {
              this.$router.go(0);
            } else {
              this.$messages("error", res.msg);
              this.QRCodeStatus = 2;
            }
          });
        })
        .catch((action) => {
          if (action === "cancel") {
            console.log("cancel");
          }
        });
    },
    callmeServices() {
      let href =
        "https://tb.53kf.com/code/client/02915166b11237caaf3a887661acdfa60/1";
      window.open(href);
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.contentdiv {
  .titlediv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rightbtn {
      margin-right: 26px;
    }
  }
}
.content {
  background-color: #2c2d37 !important;
  @media screen and (min-width: 0px) and (max-width: 1440px) {
    .content_top {
      padding: 0 24px;
    }
    .content_btm {
      padding: 0 24px;
    }
    // .content_btm_top {
    //   margin: 0 24px;
    // }
    // .content_btm_center_div {
    //   margin: 0 24px;
    // }
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    .content_top {
      padding: 0 48px;
    }
    .content_btm {
      padding: 0 48px;
    }
    // .content_btm_top {
    //   margin: 0 48px;
    // }
    // .content_btm_center_div {
    //   margin: 0 48px;
    // }
  }
  @media screen and (min-width: 1600px) and (max-width: 1920px) {
    .content_top {
      padding: 0 72px;
    }
    .content_btm {
      padding: 0 72px;
    }
    // .content_btm_top {
    //   margin: 0 72px;
    // }
    // .content_btm_center_div {
    //   margin: 0 72px;
    // }
  }
  @media screen and (min-width: 1920px) {
    .content_top {
      padding: 0 72px;
    }
    .content_btm {
      padding: 0 72px;
    }
    // .content_btm_top {
    //   margin: 0 72px;
    // }
    // .content_btm_cecontentnter_div {
    //   // padding-top: 10px;
    //   margin: 0 72px;
    // }
  }
  // @media screen and (min-height: 930px) {
  //   .content_btm_center_div {
  //     padding-top: 10px;
  //   }
  // }
  // @media screen and (min-width: 1920px) and (min-height: 930px) {
  //   .content_btm_center_div {
  //     padding-top: 10px;
  //   }
  // }

  .content_top {
    height: 146px;
    background-color: #393c4e;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(12, 25, 32, 0.3);
    padding-top: 20px;
    .content_top_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .leftdiv {
      display: flex;
      align-items: center;

      .avatar {
        position: relative;
      }
      .setavatar {
        position: absolute;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        background-color: #2a2f4e;
        border-radius: 50%;
        right: 0;
        bottom: 2px;
        align-items: end;
        .icon {
          font-size: 14px;
          color: #adb0bc;
          cursor: pointer;
        }
      }
      .leftdiv_right {
        margin-left: 24px;
        .leftdiv_right_top {
          display: flex;
          align-items: center;
          margin-bottom: 27px;
          > p {
            color: #ffffff;
            font-size: 16px;
          }
          .timep {
            color: #adb0bc;
            font-size: 14px;
            margin-left: 40px;
          }
          .titlediv {
            height: 21px;
            background-color: #c31531;
            border-radius: 11px;
            line-height: 21px;
            display: flex;
            justify-content: center;
            margin-left: 8px;
            p {
              font-weight: 700;
              padding: 0 11px;
              color: #d7d9e2;
              font-size: 10px;
              margin-left: 0;
            }
          }
        }
        .leftdiv_right_btm {
          display: flex;
          align-items: center;
          .el-button {
            padding: 0;
          }
          .btm_div {
            display: flex;
            align-items: center;
            .svgbtn {
              margin-left: 6px;
              p {
                font-size: 12px;
                margin-top: 1px;
              }
            }
          }
          .titlep {
            color: #adb0bc;
            font-size: 14px;
          }
        }
      }
    }
    .rightdiv {
      margin-top: 50px;
    }
  }
  .content_btm {
    margin-top: 20px;
    height: calc(100% - 156px);
    // min-height: 400px;
    overflow-y: auto;
    background-color: #393c4e;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(12, 25, 32, 0.3);
    .content_btm_top {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;

      .trueClass {
        //width: 146px;
        padding: 0 8px;
        height: 32px;
        border: 1px solid;
        border-color: #ffb92e;
        border-radius: 2px;
        color: #ffb92e;
        font-size: 14px;
        line-height: 32px;
        cursor: pointer;
      }
      .falseClass {
        //width: 146px;
        padding: 0 8px;
        height: 32px;
        border: 1px solid;
        border-color: #5b5e6f;
        border-radius: 2px;
        color: #5b5e6f;
        font-size: 14px;
        line-height: 32px;
        cursor: no-drop;
      }
    }
  }
}
.dialogContents {
  margin: 24px;
  display: flex;
  justify-content: center;
  ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #5d5e6e !important;
  }
  ::v-deep .el-form-item.is-error .el-input__inner {
    border-color: #f56c6c !important;
  }
  ::v-deep .el-form-item__label {
    color: #adb0bc;
    font-size: 14px;
  }
  ::v-deep .el-form-item__content {
    color: #adb0bc;
    font-size: 14px;
  }
  .inputbtn {
    display: flex;
    align-items: center;
    .btminput {
      ::v-deep .el-input__inner {
        width: 122px;
      }
    }

    ::v-deep .el-button.is-disabled {
      background: #494b5e !important;
      color: #676b80 !important;
      &:hover {
        box-shadow: none !important;
      }
    }
    .btnClass {
      padding: 0 !important;
      .btndiv {
        width: 103.5px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .topinput {
    ::v-deep .el-input__inner {
      width: 240px;
    }
  }
  .noDataDiv {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.content_btm_center_div {
  // margin: 0 24px;
  padding-top: 10px;
  border-bottom: 1px solid;
  border-color: #515464;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content_btm_center_div_leftdiv {
    .progress_class {
      ::v-deep .el-progress-bar__outer {
        width: 837px !important;
      }
      p {
        margin-bottom: 10px;
      }
      margin-bottom: 18px;
    }
    p {
      color: #ffffff;
      font-size: 14px;
      text-align: left;
      margin-bottom: 16px;
      .pspan {
        color: #ffffff;
      }
      span {
        color: #adb0bc;
        margin-right: 40px;
      }
    }
  }
  .content_btm_center_div_leftdiv_top {
    display: flex;
    align-items: center;
    margin-top: 16px;
    div {
      width: 4px;
      height: 16px;
      background-color: #46adff;
      border-radius: 2px;
      margin-right: 8px;
      margin-bottom: 16px;
    }
    p {
      font-weight: 700;
      color: #ffffff;
      font-size: 16px;
    }
  }
}
.content_bottom_callme {
  // padding-top: 10px;
  border-bottom: none;
  padding-top: 0;
  margin-top: 0;
  min-height: 40px;
  // .content_bottom_callme_div {
  // margin-top: 10px;
  // }
}
.wechat_title {
  margin-bottom: 24px;
  font-size: 18px;
  color: #fff;
}
.wechat_title1 {
  margin-top: 8px;
  color: #fff;
}

::v-deep .el-textarea__inner {
  height: 80px;
  border: 1px solid !important;
  border-color: #7d7f8b !important;
  padding: 5px 15px !important;
  &:focus {
    background: none !important;
    color: #ffffff !important;
  }
}
.wechat_content_loading {
  width: 250px;
  height: 250px;
  background-color: #454758;
  display: inline-block;
  position: relative;
}
.wechat_content_loading_img {
  position: absolute;
  top: 41%;
  left: 42%;
}
.wechat_content_loading_span {
  position: absolute;
  top: 60%;
  left: 38%;
  color: #409eff;
}
.wechat_content_error_img {
  position: absolute;
  display: block;
}
.wechat_content_error_div {
  position: absolute;
  width: 250px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.7);
}
.wechat_content_error_span {
  position: absolute;
  top: 56%;
  left: 38%;
  color: #409eff;
  cursor: pointer;
}
.wechat_content_error_span1 {
  position: absolute;
  top: 39%;
  left: 35%;
  color: #fff;
  cursor: pointer;
}
.refresh_qrcode {
  float: left;
  line-height: 40px;
  color: #409eff;
  cursor: pointer;
}
.refresh_qrcode:hover {
  color: #46adff;
}
</style>
