/*
 * @Description:el-loading二次封装
 * @version:
 * @Author: Carful
 * @Date: 2022-08-04 11:20:01
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-26 08:34:35
 */
import { Loading } from "element-ui";
let loadingCount = 0;
let loading;

const startLoading = (data) => {
  // console.log(data, "data");
  loading = Loading.service({
    lock: true,
    text: data ? data : "",
    background: "rgba(0, 0, 0, 0.3)",
  });
  // setTimeout(function () {
  //   loading.close();
  // }, 20000);
};

const endLoading = () => {
  loading.close();
};

export const showLoading = (data) => {
  if (loadingCount === 0) {
    startLoading(data);
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};
