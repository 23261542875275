<!--
 * @Description: 我的文件
 * @version: 
 * @Author: Tom
 * @Date: 2022-11-21 15:20:37
 * @LastEditors: Tom
 * @LastEditTime: 2024-11-11 16:21:53
-->
<template>
  <div class="contentdiv">
    <div class="mainCenterTop titleHeader">
      <div class="mainHeader_left">
        <div class="signoutClass" v-if="folder_pid != 0" @click="backClick">
          <svg-icon icon-class="BackPrevious" class="icon"></svg-icon>
        </div>
        <div class="titleClass">
          <svg-icon icon-class="MyFiles" class="svgClass"></svg-icon>
          <el-breadcrumb separator="/" class="breadcrumb1">
            <el-breadcrumb-item :class="0 == folder_pid ? 'current' : ''">
              <template v-if="0 != folder_pid">
                <a @click="breadcrumbClick(0)">{{
                  $t("MyFiles.MyFilesTitle")
                }}</a>
              </template>
              <template v-else>{{ $t("MyFiles.MyFilesTitle") }} </template>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              v-for="item in Breadcrumb"
              :key="item.id"
              :class="parseInt(item.id) == folder_pid ? 'current' : ''"
            >
              <template v-if="parseInt(item.id) != folder_pid">
                <a @click="breadcrumbClick(item.id)">{{ item.label }}</a>
              </template>
              <template v-else>{{ item.label }}</template>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="titleOption">
        <el-button type="primary" class="setpadding16" @click="addFolder">
          <div class="svgbtn">
            <svg-icon icon-class="NewFolder" class="icon"></svg-icon>
            <p>{{ $t("MyFiles.CreateFolder") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="content">
      <div class="contenttop">
        <div class="contenttop_left">
          <div>
            <el-date-picker
              v-model="time"
              type="daterange"
              :range-separator="$t('MyFiles.datetime2')"
              :start-placeholder="$t('MyFiles.datetime1')"
              :end-placeholder="$t('MyFiles.datetime3')"
              unlink-panels
              :editable="false"
              :format="time_format"
              @change="daterangeChange"
            >
            </el-date-picker>
          </div>
          <div class="inputClass">
            <el-input
              v-model="input"
              :placeholder="$t('MyFiles.inputFolderTips2')"
              clearable
              @keyup.enter.native="queryclick"
            ></el-input>
          </div>
          <div>
            <el-button
              type="primary"
              plain
              class="setpadding23"
              @click="queryclick"
              ><p>{{ $t("MyFiles.search") }}</p></el-button
            >
          </div>
        </div>
        <div class="contenttop_right">
          <div class="progress_class">
            <div class="progress_class_title">
              <div>
                <span style="margin-right: 12px">{{
                  $t("MyFiles.spaceUsage")
                }}</span
                >{{ percentages + "GB" + " / " }}{{ totalpercentage + "GB" }}
              </div>
              <div class="progress_class_title2">
                <span style="margin-right: 6px"
                  >{{ $t("MyFiles.fileSave") }} {{ saveDays }}
                  {{ $t("MyFiles.day") }}</span
                >
                <el-tooltip placement="top-start" popper-class="uploadptooltip">
                  <div slot="content">
                    {{ $t("MyFiles.fileSaveTips1") }}<br />
                    {{ $t("MyFiles.fileSaveTips2") }}
                  </div>
                  <svg-icon
                    icon-class="Help_Question"
                    class="svgClass"
                    style="cursor: pointer"
                  ></svg-icon>
                </el-tooltip>
              </div>
            </div>
            <el-progress
              :percentage="percentage"
              :color="customColor"
              :stroke-width="4"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
      <div class="center" style="height: 32px">
        <el-button
          type="info"
          class="setpadding16"
          v-show="multipleSelection.length > 0"
          @click="handleBatchMove"
        >
          <div class="btntop">
            <svg-icon icon-class="ShiftIn" class="ShiftIn"></svg-icon>
            <span>{{ $t("MyFiles.moveTo") }}</span>
          </div>
        </el-button>
        <el-button
          class="bombtn setpadding16"
          type="info"
          @click="handleBatchDelete"
          v-show="multipleSelection.length > 0"
        >
          <div class="btntop">
            <svg-icon icon-class="delete" class="delete"></svg-icon>
            <span>{{ $t("MyFiles.delete") }}</span>
          </div>
        </el-button>
        <el-button
          class="bombtn setpadding16"
          type="info"
          @click="handleBatchProject_click(0)"
          v-show="
            multipleSelection.length > 0 && this.user_info.team_count != '0'
          "
        >
          <!--        批量操作  打开任务-->
          <div class="btntop">
            <span>{{ $t("ProjectDetails.buttonList.name25") }}</span>
          </div>
        </el-button>
        <el-button
          class="bombtn setpadding16"
          type="info"
          @click="handleBatchProject_click(1)"
          v-show="
            multipleSelection.length > 0 && this.user_info.team_count != '0'
          "
        >
          <!--        批量操作 打开报告-->
          <div class="btntop">
            <span>{{ $t("ProjectDetails.dropdownBatchList.name32") }}</span>
          </div>
        </el-button>
        <!-- <el-button
        class="bombtn setpadding16"
        type="info"
        @click="handleBatchDownload"
        v-show="multipleSelection.length > 0"
      >
        <div class="btntop">
          <svg-icon icon-class="download" class="delete"></svg-icon
          ><span>{{ $t("MyFiles.download") }}</span>
        </div>
      </el-button> -->
      </div>
      <div class="contentbtm tableClass">
        <el-table
          :row-style="{
            height: 48 + 'px',
            background: '#393c4e',
            color: '#cbd7e8',
            fontSize: 14 + 'px',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 48 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#393c4e',
          }"
          ref="multipleTable"
          :data="newtableData"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"
          @select-all="handleALLSelect"
          @select="handleSelect"
          :row-class-name="tableRowClassName"
          :default-sort="defaultSort"
          @sort-change="sortChange"
          :row-key="
            (row) => {
              return row.num;
            }
          "
        >
          <div slot="empty" class="noDataDiv">
            <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
            <p>{{ $t("CommonName.noData") }}</p>
          </div>
          <el-table-column
            type="selection"
            width="75"
            align="center"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column
            :min-width="380"
            sortable="custom"
            prop="name"
            :sort-orders="['ascending', 'descending']"
            show-overflow-tooltip
          >
            <template slot="header">
              <span>{{ $t("MyFiles.name") }}</span>
              <span class="tableheaderspan"></span>
            </template>
            <template slot-scope="scope">
              <div
                type="text"
                style="cursor: pointer"
                class="tablescope_rbtm_div"
                :class="scope.row.showLoadingnow ? 'showColor' : ''"
                @click="tovideoDetails(scope.row)"
              >
                <div class="svgbtn" style="width: 100%">
                  <svg-icon
                    :icon-class="scope.row.icon"
                    class="icon"
                    :class="
                      scope.row.is_folder == '1'
                        ? 'svgiconclass'
                        : 'svgiconclass1'
                    "
                  ></svg-icon>
                  <p
                    :class="scope.row.is_folder == '1' ? 'folderp' : 'filep'"
                    style="width: calc(100% - 23px)"
                    class="overflowEllipsis"
                  >
                    {{ scope.row.name }}
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="file_count">
            <template slot="header">
              <div class="tableheaderdiv">
                <p>{{ $t("MyFiles.filesNum") }}</p>
                <div class="tableheader"></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="190">
            <template slot="header">
              <div class="tableheaderdiv">
                <!-- <p>{{ currentTypeName }}</p> -->
                <p>{{ $t("MyFiles.FolderType") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="typecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in typeDropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="statusClass">
                <template v-if="scope.row.private == '2'">
                  <el-tooltip
                    v-if="scope.row.job_search_type == '1'"
                    :disabled="scope.row.newstatus.progress == ''"
                    class="item"
                    effect="dark"
                    :content="scope.row.newstatus.progress"
                    placement="bottom-start"
                  >
                    <div
                      class="statusClass1"
                      :class="
                        scope.row.newstatus.class
                          ? scope.row.newstatus.class
                          : ''
                      "
                    >
                      <p>
                        {{ scope.row.newstatus.name }}
                      </p>
                    </div>
                  </el-tooltip>

                  <div v-if="scope.row.job_search_type == '3'">
                    {{ scope.row.service }}
                  </div>
                </template>

                <div v-if="scope.row.private == '1'">
                  {{ scope.row.service }}
                </div>

                <!-- {{ scope.row.newstatus.progress }} -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="attribute" :label="$t('MyFiles.team')">
            <template slot="header">
              <div class="tableheaderdiv">
                <p>{{ $t("MyFiles.team") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="statuscommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in statusDropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span>{{ $t("MyFiles.createTime") }}</span>
              <span class="tableheaderspan"></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="update_time"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span class="tableheaderspantop">{{
                $t("MyFiles.updateTime")
              }}</span>
              <span class="tableheaderspan"></span>
              <!-- <div class="tableheaderdiv">
                <p>更新时间</p>
              </div> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('MyFiles.operation')"
            width="50"
          >
            <template slot-scope="scope">
              <div class="operationStyle">
                <el-dropdown @command="commandClick" trigger="click">
                  <span class="el-dropdown-link">
                    <svg-icon
                      icon-class="MoreOperations"
                      style="color: #cbd7e8"
                    ></svg-icon>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in scope.row.is_folder == '1'
                        ? dropdownList1
                        : scope.row.private == '2'
                        ? dropdownList2
                        : dropdownList"
                      :key="item.command"
                      :command="{ type: item.command, id: scope.row }"
                    >
                      <svg-icon
                        :icon-class="item.icon"
                        style="margin-right: 8px; font-size: 18px"
                      ></svg-icon>
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px; float: right; margin-right: 20px">
          <PaginationComVue
            :tableData="[]"
            :pageSize="pageSize"
            :currentPage="currentPage"
            :total="tableTotal"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></PaginationComVue>
          <DialogComVue
            :dialogVisible="dialogVisible"
            @changeDialogVisible="changeDialogVisible"
            :width="dialogWidth"
            :top="'10%'"
            :title="dialogtitle"
            :showfooter="showFooter"
            v-if="dialogVisible"
          >
            <div
              v-if="dialogtitle == $t('MyFiles.fileDetails')"
              class="FileDetails"
            >
              <div class="dialogcontent_body">
                <div class="body" style="display: table">
                  <div
                    class="left"
                    style="
                      display: table-cell;
                      vertical-align: middle;
                      width: 176px;
                    "
                  >
                    {{ $t("MyFiles.name") }}
                  </div>
                  <!--                <el-tooltip-->
                  <!--                  class="item"-->
                  <!--                  effect="dark"-->
                  <!--                  :content="dataInfo.job_name"-->
                  <!--                  :disabled="!isShowTooltip"-->
                  <!--                >-->
                  <!--                @mouseenter="visibilityChange($event)"-->
                  <div
                    class="right right_name"
                    style="
                      display: table-cell;
                      vertical-align: middle;
                      width: 665px;
                      box-sizing: border-box;
                    "
                  >
                    {{ dataInfo.job_name }}
                  </div>
                  <!--                </el-tooltip>-->
                </div>

                <div class="body">
                  <div class="left">{{ $t("MyFiles.service") }}</div>
                  <div class="right right1">{{ dataInfo.job_type_html }}</div>
                  <div class="left">{{ $t("MyFiles.team") }}</div>
                  <div class="right">{{ dataInfo.private_html }}</div>
                </div>
                <template v-if="dataInfo.private == '1'">
                  <div
                    class="body"
                    v-if="
                      ['1', '2', '3', '5', '7', '8'].indexOf(
                        dataInfo.job_type
                      ) > -1
                    "
                  >
                    <div class="left">{{ $t("MyFiles.sourceLanguage") }}</div>
                    <div class="right right1">{{ dataInfo.slang }}</div>
                    <div class="left">{{ $t("MyFiles.targetLanguage") }}</div>
                    <div class="right">{{ dataInfo.tlang }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="
                      ['4'].indexOf(dataInfo.job_type) > -1 &&
                      dataInfo.private !== $t('MyFiles.team')
                    "
                  >
                    <div class="left">{{ $t("MyFiles.sourceLanguage") }}</div>
                    <div class="right right1">{{ dataInfo.slang }}</div>
                    <div class="left">{{ $t("MyFiles.duration") }}</div>
                    <div class="right">{{ dataInfo.duration }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="['9', '10', '11'].indexOf(dataInfo.job_type) > -1"
                  >
                    <div class="left">{{ $t("MyFiles.sourceLanguage") }}</div>
                    <div class="right right1">{{ dataInfo.slang }}</div>
                    <div class="left">{{ $t("MyFiles.DUBLanguage") }}</div>
                    <div class="right">{{ dataInfo.tlang }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="
                      ['1', '2', '3', '7', '8', '9', '10', '11'].indexOf(
                        dataInfo.job_type
                      ) > -1
                    "
                  >
                    <div class="left">{{ $t("MyFiles.segments") }}</div>
                    <div class="right right1">{{ dataInfo.segment_count }}</div>
                    <div class="left">{{ $t("MyFiles.wordCount") }}</div>
                    <div class="right">{{ dataInfo.word_count }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="
                      ['1', '2', '3', '7', '8', '9', '10', '11'].indexOf(
                        dataInfo.job_type
                      ) > -1
                    "
                  >
                    <div class="left">{{ $t("MyFiles.mediaDuration") }}</div>
                    <div class="right right1">{{ dataInfo.duration }}</div>
                    <div class="left">{{ $t("MyFiles.createTime") }}</div>
                    <div class="right">{{ dataInfo.create_time }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="['4', '5', '6'].indexOf(dataInfo.job_type) > -1"
                  >
                    <div class="left">{{ $t("MyFiles.createTime") }}</div>
                    <div class="right right1">{{ dataInfo.create_time }}</div>
                    <div class="left">{{ $t("MyFiles.updateTime") }}</div>
                    <div class="right">{{ dataInfo.update_time }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="
                      ['1', '2', '3', '7', '8', '9', '10', '11'].indexOf(
                        dataInfo.job_type
                      ) > -1
                    "
                  >
                    <div class="left">{{ $t("MyFiles.updateTime") }}</div>
                    <div class="right right1">{{ dataInfo.update_time }}</div>
                    <div class="left">{{ $t("MyFiles.deadline") }}</div>
                    <div class="right">{{ dataInfo.delivery_time }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="['6'].indexOf(dataInfo.job_type) > -1"
                  >
                    <div class="left">{{ $t("MyFiles.wordCount") }}</div>
                    <div class="right">{{ dataInfo.word_count }}</div>
                  </div>
                  <div
                    class="body"
                    v-if="['5'].indexOf(dataInfo.job_type) > -1"
                  >
                    <div class="left">{{ $t("MyFiles.imgCount") }}</div>
                    <div class="right">{{ dataInfo.image_count }}</div>
                  </div>
                </template>
                <template v-if="dataInfo.private == '2'">
                  <div class="body" v-if="dataInfo.job_search_type == '1'">
                    <div class="left">{{ $t("MyFiles.sourceLanguage") }}</div>
                    <div class="right right1">{{ dataInfo.slang }}</div>
                    <div class="left">{{ $t("MyFiles.targetLanguage") }}</div>
                    <div class="right">{{ dataInfo.tlang }}</div>
                  </div>
                  <div class="body" v-if="dataInfo.job_search_type == '3'">
                    <div class="left">{{ $t("MyFiles.sourceLanguage") }}</div>
                    <div class="right right1">{{ dataInfo.slang }}</div>
                    <div class="left">{{ $t("MyFiles.DUBLanguage") }}</div>
                    <div class="right">{{ dataInfo.tlang }}</div>
                  </div>
                  <div class="body">
                    <div class="left">{{ $t("MyFiles.segments") }}</div>
                    <div class="right right1">{{ dataInfo.segment_count }}</div>
                    <div class="left">{{ $t("MyFiles.wordCount") }}</div>
                    <div class="right">{{ dataInfo.word_count }}</div>
                  </div>
                  <div class="body" v-if="dataInfo.private == '2'">
                    <div class="left">{{ $t("MyFiles.mediaDuration") }}</div>
                    <div class="right right1">{{ dataInfo.duration }}</div>
                    <div class="left">{{ $t("MyFiles.createTime") }}</div>
                    <div class="right">{{ dataInfo.create_time }}</div>
                  </div>
                  <div class="body" v-if="dataInfo.job_search_type == '1'">
                    <div class="left">{{ $t("MyFiles.updateTime") }}</div>
                    <div class="right right1">{{ dataInfo.update_time }}</div>
                    <div class="left">{{ $t("MyFiles.deadline") }}</div>
                    <div class="right">{{ dataInfo.delivery_time }}</div>
                  </div>
                  <div class="body" v-if="dataInfo.job_search_type == '3'">
                    <div class="left">{{ $t("MyFiles.updateTime") }}</div>
                    <div class="right">{{ dataInfo.update_time }}</div>
                  </div>
                </template>
                <div class="body body1" style="display: table">
                  <div
                    class="left"
                    style="
                      display: table-cell;
                      vertical-align: middle;
                      width: 176px;
                    "
                  >
                    {{ $t("MyFiles.comments") }}
                  </div>
                  <!--                <el-tooltip-->
                  <!--                  class="item"-->
                  <!--                  effect="dark"-->
                  <!--                  :content="dataInfo.comments"-->
                  <!--                >-->
                  <div
                    class="right right_name"
                    style="
                      display: table-cell;
                      vertical-align: middle;
                      width: 665px;
                      box-sizing: border-box;
                    "
                  >
                    {{ dataInfo.comments }}
                  </div>
                  <!--                </el-tooltip>-->
                </div>
              </div>
            </div>
          </DialogComVue>
          <DialogComVue
            :dialogVisible="dialogRenameVisible"
            @determineClick="determineRenameClick"
            @changeDialogVisible="changeDialogRenameVisible"
            :width="'888px'"
            :top="'10%'"
            :title="dialogRenameTitle"
            v-if="dialogRenameVisible"
          >
            <div class="addFolder langselectclass">
              <p>{{ $t("MyFiles.newName") }}</p>
              <el-input
                v-model="RenameInput"
                :placeholder="$t('MyFiles.newNameTips')"
                maxlength="60"
              ></el-input>
            </div>
          </DialogComVue>
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisibleCreate"
      @determineClick="determineCreateClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitleCreate"
      v-if="dialogVisibleCreate"
    >
      <div class="addFolder langselectclass">
        <p>{{ $t("MyFiles.FolderName") }}</p>
        <el-input
          v-model="inputCreate"
          maxlength="50"
          :placeholder="$t('MyFiles.CreateFolder1')"
        ></el-input>
      </div>
    </DialogComVue>
    <!-- 移动至文件夹 -->
    <DialogComVue
      :dialogVisible="dialogMoveVisible"
      @determineClick="determineMoveClick"
      @changeDialogVisible="changeDialogMoveVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="$t('ProjectManagement.MoveToFileTitle')"
      v-if="dialogMoveVisible"
    >
      <div class="moveProjectSearch">
        <el-input
          v-model="filterText"
          :placeholder="$t('CommonName.search')"
          maxlength="100"
          clearable
        ></el-input>
      </div>
      <div class="moveFolder langselectclass">
        <el-tree
          ref="tree"
          :data="moveFolderList"
          :props="defaultProps"
          @node-click="handleNodeClick"
          class="move_tree"
          node-key="id"
          empty-text=""
          highlight-current
          :filter-node-method="filterNode"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <svg-icon
              :icon-class="
                data.id == '0'
                  ? 'project2'
                  : node.expanded
                  ? 'file_open'
                  : 'Folder'
              "
              class="icon"
              :class="data.id == '0' ? 'svgiconclass1' : 'svgiconclass'"
            ></svg-icon>
            <span class="folderp">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import { timestampToTime } from "@/api/setTime";
import {
  delete_folder,
  delete_private_job,
  get_job_list,
  move_job_folder,
  get_job_info,
  delete_private_batch,
  rename_folder,
  rename_job,
  get_user_quota_info,
  get_download_job,
  create_folder,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import JSZip from "jszip";
import FileSaver from "file-saver";
import $ from "jquery";

export default {
  data() {
    return {
      newtableData: [], //表格数据
      dialogWidth: "888px",
      folder_id: "",
      dialogtitle: "",
      dialogVisible: false,
      showFooter: false,
      time: [], //选择日期
      time_format:
        sessionStorage.getItem("lang") == "en" ? "MMM dd yyyy" : "yyyy-MM-dd",
      input: "", //输入框
      pageSize: 20, //分页
      currentPage: 1,
      tableHeight: 0,
      clickfilesList: {},
      commandData: {},
      dataInfo: {},
      dropdownList: [
        {
          name: this.$t("MyFiles.details"),
          command: 0,
          icon: "presentation",
        },
        // {
        //   name: "下载字幕",
        //   command: 1,
        //   icon: "download",
        // },
        {
          name: this.$t("MyFiles.rename"),
          command: 2,
          icon: "Edit",
        },
        {
          name: this.$t("MyFiles.delete"),
          command: 3,
          icon: "delete",
        },
        {
          name: this.$t("MyFiles.moveTo"),
          command: 4,
          icon: "ShiftIn",
        },
      ],
      dropdownList2: [
        {
          name: this.$t("MyFiles.details"),
          command: 0,
          icon: "presentation",
        },
        // {
        //   name: "下载字幕",
        //   command: 1,
        //   icon: "download",
        // },
        {
          name: this.$t("MyFiles.moveTo"),
          command: 4,
          icon: "ShiftIn",
        },
      ],
      dropdownList1: [
        {
          name: this.$t("MyFiles.rename"),
          command: 2,
          icon: "Edit",
        },
        {
          name: this.$t("MyFiles.delete"),
          command: 3,
          icon: "delete",
        },
        {
          name: this.$t("MyFiles.moveTo"),
          command: 4,
          icon: "ShiftIn",
        },
      ],
      multipleSelection: [],
      SelectedFolderId: -1,
      SelectedJobIds: [], //个人任务选中id集合
      SelectedTeamJobIds: [], //团队任务选中id集合
      folder_list: [],
      dialogRenameVisible: false,
      dialogRenameTitle: this.$t("MyFiles.renameFolder"),
      RenameInput: "",
      RenameType: "0", //当前重命名类型 0：文件夹；1：文件
      RenameId: "0",
      percentage: 0, //占用空间 ，动态
      percentages: 0,
      totalpercentage: "0",
      customColor: "#a9b5c9",
      saveDays: 30,
      tableTotal: 0,
      sortName: "update_time",
      sortType: "desc",
      user_info: [],
      isShowTooltip: false,
      typeDropdownList: [
        {
          id: "0",
          name: this.$t("MyFiles.All"),
        },
        {
          id: "1",
          name: this.$t("MyFiles.jobType1"),
        },
        {
          id: "2",
          name: this.$t("MyFiles.jobType2"),
        },
        {
          id: "3",
          name: this.$t("MyFiles.jobType3"),
        },
        {
          id: "4",
          name: this.$t("MyFiles.jobType4"),
        },
        {
          id: "5",
          name: this.$t("MyFiles.jobType5"),
        },
        {
          id: "6",
          name: this.$t("MyFiles.jobType6"),
        },
        {
          id: "7",
          name: this.$t("MyFiles.jobType7"),
        },
        {
          id: "8",
          name: this.$t("MyFiles.jobType8"),
        },
        {
          id: "9",
          name: this.$t("MyFiles.jobType9"),
        },
        {
          id: "10",
          name: this.$t("MyFiles.jobType10"),
        },
        {
          id: "11",
          name: this.$t("MyFiles.jobType11"),
        },
        {
          id: "101",
          name: this.$t("MyFiles.jobType101"),
        },
        {
          id: "102",
          name: this.$t("MyFiles.jobType102"),
        },
        {
          id: "103",
          name: this.$t("MyFiles.jobType103"),
        },
        {
          id: "106",
          name: this.$t("MyFiles.jobType106"),
        },
        {
          id: "104",
          name: this.$t("MyFiles.jobType104"),
        },
        {
          id: "105",
          name: this.$t("MyFiles.jobType105"),
        },
      ],
      currentType: "0",
      currentTypeName: this.$t("MyFiles.FolderType"),
      downloadFileList: [
        {
          name: "广东十虎黄澄可之云起龙骧-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8286/广东十虎黄澄可之云起龙骧-mi.mp4.zip",
        },
        {
          name: "少年朱元璋-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8287/少年朱元璋-mi.mp4.zip",
        },
        {
          name: "拳皇-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8288/拳皇-mi.mp4.zip",
        },
        {
          name: "广东十虎黄澄可之荆天棘地-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8289/广东十虎黄澄可之荆天棘地-mi.mp4.zip",
        },
        {
          name: "小西天狄道传奇-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_10/8290/小西天狄道传奇-mi.mp4.zip",
        },
        {
          name: "九纹龙史进之大破瓦罐寺-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8291/九纹龙史进之大破瓦罐寺-mi.mp4.zip",
        },
        {
          name: "浴血边关-mi.mp4.zip",
          url: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/global/2023_10_08/8292/浴血边关-mi.mp4.zip",
        },
      ],
      ShiftDown: false,
      startrow: {},
      statusDropdownList: [
        {
          id: "0",
          name: this.$t("MyFiles.All"),
        },
        {
          id: "10",
          name: this.$t("MyFiles.teamJob1"),
        },
        {
          id: "20",
          name: this.$t("MyFiles.teamJob2"),
        },
      ],
      currentTeamStatus: "0",
      dialogVisibleCreate: false,
      dialogtitleCreate: this.$t("MyFiles.CreateFolder"),
      inputCreate: "",
      folder_pid: "",
      Breadcrumb: [],
      defaultSort: { prop: "update_time", order: "descending" },
      moveFolderList: [],
      dialogMoveVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectAllFolder: false,
      move_folder_id: "0", //移动文件夹id
      SelectedFolderIds: [],
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  components: {
    PaginationComVue,
    DialogComVue,
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //移动文件夹保存
    determineMoveClick() {
      console.log("node", this.move_folder_id);
      // return;
      let job_ids = [];
      let folder_ids = [];
      showLoading(this.$t("MyFiles.loadingTips"));
      move_job_folder({
        action: "move_job_folder",
        job_ids: this.SelectedJobIds,
        folder_ids: this.SelectedFolderIds,
        team_job_ids: this.SelectedTeamJobIds,
        folder_id: this.move_folder_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_job_list().then((res) => {
          this.dialogMoveVisible = false;
          this.$messages("success", this.$t("MyFiles.moveToTips1"));
        });
      });
    },
    changeDialogMoveVisible(val) {
      this.dialogMoveVisible = false;
    },
    handleNodeClick(val) {
      // console.log(val);
      this.selectAllFolder = false;
      this.move_folder_id = val.id;
    },
    breadcrumbClick(folder_id) {
      this.folder_pid = folder_id;
      this.$router.push({
        path: "/ContainerHome/MyFiles",
        query: { folder_id: folder_id },
      });
      this.currentPage = 1;

      setTimeout(() => {
        this.$refs.multipleTable.sort("update_time", "descending");
      }, 10);

      // this.$router.go(0);
    },
    backClick() {
      sessionStorage.setItem("menuTags", "");
      let pid = this.folder_pid;
      const index = this.Breadcrumb.findIndex(
        (item) => parseInt(item.id) == pid
      );
      let id = 0;
      if (index > 0) {
        id = this.Breadcrumb[index - 1]["id"];
      }
      this.folder_pid = id;

      this.$router.push({
        path: "/ContainerHome/MyFiles",
        query: { folder_id: id },
      });
      this.currentPage = 1;

      setTimeout(() => {
        this.$refs.multipleTable.sort("update_time", "descending");
      }, 10);

      // this.$router.go(0);
    },
    /**
     * 添加新文件夹按钮
     */
    addFolder() {
      this.dialogVisibleCreate = true;
    },
    /**
     * 保存新文件夹
     */
    determineCreateClick() {
      if (this.inputCreate.trim() == "") {
        this.$messages("warning", this.$t("MyFiles.inputFolderTips"));
        return;
      }
      create_folder({
        action: "create_folder",
        name: this.inputCreate,
        folder_pid: this.folder_pid,
      }).then((res) => {
        if (res) {
          this.$messages("success", this.$t("MyFiles.inputFolderTips1"));
          this.dialogVisibleCreate = false;
          this.inputCreate = "";
          this.get_job_list();
          this.clearMultipleSelection();
        }
      });
    },
    windowkeyup(e) {
      if (e.keyCode === 16) this.ShiftDown = false;
    },
    windowkeydown(e) {
      // console.log(e.keyCode);
      if (e.keyCode === 16 && !e.ctrlKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.ShiftDown = true;
      }
    },
    /**
     *
     * @param {* 鼠标划上 显示 具体内容} e
     */
    visibilityChange(e) {
      this.isShowTooltip = e.target.scrollWidth > e.target.clientWidth;
    },
    // 点击查询
    queryclick() {
      // console.log(this.time);
      // console.log(this.input);
      this.currentPage = 1;
      this.get_job_list().then(() => {});
    },
    /**
     *
     * @param {*表格排序 } column
     */
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("MyFiles.loadingTips"));
      this.sortName = column.prop;
      if (column.order == "ascending") {
        this.sortType = "asc";
      } else {
        this.sortType = "desc";
      }
      this.get_job_list();
    },
    /**
     *
     * @param {*获取处理后的数据} dataList
     */
    setData(dataList) {
      dataList.forEach((i, index) => {
        i.index = index;
        if (i.is_folder == "1") {
          i.icon = "Folder";
          i.attribute = "--";
          i.service = "--";
          i.update_time = "--";
        } else {
          i.file_count = "--";
          if (
            ["1", "2", "3", "4", "7", "8", "9", "10", "11"].includes(i.job_type)
          ) {
            i.showLoadingnow = Number(i.file_status) < 20;
          }
          //图标显示
          if (
            ["0", "1", "2", "3", "7", "8", "9", "10", "11"].includes(i.job_type)
          ) {
            i.icon = "video";
          } else if (i.job_type == "4") {
            i.icon = "audio";
          } else if (i.job_type == "5") {
            i.icon = "Comic";
          } else {
            i.icon = "TextVoice";
          }
          //private 1: 个人； 2：团队
          if (i.private == 1) {
            i.attribute = this.$t("MyFiles.my");
            switch (i.job_type) {
              case "1":
                i.service = this.$t("MyFiles.jobType1");
                break;
              case "2":
                i.service = this.$t("MyFiles.jobType2");
                break;
              case "3":
                i.service = this.$t("MyFiles.jobType3");
                break;
              case "4":
                i.service = this.$t("MyFiles.jobType4");
                break;
              case "5":
                i.service = this.$t("MyFiles.jobType5");
                break;
              case "6":
                i.service = this.$t("MyFiles.jobType6");
                break;
              case "7":
                i.service = this.$t("MyFiles.jobType7");
                break;
              case "8":
                i.service = this.$t("MyFiles.jobType8");
                break;
              case "9":
                i.service = this.$t("MyFiles.jobType9");
                break;
              case "10":
                i.service = this.$t("MyFiles.jobType10");
                break;
              case "11":
                i.service = this.$t("MyFiles.jobType11");
                break;
              default:
                i.service = this.$t("MyFiles.jobType1");
            }
          } else if (i.private == 2) {
            if (i.team_status == "10") {
              //进行中
              i.attribute = i.nickname + " " + this.$t("MyFiles.teamJob1");
            } else {
              //已完成
              i.attribute = i.nickname + " " + this.$t("MyFiles.teamJob2");
            }
            var progress1 = "";
            var class1 = "";
            //job_search_type 1:多媒体任务； 3：配音任务
            if (i.job_search_type == "1") {
              i.icon = "video";
              switch (i.job_type) {
                case "1":
                  i.newstatus = {
                    name: this.$t("CommonName.statusName1"), //转写
                    class: "blueClass",
                    progress: "",
                  };
                  break;
                case "2":
                  progress1 = "";
                  class1 = "greenClass";
                  if (i.file_status * 1 < 25) {
                    progress1 = this.$t("CommonName.statusName1") + "未完成";
                    class1 = "grayClass";
                  } else if (i.file_status * 1 < 30) {
                    progress1 = this.$t("CommonName.statusName12") + "未完成";
                    class1 = "grayClass";
                  }
                  i.newstatus = {
                    name: this.$t("CommonName.statusName2"), //翻译
                    class: class1,
                    progress: progress1,
                  };
                  break;
                case "3":
                  // eslint-disable-next-line no-case-declarations
                  progress1 = "";
                  class1 = "yellowClass";
                  if (i.file_status * 1 < 40) {
                    progress1 =
                      this.$t("CommonName.statusName2") +
                      this.$t("MyFiles.teamJob3") +
                      " " +
                      i.progress +
                      "%";
                    class1 = "grayClass";
                  }
                  i.newstatus = {
                    name: this.$t("CommonName.statusName3"), //校对
                    class: class1,
                    progress: progress1,
                  };
                  break;
                case "5":
                  progress1 = "";
                  class1 = "stqClass";
                  if (+i.file_status < 25) {
                    progress1 = this.$t("CommonName.statusName1") + "未完成";
                    class1 = "grayClass";
                  }
                  i.newstatus = {
                    name: this.$t("CommonName.statusName12"), //STQ
                    class: class1,
                    progress: progress1,
                  };
                  break;
                default:
                  i.newstatus = {
                    name: this.$t("CommonName.statusName1"), //转写
                    class: "blueClass",
                    progress: "",
                  };
                  break;
              }
            } else if (i.job_search_type == "3") {
              switch (i.job_type) {
                case "0":
                  i.service = this.$t("MyFiles.jobType10"); //声纹定制配音
                  break;
                case "1":
                  i.service = this.$t("MyFiles.jobType11"); //视频克隆配音
                  break;
                case "2":
                  i.service = this.$t("MyFiles.jobType9"); //AI视频配音
                  break;
                default: //声纹定制配音
                  i.service = this.$t("MyFiles.jobType10");
                  break;
              }
            }
          }
        }
      });
      this.newtableData = dataList;
    },
    /**
     *
     * @param {*获取可移动的文件夹}
     */
    moveToFile() {
      if (this.multipleSelection.length > 0) {
        showLoading(this.$t("CommonName.loadingmessage"));
        let folder_ids = [];
        this.multipleSelection.forEach((item) => {
          if (item.is_folder == "1") {
            folder_ids.push(item.id);
          }
        });
        get_job_list({
          action: "get_move_folder",
          folder_ids,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          let arr = {
            id: "0",
            label: this.$t("MyFiles.MyFilesTitle"),
            pid: "0",
            user_id: "0",
          };
          this.moveFolderList = [arr, ...res.list];
          this.dialogMoveVisible = true;
        });
      }
    },
    /**
     *
     * @param {*点击选中的 文件夹} item
     */
    clickSelectedFolder(id) {
      this.SelectedFolderId = id;
    },
    /**
     * 获取总体数据
     */
    get_job_list() {
      let start = "";
      let end = "";
      if (this.time !== null && this.time.length == 2) {
        start = this.time[0];
        end = this.time[1];
      }
      this.startrow = {};
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      // console.log(this.sortName);
      // console.log(this.sortType);
      return get_job_list({
        action: "get_job_list",
        name: this.input,
        folder_id: this.folder_pid,
        start_time: start,
        end_time: end,
        sort_type: this.sortType ? this.sortType : "desc",
        sort_name: this.sortName ? this.sortName : "create_time",
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        job_type: this.currentType,
        team_status: this.currentTeamStatus,
      }).then((res) => {
        if (res) {
          // console.log(res.list);
          this.folder_list = res.folder_list;
          this.tableTotal = parseInt(res.list_count);
          this.Breadcrumb = res.Breadcrumb;
          this.currentPage = res.currentPage;
          this.setData(res.list);

          hideLoading();
        }
      });
    },
    clearMultipleSelection() {
      this.multipleSelection = [];
      if (this.newtableData.length > 0) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    /**
     *
     * @param {关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.dialogVisibleCreate = val;
    },
    /**
     * 设置选中行 背景颜色
     */
    tableRowClassName({ row }) {
      let color = "";
      for (let index = 0; index < this.multipleSelection.length; index++) {
        if (row.is_folder == "1") {
          if (this.multipleSelection[index].id === row.id) {
            color = "table-SelectedRow-bgcolor";
          }
        } else {
          if (this.multipleSelection[index].id === row.id) {
            color = "table-SelectedRow-bgcolor";
          }
        }
      }
      return color;
    },
    /**
     *
     * @param {*判断是否是文件夹 跳转不同页面} row
     */
    tovideoDetails(row) {
      // console.log(row.showLoadingnow);

      if (row.is_folder == "1") {
        this.folder_pid = row.id;
        this.$router.push({
          path: "/ContainerHome/MyFiles",
          query: { folder_id: row.id },
        });
        this.currentPage = 1;
        this.$refs.multipleTable.sort("update_time", "descending");
      } else {
        // console.log(row);
        if (row.showLoadingnow) {
          // if (row.job_type == "7") {
          //   this.$messages("warning", this.$t("MyFiles.jobStatus1"));
          //   return;
          // } else {
          this.$messages("warning", this.$t("MyFiles.jobStatus1"));
          return;
          // }
        }

        if (row.private == "2") {
          let newUrl = "";
          let url = "";
          // this.$uploadUR
          if (row.job_search_type == "1") {
            switch (row.job_type) {
              case "1":
                if (row.source_media_type == "2") {
                  url = "/ContainerHome/OverwriteOperationLocal?";
                } else {
                  url = "/ContainerHome/OverwriteOperation?";
                }
                newUrl = url + row.task_url;
                break;
              case "2":
                if (row.source_media_type == "2") {
                  url = "/ContainerHome/TranslationOperationLocal?";
                } else {
                  url = "/ContainerHome/TranslationOperation?";
                }
                newUrl = url + row.task_url;
                break;
              case "3":
                if (row.source_media_type == "2") {
                  url = "/ContainerHome/ProofingOperationLocal?";
                } else {
                  url = "/ContainerHome/ProofingOperation?";
                }
                newUrl = url + row.task_url;
                // newUrl = "/ContainerHome/ProofingOperation?" + row.task_url;
                break;
              case "5":
                if (row.source_media_type == "2") {
                  url = "/ContainerHome/OverwriteOperationLocal?";
                } else {
                  url = "/ContainerHome/OverwriteOperationNew?";
                }
                newUrl = url + row.task_url;
                // newUrl = "/ContainerHome/ProofingOperation?" + row.task_url;
                break;
              default:
                break;
            }
          } else if (row.job_search_type == "3") {
            switch (row.job_type) {
              case "0":
                url = "/TeamMain/VideoDubbingClone?";
                newUrl = url + row.task_url;
                break;
              case "1":
                url = "/TeamMain/VideoDubbingClone?";
                newUrl = url + row.task_url;
                break;
              default:
                break;
            }
          }
          let pathInfo = this.$router.resolve({
            path: newUrl,
          });

          window.open(pathInfo.href, "_blank");
        } else {
          if (["1", "2", "3", "7", "8"].includes(row.job_type)) {
            let pathInfo = this.$router.resolve({
              path: "/ContainerHome/VideoDetails",
              query: {
                job_id: row.id,
              },
            });
            window.open(pathInfo.href, "_blank");
          } else if (row.job_type == "5") {
            window.open(
              `${this.$AnimationFlipURL + "?job_id=" + row.id}&role=1`,
              "_blank"
            );
          } else if (row.job_type == "4") {
            let pathInfo = this.$router.resolve({
              path: "/AudioInfo",
              query: {
                jid: row.id,
              },
            });
            window.open(pathInfo.href, "_blank");
          } else if (row.job_type == "6") {
            let pathInfo = this.$router.resolve({
              path: "/DubbingInfo",
              query: {
                jid: row.id,
              },
            });
            window.open(pathInfo.href, "_blank");
          } else if (row.job_type == "9") {
            let pathInfo = this.$router.resolve({
              path: "/ContainerHome/VideoDubbing",
              query: {
                jid: row.id,
              },
            });
            window.open(pathInfo.href, "_blank");
          } else if (row.job_type == "10" || row.job_type == "11") {
            let pathInfo = this.$router.resolve({
              path: "/ContainerHome/VideoDubbingClone",
              query: {
                jid: row.id,
              },
            });
            window.open(pathInfo.href, "_blank");
          }
        }
      }

      // window.open("/#/ContainerHome/VideoDetails", "_block");
    },
    /**
     *
     * @param {表格下拉框选中 } command
     */
    commandClick(command) {
      // console.log(command);
      if (command.type == 0) {
        if (command.id.is_folder != 1) {
          get_job_info({
            action: "get_job_info",
            job_id: command.id.id,
            job_type: command.id.job_type,
            job_search_type: command.id.job_search_type,
            private: command.id.private,
            job_split_id: command.id.split_id,
          }).then((res) => {
            if (res) {
              this.dataInfo = res.info;
              if (this.dataInfo.private == "1") {
                this.dataInfo.create_time = command.id.create_time;
                this.dataInfo.update_time = command.id.update_time;
                this.dataInfo.private_html = this.$t("MyFiles.my");
                switch (this.dataInfo.job_type) {
                  case "1":
                    //视频转写
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType1");
                    break;
                  case "2":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType2");
                    break;
                  case "3":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType3");
                    break;
                  case "4":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType4");
                    break;
                  case "5":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType5");
                    break;
                  case "6":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType6");
                    break;
                  case "7":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType7");
                    break;
                  case "8":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType8");
                    break;
                  case "9":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType9");
                    break;
                  case "11":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType11");
                    break;
                  case "10":
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType10");
                    break;
                  default:
                    this.dataInfo.job_type_html = this.$t("MyFiles.jobType1");
                }
              } else {
                // this.dataInfo.private_html = this.$t("MyFiles.team");
                if (command.id.job_search_type == "1") {
                  switch (command.id.job_type) {
                    case "1":
                      //转写
                      this.dataInfo.job_type_html = this.$t(
                        "CommonName.statusName1"
                      );
                      break;
                    case "2":
                      this.dataInfo.job_type_html = this.$t(
                        "CommonName.statusName2"
                      );
                      break;
                    case "3":
                      this.dataInfo.job_type_html = this.$t(
                        "CommonName.statusName3"
                      );
                      break;
                    case "5":
                      this.dataInfo.job_type_html = this.$t(
                        "CommonName.statusName12"
                      );
                      break;
                    default:
                      this.dataInfo.job_type_html = this.$t(
                        "CommonName.statusName1"
                      );
                  }
                } else if (command.id.job_search_type == "3") {
                  switch (command.id.job_type) {
                    case "0":
                      this.dataInfo.job_type_html =
                        this.$t("MyFiles.jobType10"); //声纹定制配音
                      break;
                    case "1":
                      this.dataInfo.job_type_html =
                        this.$t("MyFiles.jobType11"); //视频克隆配音
                      break;
                    case "2":
                      this.dataInfo.job_type_html = this.$t("MyFiles.jobType9"); //AI视频配音
                      break;
                    default: //声纹定制配音
                      this.dataInfo.job_type_html =
                        this.$t("MyFiles.jobType10");
                      break;
                  }
                }
              }
              this.dialogtitle = this.$t("MyFiles.fileDetails");
              this.showFooter = false;
              this.dialogWidth = "888px";
              this.dialogVisible = true;
              // console.log(res.info);
            }
          });
        }
      } else if (command.type == 2) {
        if (command.id.is_folder == 1) {
          this.RenameType = "0";
          this.dialogRenameTitle = this.$t("MyFiles.renameFolder");
        } else {
          this.RenameType = "1";
          this.dialogRenameTitle = this.$t("MyFiles.renameFiles");
        }
        this.RenameId = command.id.id;
        this.RenameInput = command.id.name;
        this.dialogRenameVisible = true;
      } else if (command.type == 4) {
        //移动文件
        this.commandData = command.id;
        this.SelectedFolderIds = [];
        this.SelectedTeamJobIds = [];
        this.SelectedJobIds = [];
        if (command.id.is_folder == "1") {
          this.SelectedFolderIds = [command.id.id];
        } else if (command.id.private == "2") {
          this.SelectedTeamJobIds = [command.id.search_id];
        } else if (command.id.private == "1") {
          this.SelectedJobIds = [command.id.id];
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        get_job_list({
          action: "get_move_folder",
          folder_ids: this.SelectedFolderIds,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          let arr = {
            id: "0",
            label: this.$t("MyFiles.MyFilesTitle"),
            pid: "0",
            user_id: "0",
          };
          this.moveFolderList = [arr, ...res.list];
          this.dialogMoveVisible = true;
        });
      } else if (command.type == 3) {
        // console.log(command);
        let title = "";
        if (command.id.is_folder == 1) {
          // 删除文件夹
          title = this.$t("MyFiles.deleteTips1");
        } else {
          // 删除
          title = this.$t("MyFiles.deleteTips2");
        }
        this.$confirm(title, this.$t("MyFiles.comfirmTips1"), {
          confirmButtonText: this.$t("MyFiles.comfirmTips2"),
          cancelButtonText: this.$t("MyFiles.comfirmTips3"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          customClass: "messagebox_class",
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            showLoading(this.$t("MyFiles.loadingTips"));
            if (command.id.is_folder == 1) {
              delete_folder({
                action: "delete_folder",
                folder_id: command.id.id,
              }).then((res) => {
                if (res) {
                  this.get_job_list().then((res) => {
                    this.$messages("success", this.$t("MyFiles.deleteTips3"));
                  });
                }
              });
            } else {
              delete_private_job({
                action: "delete_private_job",
                job_id: command.id.id,
              }).then((res) => {
                if (res) {
                  this.get_job_list().then((res) => {
                    this.$messages("success", this.$t("MyFiles.deleteTips3"));
                  });
                }
              });
            }
          })
          .catch((action) => {
            if (action === "cancel") {
              console.log("cancel");
            }
          });
      }
    },
    /**
     *
     * @param {全选} val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    /**
     * 表格复选框 选中
     * @param selection
     * @param row
     */
    handleSelect(selection, row) {
      if (this.ShiftDown) {
        // this.multipleSelection.push(row);
        if (!!this.startrow.id == false) {
          this.multipleSelection = selection;
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(row);
          this.startrow = row;
        } else {
          let data;
          if (this.startrow.index < row.index) {
            data = this.newtableData.slice(this.startrow.index, row.index + 1);
          } else {
            data = this.newtableData.slice(row.index, this.startrow.index + 1);
          }
          this.multipleSelection = data;
          this.$refs.multipleTable.clearSelection();
          data.forEach((rows) => {
            this.$refs.multipleTable.toggleRowSelection(rows);
          });
        }
        return;
      }
      this.startrow = {};
      this.startrow = row;
      this.multipleSelection = selection;
    },
    /**
     * 表格复选框 全选/全不选
     * @param selection
     * @param row
     */
    handleALLSelect(selection) {
      this.startrow = {};
      this.multipleSelection = selection;
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      showLoading(this.$t("MyFiles.loadingTips"));
      this.get_job_list().then(() => {});
      // this.tableData.slice(
      //   (this.currentPage - 1) * this.pageSize,
      //   this.currentPage * this.pageSize
      // );
    },
    handleCurrentChange(val) {
      showLoading(this.$t("MyFiles.loadingTips"));
      this.currentPage = val;
      this.get_job_list().then(() => {});
      // this.tableData.slice(
      //   (this.currentPage - 1) * this.pageSize,
      //   this.currentPage * this.pageSize
      // );
    },
    daterangeChange() {
      // console.log(this.time);
      this.get_job_list().then(() => {});
    },
    handleBatchMove() {
      let that = this;
      // console.log(this.multipleSelection);
      let ids = [];
      let tids = [];
      let fids = [];
      let next = true;
      this.multipleSelection.forEach(function (data) {
        if (data.is_folder == "1") {
          fids.push(data.id);
        } else {
          if (data.private == "2") {
            tids.push(data.search_id);
          } else {
            ids.push(data.id);
          }
        }
      });
      if (next) {
        // console.log(ids.join(","));
        // console.log(tids.join(","));
        this.SelectedJobIds = ids;
        this.SelectedTeamJobIds = tids;
        this.SelectedFolderIds = fids;

        //移动文件
        this.moveToFile();
        // this.dialogtitle = that.$t("MyFiles.moveTo1");
        // this.showFooter = true;
        // this.dialogWidth = "448px";
        // this.dialogVisible = true;
        // this.SelectedFolderId = -1;
      }
    },
    handleBatchDelete() {
      let that = this;
      let jobids = [];
      let folderids = [];
      let next = true;
      this.multipleSelection.every(function (data) {
        if (data.is_folder == "1") {
          if (data.file_count > 0) {
            that.$messages("warning", that.$t("MyFiles.deleteTips4"));
            next = false;
            return false;
          } else {
            folderids.push(data.id);
            return true;
          }
        } else {
          if (data.private == "2") {
            that.$messages("warning", that.$t("MyFiles.deleteTips5"));
            next = false;
            return false;
          }
          if (data.file_status == "10") {
            that.$messages("warning", that.$t("MyFiles.deleteTips6"));
            next = false;
            return false;
          }
          jobids.push(data.id);
          return true;
        }
      });
      if (next) {
        // console.log(jobids);
        // console.log(folderids);
        let title = "";
        title = that.$t("MyFiles.deleteTips7");
        this.$confirm(title, that.$t("MyFiles.comfirmTips1"), {
          confirmButtonText: that.$t("MyFiles.comfirmTips2"),
          cancelButtonText: that.$t("MyFiles.comfirmTips3"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            showLoading(that.$t("MyFiles.loadingTips"));

            delete_private_batch({
              action: "delete_private_batch",
              job_id: jobids,
              folder_id: folderids,
            }).then((res) => {
              if (res) {
                this.get_job_list().then((res) => {
                  this.$messages("success", that.$t("MyFiles.deleteTips3"));
                });
              }
            });
          })
          .catch((action) => {
            if (action === "cancel") {
              console.log("cancel");
            }
          });
      }
    },
    determineRenameClick() {
      console.log(this.RenameInput, this.RenameId, this.RenameType);
      if (this.RenameInput.trim() == "") {
        this.$messages("warning", this.$t("MyFiles.renameTips"));
        return false;
      }
      if (this.RenameType == "0") {
        rename_folder({
          action: "rename_folder",
          name: this.RenameInput,
          folder_id: this.RenameId,
        }).then((res) => {
          if (res) {
            this.get_job_list().then(() => {
              this.$messages("success", this.$t("MyFiles.renameTip1"));
              this.dialogRenameVisible = false;
              this.RenameInput = "";
            });
          }
        });
      } else {
        rename_job({
          action: "rename_job",
          name: this.RenameInput,
          job_id: this.RenameId,
        }).then((res) => {
          if (res) {
            this.get_job_list().then(() => {
              this.$messages("success", this.$t("MyFiles.renameTip2"));
              this.dialogRenameVisible = false;
              this.RenameInput = "";
            });
          }
        });
      }
    },
    changeDialogRenameVisible() {
      this.dialogRenameVisible = false;
    },

    /**
     * 获取用户数据
     */
    get_user_quota_info() {
      return get_user_quota_info({ action: "get_user_quota_info" }).then(
        (res) => {
          if (res) {
            this.percentages = parseFloat(
              (res.info.space.current / 1024).toFixed(2)
            );
            this.totalpercentage = parseFloat(
              (res.info.space.max / 1024).toFixed(2)
            );
            let info = JSON.parse(sessionStorage.getItem("info"));
            // console.log(this.user_info);
            // if (info.role == 3) {
            //   this.totalpercentage =
            //     660 + parseInt(this.user_info.storage_space_limit);
            // }
            this.percentage =
              Number(this.percentages / this.totalpercentage) * 100;
            // this.percentage = Number(
            //   (
            //     (Number(res.info.space.current) /
            //       (res.info.space.max +
            //         parseInt(this.user_info.storage_space_limit) * 1024)) *
            //     100
            //   ).toFixed()
            // );
            console.log(this.percentages);
            console.log(this.totalpercentage);
            console.log(this.percentage);
            hideLoading();
          }
        }
      );
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    statuscommand(command) {
      this.currentPage = 1;
      this.currentTeamStatus = command.value;
      this.get_job_list();
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    typecommand(command) {
      this.currentPage = 1;
      this.currentType = command.value;
      if (command.value == "0") {
        this.currentTypeName = this.$t("MyFiles.FolderType");
      } else {
        this.currentTypeName = command.name;
      }
      this.get_job_list();
    },
    // 批量下载任务音频文件
    handleBatchDownload() {
      let that = this;
      let jobids = [];
      let folderids = [];
      let next = true;
      this.multipleSelection.every(function (data) {
        if (data.job_type != "9") {
          that.$messages("warning", that.$t("MyFiles.downloadTips"));
          next = false;
          return false;
        } else {
          jobids.push(data.id);
          return true;
        }
      });
      if (next) {
        showLoading(this.$t("MyFiles.downloadLoading"));
        get_download_job({
          action: "get_download_job",
          job_id: jobids,
        }).then((res) => {
          if (res) {
            if (res.list.length > 0) {
              // res.list = this.downloadFileList;
              var blogTitle =
                this.$t("MyFiles.downloadTitle") + new Date().getTime(); // 下载后压缩包的命名
              var zip = new JSZip();
              var promises = [];
              let cache = {};
              let arrImg = res.list;
              // for (let i = 0; i < res.list.length; i++) {
              //   let arr1 = res.list[i].url.split("/");
              //   const last = arr1.slice(-1)[0];
              //   let url1 = res.list[i].url.replace(
              //     last,
              //     encodeURIComponent(last)
              //   );
              //   arrImg.push({
              //     path: url1, // 文件链接
              //     name: last, // 文件名称
              //   });
              //   arr1 = [];
              //   url1 = null;
              // }
              // console.log("arrImg", arrImg);
              for (let item of arrImg) {
                // item.path为文件链接地址
                const promise = this.getImgArrayBuffer(item.path).then(
                  (data) => {
                    // 下载文件, 并存成ArrayBuffer对象(blob)
                    zip.file(item.name, data, { binary: true }); // 逐个添加文件
                    // cache[item.name] = data;
                  }
                );
                promises.push(promise);
              }
              Promise.all(promises)
                .then(() => {
                  zip
                    .generateAsync({
                      type: "blob",
                      compression: "DEFLATE", // STORE：默认不压缩 DEFLATE：需要压缩
                      compressionOptions: {
                        level: 9, // 压缩等级1~9    1压缩速度最快，9最优压缩方式
                      },
                    })
                    .then((content) => {
                      // 生成二进制流
                      FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  自定义文件名
                      hideLoading();
                      promises = null;
                    });
                })
                .catch((res) => {
                  hideLoading();
                  this.$messages("error", this.$t("MyFiles.downloadTips1"));
                });
            } else {
              hideLoading();
              this.$messages("warning", this.$t("MyFiles.downloadTips2"));
            }
          }
        });
      }
    },
    /**
     * 批量操作 校审 任务
     * @param data
     */
    handleBatchProject_click(data) {
      //必须是团队任务
      if (!this.multipleSelection.every((i) => i.private === "2")) {
        return;
      }
      let arr = [];
      //必须是相同项目下
      if (
        !this.multipleSelection.every(
          (i) => i.project_id === this.multipleSelection[0].project_id
        )
      ) {
        this.$messages("warning", this.$t("MyFiles.Projecttask"));
        return;
      }
      //必须是相同语言
      if (
        !this.multipleSelection.every(
          (i) => i.slang_id === this.multipleSelection[0].slang_id
        )
      ) {
        this.$messages("warning", this.$t("MyFiles.Projecttask"));
        return;
      }

      if (!this.multipleSelection.every((i) => i.split_id === "0")) {
        this.$messages("warning", this.$t("MyFiles.Projecttask"));
        return;
      }
      let path = "";
      let localName = "";
      let first_type = this.multipleSelection[0]["job_type"];
      let first_status = this.multipleSelection[0]["file_status"];
      if (first_type != "2" && data == 1) {
        //翻译报告仅支持翻译任务
        this.$messages("warning", this.$t("MyFiles.Projecttask5"));
        return;
      }
      //相同流程
      if (!this.multipleSelection.every((i) => i.job_type == first_type)) {
        this.$messages("warning", this.$t("MyFiles.Projecttask"));
        return;
      }
      console.log("first_status", first_status);
      //相同流程
      if (!this.multipleSelection.every((i) => i.file_status == first_status)) {
        this.$messages("warning", this.$t("MyFiles.Projecttask10"));
        return;
      }
      if (first_type === "2") {
        if (
          !this.multipleSelection.every(
            (i) => i.tlang_id === this.multipleSelection[0].tlang_id
          )
        ) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
        if (data == 1) {
          //   翻译
          if (!this.multipleSelection.every((i) => +i.file_status > 30)) {
            this.$messages("warning", this.$t("MyFiles.Projecttask11"));
            return;
          }
          path = "/ContainerHome/BatchTranslationReport";
          localName = "projectList_report";
        } else if (data == 0) {
          //   翻译
          if (!this.multipleSelection.every((i) => +i.file_status >= 30)) {
            this.$messages("warning", this.$t("MyFiles.Projecttask2"));
            return;
          }
          path = "/ContainerHome/BatchTranslation";
          localName = "projectList_translation";
        }
      } else if (first_type === "3") {
        if (
          !this.multipleSelection.every(
            (i) => i.tlang_id === this.multipleSelection[0].tlang_id
          )
        ) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
        //   校审
        if (!this.multipleSelection.every((i) => i.job_type === "3")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask"));
          return;
        }
        if (!this.multipleSelection.every((i) => +i.file_status >= 40)) {
          this.$messages("warning", this.$t("MyFiles.Projecttask"));
          return;
        }
        path = "/ContainerHome/BatchProofreading";
        localName = "projectList";
      } else if (first_type === "1") {
        //   转写
        if (!this.multipleSelection.every((i) => +i.file_status >= 20)) {
          this.$messages("warning", this.$t("MyFiles.Projecttask3"));
          return;
        }
        path = "/ContainerHome/BatchTranscription";
        localName = "projectList_Transcription";
      } else if (first_type === "5") {
        //   校轴
        if (!this.multipleSelection.every((i) => +i.file_status >= 25)) {
          this.$messages("warning", this.$t("MyFiles.Projecttask9"));
          return;
        }
        path = "/ContainerHome/BatchTranscriptionQC";
        localName = "projectList_TranscriptionQC";
      }
      this.multipleSelection.forEach((i, index) => {
        arr.push({
          name: i.name,
          id: i.id,
          pid: i.task_url.split("=")[1].split("&")[0],
          fid: i.task_url.split("=")[2],
          task: "",
        });
      });
      localStorage.setItem(localName, JSON.stringify(arr));
      let pathInfo = this.$router.resolve({
        path,
        query: {
          pid: arr[0].pid,
          fid: arr[0].fid,
          id: arr[0].id,
        },
      });
      window.open(pathInfo.href, "_blank");
    },

    //文件以流的形式获取（参数url为文件链接地址）
    getImgArrayBuffer(url) {
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlhttp.send();
        xmlhttp = null;
      });
    },
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    this.time = [
      timestampToTime(start).split(" ")[0],
      timestampToTime(end).split(" ")[0],
    ];
    this.folder_pid = this.$route.query.folder_id
      ? this.$route.query.folder_id
      : 0;
    // console.log("this.folder_pid", this.folder_pid);
    this.$emit("header", true, true, true, true, true);
  },
  mounted() {
    console.log("mounted", this.$route.query.folder_id);
    showLoading(this.$t("MyFiles.loadingTips"));
    this.get_job_list();
    this.get_user_quota_info();
    this.user_info = JSON.parse(sessionStorage.getItem("info"));
    if (this.user_info.role == 1) {
      this.saveDays = 30;
    } else if (this.user_info.role == 2) {
      this.saveDays = 180;
    } else if (this.user_info.role == 3) {
      this.saveDays = 666;
    }
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 300;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 300;
    };
    window.addEventListener("keyup", this.windowkeyup, true);
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keyup ", this.windowkeyup, true);
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
};
</script>
<style lang="scss" scoped>
.mainCenterTop {
  // float: right;

  padding: 0 21px;
  height: 56px;
  background-color: #393c4e;
  line-height: 56px;

  .titleOption {
    line-height: 32px;
  }

  .mainHeader_left {
    display: flex;
    width: calc(100% - 300px);

    .signoutClass {
      width: 22px;
      height: 22px;
      border-radius: 2px;
      line-height: 22px;
      margin-right: 16px;
      cursor: pointer;
      color: #fff;
      .icon {
        font-size: 22px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      text-align: left;
      width: calc(100% - 48px);
      justify-content: space-between;
      .svgClass {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }

      .overflowp {
        width: 350px;
      }

      .line1 {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      .breadcrumb1 {
        width: calc(100% - 12px);
        font-size: 17px;
        ::v-deep .el-breadcrumb__item .el-breadcrumb__inner {
          color: #adb0bc;
        }

        ::v-deep .el-breadcrumb__item.current .el-breadcrumb__inner {
          color: #fff;
        }

        ::v-deep .el-breadcrumb__inner a {
          color: #adb0bc;

          &:hover {
            color: #46adff;
          }
        }
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }
}
.titlediv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .setpadding16 {
    margin-right: 24px;
  }
  .spanClass {
    margin-left: 8px;
  }
}
.addFolder {
  margin: 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}
.svgbtn {
  p {
    font-size: 14px;
  }
}
.contenttop {
  display: flex;
  padding: 30px 24px 8px 24px;
  justify-content: space-between;

  .inputClass {
    margin: 0 24px;
    width: 190px;

    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }

  .contenttop_left {
    display: flex;
  }

  .progress_class {
    ::v-deep .el-progress-bar__outer {
      width: 480px !important;
      background-color: rgba(255, 255, 255, 0.16);
    }

    .progress_class_title {
      color: #adb0bc;
      font-size: 12px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }

    .progress_class_title2 {
      .svgClass {
        font-size: 14px;
      }
    }
  }

  // .el-button {
  //   width: 74px;
  //   height: 32px;
  //   line-height: 32px;
  // }
}

.center {
  display: flex;
  padding: 0 0 0px 24px;

  .btntop {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .el-button {
  // width: 111px;
  // height: 32px;
  // line-height: 32px;
  // }
  .bombtn {
    // width: 86px !important;
    margin-left: 20px;
  }

  .ShiftIn {
    font-size: 16px;
    margin-right: 8px;
  }

  .delete {
    font-size: 16px;
    margin-right: 8px;
  }
}

.showColor {
  .svgbtn {
    .icon {
      color: #757784 !important;
    }

    .filep {
      color: #757784 !important;
    }
  }
}

.tablescope_rbtm_div {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
  }

  .filep {
    color: #46adff;
    font-size: 14px;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 18px !important;
    margin-right: 5px;
  }

  .svgiconclass1 {
    width: 18px !important;
    height: 18px !important;
    color: #46adff;
    margin-right: 5px;
  }
}

::v-deep .el-checkbox__inner {
  width: 14px;
  height: 14px;
  background-color: #393c4e !important;
  border: 1px solid;
  border-color: #7d7f8b;
  border-radius: 2px;
}

// ::v-deep .el-table-column--selection .cell {
//   padding-left: 10px;
// }
.tableheaderdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tableheader {
    width: 1px;
    height: 12px;
    background-color: #757784;
  }

  .icondiv {
    display: flex;
    align-items: center;

    .iconsize {
      font-size: 16px;
    }

    .tableheader {
      margin-left: 6px;
      width: 1px;
      height: 12px;
      background-color: #757784;
    }
  }
}

.tableheaderspan {
  float: right;
  display: block;
  width: 1px;
  height: 12px;
  background-color: #757784;
  margin-top: 10px;
}

// ::v-deep .popper__arrow {
//   left: 108px !important;
// }

.FileDetails {
  margin: 24px;

  .dialogcontent_body {
    .body {
      width: 841px;
      min-height: 40px;
      border: 1px solid;
      border-bottom: none;
      border-color: #515464;
      background-color: #393c4e;
      display: flex;
      align-items: center;
      font-size: 14px;
      //line-height: 40px;
      color: #ffffff;

      .left {
        padding-left: 12px;
        width: 176px;
        color: #adb0bc;
        height: 100%;
        background-color: #454758;
        border-right: 1px solid;
        border-color: #515464;
      }

      .right {
        padding-left: 12px;
      }

      .right1 {
        width: 245px;
      }

      .right_name {
        width: 650px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
        line-height: normal !important;
        min-height: 40px !important;
        display: flex;
        align-items: center;
      }
    }

    .body1 {
      border-bottom: 1px solid;
      border-color: #515464;
    }
  }
}

.Moveto {
  margin: 24px 16px;
  width: 412px;
  height: 248px;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  overflow-y: auto;
  padding: 12px 0;

  p {
    color: #cbd7e8;
    width: 100%;
    line-height: 24px;
  }

  .Moveto_content {
    .MovetoSvg {
      margin: 16px 0 0 16px;
      display: flex;
      align-items: center;

      .icon {
        font-size: 14px;
        margin-right: 6px;
      }
    }

    ul {
      li {
        padding: 0 16px;
        min-height: 35px;
        line-height: 35px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:focus {
          background: #46adff;

          .icon {
            margin-right: 6px;
            color: #ffffff;
          }

          p {
            color: #ffffff !important;
          }
        }

        .icon {
          margin-right: 6px;
          color: #f5df86;
        }
      }
    }
  }
}

.addFolder {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}

.tableClass {
  .statusClass {
    display: flex;
    align-items: center;

    .statusClass1 {
      width: 36px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      margin-right: 8px;

      p {
        color: #ffffff;
      }
    }

    p {
      color: #cbd7e8;
    }
  }

  .blueClass {
    background-color: #3b99e3;
  }
  .stqClass {
    background-color: #b888c4;
  }
  .greenClass {
    background-color: #41b293;
  }

  .yellowClass {
    background-color: #d48d47;
  }

  .grayClass {
    background-color: #585a6e;

    p {
      color: #888b9f !important;
    }
  }
}

.tabledropdowndiv {
  max-height: 300px;
  overflow-y: auto;
}
.moveProjectSearch {
  margin: 24px;
  ::v-deep .el-input__inner {
    width: 100%;
  }
}
.moveFolder {
  margin: 24px;
  height: 300px;
  overflow: auto;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  padding: 16px;

  .allProject {
    display: flex;
    height: 27px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #484a5a;
    }

    .svgiconclass1 {
      color: #cbd7e8;
      width: 18px !important;
      height: 21px !important;
      margin-right: 5px;
    }

    p {
      color: #cbd7e8;
      margin-bottom: 0px;
    }
  }

  .allProject.active {
    background-color: #46adff; //背景色
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-tree {
    background-color: #393c4e;

    .el-tree-node:focus > .el-tree-node__content {
      background-color: #393c4e; //背景色
    }
    .el-tree-node.is-current > .el-tree-node__content {
      background-color: #46adff;
    }
    .el-tree-node__content {
      &:hover {
        background: #484a5a;
      }
    }
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
  }

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
  .svgiconclass1 {
    color: #cbd7e8;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
}
</style>
<style lang="scss">
.messagebox_class {
  .el-message-box__message {
    padding: 0 !important;
  }
}
</style>
