/*
 * @Description: 通用 方法
 * @version:
 * @Author: Carful
 * @Date: 2022-11-23 14:42:56
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-20 14:02:37
 */
/**
 *
 * @param {超出溢出 显示} i
 */
export function onShowNameTipsMouseenter(i) {
  if (!i.id) {
    i.id = i.job_id;
  }
  var clientWidth = document.getElementById("tag" + i.id).clientWidth;
  var scrollWidth = document.getElementById("tag" + i.id).scrollWidth;
  // console.log(clientWidth, scrollWidth);
  if (clientWidth < scrollWidth) {
    // alert("已省略……");
    i.showdropdown = true;
  } else {
    i.showdropdown = false;
  }
}
/**
 *
 * @param {添加与替换 tags} obj
 * key: 接口参数名
 * value: 接口参数值
 */
export function addAndreplaceTags(ID, command, tags, key = "", value = 0) {
  let obj = {
    name: ID + " : " + command,
    type: "info",
    id: ID,
    key,
    value,
    showdropdown: false,
  };
  if (tags.length == 0) {
    tags.push(obj);
  } else {
    let b = tags.map((t) => {
      return t.id == obj.id;
    });
    if (b.includes(true)) {
      tags = tags.map((t) => {
        return t.id == obj.id ? obj : t;
      });
    } else {
      tags.push(obj);
    }
  }
  return tags;
}
/**
 *
 * @param {*复制} data 被复制内容
 */
export function copyClick(data) {
  let input = document.createElement("input"); // js创建一个input输入框
  input.value = data; // 将需要复制的文本赋值到创建的input输入框中
  document.body.appendChild(input); // 将输入框暂时创建到实例里面
  input.select(); // 选中输入框中的内容
  document.execCommand("Copy"); // 执行复制操作
  document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
}
/**
 *
 * @param {* 调整计算精度} num1
 * @param {*} num2
 */

export function numSub(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  var precision; // 精度
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2;
  return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}
/**
 *
 * @param {设置特殊字符} html
 */
export function filter_html_auto(html) {
  html = html.replace(/\{/g, "}");
  html = html.replace(/(&lt;i&gt;|&lt;\/i&gt;)/g, "");
  html = html
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&nbsp;/gi, " ")
    .replace(/(<i>|<\/i>)/g, "");
  return (html = html.replace(
    /(}\\an1}|}\\an2}|}\\an3}|}\\an4}|}\\an5}|}\\an6}|}\\an7}|}\\an8}|}\\an9})/g,
    ""
  ));
}
