var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":'1000px',"top":'10%',"center":"","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.closeDialog}},[_c('div',{staticClass:"GlossariesDialog"},[_c('div',{staticClass:"glo_top"},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadClick(6)}}},[_c('div',{staticClass:"buttondiv"},[_vm._v(" "+_vm._s(_vm.$t("ProjectDetails.dubbingText5"))+" ")])])],1),_c('div',{staticClass:"glo_bottom"},[_c('div',{staticClass:"tableClass borderTableStyle",class:_vm.tableData.length == 0 ? 'tablebtmborder' : ''},[_c('el-table',{staticStyle:{"width":"100%","overflow-y":"auto","border-right":"1px solid"},attrs:{"row-style":{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#cbd7e8',
          },"cell-style":{ padding: 0 + 'px' },"header-cell-style":{
            height: 32 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#393c4e',
          },"data":_vm.tableData,"tooltip-effect":"dark","max-height":"273px","border":""}},[_c('el-table-column',{attrs:{"prop":"job_name","label":_vm.$t('CommonName.TaskName')}}),_c('el-table-column',{attrs:{"prop":"job_dubbing_id","label":_vm.$t('ProjectDetails.dubbingText5'),"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"menuscopediv"},[(scope.row.icon5)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._v(" "+_vm._s(scope.row.text5)+" ")])]}}])})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }