<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Description: 
 * @version: 
 * @Author: Carful
 * @Date: 2023-04-25 15:29:24
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-25 09:23:15
-->
<template>
  <div
    class="bodydiv"
    id="bodydiv"
    :class="setBackground"
    @click="rowClick(source, $event, index)"
  >
    <div class="onediv borderdiv_body">
      {{ index + 1 }}
    </div>
    <div class="twodiv borderdiv_body">
      <div class="twodiv_timediv">
        <el-input
          v-model="source.start_time"
          class="timeInput boldinput"
          :class="source.showredStartTime ? 'setpColor' : ''"
          @blur="inputBlue(source, 'start')"
          v-if="source.showStartInput"
          v-focus
        ></el-input>

        <p
          v-else
          :class="source.showredStartTime ? 'setpColor' : ''"
          class="timeInputp"
          @click="cellPClick(source, 1)"
        >
          {{ source.start_time }}
        </p>
        <el-input
          v-model="source.end_time"
          class="timeInput boldinput"
          :class="source.showredEndTime ? 'setpColor' : ''"
          @blur="inputBlue(source, 'end')"
          v-if="source.showEndInput"
          v-focus
        ></el-input>
        <p
          v-else
          :class="source.showredEndTime ? 'setpColor' : ''"
          class="timeInputp"
          @click="cellPClick(source, 2)"
        >
          {{ source.end_time }}
        </p>
      </div>
    </div>
    <div class="threediv borderdiv_body">
      <p
        :class="
          Number(source.duration) < caption_info.min_duration ||
          Number(source.duration) > caption_info.max_duration
            ? 'setpColor'
            : ''
        "
      >
        {{ source.duration }}
      </p>
    </div>
    <div class="text sbtitlediv borderdiv_body">
      <div class="sbtitlediv_div">
        <div
          class="boldinput"
          :class="{
            sentence_top_div2: !dataInfo.base_info.show,
            sentence_top_div1: dataInfo.base_info.show,
            setFontstyle: dataInfo.base_info.slang_id == '20',
          }"
        >
          <!-- @keydown.native="SendEvent($event)" -->
          <!--          <el-input-->
          <!--            v-if="source.showsourceInput"-->
          <!--            type="textarea"-->
          <!--            :autosize="-->
          <!--              dataInfo.base_info.private_job_type !== '1'-->
          <!--                ? true-->
          <!--                : { minRows: 2 }-->
          <!--            "-->
          <!--            style="font-size: 12px"-->
          <!--            v-model="source.sentence_source"-->
          <!--            @keydown.native="SendEvent($event, source.sentence_source)"-->
          <!--            @blur="textareaBlue(source, $event, 1)"-->
          <!--            @focus="textareafocus(source.sentence_source, source, 1, $event)"-->
          <!--            @input="textareainput(source.sentence_source, source, 1, $event)"-->
          <!--            resize="none"-->
          <!--            :ref="source.sentence_id + 'textareasource'"-->
          <!--            v-focus-->
          <!--          ></el-input>-->
          <el-popover
            placement="top"
            width="300"
            v-model="popoverVisible"
            trigger="manual"
            ref="popover"
            :visible-arrow="false"
            popper-class="textareapopper"
            v-if="source.showsourceInput"
          >
            <div class="icon_countdiv">
              <!--              斜体-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.italic')"
                placement="top"
              >
                <div
                  @click="addTag('i', 1, source)"
                  @mousedown="(e) => e.preventDefault()"
                  class="icon_div"
                >
                  <svg-icon icon-class="qingxie" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              下划线-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.underline')"
                placement="top"
              >
                <div
                  @click="addTag('u', 1, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="xiahuaxian" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              加粗-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Bold')"
                placement="top"
              >
                <div
                  @click="addTag('b', 1, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="jiacu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              括号中文-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Fullangleparentheses')"
                placement="top"
              >
                <div
                  @click="addTag('1', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="parentheses_cn" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              括号 英文-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Halfangleparentheses')"
                placement="top"
              >
                <div
                  @click="addTag('2', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="parentheses_en" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--             方括号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.squarebrackets')"
                placement="top"
              >
                <div
                  @click="addTag('3', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon
                    icon-class="square_brackets"
                    class="icon"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!--              小音乐符号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.SmallMusicSymbol')"
                placement="top"
              >
                <div
                  @click="addTag('♪', 2, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="danyinfu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              大音乐符号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Bigmusicsymbols')"
                placement="top"
              >
                <div
                  @click="addTag('♫', 2, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="shuangyinfu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--                    清除样式-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.ClearStyle')"
                placement="top"
              >
                <div
                  @click="clearTags(source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="cleartag" class="icon"></svg-icon>
                </div>
              </el-tooltip>
            </div>
            <textarea
              v-if="source.showsourceInput"
              style="font-size: 15px"
              :class="
                dataInfo.base_info.slang_id == '2' ? 'fontfamilyClass' : ''
              "
              :rows="!dataInfo.base_info.show ? 1 : 2"
              v-model="source.sentence_source"
              @keydown="SendEvent($event, source.sentence_source)"
              :ref="source.sentence_id + 'textareasource'"
              slot="reference"
              @mouseup="textareamouseup($event, 1, source)"
              @mousedown="textareamousedown"
              @blur="textareaBlue(source, $event, 1)"
              @focus="textareafocus(source.sentence_source, source, 1, $event)"
              @input="textareainput(source.sentence_source, source, 1, $event)"
              v-focus
            ></textarea>
          </el-popover>
          <!-- @paste.native="
              handlePaste(
                $event,
                source,
                1,
                source.sentence_id + 'textareasource'
              )
            " -->
          <p
            :class="{
              statusno30tablep1: !dataInfo.base_info.show,
              statusno30tablep: dataInfo.base_info.show,
              fontfamilyClass: dataInfo.base_info.slang_id == '2',
            }"
            v-else
            v-html="
              source.sentence_source_find !== ''
                ? source.sentence_source_find
                : source.sentence_source_html
            "
            @click="cellPClick(source, 3)"
          ></p>
        </div>
        <div
          class="sentence_btm_div boldinput"
          :class="dataInfo.base_info.tlang_id == '20' ? 'setFontstyle' : ''"
          v-if="!dataInfo.base_info.show"
        >
          <!--          <el-input-->
          <!--            v-if="source.showtransInput"-->
          <!--            type="textarea"-->
          <!--            autosize-->
          <!--            style="font-size: 12px"-->
          <!--            v-model="source.sentence_trans"-->
          <!--            @keydown.native="SendEvent($event, source.sentence_trans)"-->
          <!--            @blur="textareaBlue(source, $event, 2)"-->
          <!--            @focus="textareafocus(source.sentence_trans, source, 2, $event)"-->
          <!--            @input="textareainput(source.sentence_trans, source, 2, $event)"-->
          <!--            resize="none"-->
          <!--            :ref="source.sentence_id + 'textareatrans'"-->
          <!--            @paste.native="-->
          <!--              handlePaste(-->
          <!--                $event,-->
          <!--                source,-->
          <!--                2,-->
          <!--                source.sentence_id + 'textareatrans'-->
          <!--              )-->
          <!--            "-->
          <!--            v-focus-->
          <!--          >-->
          <!--          </el-input>-->
          <el-popover
            placement="top"
            width="300"
            v-model="popoverVisible"
            trigger="manual"
            ref="popover"
            :visible-arrow="false"
            popper-class="textareapopper"
            v-if="source.showtransInput"
          >
            <div class="icon_countdiv">
              <!--              斜体-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.italic')"
                placement="top"
              >
                <div
                  @click="addTag('i', 1, source)"
                  @mousedown="(e) => e.preventDefault()"
                  class="icon_div"
                >
                  <svg-icon icon-class="qingxie" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              下划线-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.underline')"
                placement="top"
              >
                <div
                  @click="addTag('u', 1, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="xiahuaxian" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              加粗-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Bold')"
                placement="top"
              >
                <div
                  @click="addTag('b', 1, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="jiacu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              括号中文-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Fullangleparentheses')"
                placement="top"
              >
                <div
                  @click="addTag('1', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="parentheses_cn" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              括号 英文-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Halfangleparentheses')"
                placement="top"
              >
                <div
                  @click="addTag('2', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="parentheses_en" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--             方括号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.squarebrackets')"
                placement="top"
              >
                <div
                  @click="addTag('3', 3, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon
                    icon-class="square_brackets"
                    class="icon"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!--              小音乐符号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.SmallMusicSymbol')"
                placement="top"
              >
                <div
                  @click="addTag('♪', 2, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="danyinfu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--              大音乐符号-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.Bigmusicsymbols')"
                placement="top"
              >
                <div
                  @click="addTag('♫', 2, source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="shuangyinfu" class="icon"></svg-icon>
                </div>
              </el-tooltip>
              <!--                    清除样式-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('SubtitleStyle.ClearStyle')"
                placement="top"
              >
                <div
                  @click="clearTags(source)"
                  class="icon_div"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon icon-class="cleartag" class="icon"></svg-icon>
                </div>
              </el-tooltip>
            </div>
            <textarea
              style="font-size: 15px"
              :rows="!dataInfo.base_info.show ? 1 : 2"
              v-model="source.sentence_trans"
              :class="
                dataInfo.base_info.tlang_id == '2' ? 'fontfamilyClass' : ''
              "
              @keydown="SendEvent($event, source.sentence_trans)"
              @blur="textareaBlue(source, $event, 2)"
              @focus="textareafocus(source.sentence_trans, source, 2, $event)"
              @input="textareainput(source.sentence_trans, source, 2, $event)"
              :ref="source.sentence_id + 'textareatrans'"
              slot="reference"
              @mouseup="textareamouseup($event, 2, source)"
              @mousedown="textareamousedown"
              v-focus
            ></textarea>
          </el-popover>
          <p
            class="statusno30tablep1 boldinput"
            :class="dataInfo.base_info.tlang_id == '2' ? 'fontfamilyClass' : ''"
            v-else
            @click="cellPClick(source, 4)"
            v-html="
              source.sentence_trans_find !== ''
                ? source.sentence_trans_find
                : source.sentence_trans_html
            "
          ></p>
        </div>
      </div>
    </div>
    <div class="sixdiv borderdiv_body">
      <div class="sixdiv_div">
        <div v-if="dataInfo.base_info.show">
          <div v-if="source.sentence_source !== ''">
            <div
              v-if="
                filter_html_auto(source.sentence_source).split('\n').length > 1
              "
            >
              <p :class="source.charNumbererr1 ? 'setpColor' : ''">
                {{
                  "L1: " +
                  filter_html_auto(source.sentence_source).split("\n")[0].length
                }}
              </p>
              <p
                v-if="
                  filter_html_auto(source.sentence_source).split('\n').length >
                  1
                "
                :class="source.charNumbererr2 ? 'setpColor' : ''"
              >
                {{
                  "L2: " +
                  filter_html_auto(source.sentence_source).split("\n")[1].length
                }}
              </p>
            </div>

            <div v-else :class="source.charNumbererr ? 'setpColor' : ''">
              <p>
                {{ "L1: " + filter_html_auto(source.sentence_source).length }}
              </p>
            </div>
          </div>
          <p v-else>
            {{ "L1: " + filter_html_auto(source.sentence_source).length }}
          </p>
        </div>
        <div v-else>
          <div v-if="source.charNumber !== ''">
            <div v-if="source.charNumber.split('\n').length > 1">
              <p :class="source.charNumbererr1 ? 'setpColor' : ''">
                {{ "L1: " + source.charNumber.split("\n")[0].length }}
              </p>
              <p
                v-if="source.charNumber.split('\n').length > 1"
                :class="source.charNumbererr2 ? 'setpColor' : ''"
              >
                {{ "L2: " + source.charNumber.split("\n")[1].length }}
              </p>
            </div>
            <div v-else :class="source.charNumbererr ? 'setpColor' : ''">
              <p>
                {{ "L1: " + source.charNumber.length }}
              </p>
            </div>
          </div>
          <div v-else>
            <p>
              {{ "L1: " + source.charNumber.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="lastdiv borderdiv_body">
      <p :class="source.readingNumbererr ? 'setpColor' : ''">
        {{ source.readingNumber }}
      </p>
    </div>
  </div>
</template>
<script>
import mixins from "@/utils/dispathIndex";
export default {
  name: "VideoDetialsItem",
  mixins: [mixins],
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    caption_info: {
      type: Object,
      default() {
        return {};
      },
    },
    dataInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    currentRegion: {
      type: Object,
      default() {
        return {};
      },
    },
    selectionRow: {
      type: Array,
      default() {
        return [];
      },
    },
    ShiftDown: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    /**
     *
     * @param {* 输入框获取焦点} el
     */
    focus: {
      inserted: (el) => {
        // console.log(1111);
        // if (el.querySelector("textarea")) {
        //   el.querySelector("textarea").focus();
        // } else {
        //   el.querySelector("input").focus();
        // }
        if (el.tagName.toLowerCase() === "textarea") {
          el.focus();
        } else {
          el.querySelector("input").focus();
        }
      },
    },
  },
  data() {
    return {
      // checkboxValue:{}
      color: "",
      popoverVisible: false,
      selectedText: "",
      selectionStart: 0,
      selectionEnd: 0,
    };
  },
  created() {},
  mounted() {
    // console.log(this.dataInfo.base_info.status);
  },
  computed: {
    setBackground() {
      // if (this.currentRegion.sentence_id == this.source.sentence_id) {
      //   return "backgroundtrue";
      // } else {
      //   return "";
      // }
      // console.log(this.selectionRow);
      let classNamw = "";
      // if (this.ShiftDown) {
      this.selectionRow.forEach((i) => {
        // console.log(i.sentence_id);
        if (i.sentence_id == this.source.sentence_id) {
          classNamw = "backgroundtrue";
        }
      });
      // } else {
      //   if (this.currentRegion.sentence_id == this.source.sentence_id) {
      //     classNamw = "backgroundtrue";
      //   } else {
      //     classNamw = "";
      //   }
      // }

      return classNamw;
    },
  },
  methods: {
    /**
     *
     * @param {*复制粘贴 取消换行} event
     */
    handlePaste(event, row, data, newref) {
      // console.log(data);
      event.preventDefault(); // 阻止默认粘贴行为、
      const clipboardData = event.clipboardData || window.clipboardData;
      const text = clipboardData.getData("text/plain");
      // 处理粘贴的文本
      const processedText = text.replace(/(\r\n|\n|\r)/g, ""); // 取消换行
      // const cursorPosition = event.srcElement.selectionStart; // 获取光标位置

      const textarea = this.$refs[newref].$refs.textarea;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const value = textarea.value;
      const newValue =
        value.substring(0, selectionStart) +
        processedText +
        value.substring(selectionEnd);
      textarea.value = newValue;
      textarea.setSelectionRange(
        selectionStart + processedText.length,
        selectionStart + processedText.length
      );
      this.$refs[newref].$emit("input", newValue);
      // this.dispatch(
      //   "VideoDetails",
      //   "handlePaste",
      //   row,
      //   data,
      //   processedText,
      //   cursorPosition
      // );
      // this.$nextTick(() => {
      //   const newCursorPosition = cursorPosition + processedText.length;
      //   const input = this.$refs[newref].$refs.textarea; // 获取输入框元素
      //   input.selectionStart = newCursorPosition;
      //   input.selectionEnd = newCursorPosition;
      // });
    },
    /**
     *
     * @param {设置特殊字符} html
     */
    filter_html_auto(html) {
      html = html.replace(/\{/g, "}");
      html = html.replace(/(&lt;i&gt;|&lt;\/i&gt;)/g, "");
      html = html
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&nbsp;/gi, " ")
        .replace(/(<i>|<\/i>)/g, "")
        .replace(/(<u>|<\/u>)/g, "")
        .replace(/(<b>|<\/b>)/g, "");
      return (html = html.replace(
        /(}\\an1}|}\\an2}|}\\an3}|}\\an4}|}\\an5}|}\\an6}|}\\an7}|}\\an8}|}\\an9})/g,
        ""
      ));
    },
    /**
     *   @keyup.native="SendEvent($event)"
     * @param {*阻止 换行行为} event
     * @param {*} data
     */
    SendEvent(event, data) {
      // console.log(11111);
      if (!data) {
        return;
      }
      if (data.split("\n").length > 1) {
        if (event.keyCode == 13) {
          event.returnValue = false;
          return false;
        }
      } else {
        if (event.keyCode == 13) {
          event.returnValue = true;
          return true;
        }
      }
    },
    /**
     *
     * @param {*表格内 输入框获取焦点} item
     */
    setInputfocus(item) {
      if (item.data == 1) {
        this.$nextTick(() => {
          this.$refs[item.sentence_id + "textareasource"].focus();
        });
      } else {
        this.$nextTick(() => {
          //   console.log(this.$refs[item.sentence_id + "textareatrans"]);
          this.$refs[item.sentence_id + "textareatrans"].focus();
        });
      }
    },
    /**
     *
     * @param {点击表格} row
     */
    rowClick(row, e, index) {
      //   console.log("rowclick");
      row.index = index;
      this.dispatch("VideoDetails", "rowClick", row);
    },
    /**
     *
     * @param {* 点击文字  显示输入框} row
     */
    cellPClick(row, data) {
      this.dispatch("VideoDetails", "cellPClick", row, data);
      // if (this.source.showsourceInput) {
      //   this.setInputfocus(row);
      // }
    },
    /**
     * 设置 输入框高度
     * @param row
     */
    setTextareaHeight(row) {
      this.$nextTick(() => {
        let textarea;
        if (row.data == 1) {
          textarea = this.$refs[row.sentence_id + "textareasource"];
        } else {
          textarea = this.$refs[row.sentence_id + "textareatrans"];
        }

        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        // console.log(textarea.style.height);
      });
    },
    /**
     *
     * @param {表格内输入框 失去焦点} val
     * @param {*} row
     */
    textareaBlue(row, e, data) {
      this.popoverVisible = false;
      this.selectedText = "";
      this.dispatch("VideoDetails", "textareaBlue", row, e, data);
    },
    /**
     *  获取焦点
     * @param val
     * @param row
     * @param data
     * @param event
     */
    textareafocus(val, row, data, event) {
      this.dispatch("VideoDetails", "textareafocus", val, row, data, event);
      this.setTextareaHeight(row);
    },
    textareamousedown() {
      window.getSelection().empty();
      // this.popoverVisible = false;
    },
    /**
     *选中文字
     * @param e
     */
    textareamouseup(e, data, row) {
      //阻止事件冒泡
      // console.log(11111);
      let textarea = "";
      if (data == 1) {
        textarea = this.$refs[row.sentence_id + "textareasource"];
        this.selectionStart = textarea.selectionStart;
        this.selectionEnd = textarea.selectionEnd;
        this.selectedText = row.sentence_source.substring(
          this.selectionStart,
          this.selectionEnd
        );
      } else {
        textarea = this.$refs[row.sentence_id + "textareatrans"];
        this.selectionStart = textarea.selectionStart;
        this.selectionEnd = textarea.selectionEnd;
        this.selectedText = row.sentence_trans.substring(
          this.selectionStart,
          this.selectionEnd
        );
      }
      if (this.selectedText) {
        this.popoverVisible = true;
      } else {
        this.popoverVisible = false;
      }
    },
    getSelectedText() {
      if (window.getSelection) {
        // console.log(window.getSelection.toString(), "window.getSelection");
        return window.getSelection().toString();
      } else if (document.selection && document.selection.type !== "Control") {
        // console.log(document.selection.createRange().text, "2");
        return document.selection.createRange().text;
      }
      return "";
    },
    /**
     *
     * @param {textarea输入时} val
     * @param {*} row
     */
    textareainput(val, row, data) {
      this.setTextareaHeight(row);
      this.popoverVisible = false;
      this.selectedText = "";
      this.dispatch("VideoDetails", "textareainput", val, row, data);
    },

    /**
     *
     * @param {*格内输入框 失去焦点   时间修改  输入框} row
     * @param {*start表示开始时间 end 表示结束时间} data
     */
    inputBlue(row, data) {
      // console.log(22222);
      this.dispatch("VideoDetails", "inputBlue", row, data);
    },
    /**
     * 字段 增加标签
     * @param tag
     * @param flag
     * @param row
     */
    addTag(tag, flag, row) {
      if (this.selectedText) {
        this.dispatch(
          "VideoDetails",
          "addTag",
          tag,
          flag,
          row,
          this.selectedText,
          this.selectionStart,
          this.selectionEnd
        );
        this.popoverVisible = false;
        this.setTextareaHeight(row);
      }
    },
    /**
     * 句段清除标签
     * @param row
     */
    clearTags(row) {
      this.dispatch("VideoDetails", "clearTags", row);
      this.popoverVisible = false;
      this.setTextareaHeight(row);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./VideoDetails.scss";
.bodydiv {
  display: flex;
  // align-items: center;
  font-size: 13px;
  // height: 100%;
  box-sizing: border-box;
  min-height: 58px !important;
  color: rgb(203, 215, 232) !important;
}
.setFontstyle {
  direction: rtl !important;
  ::v-deep .el-textarea__inner {
    direction: rtl !important;
  }
}
textarea {
  padding: 6px !important;
  width: 100%;
  //height: 100%;
  border-radius: 0px !important;
  box-sizing: border-box;
  resize: none;
  border: none !important;
  overflow-y: hidden;
  display: flex;
}
/**
 鼠标划上 输入框 弹出框样式
 */
.icon_countdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon_div {
    width: 28px;
    height: 28px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    .icon {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background: #46adff;
      cursor: pointer;
    }
  }
}
.fontfamilyClass {
  font-family: "Titillium Web2", "sans-serif" !important;
  line-height: 22px;
}
</style>
