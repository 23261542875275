<!--
 * @Description: 术语库管理 操作记录
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-25 10:13:06
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-18 09:14:10
-->
<template>
  <div class="content">
    <div class="title_div">
      <div class="title_content_div">
        <div class="logo"></div>
        <div class="title_p">{{ title }}</div>
        <div class="title_right">
          <div style="display: flex; align-items: center">
            <div class="avaterdiv">
              <el-avatar :size="32" :src="info.head" fit="cover"></el-avatar>
            </div>
            <p class="dropdowmtitlep">
              {{ info.nickname }}
            </p>
          </div>
          <!-- 提交 -->
          <!-- <el-button type="primary"  class="setpadding23" @click="SubmitClick"
            >{{ $t("TeamOperation.Submit") }}
          </el-button> -->
        </div>
      </div>
    </div>
    <!-- <div>
      <el-button type="primary" @click="cancelClick()"
        >{{ $t("CommonName.deleteMessageClosebutton") }}
      </el-button>
    </div> -->
    <div
      id="luckysheet"
      style="
        margin: 0px;
        padding: 0px;
        position: absolute;
        width: 100%;
        height: calc(100% - 56px);
        left: 0px;
        top: 56px;
      "
    ></div>
  </div>
</template>
<script>
import { get_language, get_user_info, get_term_info } from "@/utils/https";
import $ from "jquery";
import config from "@/config";
import { showLoading, hideLoading } from "@/utils/loading";
export default {
  created() {
    this.gridKey = this.$route.query.id;
    this.get_language();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_user_info();
    if (this.$route.query.lang_mode == 1) {
      this.language_code = this.$Cookie.get("lang") == "en" ? "en" : "zh";
    } else {
      this.language_code = this.$Cookie.get("rlang") == "en" ? "en" : "zh";
    }
  },
  data() {
    return {
      baseUrl: config.baseUrl.dev, //请求地址
      title: "",
      id: "",
      wbId: "1",
      user_id: "1",
      gridKey: "1", //表格标识id
      sheet_index: "sheet_01", //工作表下标
      beforeunloadTime: 0,
      unloadTime: 0,
      updateUrl: "",
      WebSocketUrl: config.WebSocketUrl.dev, //请求地址
      languageList: [], //请求地址
      info: {},
      language_code: "cn",
    };
  },
  methods: {
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language_code",
      }).then((res) => {
        if (res) {
          this.languageList = res.list;
        }
      });
    },
    /**
     * 获取 基础数据
     */
    get_term_info(data) {
      return get_term_info({
        action: "get_term_set_info",
        term_file_id: data,
      }).then((res) => {
        hideLoading();
        if (res) {
          this.title = res.info.name;
          this.luckySheetInit();
        }
      });
    },
    cancelClick() {
      window.luckysheet.closeWebsocket();
    },
    beforeunloadHandler() {
      this.beforeunloadTime = new Date().getTime();
    },
    unload() {
      this.unloadTime = new Date().getTime();
      // window.localStorage.setItem('timer', String(this.unloadTime - this.beforeunloadTime))
      // 本地通过localStorage中的数据看出,关闭事件间隔小于1,刷新则大于8
      if (this.unloadTime - this.beforeunloadTime <= 5) {
        // 执行退出登录
        console.log("用户退出");
        window.luckysheet.closeWebsocket();
        alert("退出成功");
      }
    },
    luckySheetInit() {
      let that = this;
      var options = {
        loading: {}, //自定义loading
        column: 20, //空表格默认的列数量
        row: 2000, //空表格默认的行数据量
        allowCopy: true, //是否允许拷贝
        showtoolbar: true, //是否第二列显示工具栏
        showinfobar: false, //是否显示顶部名称栏
        showsheetbar: true, //是否显示底部表格名称区域
        showstatisticBar: false, //是否显示底部计数栏
        pointEdit: false, //是否是编辑器插入表格模式
        pointEditUpdate: null, //编辑器表格更新函数
        pointEditZoom: 1, //编辑器表格编辑时缩放比例
        // menu: "undo|redo|freezenrow|freezencolumn|download|share|chart|pivot",
        data: [
          {
            name: "Sheet1",
            color: "",
            status: "1",
            order: "0",
            data: [],
            config: {},
            index: 0,
          },
          {
            name: "Sheet2",
            color: "",
            status: "0",
            order: "1",
            data: [],
            config: {},
            index: 1,
          },
          {
            name: "Sheet3",
            color: "",
            status: "0",
            order: "2",
            data: [],
            config: {},
            index: 2,
          },
        ], //客户端sheet数据[sheet1, sheet2, sheet3]
        title: "Luckysheet Demo", //表格的名称
        userInfo: false, // 右上角的用户信息展示样式，支持 1. boolean类型：false:不展示，true:展示默认 '<i style="font-size:16px;color:#ff6a00;" class="fa fa-taxi" aria-hidden="true"></i> rabbit' ，2. HTML模板字符串或者普通字符串，如：'<i style="font-size:16px;color:#ff6a00;" class="fa fa-taxi" aria-hidden="true"></i> Lucky'或者'用户名'， 3. 对象格式，设置 userImage：用户头像地址 和 userName：用户名 4. 不设置或者设置undefined同设置false
        userMenuItem: [
          {
            url: "www.baidu.com",
            icon: '<i class="fa fa-folder" aria-hidden="true"></i>',
            name: "我的表格",
          },
          {
            url: "www.baidu.com",
            icon: '<i class="fa fa-sign-out" aria-hidden="true"></i>',
            name: "退出登陆",
          },
        ], //点击右上角的用户信息弹出的菜单
        myFolderUrl: "www.baidu.com", //左上角<返回按钮的链接
        config: {}, //表格行高、列宽、合并单元格、公式等设置
        fullscreenmode: true, //是否全屏模式，非全屏模式下，标记框不会强制选中。
        devicePixelRatio: window.devicePixelRatio, //设备比例，比例越大表格分标率越高
        allowEdit: true, //是否允许前台编辑
        loadUrl: `${this.baseUrl}/term/get_term_sheets_load`, // 配置loadUrl的地址，luckysheet会通过ajax请求表格数据，默认载入status为1的sheet数据中的所有data，其余的sheet载入除data字段外的所有字段
        loadSheetUrl: `${this.baseUrl}/term/get_term_sheet_load`, //配置loadSheetUrl的地址，参数为gridKey（表格主键） 和 index（sheet主键合集，格式为[1,2,3]），返回的数据为sheet的data字段数据集合
        gridKey: this.gridKey, // 表格唯一标识符
        updateUrl: this.updateUrl, //表格数据的更新地址
        updateImageUrl: "", //缩略图的更新地址
        allowUpdate: true, //是否允许编辑后的后台更新
        functionButton: "", //右上角功能按钮，例如'<button id="" class="btn btn-primary" style="padding:3px 6px;font-size: 12px;margin-right: 10px;">下载</button>    <button id="" class="btn btn-primary btn-danger" style="    padding:3px 6px;    font-size: 12px;    margin-right: 10px;">分享</button>    <button id="luckysheet-share-btn-title" class="btn btn-primary btn-danger" style="    padding:3px 6px;    font-size: 12px;    margin-right: 10px;">秀数据</button>'
        showConfigWindowResize: true, //图表和数据透视表的配置会在右侧弹出，设置弹出后表格是否会自动缩进
        enableAddRow: true, //允许添加行
        enableAddBackTop: true, //允许回到顶部
        // enablePage: false,//允许加载下一页
        autoFormatw: false, //自动格式化超过4位数的数字为 亿万格式 例：true or "true" or "TRUE"
        accuracy: undefined, //设置传输来的数值的精确位数，小数点后n位 传参数为数字或数字字符串，例： "0" 或 0
        pageInfo: {
          queryExps: "",
          reportId: "",
          fields: "",
          mobile: "",
          frezon: "",
          currentPage: "",
          totalPage: 10,
          pageUrl: "",
        },
        editMode: false, //是否为编辑模式
        beforeCreateDom: null, //表格创建之前的方法
        fireMousedown: null, //单元格数据下钻
        lang: this.language_code, //language
        plugins: [], //plugins, e.g. ['chart']
        forceCalculation: false, //强制刷新公式，公式较多会有性能问题，慎用
        rowHeaderWidth: 46,
        columnHeaderHeight: 20,
        defaultColWidth: 73,
        defaultRowHeight: 19,
        defaultFontSize: 12,
        limitSheetNameLength: true, //是否限制工作表名的长度
        defaultSheetNameMaxLength: 31, //默认工作表名称的最大长度
        sheetFormulaBar: false, //是否显示公式栏
        showtoolbarConfig: {
          undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          paintFormat: true, //格式刷
          currencyFormat: false, //货币格式
          percentageFormat: false, //百分比格式
          numberDecrease: false, // '减少小数位数'
          numberIncrease: false, // '增加小数位数
          moreFormats: false, // '更多格式'
          font: true, // '字体'
          fontSize: true, // '字号大小'
          bold: true, // '粗体 (Ctrl+B)'
          italic: true, // '斜体 (Ctrl+I)'
          strikethrough: true, // '删除线 (Alt+Shift+5)'
          underline: true, // '下划线 (Alt+Shift+6)'
          textColor: true, // '文本颜色'
          fillColor: true, // '单元格颜色'
          border: true, // '边框'
          mergeCell: false, // '合并单元格'
          horizontalAlignMode: true, // '水平对齐方式'
          verticalAlignMode: true, // '垂直对齐方式'
          textWrapMode: true, // '换行方式'
          textRotateMode: true, // '文本旋转方式'
          image: false, // '插入图片'
          link: false, // '插入链接'
          chart: false, // '图表'（图标隐藏，但是如果配置了chart插件，右击仍然可以新建图表）
          postil: false, //'批注'
          pivotTable: false, //'数据透视表'
          function: false, // '公式'
          frozenMode: false, // '冻结方式'
          sortAndFilter: false, // '排序和筛选'
          conditionalFormat: false, // '条件格式'
          dataVerification: false, // '数据验证'
          splitColumn: false, // '分列'
          screenshot: false, // '截图'
          findAndReplace: false, // '查找替换'
          protection: false, // '工作表保护'
          print: false, // '打印'
        }, //自定义工具栏
        showsheetbarConfig: {
          add: true, //新增sheet
          menu: true, //sheet管理菜单
          sheet: true, //sheet页显示
        }, //自定义底部sheet页
        showstatisticBarConfig: {
          count: false, // 计数栏
          view: true, // 打印视图
          zoom: true, // 缩放
        }, //自定义计数栏
        cellRightClickConfig: {
          copy: false, // 复制
          copyAs: false, // 复制为
          paste: false, // 粘贴
          insertRow: false, // 插入行
          insertColumn: false, // 插入列
          deleteRow: false, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: false, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: false, // 行高
          columnWidth: false, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: false, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false, // 设置单元格格式
        }, //自定义单元格右键菜单
        sheetRightClickConfig: {
          delete: true, // 删除
          copy: false, // 复制
          rename: true, //重命名
          color: true, //更改颜色
          hide: false, //隐藏，取消隐藏
          move: false, //向左移，向右移
        }, //自定义底部sheet页右击菜单
        imageUpdateMethodConfig: {}, //自定义图片同步方式
        hook: {
          cellDragStop: function (cell, postion, sheetFile, ctx, event) {
            // console.info(cell, postion, sheetFile, ctx, event);
          },
          rowTitleCellRenderBefore: function (rowNum, postion, ctx) {
            // console.log(rowNum);
          },
          rowTitleCellRenderAfter: function (rowNum, postion, ctx) {
            // console.log(ctx);
          },
          columnTitleCellRenderBefore: function (columnAbc, postion, ctx) {
            // console.log(columnAbc);
          },
          columnTitleCellRenderAfter: function (columnAbc, postion, ctx) {
            // console.log(postion);
          },
          cellRenderBefore: function (cell, postion, sheetFile, ctx) {
            // console.log(cell, postion, sheetFile, ctx);
            // return false;
          },
          cellRenderAfter: function (cell, postion, sheetFile, ctx) {
            // console.log(postion);
          },
          cellMousedownBefore: function (cell, postion, sheetFile, ctx) {
            // console.log(postion);
          },
          cellMousedown: function (cell, postion, sheetFile, ctx) {
            // console.log(sheetFile);
          },
          sheetMousemove: function (cell, postion, sheetFile, moveState, ctx) {
            // console.log(cell,postion,sheetFile,moveState,ctx);
          },
          sheetMouseup: function (cell, postion, sheetFile, moveState, ctx) {
            // console.log(cell,postion,sheetFile,moveState,ctx);
          },
          cellAllRenderBefore: function (data, sheetFile, ctx) {
            // console.info(data,sheetFile,ctx)
          },
          updated: function (operate) {
            // console.log("updated");
            // console.info(operate);
            // console.info(window.luckysheet.getAllSheets());
          },
          cellUpdateBefore: function (r, c, value, isRefresh) {
            // console.info("cellUpdateBefore", r, c, value, isRefresh);
            if (!that.cellUpdateBeforeModify(r, c, value)) {
              return false;
            }
          },
          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            // console.info('cellUpdated',r,c,oldValue, newValue, isRefresh)
          },
          sheetActivate: function (index, isPivotInitial, isNewSheet) {
            console.info("sheetActivate", index, isPivotInitial, isNewSheet);
            that.sheet_index = index;
            // window.luckysheet.setRangeShow("A0", { show: false });
          },
          rangeSelect: function (index, sheet) {
            // console.info(index, sheet)
          },
          commentInsertBefore: function (r, c) {
            // console.info(r, c)
          },
          commentInsertAfter: function (r, c, cell) {
            // console.info(r, c, cell)
          },
          commentDeleteBefore: function (r, c, cell) {
            // console.info(r, c, cell)
          },
          commentDeleteAfter: function (r, c, cell) {
            // console.info(r, c, cell)
          },
          commentUpdateBefore: function (r, c, value) {
            // console.info(r, c, value)
          },
          commentUpdateAfter: function (r, c, oldCell, newCell) {
            // console.info(r, c, oldCell, newCell)
          },
          cellEditBefore: function (range) {
            // console.log("cellEditBefore");
            // console.info(range);
          },
          workbookCreateAfter: function (json) {
            console.info("workbook create after!!!!====", json);
          },
          rangePasteBefore: function (range, data, copy_range) {
            if (!that.cellPasteBeforeModify(range, data, copy_range)) {
              return false;
            }
            console.log("成功粘贴");
            return true; //Can intercept paste
          },
          rangeDeleteBefore: function (range, data) {
            console.info(111);
          },
          cellClearBefore: function (range) {
            if (!that.cellClearBeforeModify(range)) {
              return false;
            }
            return true;
          },
          sheetDeleteBefore: function (data) {
            console.log("data", data);
            if (
              data.sheet.index == "sheet_01" ||
              data.sheet.index == "sheet_02"
            ) {
              that.$messages(
                "warning",
                that.$t("TermBaseManagement.warningmessage10")
              );
              return false;
            }
          },
          sheetEditNameBefore: function (data) {
            // console.log("sheetEditNameBefore", data);
            // if (data == "3") {
            //   return true;
            // }
            // that.$messages(
            //   "warning",
            //   that.$t("TermBaseManagement.warningmessage10")
            // );
            // return false;
          },
        },
      };
      window.luckysheet.create(options);
    },
    //单元格编辑验证
    cellUpdateBeforeModify(r, c, value) {
      // console.log("lang", window.luckysheet.getCellValue(0, c));
      if (this.sheet_index != "sheet_01" && this.sheet_index != "sheet_02") {
        return true;
      }
      console.log("value", value);
      //create_term_set
      if (r == 0) {
        //不能在首行 前三列 修改内容
        if (c <= 2) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage9")
          );
          return false;
        }
        //第一行内容 必须是lang_code
        if (
          this.languageList.findIndex((itemd) => itemd.lang_code == value) == -1
        ) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage5")
          );
          return false;
        }
        //查询当前行 看是否有重复的lang_code
        let options = {
          isWholeWord: true,
          rowIndex: r,
        };
        let arr = window.luckysheet.find(value, options);
        console.log(arr);
        if (arr.length > 0 && arr[0]["column"] > 2 && arr[0]["column"] != c) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage13") +
              (+arr[0]["column"] + 1) +
              this.$t("TermBaseManagement.warningmessage14")
          );
          return false;
        }
      } else {
        if (value == "") {
          return true;
        }
        //从第4列开始
        if (c > 2) {
          //需判断当前列第一行内容是否不为空
          let lang_r = window.luckysheet.getCellValue(0, c);
          if (lang_r === null || lang_r === "") {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage6")
            );
            return false;
          }
        }
        //仅校验第四列 4/30
        if (c == 3) {
          //查询当前列 看是否有重复的术语
          let options = {
            isWholeWord: true,
            colIndex: c,
          };
          let arr = window.luckysheet.find(value, options);

          if (arr.length > 0 && arr[0]["row"] != 0 && arr[0]["row"] != r) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage11") +
                (+arr[0]["row"] + 1) +
                this.$t("TermBaseManagement.warningmessage12")
            );
            return false;
          }
        }
      }
      return true;
    },
    //单元格清空内容验证
    cellClearBeforeModify(range) {
      // console.log("range", range);
      if (this.sheet_index != "sheet_01" && this.sheet_index != "sheet_02") {
        return true;
      }
      let row = range[0]["row"];
      let column = range[0]["column"];
      if (row[0] == 0) {
        //不能在首行 前三列 修改内容
        if (column[0] <= 2) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage9")
          );
          return false;
        }
      }
      return true;
    },
    //单元格粘贴内容验证
    cellPasteBeforeModify(range, txtdata, copy_range) {
      if (this.sheet_index != "sheet_01" && this.sheet_index != "sheet_02") {
        return true;
      }
      if (txtdata.indexOf("table") > -1) {
        $("#luckysheet-copy-content").html(txtdata);
        let isnull = false;
        let data = new Array(
          $("#luckysheet-copy-content").find("table tr").length
        );
        let colLen = 0;
        const cellElements = "th, td";
        $("#luckysheet-copy-content")
          .find("table tr")
          .eq(0)
          .find(cellElements)
          .each(function () {
            let colspan = parseInt($(this).attr("colspan"));
            if (isNaN(colspan)) {
              colspan = 1;
            }
            colLen += colspan;
          });

        for (let i = 0; i < data.length; i++) {
          data[i] = new Array(colLen);
        }
        let r = 0;
        $("#luckysheet-copy-content")
          .find("table tr")
          .each(function () {
            let $tr = $(this);
            let c = 0;
            $tr.find(cellElements).each(function () {
              let $td = $(this);
              let cell = {};
              let txt = $td.text();
              if ($.trim(txt).length == 0) {
                cell.v = null;
                isnull = true;
              } else {
                cell.v = $td.text();
              }

              while (c < colLen && data[r][c] != null) {
                c++;
              }

              if (c == colLen) {
                return true;
              }
              data[r][c] = cell;
              c++;
            });

            r++;
          });
        console.log(data);
        // console.log(JSON.stringify(data));
        // console.log(JSON.stringify(data).length);
        let rowRange = range[0]["row"];
        let columnRange = range[0]["column"];
        // let copyRowRange = copy_range[0]["row"];
        // let copyColumnRange = copy_range[0]["column"];
        console.log("rowRange", rowRange);
        console.log("columnRange", columnRange);
        // console.log("copyRowRange", copyRowRange);
        // console.log("copyColumnRange", copyColumnRange);
        // let copyColumnlength = copyColumnRange[1] - copyColumnRange[0];
        //首行校验
        if (rowRange[0] == 0) {
          //不能在首行 前三列 修改内容
          if (columnRange[0] <= 2) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage9")
            );
            return false;
          }

          //不能在首行粘贴多行内容
          if (data.length > 1) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage7")
            );
            return false;
          }
          //粘贴内容不能有空值 /null
          // if (data[0].length <= copyColumnlength) {
          //   this.$messages(
          //     "warning",
          //     this.$t("TermBaseManagement.warningmessage8")
          //   );
          //   return false;
          // } else
          if (isnull) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage8")
            );
            return false;
          } else {
            //校验内容是否是lang_code
            for (let i = 0; i < data[0].length; i++) {
              //第一行内容 必须是lang_code
              if (
                this.languageList.findIndex(
                  (itemd) => itemd.lang_code == data[0][i].v
                ) == -1
              ) {
                this.$messages(
                  "warning",
                  this.$t("TermBaseManagement.warningmessage5")
                );
                return false;
              }
            }
          }
        } else {
          //查看更新列首行是否有内容
          let is_next = true;
          //粘贴内容与现有内容重复的行数集合
          let same_col = [];
          for (
            let i = columnRange[0];
            i < columnRange[0] + data[0].length;
            i++
          ) {
            //前三列粘贴不限制
            if (i != 3) {
              continue;
            }
            console.log("i", i);
            let lang_r = window.luckysheet.getCellValue(0, i);
            console.log("lang_r", lang_r);
            if (lang_r === null || lang_r === "") {
              this.$messages(
                "warning",
                this.$t("TermBaseManagement.warningmessage6")
              );
              is_next = false;
              return false;
            } else {
              //查询当前列是否包含粘贴内容
              let paste_arr = [];
              for (let j = 0; j < data.length; j++) {
                if (
                  data[j][i - columnRange[0]] &&
                  data[j][i - columnRange[0]].v != null
                ) {
                  // console.log("j", data[j][i - columnRange[0]].v);
                  if (paste_arr.includes(data[j][i - columnRange[0]].v)) {
                    this.$messages(
                      "warning",
                      this.$t("TermBaseManagement.warningmessage15")
                    );
                    return false;
                  } else {
                    paste_arr.push(data[j][i - columnRange[0]].v);
                    //查询当前列 看是否有重复的术语
                    let options = {
                      isWholeWord: true,
                      colIndex: i,
                    };
                    let arr = window.luckysheet.find(
                      data[j][i - columnRange[0]].v,
                      options
                    );

                    if (
                      arr.length > 0 &&
                      arr[0]["row"] != 0 &&
                      arr[0]["row"] != r
                    ) {
                      same_col.push(+arr[0]["row"] + 1);
                    }
                  }
                }
              }
            }
          }
          console.log("same_col", same_col);
          const uniqueArr = [...new Set(same_col)];
          if (uniqueArr.length > 0) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage11") +
                uniqueArr.join(",") +
                this.$t("TermBaseManagement.warningmessage12")
            );
            return false;
          }
          if (!is_next) {
            console.log("阻止");
            return false;
          }
        }
      } else {
        return true;
      }
      return true;
    },
    /**
     * 获取 用户信息
     */
    get_user_info() {
      get_user_info({
        action: "get_user_info",
      }).then((res) => {
        hideLoading();
        if (res) {
          this.user_id = res.info.user_id;
          this.info = res.info;
          this.updateUrl = `${this.WebSocketUrl}/websocket-endpoint/wt_fun/ws/${this.user_id}/${this.gridKey}`;
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_term_info(this.gridKey);
        }
      });
    },
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unload(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unload(e));
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #2c2d37;
  overflow-y: auto;
  box-sizing: border-box;

  .title_div {
    width: 100%;
    height: 56px;
    background-color: #393c4e;

    .title_content_div {
      width: calc(100% - 48px);
      margin: 0 24px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 25px;
        width: 100px;
        color: #ffffff;
        background-image: url("@/assets/imgs/WiiMedia_Logo.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .title_p {
        color: #ffffff;
        font-size: 14px;

        .project_dropdown {
          ::v-deep .el-dropdown-menu {
            width: 400px !important;
            max-height: 400px !important;
            overflow-y: auto;
          }

          .project_name_class {
            color: #46adff;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .title_right {
        display: flex;
        align-items: center;
        font-weight: bold;

        p {
          color: #adb0bc;
          font-size: 12px;
          margin-right: 24px;
        }

        .svgbtn {
          .icon {
            width: 20px !important;
            height: 20px !important;
          }
        }
        .avaterdiv {
          margin: 2px 8px 0 0px;
          cursor: pointer;
          // .el-avatar {
          // background: none;
          // }
        }
        .dropdowmtitlep {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 700;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style>
#luckysheet-exportXlsx-btn-title {
  display: none;
}
#replaceTab {
  display: none;
}
</style>
