<!--
 * @Description: 弹窗 封装
 * @version: 
 * @Author: Carful
 * @Date: 2022-10-20 10:25:20
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-17 14:18:51
-->
<template>
  <el-dialog
    :title="this.title"
    :visible.sync="CenterdialogVisible"
    :width="this.width"
    :top="this.top"
    :show-close="showClose"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
    :destroy-on-close="destroyonclose"
    :modal="modal"
    :custom-class="customClass"
    :fullscreen="fullscreen"
    :modal-append-to-body="modalAppend"
    :append-to-body="appendToBody"
    :close-on-press-escape="false"
  >
    <div slot="title" v-if="showTitle">
      <slot name="title"></slot>
    </div>
    <slot></slot>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 24px"
      v-if="showfooter"
    >
      <el-button
        @click="determineClick(1)"
        type="info"
        class="setpadding23"
        v-if="showotherclosebtn"
        ><p class="footerleftbuttonp">
          {{
            closefooterbtnName == ""
              ? $t("PersonalCenter.comfirmTips3")
              : closefooterbtnName
          }}
        </p></el-button
      >
      <el-button @click="closeDialog" type="info" class="setpadding23" v-else
        ><p class="footerleftbuttonp">
          {{
            closefooterbtnName == ""
              ? $t("PersonalCenter.comfirmTips3")
              : closefooterbtnName
          }}
        </p></el-button
      >

      <el-button
        type="primary"
        @click="determineClick"
        class="setpadding23"
        :loading="loading"
      >
        {{
          footerbtnName == ""
            ? $t("PersonalCenter.comfirmTips2")
            : footerbtnName
        }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "888px",
    },
    customClass: {
      type: String,
      default: "888px",
    },
    top: {
      type: String,
      default: "",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showfooter: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    destroyonclose: {
      type: Boolean,
      default: false,
    },
    footerbtnName: {
      type: String,
      default: "",
    },
    closefooterbtnName: {
      type: String,
      default: "",
    },
    // 是否 显示其他 取消按钮
    showotherclosebtn: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    modalAppend: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CenterdialogVisible: false,
      loading: false,
    };
  },
  watch: {
    buttonLoading(newValue, oldValue) {
      // 在这里可以处理propName值的改变
      // console.log("propName changed:", newValue, oldValue);
      this.$nextTick(() => {
        this.loading = newValue;
      });
      // console.log("loading", this.loading);
    },
  },
  methods: {
    /**
     * 点击确定按钮
     */
    determineClick(data) {
      // this.loading = true;
      this.$emit("determineClick", data);
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.CenterdialogVisible = false;
      this.loading = false;
      this.$emit("changeDialogVisible", this.CenterdialogVisible);
    },
  },
  mounted() {
    this.CenterdialogVisible = this.dialogVisible;
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/dialogStyle.scss";
// ::v-deep .el-dialog__title {
//   margin-top: 24px !important;
// }
// ::v-deep .el-dialog__title {
//   // width: 64px;
//   height: 21px;
//   font-family: Microsoft YaHei;
//   color: #ffffff;
//   font-size: 16px;
//   line-height: 0;
//   margin: 0 0 0 24px;
//   float: left;
//   // margin-top: 25px;
// }
// ::v-deep .el-dialog__headerbtn {
//   font-size: 20px;
//   top: 12px;
// }
// ::v-deep .el-dialog__body {
//   min-height: 170px !important;
// }
// ::v-deep .el-dialog--center .el-dialog__body {
//   padding: 0px !important;
//   max-height: 80vh;
//   overflow-y: auto;
// }
// ::v-deep .el-dialog__title {
//   font-size: 16px;
//   font-weight: normal;
//   color: rgba(255, 255, 255, 1);
//   margin-top: 13px;
// }
// ::v-deep .el-dialog__header {
//   min-height: 48px;
//   background: rgba(57, 60, 78, 1);
//   border-bottom: 1px solid #525462;
//   padding: 0;
// }
// ::v-deep .el-dialog__body {
//   background: rgba(57, 60, 78, 1);
//   min-height: 308px;
//   padding: 0;
// }
// ::v-deep .el-dialog__footer {
//   min-height: 80px;
//   padding: 0;
//   border-top: 1px solid #525462;
//   background: rgba(57, 60, 78, 1);
// }
// .dialogp {
//   height: 19px;
//   font-family: Microsoft YaHei;
//   color: #adb0bc;
//   font-size: 14px;
//   margin-bottom: 6px;
// }
// ::v-deep input::-webkit-input-placeholder {
//   -webkit-text-fill-color: #787e83;
// }
// ::v-deep .el-upload-list__item-name {
//   color: #ffffff;
// }
// ::v-deep .el-upload-list__item .el-icon-upload-success {
//   color: #46adff;
// }
// ::v-deep .el-input__inner {
//   width: 360px;
//   height: 32px;
//   background-color: rgba(57, 60, 78, 1);
//   border: 1px solid;
//   border-color: #7d7f8b;
//   border-radius: 2px;
//   font-family: TitilliumWeb-SemiBold;
//   color: #ffffff;
//   font-size: 14px;
// }
// ::v-deep .el-input__inner {
//   font-family: TitilliumWeb-SemiBold;
//   color: #ffffff;
//   font-size: 14px;
// }
</style>
