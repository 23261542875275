<!--
 * @Description: 批量创建 任务
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-21 10:13:34
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-27 08:55:19
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <!-- ------------------------------------------------------步骤一----------------------------------------------------- -->
    <div v-if="step == '1'" class="step1Center">
      <div class="step1Center_top">
        <div class="radioClass step1Center_left">
          <div class="verification">
            <!-- Task workflow -->
            <p>{{ $t("CommonName.Taskworkflow.name") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.radio1 == '-1'"
            ></svg-icon>
          </div>
          <el-radio-group v-model="form.radio1" @change="radiochange1">
            <el-radio
              v-for="i in radioList1"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="radioClass step1Center_right">
          <div class="verification">
            <!-- PreprocessOptions -->
            <p>{{ $t("CommonName.PreprocessOptions.name") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.radio2 == '-1'"
            ></svg-icon>
          </div>
          <el-radio-group v-model="form.radio2" @change="radiochange2">
            <el-radio
              v-for="i in radioList2"
              :key="i.label"
              :label="i.label"
              :disabled="i.disabled"
              border
              >{{ i.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div
        class="step1Center_center_1 radioClass"
        v-if="
          ['1', '2', '3', '4', '6'].includes(form.radio1) &&
          ['2'].includes(form.radio2)
        "
      >
        <!-- Order of Texts in Subtitle File -->
        <p>{{ $t("CommonName.Orderoftextsinsubtitlefile.name") }}</p>
        <el-radio-group v-model="form.radio4">
          <el-radio
            v-for="i in radioList4"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div
        class="step1Center_center radioClass"
        v-if="form.radio2 == '0'"
        v-show="info.role == 3"
      >
        <!-- "ASR Engine" -->
        <p>{{ $t("CommonName.ASREngine.name") }}</p>
        <el-radio-group v-model="form.radio3">
          <el-radio
            v-for="i in radioList3"
            :key="i.label"
            :label="i.label"
            :disabled="i.disabled"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="step1Center_bottom">
        <div class="step1Center_left">
          <div class="verification">
            <!-- Source language-->
            <p>{{ $t("CommonName.Sourcelanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.select1 == null"
            ></svg-icon>
          </div>
          <el-select
            v-model="form.select1"
            @change="changeselectLanguage1"
            class="selectclass"
            filterable
          >
            <el-option
              v-for="item in languageObj.selectLanguage1"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="step1Center_right"
          v-if="['1', '2', '3', '4', '6'].includes(form.radio1)"
        >
          <div class="verification">
            <!-- Target language-->
            <p>{{ $t("CommonName.Targetlanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select2_error == '0'"
            ></svg-icon>
          </div>
          <el-select
            v-model="form.select2"
            multiple
            @change="changeselectLanguage2"
            class="selectclass"
            filterable
          >
            <el-option
              v-for="item in languageObj.selectLanguage2"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="step1Center_center checkboxClass"
        v-show="['1', '2', '3', '4', '6'].includes(form.radio1)"
      >
        <!-- "AIT" -->
        <el-checkbox v-model="AITCheck" border style="width: 400px">{{
          $t("CommonName.AITText")
        }}</el-checkbox>
      </div>
    </div>
    <!-- ------------------------------------------------------步骤二----------------------------------------------------- -->
    <div v-show="step == '2'" class="step2Center">
      <div class="radioClass content_1">
        <p>{{ $t("ExternalCreateTask.Sourceofmediafile.name") }}</p>
        <el-radio-group v-model="form.radio">
          <el-radio
            v-for="i in radioList"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="content_center_1">
        <div class="uploadp">
          <p>{{ $t("BatchCreationTask.UploadMediaZipFile") }}</p>
          <el-tooltip placement="top-start">
            <div slot="content">
              {{ $t("ProjectManagement.changeVideo4") }}<br />
              {{ $t("ProjectManagement.changeVideo3") }}<br />
              {{ $t("ProjectManagement.changeVideo6") }}<br />
              {{
                form.radio2 == "1" ? $t("ProjectManagement.changeVideo5") : ""
              }}
            </div>
            <svg-icon
              icon-class="presentation"
              class="icon"
              style="cursor: pointer"
            ></svg-icon>
          </el-tooltip>

          <svg-icon
            icon-class="presentation"
            class="verificationIcon"
            v-if="form.uploadinput1 == null"
          ></svg-icon>
        </div>
        <el-input
          v-model="form.uploadinput1"
          class="input-with-select srtUnput"
          @input="form.uploadinput1 = ''"
        >
          <el-upload
            slot="append"
            class="upload-demo"
            ref="upload_media_file"
            :with-credentials="true"
            :action="$uploadURL + '/videojob/gateway'"
            name="media_file"
            :data="uploadData"
            :on-change="uploadChange"
            :on-progress="uploadProgress"
            :on-success="uploadSuccess"
            :auto-upload="false"
            :show-file-list="false"
            :limit="1"
          >
            <el-button type="text" @click="srtupload('upload_media_file')">
              <div class="uploadbtn">
                <svg-icon icon-class="upload" class="icon"></svg-icon>
                <!-- 上传 -->
                <span>{{ $t("CommonName.Upload") }}</span>
              </div>
            </el-button>
          </el-upload>
        </el-input>
      </div>
      <!-- Select project  下拉框-->
      <div
        class="radio_content_2"
        v-if="form.radio == '0'"
        v-show="info.role === '3'"
      >
        <p>{{ $t("BatchCreationTask.OSSMode") }}</p>
        <el-select v-model="form.select3" class="selectclass" placeholder="">
          <el-option
            v-for="item in selectList3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- =========== Speech transcription deadline  step2time1,2-->
      <div class="content_center_footer">
        <div
          class="content__footer_left"
          v-if="['0', '1', '4', '5', '6'].includes(form.radio1)"
        >
          <p>{{ $t("CommonName.Speechtranscriptiondeadline") }}</p>
          <el-date-picker
            v-model="form.step2time1"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <div
          class="content__footer_right"
          v-if="['5', '6'].includes(form.radio1)"
        >
          <!-- SQT deadline -->
          <p>{{ $t("CommonName.STQDeadline") }}</p>
          <el-date-picker
            v-model="form.step2time4"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <div
          class="content__footer_right"
          v-if="['1', '2', '3', '4'].includes(form.radio1)"
        >
          <!-- Subtitle translation deadline -->
          <p>{{ $t("CommonName.Subtitletranslationdeadline") }}</p>
          <el-date-picker
            v-model="form.step2time2"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
      </div>
      <!-----------------------------Subtitle Review Deadline------step2time3  -->
      <div v-if="['3', '4'].includes(form.radio1)">
        <div class="content__footer_left">
          <!-- Subtitle review deadline-->
          <div class="verification">
            <p>{{ $t("CommonName.Subtitlereviewdeadline") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.step2time3 == null"
            ></svg-icon>
          </div>
          <el-date-picker
            v-model="form.step2time3"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="content_center_footer" v-if="['6'].includes(form.radio1)">
        <div class="content__footer_left">
          <p>{{ $t("CommonName.Subtitletranslationdeadline") }}</p>
          <el-date-picker
            v-model="form.step2time2"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <div class="content__footer_right">
          <!-- Subtitle translation deadline -->
          <p>{{ $t("CommonName.Subtitlereviewdeadline") }}</p>
          <el-date-picker
            v-model="form.step2time3"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
      </div>
      <template v-if="['1', '2', '3', '4', '6'].includes(form.radio1)">
        <div
          class="content_center_footer"
          v-for="(itemL, index) in selectTM"
          :key="index"
        >
          <!-- Temp TM T(optional)           select6 -->
          <div class="content__footer_left">
            <div class="verification">
              <p>
                {{ itemL.lang_name + " " + $t("CommonName.TempTMToptional") }}
              </p>
            </div>
            <el-select v-model="selectTM[index]['t']" class="selectclass">
              <el-option
                v-for="item in select6List"
                :key="item.tm_id"
                :label="item.name"
                :value="item.tm_id"
                v-show="item.tlang == itemL.lang_id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- Temp TM E(optional)-------------select7 -->
          <div
            class="content__footer_right"
            v-if="['3', '4', '6'].includes(form.radio1)"
          >
            <div class="verification">
              <p>
                {{ itemL.lang_name + " " + $t("CommonName.TempTMEoptional") }}
              </p>
            </div>
            <el-select v-model="selectTM[index]['e']" class="selectclass">
              <el-option
                v-for="item in select6List"
                :key="item.tm_id"
                :label="item.name"
                :value="item.tm_id"
                v-show="item.tlang == itemL.lang_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div class="Previousbtn" v-if="step !== '1'">
        <el-button @click="PreviousClick" type="info" class="setpadding16">
          <div class="btnicon">
            <svg-icon icon-class="Aright" class="icon"></svg-icon>
            <p class="footerleftbuttonp">{{ $t("CommonName.Previous") }}</p>
          </div>
        </el-button>
      </div>
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="NextClick"
          class="setpadding23"
          v-if="step == '1'"
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Next") }}
          </p>
        </el-button>
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          v-else
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Sure") }}
          </p>
        </el-button>
      </div>
    </div>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
  </el-dialog>
</template>
<script>
import { getCurrentTime } from "@/api/setTime";
import { hideLoading, showLoading } from "@/utils/loading";
import {
  get_language,
  get_member_list, //获取成员 列表信息
  get_tm_list, //获取TM列表
  get_oss_list,
} from "@/utils/https";

export default {
  components: {},
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      info: {},
      uploadData: {}, //上传 文件 需要上传 的 其他数据
      loading: false, //开启 上传进度
      percentage: 0, //上传进度
      project_id: "",
      dialogVisible: false,
      title: this.$t("BatchCreationTask.title1"),
      form: {
        radio: "0", //Source of Media File
        radio1: "", //Task workflow
        radio2: "", //Preprocess Options
        radio3: "1", //ASR Engine
        radio4: "0", //Order of texts in subtitle file  	0目标语言在上, 1原语言在上
        uploadinput1: "", //Upload Media Zip File
        select1: "", //Source language
        select2: [], //Target language
        select3: "", //OSSMode
        select5: "", //Main TM (optional)
        select6: "", //Temp TM T(optional)
        select7: "", //Temp TM T(optional)
        step2time1: "", //Speech transcription deadline
        step2time2: "", //Subtitle translation deadline
        step2time3: "", //Subtitle Review Deadline
        step2time4: "", //SQT Deadline
      },
      step: "1",
      radioList: [],
      radioList1: [
        { label: "0", name: this.$t("CommonName.Taskworkflow.label0") },
        { label: "1", name: this.$t("CommonName.Taskworkflow.label1") },
        { label: "2", name: this.$t("CommonName.Taskworkflow.label2") },
        { label: "3", name: this.$t("CommonName.Taskworkflow.label3") },
        { label: "4", name: this.$t("CommonName.Taskworkflow.label4") },
        { label: "5", name: this.$t("CommonName.Taskworkflow.label5") },
        { label: "6", name: this.$t("CommonName.Taskworkflow.label6") },
      ],
      //   Preprocess Options
      radioList2: [
        {
          label: "0",
          name: this.$t("CommonName.PreprocessOptions.label0"),
          disabled: true,
        },
        {
          label: "1",
          name: this.$t("CommonName.PreprocessOptions.label1"),
          disabled: true,
        },
        {
          label: "2",
          name: this.$t("CommonName.PreprocessOptions.label2"),
          disabled: true,
        },
        {
          label: "5",
          name: this.$t("CommonName.PreprocessOptions.label5"),
          disabled: true,
        },
      ],
      radioList3: [
        {
          label: "1",
          name: this.$t("CommonName.ASREngine.label0"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.ASREngine.label1"),
          disabled: false,
        },
        {
          label: "3",
          name: this.$t("CommonName.ASREngine.label2"),
          disabled: false,
        },
      ],
      step2radioList1: [
        { label: "0", name: "Optional tasks" },
        { label: "1", name: "All tasks" },
      ],
      languageoptions: [], //所有 语言  数据
      selectList3: [], //OSSMode
      select5List: [], //主TM
      select6List: [], //临时TM
      time_format:
        sessionStorage.getItem("lang") == "en"
          ? "MMM dd yyyy HH:mm"
          : "yyyy-MM-dd HH:mm",
      languageObj: {
        //源语言 目标语言 创建任务时 互斥 关系
        selectLanguage1: [],
        selectLanguage2: [],
      },
      select2_error: "1",
      selectTM: [], //TM选择集合
      AITCheck: false,
      SubtitleAlignlanguageList: ["2", "1", "13", "25"], //字幕对齐支持的源语言
      radioList4: [
        {
          label: "0",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label0"),
        },
        {
          label: "1",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label1"),
        },
      ],
    };
  },
  methods: {
    // =======================================接口调用=====================================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
          this.languageObj.selectLanguage1 = res.list;
          this.languageObj.selectLanguage2 = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取TM列表} data
     */
    get_tm_list(data) {
      get_tm_list({
        action: "get_tm_list",
        type: data, //1主TM，2临时TM
        slang_id: this.form.select1,
        tlang_ids: this.select2,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (data == "1") {
          this.select5List = res.list;
        } else {
          this.select6List = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取OSS节点列表信息} data
     */
    get_oss_list() {
      get_oss_list({
        action: "get_oss_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        res.list.map((i) => {
          if (i.default == true) {
            this.form.select3 = i.id;
          }
        });
        this.selectList3 = res.list;
      });
    },
    /**
     * 第一步 右侧 点击 选项 选择 字幕对齐
     * @param val
     */
    radiochange2(val) {
      if (val == "5") {
        this.title = this.$t("ExternalCreateTask.title1");
        this.languageObj.selectLanguage1 = this.setsubLanguage();
        if (!this.SubtitleAlignlanguageList.includes(this.select1)) {
          this.select1 = "";
        }
      } else {
        this.languageObj.selectLanguage1 = this.languageoptions;
        this.title = this.$t("ExternalCreateTask.title1");
      }
    },
    /**
     * 过滤 字幕对齐 源语言
     * @returns {*[]}
     */
    setsubLanguage() {
      return this.languageoptions.filter((i) => {
        return this.SubtitleAlignlanguageList.includes(i.language_id);
      });
    },
    // ======================================= 上传方法=======================================

    /**
     *
     * @param {* 点击上传文件 清空已上传文件} refs
     * @param {*} data
     */
    srtupload(refs) {
      this.form.uploadinput1 = "";
      let uploadFilesArr = this.$refs[refs].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs[refs].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*上传视频 改变视频} file
     */
    uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

      const extension = testmsg.toLowerCase() === "zip";
      const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
      if (!extension) {
        this.$messages("warning", this.$t("ProjectManagement.warningmessage2"));
        this.form.uploadinput1 = "";
        this.$refs.upload_media_file.clearFiles();
        return false;
      } else if (!isLt2M) {
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage3")
        );
        this.form.uploadinput1 = "";
        this.$refs.upload_media_file.clearFiles();
        return false;
      } else {
        this.form.uploadinput1 = file.name;
        return true;
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      if (file.percent == 100) {
        this.percentage = 0;
        this.loading = false;
        showLoading(this.$t("CommonName.warningmessage6"));
      }
    },
    /**
     *
     * @param {*上传成功} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      if (response.result == "SUCCESS") {
        this.dialogVisible = false;
        this.$emit("createdBatchTask", this.dialogVisible);
        // hideLoading();
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("error", response.msg);
      }
    },
    // ======================================通用方法=========================================
    /**
     *
     * @param {* 设置Task Workflow  } data
     */
    setRadio1andRadio2(data) {
      let obj = {};
      obj.a = this.form.radio1;
      obj.b = this.form.radio2;
      obj.a = String(+obj.a + 1);
      obj.b = String(+obj.b + 1);
      if (obj.a == "2") {
        obj.a = "3";
      } else if (obj.a == "3") {
        obj.a = "2";
      }
      return obj;
    },
    /**
     *
     * @param {* 设置 创建任务 所需上传的数据} obj
     */
    setUploadData(obj) {
      let item = {
        action: "create_job_file_batch",
        project_id: this.project_id,
        slang: this.form.select1,
        tlang: obj.a !== "1" ? this.form.select2 : "",
        task_type: obj.a, //	任务类型，1转写、2翻译、3转写+翻译，4翻译+校审，5转写+翻译+校审，6转写+SQT
        job_name: this.form.uploadinput1, //this.form.radio=>1,2 时 为空
        transfer_type: obj.b, //1自动，2单语
        file_mode_type: obj.b == "3" ? this.form.radio4 : "", //0目标语言在上, 1原语言在上  radio2->3 时选
        oss_mode_type: this.form.radio == "0" ? this.form.select3 : "", //
        source_media_type: this.form.radio, //	源文件来源方式0 上传,1 借调， 2 本地
        // media_file: "", //this.form.radio=>1,2 时 为空"
        tm_list: JSON.stringify(this.selectTM), //tm集合
        date_dead_stt: ["1", "3", "5", "6", "7"].includes(obj.a)
          ? this.form.step2time1
          : "", //转写预计交付时间
        date_dead_tra: ["2", "3", "4", "5", "7"].includes(obj.a)
          ? this.form.step2time2
          : "", //翻译预计交付时间 按照任务类型 没有该节点就传空
        date_dead_rev: ["4", "5", "7"].includes(obj.a)
          ? this.form.step2time3
          : "", //校审预计交付时间 按照任务类型 没有该节点就传空
        date_dead_sqt: ["6", "7"].includes(obj.a) ? this.form.step2time4 : "", //sqt预计交付时间 按照任务类型 没有该节点就传空
        user_stt: "", //转写任务执行人id
        user_tra: "", //翻译任务执行人id
        user_rev: "", //校对任务执行人id
        user_sqt: "", //sqt任务执行人id
        asr_mode_type: obj.b == "1" ? this.form.radio3 : "",
        ait_mode: this.AITCheck ? 1 : 0,
      };
      return item;
    },
    //======================================== 其他方法 =====================================

    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("clickBatchCreatingTask", this.dialogVisible);
    },
    /**
     * 确定按钮
     */
    determineClick() {
      let obj = this.setRadio1andRadio2();
      if (!this.form.uploadinput1) {
        this.form.uploadinput1 = null;
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage6")
        );
        return;
      }

      // 判断转写 时间 是否填写
      if (["1", "3", "5", "6", "7"].includes(obj.a)) {
        if (!this.form.step2time1) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断翻译时间 是否填写
      if (["2", "3", "4", "5", "7"].includes(obj.a)) {
        if (!this.form.step2time2) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断 校对时间 是否填写
      if (["4", "5", "7"].includes(obj.a)) {
        if (!this.form.step2time3) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断SQT 时间 是否填写
      if (["6", "7"].includes(obj.a)) {
        if (!this.form.step2time4) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      this.uploadData = this.setUploadData(obj);
      this.$nextTick(() => {
        this.$refs.upload_media_file.submit();
      });
    },
    /**
     * 点击下一步
     */
    NextClick() {
      this.form.radio1 = this.form.radio1 || "-1";
      this.form.select1 = this.form.select1 || null;
      if (this.form.radio1 !== "0" && this.form.radio1 !== "5") {
        this.select2_error = this.form.select2.length > 0 ? "1" : "0";
      }
      if (this.form.radio1 == "-1") {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      } else {
        this.form.radio2 = this.form.radio2 || "-1";
        if (!this.form.radio2 || this.form.radio2 == "-1") {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
      }
      if (this.form.radio1 !== "0" && this.form.radio1 !== "5") {
        if (!this.form.select1 || this.form.select2.length == 0) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.form.select2.includes(this.form.select1)) {
          this.$messages("warning", this.$t("CommonName.selectMessage1"));
          return;
        }
      } else {
        if (!this.form.select1) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
      }

      this.title = this.$t("CommonName.title2");
      this.step = "2";
      this.get_oss_list();
      this.form.select5 = "";
      this.form.select6 = "";
      this.form.select7 = "";
      this.form.step2time1 = getCurrentTime("0");
      this.form.step2time2 = getCurrentTime("1");
      this.form.step2time3 = getCurrentTime("2");
      this.form.step2time4 = getCurrentTime("1");
      this.selectTM = [];
      if (this.form.select2.length > 0) {
        this.form.select2.forEach((item) => {
          const targetItem = this.languageoptions.find(
            (item1) => item1.language_id === item
          );
          if (targetItem) {
            this.selectTM.push({
              lang_id: item,
              t: "",
              e: "",
              lang_name: targetItem.cname,
            });
          }
        });
      }
      if (this.form.radio1 !== "0") {
        this.get_tm_list("2");
      }
      if (this.form.radio2 == "0") {
        this.radioList = [
          {
            label: "0",
            name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
          },
        ];
      } else {
        this.radioList = [
          {
            label: "0",
            name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
          },
        ];
      }
    },
    /**
     * 上一步
     */
    PreviousClick() {
      this.title = this.$t("BatchCreationTask.title1");
      this.step = "1";
      // this.form.uploadinput1 = "";
    },
    /**
     *
     * @param {*左侧单选框} val
     */
    radiochange1(val) {
      // if (["0", "5"].includes(val)) {
      //   this.radioList2.forEach((i) => {
      //     i.disabled = false;
      //   });
      // } else if (["1", "4", "6"].includes(val)) {
      // } else if (["2", "3"].includes(val)) {
      //   this.radioList2[0].disabled = true;
      //   this.radioList2[1].disabled = false;
      //   this.radioList2[2].disabled = true;
      // }
      // this.form.radio2 = "";
      // if (val == "0") {
      //   this.form.select2 = "";
      // }
      let a = this.radioList2;
      a.forEach((i) => {
        if (val == "0" || val == "5") {
          if (this.form.radio2 == "2" || this.form.radio2 == "3") {
            this.form.radio2 = "-1";
          }
          if (i.label == "2" || i.label == "3") {
            i.disabled = true;
          } else {
            i.disabled = false;
          }
        } else if (val == "2" || val == "3") {
          if (
            this.form.radio2 == "0" ||
            this.form.radio2 == "4" ||
            this.form.radio2 == "5"
          ) {
            this.form.radio2 = "-1";
          }
          if (i.label == "0" || i.label == "4" || i.label == "5") {
            i.disabled = true;
          } else {
            i.disabled = false;
          }
        } else if (val == "1" || val == "4" || val == "6") {
          i.disabled = false;
        }
      });
      this.radioList2 = a;
      if (val == "0") {
        let arr = JSON.parse(JSON.stringify(this.languageoptions));
        this.languageObj.selectLanguage1 = arr;
        this.form.select2 = [];
      }
    },
    /**
     *
     * @param {* 源语言 下拉选 语言 与目标语言 互斥} select
     */
    changeselectLanguage1(select) {
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      this.languageObj.selectLanguage2 = arr.filter(
        (i) => i.language_id !== select
      );
      // console.log(select, "select1");
    },
    /**
     *
     * @param {*目标语言 下拉选 语言 与源语言 互斥} select
     */
    changeselectLanguage2(select) {
      this.select2_error = "1";
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      //字幕对齐需要筛选源语言
      if (this.form.radio2 == "5") {
        arr = arr.filter((i) => {
          return this.SubtitleAlignlanguageList.includes(i.language_id);
        });
      }

      this.languageObj.selectLanguage1 = arr.filter(
        (i) => !select.includes(i.language_id)
      );
    },
  },
  mounted() {
    this.get_language();
    // this.get_oss_list();
    this.project_id = this.$route.query.id;
    this.dialogVisible = this.showdialog;
    this.info = JSON.parse(sessionStorage.getItem("info"));
  },
};
</script>
<style scoped lang="scss">
@import "./AllDialogradio.scss";

.step1Center {
  margin: 4px 24px 24px 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }

  .el-radio.is-bordered.is-disabled {
    border-color: #5b5d6c;
  }

  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #595d69 !important;
  }

  ::v-deep .el-radio__input.is-disabled .el-radio__inner {
    background: none;
    border-color: #595d69;
  }

  .step1Center_top {
    display: flex;

    .step1Center_left {
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }

      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }

    .step1Center_right {
      margin-left: 40px;

      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }

      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
  }

  .step1Center_center {
    .el-radio.is-bordered {
      width: 210px;
      margin-top: 6px;
    }
  }

  .step1Center_bottom {
    display: flex;

    .step1Center_right {
      margin-left: 40px;
    }
  }
}

.step2Center {
  margin: 4px 24px 24px 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }

  .content_1 {
    .el-radio.is-bordered {
      width: 200px;
      margin-top: 6px;
    }
  }
}
</style>
