<!--
 * @Description: 邀请记录
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-23 10:14:44
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-15 11:24:41
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <span class="spanClass">{{ $t("InvitationRecord.title") }}</span>
      </p>
    </div>
    <div class="content">
      <div
        class="tableborderClass tableClass borderTableStyle"
        :class="tableData.length == 0 ? 'tablebtmborder' : ''"
      >
        <el-table
          :row-style="{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          height="100%"
          border
        >
          <div slot="empty" class="noDataDiv">
            <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
            <p>{{ $t("CommonName.noData") }}</p>
          </div>
          <!--邀请时间  -->
          <el-table-column
            prop="time"
            :label="$t('InvitationRecord.Invitationtime')"
          >
          </el-table-column>
          <!-- 账户 ID -->
          <el-table-column
            prop="user_key"
            :label="$t('InvitationRecord.AccountID')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="content" :label="$t('CommonName.status')">
          </el-table-column>
          <el-table-column
            prop="email"
            :label="$t('InvitationRecord.AccountEmail')"
          >
          </el-table-column>
          <el-table-column
            prop="nickname"
            :label="$t('InvitationRecord.Accountnickname')"
            show-overflow-tooltip
            label-class-name="labelClassName"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { get_invitation_log } from "@/utils/https";
export default {
  created() {
    this.$emit("header", false, false, true, true, true);
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    get_invitation_log() {
      get_invitation_log({ action: "get_invitation_log" }).then((res) => {
        if (res.result == "SUCCESS") {
          // console.log(res.list);
          this.tableData = res.list;
        }
      });
    },
    BackPrevious() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.get_invitation_log();
  },
};
</script>
<style lang="scss" scoped>
.content {
  .tableborderClass {
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    // .el-table {
    //   display: flex;
    //   flex-direction: column;
    //   //   overflow: auto;
    // }
    // ::v-deep .el-table__header-wrapper {
    //   overflow: visible !important;
    // }
  }
}
</style>
