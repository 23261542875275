<!--
 * @Description: 项目详情
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-14 17:26:17
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-11 14:44:13
-->
<template>
  <div class="center">
    <div class="center_top" v-if="dataList.length > 0">
      <div class="legth1Top" v-for="i in dataList" :key="i.id">
        <div :class="setClass + ' ' + i.class1">
          <div class="title">
            <div class="titleleft">
              <p class="titlep">{{ i.name }}</p>
              <svg-icon
                icon-class="Yes"
                v-if="i.showyes"
                class="icon"
              ></svg-icon>
              <p
                v-if="
                  i.workflow == 2 && i.status <= 100 && dataInfo.status == '30'
                "
                class="statusno0"
              >
                {{ i.status + "%" }}
              </p>
            </div>
            <div v-if="i.status > 0" class="titleright"></div>
          </div>
          <div class="centerbody" style="">
            <div class="centerbody_top">
              <div class="centerbody_top_left" :class="setClass3">
                <div style="margin-bottom: 3px">
                  <!-- Deadline -->
                  <div class="iconp">
                    <p>{{ $t("ProjectManagement.Deadline") }}</p>
                    <div class="icondiv" v-if="!i.showyes">
                      <el-popover
                        placement="bottom"
                        width="220"
                        v-model="i.deadVisible"
                        trigger="click"
                        :visible-arrow="false"
                        popper-class="popoverClasss"
                      >
                        <div class="popoverInputTextarea">
                          <el-date-picker
                            v-model="i.time"
                            type="datetime"
                            :format="time_format"
                            :value-format="time_format"
                            :placeholder="
                              $t('ProjectManagement.SelectDateTime')
                            "
                            class="DeadlineClass"
                          >
                          </el-date-picker>
                          <div style="margin-top: 20px; float: right">
                            <el-button
                              type="info"
                              class="setpadding16"
                              @click="i.deadVisible = false"
                              >{{ $t("CommonName.deleteMessageClosebutton") }}
                            </el-button>
                            <el-button
                              type="primary"
                              class="setpadding16"
                              @click="deadlineClick(i)"
                              >{{ $t("CommonName.determine") }}
                            </el-button>
                          </div>
                        </div>
                        <svg-icon
                          icon-class="Edit"
                          class="icon"
                          slot="reference"
                          @click="i.time = i.deadline"
                        ></svg-icon>
                      </el-popover>
                      <div class="tableheader"></div>
                    </div>
                  </div>

                  <p class="btmp">{{ i.deadline }}</p>
                </div>
                <div>
                  <!-- Completed on -->
                  <p>{{ $t("ProjectManagement.Completedon") }}</p>
                  <p class="btmp">{{ i.complete_time }}</p>
                </div>
                <div>
                  <!-- Update on -->
                  <p>{{ $t("ProjectManagement.ModificationTime") }}</p>
                  <p class="btmp">{{ i.update_time }}</p>
                </div>
              </div>

              <div class="" style="margin-bottom: 0px" :class="setClass2">
                <div>
                  <div class="iconp">
                    <!-- Waterprint 水印信息 -->
                    <p>{{ $t("ProjectManagement.Waterprint") }}</p>
                    <div class="icondiv">
                      <el-popover
                        placement="bottom"
                        width="278"
                        v-model="i.visible"
                        trigger="click"
                        :visible-arrow="false"
                        popper-class="popoverClasss"
                      >
                        <div class="popoverInputTextarea">
                          <el-input
                            type="textarea"
                            v-model="i.popoverInput"
                            resize="none"
                            maxlength="45"
                          ></el-input>
                          <div style="margin-top: 20px; float: right">
                            <el-button
                              type="info"
                              class="setpadding16"
                              @click="i.visible = false"
                              >{{ $t("CommonName.deleteMessageClosebutton") }}
                            </el-button>
                            <el-button
                              type="primary"
                              class="setpadding16"
                              @click="searchdetermineClick(i)"
                              >{{ $t("CommonName.determine") }}
                            </el-button>
                          </div>
                        </div>
                        <svg-icon
                          icon-class="Edit"
                          class="icon"
                          slot="reference"
                          @click="i.popoverInput = i.watermark_info"
                        ></svg-icon>
                      </el-popover>
                      <div class="tableheader"></div>
                    </div>
                  </div>
                  <p
                    class="btmp overflowEllipsis"
                    :id="'tag' + i.id"
                    @mouseover="onShowNameTipsMouseenter(i)"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="i.watermark_info"
                      placement="top-start"
                      :disabled="!i.showdropdown"
                      :visible-arrow="false"
                    >
                      <span>
                        {{
                          i.watermark_info !== ""
                            ? i.watermark_info
                            : $t("ProjectManagement.notSet")
                        }}
                      </span>
                    </el-tooltip>
                  </p>
                </div>
                <div :class="dataList.length > 2 ? 'centerbody_top_left1' : ''">
                  <div class="iconp">
                    <p>{{ $t("ProjectManagement.Waterprint1") }}</p>
                    <!-- <svg-icon
                      icon-class="Edit"
                      class="icon"
                      v-if="dropdownList.length == 0"
                      @click="$messages('warning', '11111')"
                    ></svg-icon> -->
                    <el-dropdown
                      trigger="click"
                      placement="bottom-start"
                      @command="handleCommand"
                      @visible-change="visibleChange"
                    >
                      <span class="el-dropdown-link">
                        <svg-icon icon-class="Edit" class="icon"></svg-icon>
                      </span>
                      <!-- 任务执行人 -->
                      <el-dropdown-menu
                        slot="dropdown"
                        class="tabledropdowndiv"
                      >
                        <el-input
                          v-model="searchText"
                          :placeholder="$t('OverwriteOperation.Searchsource1')"
                          @input="handleSearch"
                          class="iconp_input"
                        ></el-input>
                        <el-dropdown-item
                          v-if="dropdownList.length == 0"
                          command="-1"
                          >{{ $t("OverwriteOperation.warningmessage14") }}
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-for="j in filteredOptions"
                          :key="j.member_id"
                          :command="{ id: j.member_id, item: i }"
                          >{{ j.nickname }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div class="nickname_div">
                    <p class="btmp overflowEllipsis">
                      {{
                        i.nickname == ""
                          ? $t("ProjectManagement.notSet")
                          : i.nickname
                      }}
                    </p>

                    <el-button
                      type="text"
                      @click="handleloseClick(i)"
                      v-if="i.nickname"
                      ><i class="el-icon-circle-close close_i"></i
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="centerbody_btm"
              :class="dataList.length > 1 ? 'setFlex' : 'setFlex1'"
              v-if="info.role == '3'"
            >
              <div>
                <!-- Task URL -->
                <p>{{ $t("ProjectManagement.TaskURL") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="i.newTaskUrl"
                    target="_blank"
                    :id="'a' + i.id"
                    rel="noopener noreferrer"
                    @click="claseCreateTaskDialog(false)"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon
                    icon-class="Copy"
                    class="icon"
                    @click="copyClick(i, info.role)"
                  ></svg-icon>
                </div>
              </div>
              <div v-if="i.workflow == 1 && +dataInfo.status > 20">
                <!-- 转写提交对比报告 URL -->
                <p>{{ $t("ProjectManagement.STTUrl1") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="setSttUrl"
                    target="_blank"
                    :id="'g' + i.id"
                    rel="noopener noreferrer"
                    @click="claseCreateTaskDialog(false)"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon
                    icon-class="Copy"
                    class="icon"
                    @click="copyClick(setSttUrl, '-1')"
                  ></svg-icon>
                </div>
              </div>
              <!-- SQT提交对比报告 URL -->
              <div v-if="i.workflow == 5 && +dataInfo.status >= 25">
                <p>{{ $t("ProjectManagement.STQUrl1") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="i.reportUrl"
                    target="_blank"
                    :id="'g' + i.id"
                    rel="noopener noreferrer"
                    @click="claseCreateTaskDialog(false)"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon
                    icon-class="Copy"
                    class="icon"
                    @click="copyClick(i.reportUrl, '-1')"
                  ></svg-icon>
                </div>
              </div>
              <div v-if="i.workflow == 2">
                <!-- Report URL -->
                <p>{{ $t("ProjectManagement.ProofUrl1") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="setproofurl1"
                    target="_blank"
                    :id="'g' + i.id"
                    rel="noopener noreferrer"
                    @click="claseCreateTaskDialog(false)"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon
                    icon-class="Copy"
                    class="icon"
                    @click="copyClick(setproofurl1, '-1')"
                  ></svg-icon>
                </div>
              </div>
              <div v-if="i.workflow == 3">
                <!-- Report URL -->
                <p>{{ $t("ProjectManagement.ReportURL1") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="i.reportUrl"
                    target="_blank"
                    :id="'a' + i.id"
                    rel="noopener noreferrer"
                    @click="claseCreateTaskDialog(false)"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon
                    icon-class="Copy"
                    class="icon"
                    @click="copyClick(i.reportUrl, '-1')"
                  ></svg-icon>
                </div>
              </div>
              <div v-if="false">
                <!-- Report URL -->
                <p>{{ $t("ProjectManagement.ReportURL") }}</p>
                <div class="TaskURL_div">
                  <a
                    :href="i.task_url"
                    target="_blank"
                    :id="'a' + i.id"
                    rel="noopener noreferrer"
                    >{{ i.task_url }}</a
                  >
                  <svg-icon icon-class="Copy" class="icon"></svg-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="footrtbtn" v-if="info.role == '2'">
            <el-button type="text" @click="copyClick(i, info.role)">
              <div class="btnClass">
                <!--  查看详情-->
                <p>
                  {{ $t("ProjectManagement.CopyTaskURL1") }}
                </p>
              </div>
            </el-button>
          </div>
        </div>
        <div v-if="dataList.length > 1">
          <svg-icon
            icon-class="Littlearrow"
            class="icons"
            v-if="i.showicon"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div class="center_btm">
      <el-collapse accordion @change="handleChange">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="legth1btm_top">
              <!-- <div class="Splitline"></div> -->
              <div>
                <el-button type="text" style="margin-right: 14px">
                  <div class="btnClass">
                    <svg-icon
                      icon-class="CircularArrow1"
                      class="icon"
                      v-if="showcollapse"
                    ></svg-icon>
                    <svg-icon
                      icon-class="CircularArrow0"
                      class="icon"
                      v-else
                    ></svg-icon>

                    <p class="">
                      {{ $t("ProjectManagement.TaskInformation") }}
                    </p>
                  </div>
                </el-button>

                <!-- Task Information -->
                <!--下载字幕-->
                <el-button type="text" @click.stop="changeVideo(3)">
                  <div class="btnClass">
                    <svg-icon icon-class="download"></svg-icon>
                    <p>{{ $t("ProjectManagement.Downloadsubtitles") }}</p>
                  </div>
                </el-button>
              </div>
              <!--              <div class="tableDiv_right1">-->
              <!--                <div class="tableDiv_left">-->
              <!--                  <p>{{ $t("ProjectManagement.Status") }}</p>-->
              <!--                </div>-->
              <!--                <div class="rightp1">-->
              <!--                  <p class="rightp1_p">-->
              <!--                    {{ dataInfo.status_name }}-->
              <!--                  </p>-->
              <!--                  打回-->
              <div class="legth1btm_top_right">
                <el-button
                  type="text"
                  @click.stop="rejectjob"
                  v-if="dataInfo.showrepulsebtn"
                >
                  <div class="svgbtn">
                    <svg-icon icon-class="RejectBack" class="icon"></svg-icon>
                    <p>
                      {{ $t("ProjectManagement.refuse") }}
                    </p>
                  </div>
                </el-button>
              </div>

              <!--                </div>-->
              <!--              </div>-->
            </div>
          </template>

          <div class="legth1btm_btm">
            <div class="tableDiv">
              <div class="tableDiv_left">
                <p>{{ $t("CommonName.TaskName") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep title_p overflowEllipsis">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="dataInfo.job_name"
                    placement="top-start"
                    :visible-arrow="false"
                  >
                    <span>{{ dataInfo.job_name }}</span>
                  </el-tooltip>
                </p>
                <el-button
                  type="text"
                  @click="changeVideo(1)"
                  v-if="dataInfo.source_media_type !== '2'"
                >
                  <div class="btnClass">
                    <svg-icon icon-class="Import" class="icon"></svg-icon>
                    <!-- 更换视频文件 -->
                    <p>{{ $t("ProjectManagement.Replacevideofile") }}</p>
                  </div>
                </el-button>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- SRT File Name -->
                <p>{{ $t("ProjectManagement.SRTFileName") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">{{ dataInfo.srt_name }}</p>
                <el-button type="text" @click="changeVideo(2)">
                  <div class="btnClass">
                    <svg-icon icon-class="Import" class="icon"></svg-icon>
                    <!--  导入-->
                    <p>{{ $t("CommonName.import") }}</p>
                  </div>
                </el-button>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Workflow</p> -->
                <p>{{ $t("ProjectManagement.Workflow") }}</p>
              </div>
              <div class="tableDiv_right1">
                <div class="rightp">
                  <p>{{ dataInfo.job_type_name }}</p>
                </div>
                <div class="tableDiv_left">
                  <!-- <p>Status</p> -->
                  <p>{{ $t("ProjectManagement.Status") }}</p>
                </div>
                <div class="rightp1 rightp2">
                  <p>
                    {{ dataInfo.status_name }}
                  </p>
                  <!--                  打回-->
                  <!--                  <el-button-->
                  <!--                    type="text"-->
                  <!--                    @click="rejectjob"-->
                  <!--                    v-if="dataInfo.showrepulsebtn"-->
                  <!--                  >-->
                  <!--                    <div class="svgbtn">-->
                  <!--                      <svg-icon icon-class="RejectBack" class="icon"></svg-icon>-->
                  <!--                      <p>-->
                  <!--                        {{ $t("ProjectManagement.refuse") }}-->
                  <!--                      </p>-->
                  <!--                    </div>-->
                  <!--                  </el-button>-->
                </div>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Source Language</p> -->
                <p>{{ $t("CommonName.Sourcelanguage") }}</p>
              </div>
              <div class="tableDiv_right1">
                <div class="rightp">
                  <p>
                    {{ dataInfo.source_langName }}
                  </p>
                </div>
                <div class="tableDiv_left">
                  <!-- <p>Target Language</p> -->
                  <p>{{ $t("CommonName.Targetlanguage") }}</p>
                </div>
                <div class="rightp1">
                  <p>{{ dataInfo.target_langName }}</p>
                </div>
              </div>
            </div>
            <div class="tableDiv" v-show="info.role == 3">
              <div class="tableDiv_left">
                <!-- <p>File Storage location</p> -->
                <p>{{ $t("ExternalCreateTask.FileStoragelocation") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">{{ dataInfo.oss_mode_str }}</p>
                <el-button
                  type="text"
                  @click="changeVideo(4)"
                  v-if="dataInfo.source_media_type != '2'"
                >
                  <div class="btnClass">
                    <svg-icon icon-class="Import" class="icon"></svg-icon>
                    <!-- 更换oss模式 -->
                    <p>{{ $t("ProjectManagement.ReplaceOssMode") }}</p>
                  </div>
                </el-button>
              </div>
            </div>
            <div class="tableDiv" v-show="info.role == 3">
              <div class="tableDiv_left">
                <!-- <p>Main ASR Engine</p> -->
                <p>{{ $t("ProjectManagement.MainASREngine") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">{{ dataInfo.Engine }}</p>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Media Length</p> -->
                <p>{{ $t("ProjectManagement.MediaLength") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">{{ dataInfo.duration }}</p>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Media Segments</p> -->
                <p>{{ $t("ProjectManagement.MediaSegments") }}</p>
              </div>
              <div class="tableDiv_right1">
                <div class="rightp">
                  <p>{{ dataInfo.segment }}</p>
                </div>
                <div class="tableDiv_left">
                  <!-- <p>Source Wordcount</p> -->
                  <p>{{ $t("ProjectManagement.SourceWordcount") }}</p>
                </div>
                <div class="rightp1">
                  <p>{{ dataInfo.word_count }}</p>
                </div>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Created on</p> -->
                <p>{{ $t("ProjectManagement.Createdon") }}</p>
              </div>
              <div class="tableDiv_right1">
                <div class="rightp">
                  <p>{{ dataInfo.create_time }}</p>
                </div>
                <div class="tableDiv_left">
                  <!-- <p>Delivered on</p> -->
                  <p>{{ $t("ProjectManagement.Deliveredon") }}</p>
                </div>
                <div class="rightp1">
                  <p>
                    {{
                      dataInfo.delivery_time == ""
                        ? "--"
                        : dataInfo.delivery_time
                    }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="tableDiv">
              <div class="tableDiv_left">
                <p>{{ $t("TMManagement.MainTM") }}</p>
              </div>
              <div class="tableDiv_right" v-if="dataInfo.main_tm">
                <p class="titlep">
                  {{ dataInfo.main_tm["name"] }}
                </p>
              </div>
            </div> -->
            <div class="tableDiv">
              <div class="tableDiv_left">
                <!-- <p>Temp TM</p> -->
                <p>{{ $t("ProjectManagement.TempTM") }}</p>
              </div>
              <div class="tableDiv_right" v-if="dataInfo.trans_tm">
                <p class="titlep">
                  {{ dataInfo.trans_tm["name"] }}
                </p>
              </div>
            </div>
            <div class="tableDiv">
              <div class="tableDiv_left">
                <p>{{ $t("ProjectManagement.EditTM") }}</p>
              </div>
              <div class="tableDiv_right" v-if="dataInfo.edit_tm">
                <p class="titlep">
                  {{ dataInfo.edit_tm["name"] }}
                </p>
              </div>
            </div>
            <div class="tableDiv" v-if="info.role == '3'">
              <div class="tableDiv_left">
                <!-- <p>Proof Url</p> -->
                <p>{{ $t("ProjectManagement.ProofUrl") }}</p>
              </div>
              <div class="tableDiv_right">
                <a
                  :href="setproofurl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ setproofurl }}
                </a>
                <el-button type="text" @click="copyClick(setproofurl, '-1')">
                  <div class="btnClass">
                    <svg-icon icon-class="Copy" class="icon"></svg-icon>
                  </div>
                </el-button>
              </div>
            </div>
            <div class="tableDiv" v-if="info.role == '3'">
              <div class="tableDiv_left">
                <!-- <p>Proof Url</p> -->
                <p>{{ $t("ProjectManagement.ProofUrl1") }}</p>
              </div>
              <div class="tableDiv_right">
                <a
                  :href="setproofurl1"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="overflowEllipsis"
                  style="width: 700px"
                >
                  {{ setproofurl1 }}
                </a>
                <el-button type="text" @click="copyClick(setproofurl1, '-1')">
                  <div class="btnClass">
                    <svg-icon icon-class="Copy" class="icon"></svg-icon>
                  </div>
                </el-button>
              </div>
            </div>
            <!-- 转写提交对比报告 URL -->
            <!-- <div
              class="tableDiv"
              v-if="info.role == '3' && +dataInfo.status > 20"
            >
              <div class="tableDiv_left">
                <p>{{ $t("ProjectManagement.STTUrl1") }}</p>
              </div>
              <div class="tableDiv_right">
                <a
                  :href="setSttUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="overflowEllipsis"
                  style="width: 700px"
                  @click="claseCreateTaskDialog(false)"
                >
                  {{ setSttUrl }}
                </a>
                <el-button type="text" @click="copyClick(setSttUrl, '-1')">
                  <div class="btnClass">
                    <svg-icon icon-class="Copy" class="icon"></svg-icon>
                  </div>
                </el-button>
              </div>
            </div> -->
            <div class="tableDiv" v-if="dataInfo.location != '0'">
              <!--              v-if="+dataInfo.location > 0"-->
              <div class="tableDiv_left">
                <!-- <p>WiiTube标题描述链接 </p> -->
                <p>{{ $t("ProjectManagement.LocalizationUrl") }}</p>
              </div>
              <div class="tableDiv_right">
                <a
                  :href="
                    this.mediaUrl +
                    'ContainerHome/TranslationLanguageChange?job_id=' +
                    this.Project_info.job_id
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{
                    this.mediaUrl +
                    "ContainerHome/TranslationLanguageChange?job_id=" +
                    this.Project_info.job_id
                  }}
                </a>
                <el-button type="text" @click="copyClick(setyoutubeUrl, '-1')">
                  <div class="btnClass">
                    <svg-icon icon-class="Copy" class="icon"></svg-icon>
                  </div>
                </el-button>
              </div>
            </div>
            <!--            youtube_id-->
            <div class="tableDiv" v-if="dataInfo.youtube_id != ''">
              <div class="tableDiv_left">
                <!-- <p>WiiTube 视频id</p> -->
                <p>{{ $t("ProjectManagement.WiiTubeVideoID") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">
                  {{ dataInfo.youtube_id }}
                </p>
              </div>
            </div>
            <div class="tableDiv" style="border-bottom: none">
              <div class="tableDiv_left">
                <!-- <p>Comments</p> -->
                <p>{{ $t("TMManagement.Comments") }}</p>
              </div>
              <div class="tableDiv_right">
                <p class="titlep">
                  {{ dataInfo.comments }}
                </p>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog
      width="1024px"
      :title="innerVisibleTitle"
      :visible.sync="innerVisible"
      @close="closeinnerDialog"
      v-if="innerVisible"
      append-to-body
      top="3%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <!-- 修改 视频文件 -->
        <div class="content_center_1" v-if="showInnerCenter == 1">
          <div class="uploadp">
            <p>{{ $t("ExternalCreateTask.UploadMediaFile") }}</p>
            <el-tooltip placement="top-start" popper-class="uploadptooltip">
              <div slot="content">
                {{ $t("ProjectManagement.changeVideo1") }}<br />
                {{ $t("ProjectManagement.changeVideo2") }}<br />
                {{ $t("ProjectManagement.changeVideo3") }}
              </div>
              <svg-icon
                icon-class="presentation"
                class="icon"
                style="cursor: pointer"
              ></svg-icon>
            </el-tooltip>
          </div>
          <el-input
            v-model="uploadinput"
            class="input-with-select srtUnput"
            @input="uploadinput = ''"
          >
            <el-upload
              slot="append"
              class="upload-demo"
              ref="upload_media_file"
              :with-credentials="true"
              :action="$uploadURL + '/videojob/gateway'"
              :data="uploadData"
              :on-change="uploadChange"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
            >
              <el-button type="text" @click="srtupload('upload_media_file', 1)">
                <div class="uploadbtn">
                  <svg-icon icon-class="upload" class="icon"></svg-icon>
                  <!-- 上传 -->
                  <span>{{ $t("CommonName.Upload") }}</span>
                </div>
              </el-button>
            </el-upload>
          </el-input>
          <!-- File Storage location -->
          <div class="uploadp" style="margin-top: 20px">
            <p>{{ $t("ExternalCreateTask.FileStoragelocation") }}</p>
          </div>
          <el-select v-model="ossType" class="selectclass" value-key="id">
            <el-option
              v-for="item in ossList"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
        <div class="content_center_2" v-if="showInnerCenter == 2">
          <div class="content_center_div">
            <p>{{ $t("CommonName.TaskName") }}</p>
            <el-input :disabled="true" v-model="TaskName"></el-input>
          </div>
          <div class="content_center_div radioClass radiowidth">
            <p>{{ $t("toolset.Importoptions") }}</p>
            <el-radio-group v-model="radio">
              <el-radio
                v-for="i in radioList"
                :key="i.label"
                :label="i.label"
                border
                >{{ i.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div radioClass radiowidth1">
            <p>{{ $t("toolset.Importoptions1") }}</p>
            <el-radio-group v-model="radio1">
              <el-radio
                v-for="i in radioList1"
                :key="i.label"
                :label="i.label"
                :disabled="radio == '1' && i.label == '2'"
                border
                >{{ i.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div
            class="content_center_div radioClass radiowidth"
            v-if="radio1 == '3'"
          >
            <p>{{ $t("ProjectManagement.TextFormat") }}</p>
            <el-radio-group v-model="radio2">
              <el-radio
                v-for="i in radioList2"
                :key="i.label"
                :label="i.label"
                border
                >{{ i.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div radioClass radiowidth">
            <p>{{ $t("ProjectManagement.SubtitleFile") }}</p>
            <el-radio-group v-model="radio3" @input="radioInput">
              <el-radio
                v-for="i in radioList3"
                :key="i.label"
                :label="i.label"
                border
                >{{ i.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div" v-if="radio3 == '1'">
            <p>{{ $t("ProjectManagement.UploadSubtitleFile") }}</p>
            <el-input
              v-model="srtuploadinput"
              class="input-with-select srtUnput"
              @input="srtuploadinput = ''"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="setUploadFile"
                :with-credentials="true"
                :action="$uploadURL + '/videojob/gateway'"
                :data="uploadData"
                :on-change="uploadChange"
                :on-progress="uploadProgress"
                :on-success="uploadSuccess"
                :auto-upload="false"
                :show-file-list="false"
                :limit="1"
              >
                <el-button type="text" @click="srtupload('setUploadFile', 0)">
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
          <div class="content_center_div" v-if="radio3 == '2'">
            <p>{{ $t("CommonName.Selectproject") }}</p>
            <el-select
              v-model="select"
              class="selectclass"
              @change="selectChange"
            >
              <el-option
                v-for="item in selectList"
                :key="item.project_id"
                :label="item.project_name"
                :value="item.project_id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- Select Task in Project -->
          <div class="content_center_div" v-if="radio3 == '2'">
            <p>{{ $t("ProjectManagement.SelectTaskinProject") }}</p>
            <el-select
              v-model="select1"
              class="langselectclass"
              :disabled="select1Disabled"
              @change="select1change"
              placeholder=""
            >
              <el-option
                v-for="item in select1List"
                :key="item.job_id"
                :label="item.job_name"
                :value="item.job_file_id"
                :disabled="item.job_id == Project_info.job_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="content_center_div" v-if="radio3 == '2'">
            <p>{{ $t("ProjectManagement.SelectSubtitleFileinTask") }}</p>
            <el-input :disabled="true" v-model="select3"></el-input>
          </div>
        </div>
        <div class="content_center_3" v-if="showInnerCenter == 3">
          <div class="content_center_div">
            <!-- <p>{{ $t("CommonName.TaskName") }}</p> -->
            <div class="checkboxClass">
              <el-checkbox-group v-model="checkbox">
                <div>
                  <el-checkbox
                    v-for="i in checkboxList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                    >{{ i.name }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="content_center_1" v-if="showInnerCenter == 4">
          <!-- File Storage location -->
          <div class="uploadp" style="margin-top: 20px">
            <p>{{ $t("ExternalCreateTask.FileStoragelocation") }}</p>
          </div>
          <el-select v-model="ossType" class="selectclass" value-key="id">
            <el-option
              v-for="item in ossList"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CancelClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="SureClick"
          :loading="loading_import"
          class="setpadding23"
          >{{ innerfooterText }}
        </el-button>
      </div>
      <div
        class="progressdiv"
        v-if="percentage > 0"
        v-loading.fullscreen.lock="loading"
        element-loading-custom-class="disaplaynoneicon"
        element-loading-background="rgba(0, 0, 0, 0.3)"
      >
        <el-progress
          type="circle"
          :percentage="percentage"
          :stroke-width="10"
        ></el-progress>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  get_project_job_info,
  get_member_list,
  set_job_user,
  update_watermark_info,
  get_import_project_job_list,
  get_import_project_list,
  upload_source_srt_file,
  update_deadline_info,
  reject_job,
  get_oss_list,
  change_oss_mode,
} from "@/utils/https";
import { onShowNameTipsMouseenter } from "@/assets/js/Publicmethods";
import config from "@/config";
import { hideLoading, showLoading } from "@/utils/loading";

export default {
  computed: {
    setClass() {
      // this.Project_info.status 20 转写高亮 30 翻译高亮 40 校对高亮
      let lengthclass = "";
      if (this.dataList.length == 1) {
        lengthclass = "length1Class";
      } else if (this.dataList.length == 2) {
        lengthclass = "length2Class";
      } else if (this.dataList.length == 4) {
        lengthclass = "length4Class";
      } else {
        lengthclass = "length3Class";
      }
      return lengthclass;
    },
    // 设置 不同 数据长度 分布不用
    setClass2() {
      let lengthclass = "";
      if (this.dataList.length == 1) {
        lengthclass = "setTopWidth1 centerbody_top_left";
      } else if (this.dataList.length == 2) {
        lengthclass = "setTopWidth centerbody_top_left";
      } else if (this.dataList.length == 4) {
        lengthclass = "setTopWidth4 centerbody_top_left";
      } else {
        lengthclass = "setTopWidth3";
      }
      return lengthclass;
    },
    setClass3() {
      let lengthclass = "";
      if (this.dataList.length == 1) {
        lengthclass = "setTopWidth1 ";
      } else if (this.dataList.length == 2) {
        lengthclass = "setTopWidth";
      } else if (this.dataList.length == 4) {
        lengthclass = "setTopWidth4 ";
      } else {
        lengthclass = "setTopWidth";
      }
      return lengthclass;
    },
    // 设置 url 区分本地
    setproofurl() {
      let url = "";
      if (this.dataInfo.source_media_type == "2") {
        url =
          this.mediaUrl + "ContainerHome/ProofUrlLocal?" + this.dataInfo.proof;
      } else {
        url = this.mediaUrl + "ContainerHome/ProofUrl?" + this.dataInfo.proof;
      }
      return url;
    },
    // 设置 url 区分本地
    setproofurl1() {
      // console.log(this.dataInfo, "this.dataInfo.proof");
      let url = "";
      url =
        this.mediaUrl +
        "ContainerHome/SubmitReport?" +
        "job_id=" +
        this.dataInfo.job_id +
        "&" +
        this.dataInfo.proof;
      return url;
    },
    // 设置 url 区分本地
    setSttUrl() {
      // console.log(this.dataInfo, "this.dataInfo.proof");
      let url = "";
      url =
        this.mediaUrl +
        "ContainerHome/SubmitReport1?" +
        "mode=stt&job_id=" +
        this.dataInfo.job_id +
        "&" +
        this.dataInfo.proof;
      return url;
    },
    setSqtUrl() {
      // console.log(this.dataInfo, "this.dataInfo.proof");
      let url = "";
      url =
        this.mediaUrl +
        "ContainerHome/TranscriptionReport?" +
        "mode=sqt&job_id=" +
        this.dataInfo.job_id +
        "&" +
        this.dataInfo.proof;
      return url;
    },
    setyoutubeUrl() {
      return (
        this.mediaUrl +
        "ContainerHome/TranslationLanguageChange?job_id=" +
        this.Project_info.job_id
      );
    },
    // 筛选 任务执行人
    filteredOptions() {
      return this.dropdownList.filter((option) =>
        option.nickname.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  props: {
    Project_info: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.project_id = this.$route.query.id;
    // console.log(this.info);
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_project_job_info(this.Project_info.job_id);
    // if (this.info.role == "2") {
    this.get_member_list(2);
    this.get_oss_list();
  },
  data() {
    return {
      uploadType: 0, //判断是否支持 webm
      ahref: "",
      showButton: false, //是否显示 删除按钮
      searchText: "", //任务执行人搜索框
      dataList: [], // task 流程 数据
      dataInfo: {}, //task 详情 数据
      info: {}, // 获取用户数据
      // 修改 Waterprint 的值
      dropdownList: [],
      project_id: "",
      innerVisible: false, //内层弹窗 开关
      innerVisibleTitle: "",
      showInnerCenter: 0,
      uploadinput: "",
      percentage: 0,
      loading: false,
      uploadData: {}, //上传 所需 数据
      radio: "1", //导入方式 1替换 2合并
      radioList: [
        {
          label: "1",
          name: this.$t("ProjectManagement.Replace"),
        },
        {
          label: "2",
          name: this.$t("TMManagement.Merge"),
        },
      ],
      radio1: "1", //字幕文件类型 1：单原文  2：单译文  3：双语
      radioList1: [
        {
          label: "1",
          name: this.$t("ProjectManagement.Source"),
        },
        {
          label: "2",
          name: this.$t("ProjectManagement.Target"),
        },
        {
          label: "3",
          name: this.$t("ProjectManagement.Bilingual"),
        },
      ],
      radio2: "0", //双语文件时， 0：译文在上 1：原文在上
      radioList2: [
        {
          label: "0",
          name: this.$t("ProjectManagement.TargetFirst"),
        },
        {
          label: "1",
          name: this.$t("ProjectManagement.SourceFirst"),
        },
      ],
      radio3: "1", //	字幕文件来源： 1：上传文件；2：借调其他任务的字幕文件
      srtuploadinput: "", //	上传文件名 当来源选择1时需上传
      radioList3: [
        {
          label: "1",
          name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
        },
        {
          label: "2",
          name: this.$t("ExternalCreateTask.Sourceofmediafile.label1"),
        },
      ],
      TaskName: "",
      select: "", //借调任务id  当来源选择2时需选择 Select Project
      selectList: [],
      select1: "", //Select Media File in Project
      select1List: [],
      select3: "",
      select1Disabled: true,
      checkbox: [], //Download Subtitle Files下载 字幕 文件
      checkboxList: [],
      baseUrl: config.baseUrl.dev, //请求地址
      mediaUrl: config.mediaUrl.dev, //跳转地址
      showcollapse: false,
      innerfooterText: this.$t("CommonName.Sure"),
      time_format:
        sessionStorage.getItem("lang") == "en"
          ? "MMM dd yyyy HH:mm"
          : "yyyy-MM-dd HH:mm",
      ossList: [],
      ossType: "0",
      loading_import: false,
    };
  },
  watch: {
    radio(value) {
      if (value == "1" && this.radio1 == "2") {
        this.radio1 = "1";
      }
    },
  },
  methods: {
    claseCreateTaskDialog(data, href) {
      this.$emit("claseDialog", data);
    },
    // =======================================通用方法==========================================
    /**
     *
     * @param {* 设置 数据} item
     */
    setInfoData(item) {
      switch (item.job_type) {
        case "1":
          item.job_type_name = this.$t("ProjectDetails.Transcription");
          if (item.status > 20) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        case "2":
          item.job_type_name = this.$t("ProjectDetails.Translation");
          if (item.status > 30) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        case "3":
          item.job_type_name =
            this.$t("ProjectDetails.Transcription") +
            " > " +
            this.$t("ProjectDetails.Translation");
          if (item.status > 20) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        case "4":
          item.job_type_name =
            this.$t("ProjectDetails.Translation") +
            " > " +
            this.$t("ProjectDetails.Review");
          if (item.status > 30) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        case "6":
          item.job_type_name =
            this.$t("ProjectDetails.Transcription") +
            " > " +
            this.$t("ProjectDetails.TranscriptionQC");
          if (item.status > 20) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        case "7":
          item.job_type_name =
            this.$t("ProjectDetails.Transcription") +
            " > " +
            this.$t("ProjectDetails.TranscriptionQC") +
            " > " +
            this.$t("ProjectDetails.Translation") +
            " > " +
            this.$t("ProjectDetails.Review");
          if (item.status > 20) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
        default:
          item.job_type_name =
            this.$t("ProjectDetails.Transcription") +
            " > " +
            this.$t("ProjectDetails.Translation") +
            " > " +
            this.$t("ProjectDetails.Review");
          if (item.status > 20) {
            item.showrepulsebtn = true;
          } else {
            item.showrepulsebtn = false;
          }
          break;
      }
      switch (item.status) {
        case "10":
          item.status_name = this.$t("ProjectDetails.Pending");
          break;
        case "20":
          item.status_name = this.$t("ProjectDetails.Transcription");
          break;
        case "25":
          item.status_name = this.$t("ProjectDetails.TranscriptionQC");
          break;
        case "30":
          item.status_name = this.$t("ProjectDetails.Translation");
          break;
        case "40":
          item.status_name = this.$t("ProjectDetails.Review");
          break;
        case "50":
          item.status_name = this.$t("ProjectDetails.Delivered");
          break;
        default:
          item.status_name = this.$t("CommonName.statusName6");
          break;
      }
      // role为2显示中文role为3显示英文
      // if (this.info.role == "2") {

      // } else if (this.info.role == "3") {

      // }
      if (sessionStorage.getItem("lang") == "en") {
        item.source_langName = item.source_lang_ename;
        item.target_langName = item.target_lang_ename;
      } else {
        item.source_langName = item.source_lang_cname;
        item.target_langName = item.target_lang_cname;
      }
      return item;
    },

    /**
     *
     * @param {* 设置 task_info 数据} item
     */
    setTaskInfoData(item) {
      let url = "";
      // console.log( this.dataInfo.source_media_type);
      switch (item.workflow) {
        case 1:
          item.name = this.$t("ProjectManagement.STTname");

          if (this.dataInfo.status == "20") {
            item.class1 = "length2Class2";
          } else {
            item.class1 = "";
          }
          item.showyes = this.dataInfo.status > 20;
          if (this.dataInfo.source_media_type == "2") {
            url = "ContainerHome/OverwriteOperationLocal?";
          } else {
            url = "ContainerHome/OverwriteOperation?";
          }
          if (sessionStorage.getItem("lang") !== null) {
            item.newTaskUrl =
              this.mediaUrl +
              url +
              item.task_url +
              "&lang=" +
              sessionStorage.getItem("lang");
          } else {
            item.newTaskUrl = this.mediaUrl + url + item.task_url;
          }

          break;
        case 2:
          // console.log(this.Project_info);
          if (this.dataInfo.status == "30") {
            item.class1 = "length2Class2";
          } else {
            item.class1 = "";
          }
          item.name = this.$t("ProjectManagement.TRAname");
          item.status = this.Project_info.progress;
          if (this.dataInfo.status > 30) {
            item.showyes = true;
          } else {
            item.showyes = false;
          }
          if (this.dataInfo.source_media_type == "2") {
            url = "ContainerHome/TranslationOperationLocal?";
          } else {
            url = "ContainerHome/TranslationOperation?";
          }
          if (sessionStorage.getItem("lang") !== null) {
            item.newTaskUrl =
              this.mediaUrl +
              url +
              item.task_url +
              "&lang=" +
              sessionStorage.getItem("lang");
          } else {
            item.newTaskUrl = this.mediaUrl + url + item.task_url;
          }

          break;
        case 5:
          item.name = this.$t("ProjectManagement.STQname");
          if (this.dataInfo.status == "25") {
            item.class1 = "length2Class2";
          } else {
            item.class1 = "";
          }
          item.showyes = this.dataInfo.status > 25;
          if (this.dataInfo.source_media_type == "2") {
            url = "ContainerHome/OverwriteOperationLocal?";
          } else {
            url = "ContainerHome/OverwriteOperationNew?";
          }
          if (sessionStorage.getItem("lang") !== null) {
            item.newTaskUrl =
              this.mediaUrl +
              url +
              item.task_url +
              "&lang=" +
              sessionStorage.getItem("lang");
          } else {
            item.newTaskUrl = this.mediaUrl + url + item.task_url;
          }
          item.reportUrl =
            this.mediaUrl +
            "ContainerHome/TranscriptionReport?job_id=" +
            this.Project_info.job_id +
            "&" +
            item.task_url;
          break;
        default:
          if (this.dataInfo.status == "40") {
            item.class1 = "length2Class2";
          } else {
            item.class1 = "";
          }
          item.name = this.$t("ProjectManagement.REVname");
          if (this.dataInfo.status > 40) {
            item.showyes = true;
          } else {
            item.showyes = false;
          }

          if (this.dataInfo.source_media_type == "2") {
            url = "ContainerHome/ProofingOperationLocal?";
          } else {
            url = "ContainerHome/ProofingOperation?";
          }
          if (sessionStorage.getItem("lang") !== null) {
            item.newTaskUrl =
              this.mediaUrl +
              url +
              item.task_url +
              "&lang=" +
              sessionStorage.getItem("lang");
          } else {
            item.newTaskUrl = this.mediaUrl + url + item.task_url;
          }
          item.reportUrl =
            this.mediaUrl +
            "ContainerHome/TranslationReport?job_id=" +
            this.Project_info.job_id +
            "&" +
            item.task_url;
          break;
      }
      item.id = item.workflow + "btmp";
      item.visible = false;
      item.popoverInput = "";
      item.showdropdown = false;
      item.deadVisible = false;
      item.time = "";
    },
    /**
     *
     * @param {* 取消 按钮} data
     */
    CancelClick() {
      // if (this.showInnerCenter == 1) {
      //   this.uploadinput = "";
      // }
      this.innerVisible = false;
    },
    /**
     *
     * @param {* 关闭弹窗} data
     */
    closeinnerDialog() {
      // if (this.showInnerCenter == 1) {
      // }
    },
    /**
     *
     * @param {* 确定 按钮} data
     */
    SureClick() {
      if (this.showInnerCenter == 1) {
        if (!this.uploadinput) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        this.uploadData = {
          action: "upload_source_file",
          project_id: this.project_id,
          job_id: this.Project_info.job_id,
          file_name: this.uploadinput,
          oss_mode: this.ossType,
        };
        this.loading_import = true;
        this.$nextTick(() => {
          this.$refs.upload_media_file.submit();
        });
      }
      if (this.showInnerCenter == 2) {
        if (this.radio3 == "1") {
          if (!this.srtuploadinput) {
            this.$messages(
              "warning",
              this.$t("ExternalCreateTask.warningmessage6")
            );
            return;
          }
        } else {
          if (!this.select1) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
        }

        this.uploadData = {
          action: "upload_source_srt_file",
          project_id: this.project_id,
          job_id: this.Project_info.job_id,
          file_mode_type: this.radio1 == "3" ? this.radio2 : "",
          option_type: this.radio1,
          srt_import_type: this.radio,
          source_srt_type: this.radio3,
          source_file_id: this.radio3 == "2" ? this.select1 : "",
          show_name: this.radio3 == "2" ? "" : this.srtuploadinput,
        };
        this.loading_import = true;
        if (this.radio3 == "1") {
          // console.log(this.uploadData);
          this.$nextTick(() => {
            this.$refs.setUploadFile.submit();
          });
        } else {
          showLoading(this.$t("CommonName.loadingmessage"));
          this.uploadData.file = "";
          this.upload_source_srt_file(this.uploadData);
        }
      }
      if (this.showInnerCenter == 3) {
        let arr1 = JSON.parse(JSON.stringify(this.checkbox));
        if (arr1.length == 0) {
          return false;
        }

        let arr = ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"];
        let obj = {
          project_id: this.project_id,
          job_id: this.Project_info.job_id,
        };
        if (this.checkbox.length > 1) {
          obj.is_zip = "1";
        } else {
          obj.is_zip = "0";
        }
        if (
          this.Project_info.job_type == "1" ||
          this.Project_info.job_type == "6"
        ) {
          if (arr1.includes("1")) {
            arr[0] = "1";
          } else {
            arr[0] = "0";
          }
          if (arr1.includes("2")) {
            arr[7] = "1";
          } else {
            arr[7] = "0";
          }
          if (arr1.includes("3")) {
            arr[2] = "1";
          } else {
            arr[2] = "0";
          }
          if (arr1.includes("4")) {
            arr[6] = "1";
          } else {
            arr[6] = "0";
          }
          if (arr1.includes("10")) {
            arr[9] = "1";
          } else {
            arr[9] = "0";
          }
        } else {
          if (arr1.includes("1")) {
            arr[0] = "1";
          } else {
            arr[0] = "0";
          }
          if (arr1.includes("2")) {
            arr[7] = "1";
          } else {
            arr[7] = "0";
          }
          if (arr1.includes("3")) {
            arr[2] = "1";
          } else {
            arr[2] = "0";
          }
          if (arr1.includes("4")) {
            arr[6] = "1";
          } else {
            arr[6] = "0";
          }
          if (arr1.includes("5")) {
            arr[1] = "1";
          } else {
            arr[1] = "0";
          }
          if (arr1.includes("6")) {
            arr[8] = "1";
          } else {
            arr[8] = "0";
          }
          if (arr1.includes("7")) {
            arr[3] = "1";
          } else {
            arr[3] = "0";
          }
          if (arr1.includes("8")) {
            arr[4] = "1";
          } else {
            arr[4] = "0";
          }
          if (arr1.includes("9")) {
            arr[5] = "1";
          } else {
            arr[5] = "0";
          }
          if (arr1.includes("10")) {
            arr[9] = "1";
          } else {
            arr[9] = "0";
          }
        }
        obj.download_type_str = arr.join(",");
        this.download_str_file(obj);
      }
      if (this.showInnerCenter == 4) {
        showLoading(this.$t("CommonName.loadingmessage"));
        change_oss_mode({
          action: "change_oss_mode",
          job_id: this.Project_info.job_id,
          oss_mode: this.ossType,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_project_job_info(this.Project_info.job_id).then(() => {
            this.$messages("success", this.$t("CommonName.successmessage6"));
            this.innerVisible = false;
          });
          hideLoading();
        });
      }
    },
    // =======================================请求数据 方法=====================================
    /**
     *
     * @param {* 获取数据} id
     */
    get_project_job_info(id) {
      return get_project_job_info({
        action: "get_job_info",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }

        function func(a, b) {
          return a.workflow - b.workflow;
        }

        // console.log(res.info);
        // res.info.task_info = res.info.task_info.sort(func);
        this.dataInfo = this.setInfoData(res.info.base_info);
        res.info.task_info.forEach((i) => {
          i = this.setTaskInfoData(i);
        });
        if (res.info.task_info.length == 1) {
          res.info.task_info[0].showicon = false;
        } else if (res.info.task_info.length == 2) {
          res.info.task_info[0].showicon = true;
        } else if (res.info.task_info.length == 4) {
          res.info.task_info[0].showicon = true;
          res.info.task_info[1].showicon = true;
          res.info.task_info[2].showicon = true;
        } else {
          res.info.task_info[0].showicon = true;
          res.info.task_info[1].showicon = true;
        }
        // console.log(res.info.task_info);
        this.dataList = res.info.task_info;
        hideLoading();
      });
    },

    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dropdownList = res.list;
      });
    },
    /**
     *
     * @param {*} data
     */
    set_job_user(item) {
      return set_job_user({
        action: "set_job_user",
        job_id: item.job_id, //任务id
        type: item.type, //任务流程，1STT，2TRA，3REV
        user_id: item.user_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_job_info(item.job_id);
        this.$emit("ProjectDetailsgetData");
      });
    },
    /**
     *
     * @param {*} item
     */
    update_watermark_info(item) {
      return update_watermark_info({
        action: "update_watermark_info",
        project_id: item.project_id,
        job_id: item.job_id,
        work_flow: item.work_flow, //任务流程，1：STT，2：TRA，3：REV
        watermark: item.watermark,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_job_info(item.job_id);
      });
    },
    /**
     *
     * @param {*获取可以导入的项目列表信息} data
     */
    get_import_project_list() {
      get_import_project_list({
        action: "get_import_project_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.selectList = res.list;
      });
    },
    /**
     *
     * @param {*获取项目下可以导入的任务列表信息} id
     */
    get_import_project_job_list(id) {
      get_import_project_job_list({
        action: "get_import_project_job_list",
        project_id: id, //项目id
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select1List = res.list;
      });
    },
    /**
     *
     * @param {*替换字幕文件} item
     */
    upload_source_srt_file(item) {
      upload_source_srt_file(item).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_job_info(this.Project_info.job_id).then(() => {
          this.$messages("success", this.$t("CommonName.successmessage4"));
          this.loading_import = false;
          this.$emit("ProjectDetailsgetData");
          this.innerVisible = false;
        });
      });
    },
    /**
     *
     * @param {*下载字幕文件(集合版)} item
     */
    download_str_file(item) {
      let url = this.baseUrl + "/videojob/download_subtitle_file?";
      url += "project_id=" + item.project_id;
      url += "&job_id=" + item.job_id;
      url += "&is_zip=" + item.is_zip;
      url += "&download_type_str=" + item.download_type_str;
      url += "&time=" + new Date().getTime();
      window.open(url);
      this.innerVisible = false;
    },
    /**
     *
     * @param {*打回任务至上一节点} id
     */
    reject_job(id) {
      reject_job({
        action: "reject_job",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_job_info(id).then(() => {
          this.$emit("ProjectDetailsgetData");
        });
      });
    },
    // =======================================上传方法=====================================
    /**
     *
     * @param {*上传视频 改变视频} file
     */
    uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (this.showInnerCenter == 1) {
        let arr = [];
        if (this.uploadType == 1) {
          arr = [
            "aac",
            "ac3",
            "ape",
            "flac",
            "m4a",
            "mp3",
            "ogg",
            "wav",
            "wma",
            "mp4",
            "webm",
          ];
        } else {
          arr = [
            "aac",
            "ac3",
            "ape",
            "flac",
            "m4a",
            "mp3",
            "ogg",
            "wav",
            "wma",
            "mp4",
          ];
        }

        let extension = testmsg.toLowerCase();
        const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
        if (!arr.includes(extension)) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage2")
          );
          this.uploadinput = "";
          this.$refs.upload_media_file.clearFiles();
          return false;
        } else if (!isLt2M) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage3")
          );
          this.uploadinput = "";
          this.$refs.upload_media_file.clearFiles();
          return false;
        } else {
          this.uploadinput = file.name;
          return true;
        }
      }
      if (this.showInnerCenter == 2) {
        let extension = testmsg.toLowerCase() === "srt";
        if (!extension) {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage1")
          );
          this.srtuploadinput = "";
          this.$refs.setUploadFile.clearFiles();
          return false;
        } else {
          this.srtuploadinput = file.name;
          return true;
        }
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      if (file.percent == 100) {
        this.percentage = 0;
        this.loading = false;
        showLoading(this.$t("CommonName.warningmessage6"));
      }
    },
    /**
     *
     * @param {*上传成功} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      if (response.result == "SUCCESS") {
        if (this.showInnerCenter == 2) {
          this.get_project_job_info(this.Project_info.job_id).then(() => {
            this.$messages("success", this.$t("CommonName.successmessage4"));
            this.$emit("ProjectDetailsgetData");
            this.loading_import = false;
            this.innerVisible = false;
          });
        }
        if (this.showInnerCenter == 1) {
          this.get_project_job_info(this.Project_info.job_id).then(() => {
            this.loading_import = false;
            this.$messages("success", this.$t("CommonName.successmessage6"));
            this.innerVisible = false;
          });
          hideLoading();
        }
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("error", response.msg);
        this.innerVisible = false;
      }
    },
    /**
     *
     * @param {* 清空已上传文件} refs
     */
    srtupload(refs, data) {
      this.uploadType = data;
      if (this.showInnerCenter == 2) {
        this.srtuploadinput = "";
      } else {
        this.uploadinput = "";
      }

      let uploadFilesArr = this.$refs[refs].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs[refs].uploadFiles = [];
      }
    },
    // =======================================其他方法=====================================
    /**
     *
     * @param {点击复制链接} url
     */
    copyClick(url, data) {
      // data == -1 复制 链接 2 链接跳转 操作页面 3 复制 信息
      // console.log(url);
      if (data == "-1") {
        let input = document.createElement("input"); // js创建一个input输入框
        input.value = url; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      } else if (data == "2") {
        let newUrl = "";
        // this.$uploadURL
        if (this.dataInfo.status !== "10") {
          switch (url.workflow) {
            case 1:
              newUrl = "/ContainerHome/OverwriteOperation?" + url.task_url;
              break;
            case 2:
              newUrl = "/ContainerHome/TranslationOperation?" + url.task_url;
              break;
            case 3:
              newUrl = "/ContainerHome/ProofingOperation?" + url.task_url;
              break;
            default:
              break;
          }
          let pathInfo = this.$router.resolve({
            path: newUrl,
          });
          window.open(pathInfo.href, "_blank");
        } else {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage5")
          );
        }
      } else {
        let input = document.createElement("textarea"); // js创建一个input输入框
        input.value =
          this.$t("CommonName.TaskName") +
          " : " +
          this.dataInfo.job_name +
          "\n" +
          this.$t("CommonName.WorkflowNode") +
          " : " +
          url.name +
          "\n" +
          this.$t("ProjectManagement.TaskURL") +
          " : " +
          url.newTaskUrl +
          "\n" +
          this.$t("OverwriteOperation.verif_text6") +
          " : " +
          url.check_code; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      }
    },

    /**
     *
     * @param {* 点击 修改 水印} item
     */
    searchdetermineClick(item) {
      let obj = {};
      obj.project_id = this.project_id;
      obj.job_id = this.Project_info.job_id;
      obj.work_flow = item.workflow;
      obj.watermark = item.popoverInput;
      if (item.popoverInput.trim() == "") {
        return false;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      this.update_watermark_info(obj).then(() => {
        item.visible = false;
      });
    },
    handleSearch() {},
    /**
     *
     * @param { 点击删除 指派人} item
     */
    handleloseClick(item) {
      let obj = {};
      obj.user_id = "0";
      obj.type = item.workflow;
      obj.job_id = this.Project_info.job_id;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.set_job_user(obj).then(() => {
        item.nickname = "";
      });
    },
    /**
     *
     * @param {*修改执行人} command
     */
    handleCommand(command) {
      if (command == "-1") {
        return;
      }
      let obj = {};
      obj.user_id = command.id;
      obj.type = command.item.workflow;
      obj.job_id = this.Project_info.job_id;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.set_job_user(obj);
    },
    /**
     *
     * @param {*下拉菜单 出现 清空输入框内容} val
     */
    visibleChange(val) {
      if (val) {
        this.searchText = "";
      }
    },
    /**
     *
     * @param {* 弹出框 方法 切换图标} val
     */
    handleChange(val) {
      // console.log(val);
      this.showcollapse = !this.showcollapse;
    },
    /**
     *
     * @param {*鼠标划上  显示 未显示的文字} item
     */
    onShowNameTipsMouseenter(item) {
      // console.log(item);
      onShowNameTipsMouseenter(item);
    },
    /**
     * 通过选择Select Project  显示Select Task in Project
     */
    selectChange() {
      this.select1Disabled = false;
      this.select1 = "";
      this.select3 = "";
      if (this.select) {
        this.get_import_project_job_list(this.select);
      }
    },
    /**
     *
     * @param {* 选择 Select Subtitle File in Task 的值} value
     */
    select1change(value) {
      this.select1List.forEach((i) => {
        if (i.job_file_id == value) {
          this.select3 = i.srt_name;
        }
      });
    },
    /**
     *
     * @param {* 字幕文件来源： 1：上传文件；2：借调其他任务的字幕文件} label
     */
    radioInput(label) {
      // console.log(label);
      if (label == "2") {
        this.get_import_project_list();
      }
    },
    /**
     *
     * @param {*点击更换视频文件  按钮  } data
     */
    changeVideo(data) {
      // data 1.Change Source 修改视频
      this.showInnerCenter = data;
      this.uploadData = {};
      switch (data) {
        case 1:
          this.uploadinput = "";
          this.innerVisible = true;
          this.innerVisibleTitle = this.$t(
            "ProjectManagement.Replacevideofile"
          );
          this.innerfooterText = this.$t("ProjectManagement.confirm");
          this.ossType = this.dataInfo.oss_mode;
          break;
        case 2:
          this.TaskName = this.dataInfo.job_name;
          this.radio = "1";
          this.radio1 = "1";
          this.radio2 = "0";
          this.radio3 = "1";
          this.srtuploadinput = "";
          this.select = "";
          this.select1 = "";
          this.select3 = "";
          this.select1Disabled = true;
          this.innerVisible = true;
          this.innerVisibleTitle = this.$t("ProjectManagement.ImportSRTname");
          this.innerfooterText = this.$t("CommonName.Sure");
          break;
        case 3:
          if (
            this.Project_info.job_type !== "1" &&
            this.Project_info.job_type !== "6"
          ) {
            this.checkboxList = [
              { id: "1", name: this.$t("ProjectManagement.SourceTXT") },
              { id: "2", name: this.$t("ProjectManagement.SourceDOCX") },
              { id: "3", name: this.$t("ProjectManagement.SourceSRT") },
              { id: "4", name: this.$t("ProjectManagement.SourceChangeSRT") },
              { id: "5", name: this.$t("ProjectManagement.TargetTXT") },
              { id: "6", name: this.$t("ProjectManagement.TargetTTML") },
              { id: "7", name: this.$t("ProjectManagement.TargetSRT") },
              { id: "8", name: this.$t("ProjectManagement.BilingualSRT1") },
              { id: "9", name: this.$t("ProjectManagement.BilingualSRT2") },
              { id: "10", name: this.$t("ProjectManagement.HuaSRT2") },
            ];
            if (this.dataInfo.status > 25) {
              this.checkbox = ["7"];
            } else {
              this.checkbox = ["3"];
            }
          } else {
            this.checkboxList = [
              { id: "1", name: this.$t("ProjectManagement.SourceTXT") },
              { id: "2", name: this.$t("ProjectManagement.SourceDOCX") },
              { id: "3", name: this.$t("ProjectManagement.SourceSRT") },
              { id: "4", name: this.$t("ProjectManagement.SourceChangeSRT") },
              { id: "10", name: this.$t("ProjectManagement.HuaSRT2") },
            ];
            this.checkbox = ["3"];
          }
          this.innerVisible = true;
          this.innerVisibleTitle = this.$t(
            "ProjectManagement.DownloadSubtitleFiles"
          );
          this.innerfooterText = this.$t("CommonName.download");
          break;
        case 4:
          this.innerVisible = true;
          this.innerVisibleTitle = this.$t(
            "ProjectManagement.ReplaceOssModeTitle"
          );
          this.innerfooterText = this.$t("ProjectManagement.confirm");
          this.ossType = this.dataInfo.oss_mode;
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {*打回任务至上一节点} data
     */
    rejectjob() {
      showLoading(this.$t("CommonName.loadingmessage"));
      this.reject_job(this.Project_info.job_id);
    },
    /**
     *
     * @param {* 点击 修改 截止日期} item
     */
    deadlineClick(item) {
      let obj = {};
      obj.project_id = this.project_id;
      obj.job_id = this.Project_info.job_id;
      obj.work_flow = item.workflow;
      obj.deadline = item.time;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.update_deadline_info(obj).then(() => {
        item.deadVisible = false;
      });
    },
    /**
     *
     * @param {*} item
     */
    update_deadline_info(item) {
      return update_deadline_info({
        action: "update_deadline_info",
        project_id: item.project_id,
        job_id: item.job_id,
        work_flow: item.work_flow, //任务流程，1：STT，2：TRA，3：REV
        deadline: item.deadline,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_job_info(item.job_id);
      });
    },
    /**
     *
     * @param {*获取OSS节点列表信息} data
     */
    get_oss_list() {
      get_oss_list({
        action: "get_oss_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.ossList = res.list;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/dialogStyle.scss";

.center {
  margin: 24px;

  .center_top {
    display: flex;

    .length1Class {
      width: 976px;
      // height: 245px;
      background-color: rgba(69, 71, 88, 0.29);
      border: 1px solid;
      border-color: #515464;
      border-radius: 4px;
    }

    .length2Class {
      width: 477px;
      height: 317px;
      background-color: #3d3f50;
      border: 1px solid;
      border-color: #515464;
      border-radius: 4px;
    }

    .length2Class2 {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #454758 !important;
    }

    .length3Class {
      width: 309px;
      height: 370px;
      background-color: #3d3f50;
      border: 1px solid;
      border-color: #515464;
      border-radius: 4px;
    }

    .length4Class {
      width: 225px;
      height: 420px;
      background-color: #3d3f50;
      border: 1px solid;
      border-color: #515464;
      border-radius: 4px;
    }

    .legth1Top {
      display: flex;
      align-items: center;

      .icons {
        margin: 0 5px;
        font-size: 12px;
        color: #515464;
      }

      .title {
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        border-color: #515464;

        .titleleft {
          display: flex;
          align-items: center;

          font-size: 14px;

          .titlep {
            padding-left: 14px;
            color: #ffffff;
          }

          .icon {
            color: #b1b2b9;
            margin-left: 17px;
          }

          .statusno0 {
            color: #b1ff64;
            font-family: TitilliumWeb-SemiBold;
            margin-left: 6px;
          }
        }

        .titleright {
          .textBtn {
            display: flex;
            align-items: center;
            color: #ffb92e;
            margin-right: 20px;

            .icon {
              color: #ffb92e;
              font-size: 16px;
              margin-right: 6px;
            }
          }
        }
      }

      .centerbody {
        margin-top: 21px;
        margin-bottom: 21px;

        .centerbody_top {
          display: flex;
          flex-wrap: wrap;

          margin-left: 14px;

          .centerbody_top_left {
            display: flex;
            flex-wrap: wrap;
          }

          .centerbody_top_left2 {
            margin-bottom: 16px;
          }

          .centerbody_top_left1 {
            margin-top: 10px;
            // margin-bottom: 16px;
          }

          .setTopWidth {
            width: 100%;
            margin-bottom: 10px;

            div {
              width: 50%;
            }
          }
          .setTopWidth4 {
            width: 100%;
            margin-bottom: 10px;
          }
          .setTopWidth4 > div {
            width: 100%;
          }
          .setTopWidth3 {
            width: 100%;
            margin-bottom: 16px;
          }

          .setTopWidth1 {
            width: 50%;
          }
          .setTopWidth1 > div {
            width: 33.3%;
            div {
              width: 50%;
            }
          }

          p {
            font-family: TitilliumWeb-SemiBold;
            color: #adb0bc;
            font-size: 12px;
            // margin-bottom: 4px;
          }

          .btmp {
            color: #ffffff;
            margin: 0;
            margin-top: 4px;
            padding-right: 3px;
          }

          .iconp {
            display: flex;
            align-items: center;
            width: 100% !important;

            .icon {
              font-size: 16px;
              margin-left: 6px;
              color: #adb0bc;
              cursor: pointer;
            }

            .el-dropdown {
              width: 16px !important;
            }
          }
        }

        .setFlex {
          flex-direction: column;
          // padding-bottom: 16px;
          div {
            margin-top: 6px;
            width: 100%;
          }
        }

        .setFlex1 {
          flex-direction: row;
          // padding-bottom: 30px;
          div {
            margin-top: 16px;
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .centerbody_btm {
          display: flex;
          padding-left: 14px;

          p {
            color: #adb0bc;
            font-size: 12px;
            margin-bottom: 4px;
          }

          a {
            font-size: 10px;
          }

          .TaskURL_div {
            display: flex;
            justify-content: space-between;
            width: 97%;

            a {
              width: 80%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .icon {
              font-size: 16px;
              color: #53b3ff;
              cursor: pointer;
            }
          }
        }
      }

      .footrtbtn {
        border-top: 1px solid;
        border-color: #515464;
        // height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btnClass {
          display: flex;
          align-items: center;

          p {
            margin-left: 6px;
          }

          .icon {
            font-size: 16px;
          }
        }
      }
    }
  }

  .center_btm {
    ::v-deep .el-collapse-item__header {
      background: none !important;
      border-bottom: none !important;
      height: auto !important;
      line-height: normal !important;
      width: 975px !important;
    }

    .el-collapse {
      border-top: none !important;
      border-bottom: none !important;
    }

    ::v-deep .el-collapse-item__content {
      padding-bottom: 0 !important;
    }

    ::v-deep .el-collapse-item__wrap {
      border-bottom: none !important;
      width: 975px !important;
      box-sizing: border-box !important;
    }

    ::v-deep .el-collapse-item__arrow {
      color: #46adff !important;
      font-size: 16px !important;
      display: none;
    }

    .legth1btm_top {
      display: flex;
      height: 48px;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .legth1btm_top_right {
        .el-button {
          color: #ffb92e !important;
        }
      }

      .tableDiv_right1 {
        width: 467px;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border: 1px solid;
        border-color: #515464;

        .tableDiv_left {
          width: 167px;
          height: 100%;
          background-color: #454758;
          line-height: 40px;
          padding-left: 10px;
          color: #adb0bc;
        }

        .rightp1 {
          width: 300px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;

          .rightp1_p {
            color: #ffffff;
          }
        }
      }

      .titlep {
        margin: 0 40px 0 8px;
        color: #adb0bc;
        font-size: 14px;
      }

      .Splitline {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      .btnClass {
        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .el-button {
        padding: 0;
      }

      .btnClass {
        display: flex;
        align-items: center;

        p {
          margin-left: 6px;
        }
      }
    }

    .legth1btm_btm {
      p {
        font-size: 14px;
      }

      width: 975px;
      box-sizing: border-box !important;
      border: 1px solid #515464;
      background-color: #393c4e;

      .tableDiv {
        height: 40px;
        width: 975px;
        box-sizing: border-box !important;
        display: flex;
        align-items: center;
        border-bottom: 1px solid;
        border-color: #515464;

        .tableDiv_left {
          width: 177px;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: #454758;

          p {
            color: #adb0bc;
            font-size: 14px;
            margin-left: 12px;
          }
        }

        .tableDiv_right {
          width: 800px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            margin-left: 12px;
            font-size: 14px;
          }

          .titlep {
            color: #ffffff;
            margin-left: 12px;
          }

          .title_p {
            width: 600px;
          }

          .btnClass {
            display: flex;
            align-items: center;
            margin-right: 12px;

            .icon {
              font-size: 16px;
              margin-right: 6px;
            }
          }
        }

        .tableDiv_right1 {
          display: flex;
          align-items: center;
          height: 100%;

          .rightp {
            width: 310px;
            color: #ffffff;

            p {
              margin-left: 12px;
            }
          }

          .rightp1 {
            color: #ffffff;

            p {
              margin-left: 12px;
            }
          }

          .rightp2 {
            width: 310px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .svgbtn {
            margin-right: 12px;
            color: #ffb92e;
          }
        }
      }
    }
  }
}

a {
  color: #53b3ff;
  text-decoration: none;
}

.popoverClasss {
  .popoverInputTextarea {
    ::v-deep .el-textarea__inner {
      background: none !important;
      border: 1px solid #7d7f8b !important;
      height: 100px !important;
      padding: 5px !important;
      overflow-y: auto !important;

      &:focus {
        background: none !important;
        color: #ffffff !important;
      }
    }
  }
}

// 二次 弹窗 内部
.content_center {
  margin: 24px 24px 24px 24px;

  .content_center_1 {
    .uploadp {
      display: flex;
      align-items: center;
      color: #adb0bc;
      margin-bottom: 6px;

      .icon {
        color: #adb0bc;
        margin-left: 6px;
      }
    }
  }

  .srtUnput {
    ::v-deep .el-input__inner {
      width: 892px !important;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
      width: 84px;
      background: none;
      border: 1px solid #7d7f8b;
      border-left: none;
      text-align: center;
      padding: 0;
    }

    // .srtUnput {
    //   margin-top: 6px;
    // }
  }

  .content_center_2 {
    .radiowidth {
      .el-radio.is-bordered {
        width: 476px;
      }
    }

    .radiowidth1 {
      .el-radio.is-bordered {
        width: 310px;
      }
    }

    .content_center_div {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .selectclass {
        ::v-deep .el-input__inner {
          width: 476px !important;
        }
      }

      ::v-deep .el-input__icon {
        line-height: 32px;
      }

      .langselectclass {
        width: 100%;

        ::v-deep .el-input__inner {
          width: 100% !important;
        }
      }

      ::v-deep .el-input__inner {
        width: 100% !important;
      }

      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        justify-content: space-between;
      }

      margin-bottom: 20px;
    }
  }

  .content_center_3 {
    .content_center_div {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .checkboxClass {
        margin-top: 0px;

        .el-button + .el-button,
        .el-checkbox.is-bordered + .el-checkbox.is-bordered {
          margin-left: 0px;
        }
      }

      .checkboxClass .el-checkbox.is-bordered {
        width: 476px !important;
        margin-bottom: 6px;
      }
    }
  }
}

.DeadlineClass {
  ::v-deep .el-input__inner {
    width: 100%;
  }
}

.iconp_input {
  ::v-deep .el-input__inner {
    width: auto !important;
    margin: 0 5px 8px 5px;
  }
}

.nickname_div {
  display: flex;
  align-items: center;

  ::v-deep .el-button {
    padding: 0 !important;
    margin-top: 4px;
  }

  .close_i {
    color: #c0c4cc;
  }
}
</style>
