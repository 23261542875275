/*
 * @Description:二次封装 el-message
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:29:50
 * @LastEditors: Carful
 * @LastEditTime: 2023-01-05 08:41:43
 */
import { Message } from "element-ui";
import "./messageCss.scss";

export function alertOK(msg, title, duration = 2000, offset = 60) {
  let customClass = "";
  if (msg === "warning") {
    customClass = "warningMessage";
  } else if (msg === "success") {
    customClass = "successMessage";
  } else if (msg === "error") {
    customClass = "errorMessage";
  }
  Message({
    type: msg,
    message: title,
    duration: duration,
    offset: offset,
    customClass: customClass,
  });
}

export default alertOK;
