/*
 * @Description: 配置文件 其他资源
 * @version:
 * @Author: Tom
 * @Date: 2024-05-24 10:26:01
 * @LastEditors: Tom
 * @LastEditTime: 2024-07-13 11:08:08
 */
export default {
  /**
   * 字体样式集
   * */
  fontFamilyList: [
    {
      name: "fontFamilyText1", //TitilliumWeb-SemiBold
      value: "TitilliumWeb-SemiBold",
    },
    {
      name: "fontFamilyText2", //思源宋体
      value: "SourceHanSerifCN-SemiBold",
    },
    {
      name: "fontFamilyText3", //思源黑体
      value: "SourceHanSansCN-Normal",
    },
    {
      name: "fontFamilyText4", //Roboto-Regular
      value: "Roboto-Regular",
    },
  ], //字体列表
  fontSizeList: [
    {
      value: "12",
    },
    {
      value: "13",
    },
    {
      value: "14",
    },
    {
      value: "15",
    },
    {
      value: "16",
    },
    {
      value: "17",
    },
    {
      value: "18",
    },
    {
      value: "19",
    },
    {
      value: "20",
    },
    {
      value: "22",
    },
    {
      value: "24",
    },
    {
      value: "32",
    },
    {
      value: "48",
    },
    {
      value: "60",
    },
  ], //字符大小列表
  letterSpacingList: [
    {
      id: "0",
      value: "0",
    },
    {
      id: "1",
      value: "0.1",
    },
    {
      id: "2",
      value: "0.2",
    },
    {
      id: "3",
      value: "0.4",
    },
    {
      id: "4",
      value: "0.6",
    },
    {
      id: "5",
      value: "1",
    },
    {
      id: "6",
      value: "2",
    },
  ], //字符间距列表
  predefineColors: [
    "#000000",
    "#ffffff",
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#90ee90",
    "#00ced1",
    "#1e90ff",
    "#c71585",
    "rgba(255, 69, 0, 0.68)",
    "rgb(255, 120, 0)",
    "hsv(51, 100, 98)",
    "hsva(120, 40, 94, 0.5)",
    "hsl(181, 100%, 37%)",
    "hsla(209, 100%, 56%, 0.73)",
  ], //颜色默认
};
